var __jsx = React.createElement;
import { styled as _styled } from "../../../libraries/stitches.library.ts";
import React from "react";
import styles from "./Spacer.styles";

var _TwComponent = _styled("div", {});

var Spacer = function Spacer(props) {
  return __jsx(_TwComponent, {
    css: styles.root(props)
  });
};

Spacer.displayName = "Spacer";
export default Spacer;