import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread(_objectSpread({}, {
    "borderRadius": "9999px"
  }), {}, {
    fontFamily: "$tenor",
    color: "$white",
    variants: {
      color: {
        success: {
          backgroundColor: "$success"
        },
        info: {
          backgroundColor: "$information"
        },
        warning: {
          backgroundColor: "$warning"
        },
        danger: {
          backgroundColor: "$danger"
        },
        white: {
          backgroundColor: "white",
          color: "$gray9"
        },
        black: {
          backgroundColor: "$black"
        },
        gray: {
          backgroundColor: "$gray6"
        },
        "default": {
          backgroundColor: "$default"
        }
      },
      size: {
        sm: _objectSpread(_objectSpread({}, {
          "paddingLeft": "0.375rem",
          "paddingRight": "0.375rem",
          "paddingTop": "0px",
          "paddingBottom": "0px"
        }), {}, {
          fontSize: "$2"
        }),
        md: _objectSpread({}, {
          "paddingLeft": "0.75rem",
          "paddingRight": "0.75rem",
          "paddingTop": "0.25rem",
          "paddingBottom": "0.25rem"
        }),
        lg: _objectSpread(_objectSpread({}, {
          "paddingLeft": "1rem",
          "paddingRight": "1rem",
          "paddingTop": "0.375rem",
          "paddingBottom": "0.375rem"
        }), {}, {
          fontSize: "$4"
        })
      }
    }
  }))
};
export default styles;