var __jsx = React.createElement;
import { XMarkIcon } from "@heroicons/react/24/outline";
import isFunction from "lodash/isFunction";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { Button, Input } from "@/components/ui";
import useCheckout from "@/hooks/useCheckout.hook";
import styles from "./PromoCode.styles";

var PromoCodeForm = function PromoCodeForm(_ref) {
  var _checkout$giftCards$;

  var onSubmit = _ref.onSubmit,
      onRemove = _ref.onRemove,
      formDisabled = _ref.formDisabled;
  var t = useIntl();

  var _useCheckout = useCheckout(),
      checkout = _useCheckout.checkout,
      loading = _useCheckout.loading,
      submitting = _useCheckout.submitting,
      activePublicPromoCode = _useCheckout.activePublicPromoCode;

  var _useFormContext = useFormContext(),
      watch = _useFormContext.watch,
      setValue = _useFormContext.setValue;

  var promoCode = watch("promoCode");
  var activePromoCode = checkout === null || checkout === void 0 ? void 0 : checkout.voucherCode;
  var activeGiftCardCode = checkout === null || checkout === void 0 || (_checkout$giftCards$ = checkout.giftCards[0]) === null || _checkout$giftCards$ === void 0 ? void 0 : _checkout$giftCards$.code;

  var handleSubmit = function handleSubmit(event) {
    event.preventDefault();
    onSubmit();
  };

  React.useEffect(function () {
    if (promoCode) {
      var promoCodeUpperCase = promoCode.toUpperCase();

      if (promoCodeUpperCase !== promoCode) {
        setValue("promoCode", promoCodeUpperCase);
      }
    }
  }, [promoCode, setValue]);
  return __jsx("form", {
    className: styles.form(),
    onSubmit: handleSubmit
  }, __jsx("div", {
    className: styles.inputContainer()
  }, __jsx(Input, {
    defaultOnEnter: true,
    disabled: loading || submitting || !(checkout !== null && checkout !== void 0 && checkout.id),
    name: "promoCode",
    type: "text",
    options: {
      disabled: loading || submitting
    },
    placeholder: t.formatMessage({
      defaultMessage: "Enter code",
      id: "promoCode.form.placeholder"
    }),
    error: {
      maxLength: 50,
      placement: "top"
    },
    className: styles.input()
  }), isFunction(onRemove) && (activePublicPromoCode && activePublicPromoCode !== null && activePublicPromoCode !== void 0 && activePublicPromoCode.canBeRemoved || !activePublicPromoCode) && (!!activePromoCode || !!activeGiftCardCode) ? __jsx("div", {
    className: styles.inputButtonContainer()
  }, __jsx(Button, {
    "aria-label": t.formatMessage({
      id: "common.close",
      defaultMessage: "close"
    }),
    disabled: loading || submitting || formDisabled,
    onClick: onRemove,
    variant: "naked",
    labelSize: "sm",
    color: "dark"
  }, __jsx(XMarkIcon, {
    width: 16,
    height: 16
  }))) : null), __jsx(Button, {
    type: "submit",
    disabled: loading || submitting || !activePromoCode && !promoCode || !activeGiftCardCode && !promoCode || !!activePromoCode || !!activeGiftCardCode || !(checkout !== null && checkout !== void 0 && checkout.id) || formDisabled,
    color: "flavor",
    variant: "oval",
    labelSize: "sm",
    className: styles.button()
  }, t.formatMessage({
    defaultMessage: "Add",
    id: "common.add"
  })));
};

export default PromoCodeForm;