import { createIntl, createIntlCache } from "react-intl";
import { DEFAULT_CHANNEL, DEFAULT_LOCALE } from "@/config/region.config";
import { messages } from "@/helpers/translation.helpers";
import { pagesPath } from "~/src/$path";
export var getTranslatedMessage = function getTranslatedMessage(locale) {
  var cache = createIntlCache();
  var t = createIntl({
    locale: locale,
    messages: messages[locale]
  }, cache);
  return {
    message: t.formatMessage({
      id: "notify.order-created.message",
      defaultMessage: "Ktoś właśnie dokonał zakupu!"
    })
  };
};
export var mapSupportedPageForNotification = function mapSupportedPageForNotification(supportedPages) {
  switch (supportedPages) {
    case "ABOUT_US":
      return {
        pathname: pagesPath._channel(DEFAULT_CHANNEL.slug)._locale(DEFAULT_LOCALE).about_us.$url().pathname
      };

    case "ACCOUNT_PROFILE":
      return {
        pathname: pagesPath._channel(DEFAULT_CHANNEL.slug)._locale(DEFAULT_LOCALE).account.profile.$url().pathname
      };

    case "ACCOUNT_ORDERS":
      return {
        pathname: pagesPath._channel(DEFAULT_CHANNEL.slug)._locale(DEFAULT_LOCALE).account.orders.$url().pathname
      };

    case "CHECKOUT":
      return {
        pathname: pagesPath._channel(DEFAULT_CHANNEL.slug)._locale(DEFAULT_LOCALE).checkout.$url().pathname
      };

    case "LOGIN":
      return {
        pathname: pagesPath._channel(DEFAULT_CHANNEL.slug)._locale(DEFAULT_LOCALE).login.$url().pathname
      };

    case "ORDER":
      return {
        pathname: pagesPath._channel(DEFAULT_CHANNEL.slug)._locale(DEFAULT_LOCALE).order._id("").$url().pathname
      };

    case "ALL_PRODUCTS":
      return {
        pathname: pagesPath._channel(DEFAULT_CHANNEL.slug)._locale(DEFAULT_LOCALE)._slug("").$url().pathname,
        position: {
          placement: "bottom",
          justify: "end",
          witOffset: true
        },
        style: {
          size: "half"
        },
        timeout: 10000
      };

    case "REGISTER":
      return {
        pathname: pagesPath._channel(DEFAULT_CHANNEL.slug)._locale(DEFAULT_LOCALE).register.$url().pathname
      };

    case "HOME":
      return {
        pathname: pagesPath._channel(DEFAULT_CHANNEL.slug)._locale(DEFAULT_LOCALE).$url().pathname
      };

    default:
      return {
        pathname: pagesPath._channel(DEFAULT_CHANNEL.slug)._locale(DEFAULT_LOCALE).$url().pathname
      };
  }
};