import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../libraries/stitches.library.ts";
var styles = {
  timer: _css(_objectSpread(_objectSpread({}, {
    "display": "grid",
    "gap": "0.25rem",
    "gridTemplateColumns": "repeat(4, 1fr)"
  }), {}, {
    variants: {
      hidden: {
        "true": {
          "display": "none"
        }
      }
    }
  })),
  column: _css(_objectSpread({}, {
    "display": "flex",
    "flexDirection": "column",
    "alignItems": "center",
    "justifyContent": "center"
  })),
  number: _css(_objectSpread(_objectSpread({}, {
    "position": "relative",
    "fontWeight": "700",
    "textTransform": "uppercase"
  }), {}, {
    lineHeight: "1.4rem"
  })),
  loader: _css(_objectSpread(_objectSpread({}, {
    "position": "relative",
    "display": "block",
    "padding": "0.375rem"
  }), {}, {
    height: "1.4rem",
    width: "1.4rem",
    "&::before": _objectSpread(_objectSpread({}, {
      "position": "relative",
      "display": "block",
      "height": "100%",
      "width": "100%",
      "animation": "pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite",
      "borderRadius": "9999px",
      "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke",
      "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
      "transitionDuration": "1000ms",
      "animationDuration": "1000ms",
      "animationTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)"
    }), {}, {
      backgroundColor: "$white",
      content: ""
    })
  })),
  caption: _css(_objectSpread(_objectSpread({}, {
    "position": "relative",
    "textTransform": "uppercase"
  }), {}, {
    fontSize: "0.6rem",
    lineHeight: 1.1
  }))
};
export default styles;