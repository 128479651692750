import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread(_objectSpread({}, {
    "marginLeft": "-1rem",
    "marginRight": "-1rem",
    "overflow": "clip"
  }), {}, {
    "@sm": _objectSpread({}, {
      "marginLeft": "-1.5rem",
      "marginRight": "-1.5rem"
    }),
    "@md": {
      margin: "0 calc((((100vw - $md)/2) + 1.5rem)* -1)"
    },
    "@lg": _objectSpread({}, {
      "marginLeft": "0px",
      "marginRight": "0px",
      "paddingTop": "4rem"
    }),
    ".swiper-slide": _objectSpread({}, {
      "overflow": "clip",
      "borderBottomRightRadius": "1rem",
      "borderBottomLeftRadius": "1rem"
    })
  })),
  navigationContainer: _css(_objectSpread(_objectSpread({}, {
    "display": "none"
  }), {}, {
    "@lg": _objectSpread({}, {
      "position": "absolute",
      "left": "0px",
      "right": "0px",
      "top": "0px",
      "zIndex": "20",
      "marginLeft": "auto",
      "marginRight": "auto",
      "marginTop": "1rem",
      "display": "flex",
      "width": "100%",
      "maxWidth": "32rem",
      "justifyContent": "space-between"
    })
  })),
  paginationContainer: _css(_objectSpread(_objectSpread({}, {
    "display": "none"
  }), {}, {
    "@sm": _objectSpread({}, {
      "display": "flex",
      "alignItems": "center"
    })
  }))
};
export default styles;