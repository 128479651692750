import { CheckoutView } from "@/checkout/checkout.types";
import { PRODUCT_VARIANT_VOLUME_ATTRIBUTE } from "@/config/common.config";
import { getAttribute } from "@/helpers/attribute.helper";
import { translate } from "@/helpers/translation.helpers";
export var getViewLabel = function getViewLabel(t, view, isShippingRequired) {
  switch (view) {
    case CheckoutView.ADDRESS:
      return t.formatMessage({
        defaultMessage: "Address",
        id: "common.address"
      });

    case CheckoutView.DETAILS:
      return isShippingRequired ? t.formatMessage({
        defaultMessage: "Delivery and payment",
        id: "checkout.view.deliveryAndPayment"
      }) : t.formatMessage({
        defaultMessage: "Payment",
        id: "checkout.view.payment"
      });

    case CheckoutView.PAYMENT:
      return t.formatMessage({
        defaultMessage: "Summary",
        id: "common.summary"
      });

    default:
      return "";
  }
};
export var getErrorMessage = function getErrorMessage(errorCode) {
  switch (errorCode) {
    case "INVALID":
      return "To pole ma nieprawidłowy format";
  }
};
export var checkoutFieldErrorCodes = ["INVALID", "REQUIRED"];
export var checkoutFieldErrorMessages = function checkoutFieldErrorMessages(error) {
  return {
    REQUIRED: {
      id: "filed.error.required",
      defaultMessage: "This field is required"
    },
    INVALID: error.field === "promoCode" ? {
      id: "field.error.promoCode.invalid",
      defaultMessage: "This promo code is invalid"
    } : {
      id: "field.error.invalid",
      defaultMessage: "This field is invalid"
    }
  };
};
export var checkoutNotificationErrorCodes = ["INSUFFICIENT_STOCK", "APPLY_PROPOSED_PRODUCT_SET", "PROMO_CODE_RULES_NOT_MEET", "VOUCHER_MIN_SPENT", "VOUCHER_MIN_QUANTITY_LIMIT", "VOUCHER_SHIPPING_REQUIRED"];
export var checkoutNotificationErrorMessages = {
  INSUFFICIENT_STOCK: {
    defaultMessage: "The number of products in the basket is inconsistent with the current stock. The shopping cart has been automatically updated.",
    id: "notification.error.insufficientStock"
  },
  APPLY_PROPOSED_PRODUCT_SET: {
    defaultMessage: "An error occurred while adding a product set.",
    id: "notification.error.applyProposedProductSet"
  },
  PROMO_CODE_RULES_NOT_MEET: {
    defaultMessage: "The requirements for this promotional code have not been met.",
    id: "notification.error.promoCodeRulesNotMeet"
  },
  VOUCHER_MIN_SPENT: {
    defaultMessage: "The minimum order amount has not been reached",
    id: "notification.error.voucherMinSpent"
  },
  VOUCHER_MIN_QUANTITY_LIMIT: {
    defaultMessage: "The minimum quantity of products in the cart has not been reached.",
    id: "notification.error.voucherMinQuantityLimit"
  },
  VOUCHER_SHIPPING_REQUIRED: {
    defaultMessage: "Use the code for free delivery in the second step of the order summary.",
    id: "notification.error.voucherShippingRequired"
  }
};
export var getShippingOrBillingAddressValue = function getShippingOrBillingAddressValue(form, key) {
  var shippingAddressValue = form.shippingAddress[key];

  if (shippingAddressValue && shippingAddressValue.length > 0) {
    return shippingAddressValue;
  }

  var billingAddressValue = form.billingAddress[key];

  if (billingAddressValue && billingAddressValue.length > 0) {
    return billingAddressValue;
  }

  return "";
};
export var makeCheckoutLinesDataAttributes = function makeCheckoutLinesDataAttributes(lines) {
  var result = [];

  if (!lines) {
    return JSON.stringify(result);
  }

  lines.forEach(function (line) {
    var _getAttribute, _variant$media, _variant$sku, _variant$pricing, _variant$pricing2, _variant$pricing3, _variant$pricing4, _variant$pricing5, _variant$pricing6;

    var variant = line.variant;
    var name = translate(variant.product, "name");
    var volume = (_getAttribute = getAttribute(PRODUCT_VARIANT_VOLUME_ATTRIBUTE, variant === null || variant === void 0 ? void 0 : variant.attributes)) === null || _getAttribute === void 0 ? void 0 : _getAttribute.value[0];
    var media1024Url = variant === null || variant === void 0 || (_variant$media = variant.media1024) === null || _variant$media === void 0 || (_variant$media = _variant$media.find(function (m) {
      return m.type === "IMAGE";
    })) === null || _variant$media === void 0 ? void 0 : _variant$media.url;
    result.push({
      productId: (_variant$sku = variant.sku) !== null && _variant$sku !== void 0 ? _variant$sku : variant.product.id,
      productName: name,
      variantName: volume ? "".concat(name, " ").concat(volume) : name,
      productPrice: ((_variant$pricing = variant.pricing) === null || _variant$pricing === void 0 || (_variant$pricing = _variant$pricing.price) === null || _variant$pricing === void 0 ? void 0 : _variant$pricing.gross.amount) || 0,
      productLinePrice: (((_variant$pricing2 = variant.pricing) === null || _variant$pricing2 === void 0 || (_variant$pricing2 = _variant$pricing2.price) === null || _variant$pricing2 === void 0 ? void 0 : _variant$pricing2.gross.amount) || 0) * line.quantity,
      productPriceCurrency: ((_variant$pricing3 = variant.pricing) === null || _variant$pricing3 === void 0 || (_variant$pricing3 = _variant$pricing3.price) === null || _variant$pricing3 === void 0 ? void 0 : _variant$pricing3.gross.currency) || "PLN",
      productPriceUndiscounted: ((_variant$pricing4 = variant.pricing) === null || _variant$pricing4 === void 0 || (_variant$pricing4 = _variant$pricing4.priceUndiscounted) === null || _variant$pricing4 === void 0 ? void 0 : _variant$pricing4.gross.amount) || 0,
      productLinePriceUndiscounted: (((_variant$pricing5 = variant.pricing) === null || _variant$pricing5 === void 0 || (_variant$pricing5 = _variant$pricing5.priceUndiscounted) === null || _variant$pricing5 === void 0 ? void 0 : _variant$pricing5.gross.amount) || 0) * line.quantity,
      productPriceUndiscountedCurrency: ((_variant$pricing6 = variant.pricing) === null || _variant$pricing6 === void 0 || (_variant$pricing6 = _variant$pricing6.priceUndiscounted) === null || _variant$pricing6 === void 0 ? void 0 : _variant$pricing6.gross.currency) || "PLN",
      productQuantity: line.quantity,
      productImage: media1024Url || ""
    });
  });
  return JSON.stringify(result);
};