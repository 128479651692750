export var parseJsonString = function parseJsonString(jsonString) {
  var _data$blocks;

  if (!jsonString) {
    return null;
  }

  var data;

  try {
    data = JSON.parse(jsonString);
  } catch (e) {
    return null;
  }

  if (!((_data$blocks = data.blocks) !== null && _data$blocks !== void 0 && _data$blocks.length)) {
    return null;
  }

  if (!data.time) {
    data.time = Date.now().toString();
  }

  if (!data.version) {
    data.version = "2.22.2";
  }

  return data;
};