var __jsx = React.createElement;
import React from "react";
import AccentIcon from "@/components/ui/Icon/$/Accent.icon";
import { cn } from "@/libraries/clsx.library";
import styles from "./Accent.styles";

var Accent = function Accent(_ref) {
  var className = _ref.className,
      children = _ref.children,
      color = _ref.color;
  return __jsx("span", {
    className: styles.root()
  }, __jsx("span", {
    className: cn(styles.text(), className)
  }, children), __jsx(AccentIcon, {
    color: color,
    className: styles.accent()
  }));
};

Accent.displayName = "Accent";
export default Accent;