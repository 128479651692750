import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread({}, {
    "position": "relative",
    "textAlign": "left"
  })),
  button: _css(_objectSpread(_objectSpread({}, {
    "display": "inline-flex",
    "flexDirection": "row",
    "alignItems": "center",
    "borderWidth": "1px",
    "borderColor": "transparent",
    "&:hover": {
      "--tw-border-opacity": "1",
      "borderColor": "hsl(359 4% 95% / var(--tw-border-opacity))",
      "--tw-bg-opacity": "1",
      "backgroundColor": "hsl(359 4% 98% / var(--tw-bg-opacity))"
    }
  }), {}, {
    variants: {
      disabled: {
        "true": _objectSpread(_objectSpread({}, {
          "pointerEvents": "none",
          "cursor": "not-allowed"
        }), {}, {
          color: "$gray6",
          backgroundColor: "transparent",
          borderColor: "transparent"
        })
      }
    }
  })),
  truncate: _css(_objectSpread({}, {
    "maxWidth": "72px",
    "overflow": "hidden",
    "textOverflow": "ellipsis",
    "whiteSpace": "nowrap",
    "@media (min-width: 640px)": {
      "maxWidth": "100%"
    }
  })),
  items: _css(_objectSpread(_objectSpread({}, {
    "position": "absolute",
    "zIndex": "10",
    "marginTop": "0.5rem",
    "transformOrigin": "top right",
    "> :not([hidden]) ~ :not([hidden])": {
      "--tw-divide-y-reverse": "0",
      "borderTopWidth": "calc(1px * calc(1 - var(--tw-divide-y-reverse)))",
      "borderBottomWidth": "calc(1px * var(--tw-divide-y-reverse))"
    },
    "borderRadius": "0.375rem",
    "borderWidth": "1px",
    "--tw-shadow": "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
    "--tw-shadow-colored": "0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color)",
    "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
  }), {}, {
    color: "$black",
    backgroundColor: "$white",
    borderColor: "gray4",
    "&:focus": _objectSpread({}, {
      "outline": "2px solid transparent",
      "outlineOffset": "2px"
    }),
    variants: {
      position: {
        left: _objectSpread({}, {
          "left": "0px"
        }),
        right: _objectSpread({}, {
          "right": "0px"
        })
      }
    }
  })),
  group: _css(_objectSpread({}, {
    "width": "100%"
  })),
  item: _css(_objectSpread(_objectSpread({}, {
    "display": "block",
    "width": "100%",
    "paddingLeft": "1rem",
    "paddingRight": "1rem",
    "paddingTop": "0.5rem",
    "paddingBottom": "0.5rem",
    "textAlign": "left",
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem",
    "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
    "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "transitionDuration": "150ms",
    "animationDuration": "150ms",
    "animationTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "@media (min-width: 640px)": {
      "fontSize": "1rem",
      "lineHeight": "1.5rem"
    }
  }), {}, {
    color: "$black",
    variants: {
      active: {
        "true": {
          backgroundColor: "$lighten",
          color: "$dark"
        }
      }
    }
  })),
  label: _css(_objectSpread({}, {
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem",
    "--tw-text-opacity": "1",
    "color": "hsl(359 4% 45% / var(--tw-text-opacity))",
    "@media (min-width: 640px)": {
      "fontSize": "1rem",
      "lineHeight": "1.5rem",
      "--tw-text-opacity": "1",
      "color": "hsl(0 4% 15% / var(--tw-text-opacity))"
    }
  })),
  caption: _css(_objectSpread({}, {
    "paddingRight": "0.25rem",
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem",
    "fontWeight": "400",
    "--tw-text-opacity": "1",
    "color": "hsl(359 4% 45% / var(--tw-text-opacity))",
    "@media (min-width: 640px)": {
      "paddingRight": "0.5rem",
      "fontSize": "1rem",
      "lineHeight": "1.5rem"
    }
  })),
  chevron: _css(_objectSpread(_objectSpread({}, {
    "marginRight": "-0.25rem",
    "height": "1.5rem",
    "width": "1.5rem",
    "@media (min-width: 640px)": {
      "marginLeft": "0.25rem"
    }
  }), {}, {
    color: "$dark",
    variants: {
      open: {
        "true": _objectSpread({}, {
          "--tw-rotate": "180deg",
          "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
        })
      }
    }
  }))
};
export default styles;