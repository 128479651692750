var __jsx = React.createElement;
import React, { Fragment } from "react";

var Font = function Font() {
  return __jsx(Fragment, null, __jsx("link", {
    rel: "preload",
    href: "/fonts/Inter-Variable.woff2",
    as: "font",
    type: "font/woff2",
    crossOrigin: "anonymous"
  }), __jsx("link", {
    rel: "preload",
    href: "/fonts/Tenor-Regular.woff2",
    as: "font",
    type: "font/woff2",
    crossOrigin: "anonymous"
  }), __jsx("link", {
    rel: "preload",
    href: "/fonts/Inria-Bold.woff2",
    as: "font",
    type: "font/woff2",
    crossOrigin: "anonymous"
  }), __jsx("link", {
    rel: "preload",
    href: "/fonts/Inria-Bold-Italic.woff2",
    as: "font",
    type: "font/woff2",
    crossOrigin: "anonymous"
  }), __jsx("link", {
    rel: "preload",
    href: "/fonts/Inria-Regular.woff2",
    as: "font",
    type: "font/woff2",
    crossOrigin: "anonymous"
  }), __jsx("link", {
    rel: "preload",
    href: "/fonts/Inria-Regular-Italic.woff2",
    as: "font",
    type: "font/woff2",
    crossOrigin: "anonymous"
  }));
};

export default Font;