import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from "react";

var SvgDroplet = function SvgDroplet(props) {
  return __jsx("svg", _extends({
    width: 12,
    height: 20,
    viewBox: "0 0 12 20",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("path", {
    d: "M6 19C2.63197 19 0.316395 15.615 1.53712 12.476L5.75254 1.63632C5.84032 1.41061 6.15968 1.41061 6.24746 1.63632L10.4629 12.476C11.6836 15.615 9.36803 19 6 19V19Z",
    stroke: "#5D5656",
    strokeWidth: 1.5
  }));
};

export default SvgDroplet;