import { PRODUCT_HIDDEN_ON_STOREFRONT_ATTRIBUTE, PRODUCT_ICON_ATTRIBUTE, PRODUCT_VARIANT_LOW_PRICE_ZONE_ATTRIBUTE } from "@/config/common.config";
import { getAttribute } from "./attribute.helper";
export var getIconFromProductAttribute = function getIconFromProductAttribute(product) {
  var _iconAttribute$values;

  var attributes = product === null || product === void 0 ? void 0 : product.attributes;
  if (!Array.isArray(attributes)) return;
  var iconAttribute = attributes.find(function (_ref) {
    var slug = _ref.attribute.slug;
    return slug === PRODUCT_ICON_ATTRIBUTE;
  });
  return iconAttribute === null || iconAttribute === void 0 || (_iconAttribute$values = iconAttribute.values) === null || _iconAttribute$values === void 0 || (_iconAttribute$values = _iconAttribute$values[0]) === null || _iconAttribute$values === void 0 || (_iconAttribute$values = _iconAttribute$values.file) === null || _iconAttribute$values === void 0 ? void 0 : _iconAttribute$values.url;
};
export var getVariantsSetDiscountPrice = function getVariantsSetDiscountPrice(variantsSetProduct, defaultCurrency) {
  var _variantsSetProduct$v, _variantsSetProduct$d, _variantsSetProduct$d2;

  if (!(variantsSetProduct !== null && variantsSetProduct !== void 0 && variantsSetProduct.variantsInSet) || !(variantsSetProduct !== null && variantsSetProduct !== void 0 && (_variantsSetProduct$v = variantsSetProduct.variantsInSet) !== null && _variantsSetProduct$v !== void 0 && _variantsSetProduct$v.length)) return {
    amount: 0,
    currency: defaultCurrency
  };
  var variantsInSetAmount = variantsSetProduct.variantsInSet.reduce(function (amount, variantInSet) {
    var _variantInSet$variant;

    var variantPrice = ((_variantInSet$variant = variantInSet.variant.pricing) === null || _variantInSet$variant === void 0 || (_variantInSet$variant = _variantInSet$variant.price) === null || _variantInSet$variant === void 0 ? void 0 : _variantInSet$variant.gross.amount) || 0;
    return amount + variantPrice * variantInSet.variantQuantity;
  }, 0);
  return {
    amount: variantsInSetAmount - ((variantsSetProduct === null || variantsSetProduct === void 0 || (_variantsSetProduct$d = variantsSetProduct.defaultVariant) === null || _variantsSetProduct$d === void 0 || (_variantsSetProduct$d = _variantsSetProduct$d.pricing) === null || _variantsSetProduct$d === void 0 || (_variantsSetProduct$d = _variantsSetProduct$d.price) === null || _variantsSetProduct$d === void 0 ? void 0 : _variantsSetProduct$d.gross.amount) || 0),
    currency: (variantsSetProduct === null || variantsSetProduct === void 0 || (_variantsSetProduct$d2 = variantsSetProduct.defaultVariant) === null || _variantsSetProduct$d2 === void 0 || (_variantsSetProduct$d2 = _variantsSetProduct$d2.pricing) === null || _variantsSetProduct$d2 === void 0 || (_variantsSetProduct$d2 = _variantsSetProduct$d2.price) === null || _variantsSetProduct$d2 === void 0 ? void 0 : _variantsSetProduct$d2.gross.currency) || defaultCurrency
  };
};
export var isLowPriceZoneVariant = function isLowPriceZoneVariant(variant) {
  var _getAttribute;

  return !!((_getAttribute = getAttribute(PRODUCT_VARIANT_LOW_PRICE_ZONE_ATTRIBUTE, variant.attributes)) !== null && _getAttribute !== void 0 && _getAttribute.value[0]);
};
export var isHiddenOnStorefront = function isHiddenOnStorefront(product) {
  var _getAttribute2;

  return !!((_getAttribute2 = getAttribute(PRODUCT_HIDDEN_ON_STOREFRONT_ATTRIBUTE, product.attributes || [])) !== null && _getAttribute2 !== void 0 && _getAttribute2.value[0]);
};