import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../libraries/stitches.library.ts";
var styles = {
  form: _css(_objectSpread({}, {
    "display": "flex",
    "flexDirection": "row",
    "alignItems": "flex-end",
    "justifyContent": "space-between",
    "> :not([hidden]) ~ :not([hidden])": {
      "--tw-space-x-reverse": "0",
      "marginRight": "calc(1rem * var(--tw-space-x-reverse))",
      "marginLeft": "calc(1rem * calc(1 - var(--tw-space-x-reverse)))"
    }
  })),
  input: _css(_objectSpread({}, {
    "paddingRight": "calc(46px + 0.5rem)"
  })),
  inputContainer: _css(_objectSpread({}, {
    "position": "relative",
    "flex": "1 1 0%"
  })),
  inputButtonContainer: _css(_objectSpread({}, {
    "position": "absolute",
    "bottom": "0px",
    "right": "0px",
    "zIndex": "10",
    "display": "flex",
    "height": "46px",
    "width": "46px",
    "alignItems": "center",
    "justifyContent": "center"
  })),
  button: _css({
    flex: "none",
    maxHeight: "3rem",
    width: "30%",
    maxWidth: "10rem",
    minWidth: "6rem"
  })
};
export default styles;