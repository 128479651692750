var __jsx = React.createElement;
import React from "react";
import { useIntl } from "react-intl";
import { Loader as Dots } from "@/components/ui";
import styles from "./Loader.styles";

var Loader = function Loader(_ref) {
  var active = _ref.active;
  var t = useIntl();
  return __jsx("div", {
    className: styles.wrapper()
  }, __jsx("div", {
    className: styles.loader({
      active: active
    })
  }, __jsx(Dots, {
    color: "flavor"
  })), __jsx("div", {
    className: styles.loaderContent()
  }, __jsx("h2", {
    className: styles.loaderTitle()
  }, t.formatMessage({
    defaultMessage: "We are processing your order.",
    id: "checkout.loader.title"
  })), __jsx("div", {
    className: styles.loaderDescription()
  }, t.formatMessage({
    defaultMessage: "Thank you for your order. We will redirect you to payment in a moment. we keep you informed about the status of your order by sending information on its implementation to your e-mail address.",
    id: "checkout.loader.description"
  }))));
};

Loader.displayName = "Loader";
export default Loader;