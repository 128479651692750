/**
 * @description Messenger API host for building websocket url
 * @type {string}
 */
export var MESSENGER_HOST = process.env.NEXT_PUBLIC_MESSENGER_HOST || "";
/**
 * @description Messenger API bearer token (should be send in Sec-WebSocket-Protocol)
 * @type {string}
 */

export var MESSENGER_TOKEN = process.env.MESSENGER_TOKEN || "";
/**
 * @description Storefront UUID for sending messages
 * @type {string}
 */

export var STOREFRONT_LUID = process.env.STOREFRONT_LUID || "";
/**
 * @description Number of messages to fetch on websocket initialization
 * @type {number}
 */

export var INITIAL_MESSAGES_COUNT = 1;
/**
 * @description Maximum number of messages to keep in queue
 * @type {number}
 */

export var MAX_MESSAGE_QUEUE_SIZE = INITIAL_MESSAGES_COUNT + 1;
/**
 * @description Time in ms between notifications
 * @example 1st notification: 5s, 2nd notification: 20s, 3rd notification: 40s
 * DBN * index * DBNM           , 5000 * 1 * 4         , 5000 * 2 * 4
 * @type {number}
 */

export var DELAY_BEETWEN_NOTIFICATIONS = 5000;
export var DELAY_BEETWEN_NOTIFICATIONS_MULTIPLIER = 4;