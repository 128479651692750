export var LOVA_PLUGIN_PROMO_BAR_IS_ACTIVE_NAME = "promobar_is_active";
export var LOVA_PLUGIN_DEFAULT_PROMO_BAR_TEXT_NAME = "default_promobar_text";
export var LOVA_PLUGIN_DEFAULT_PROMO_BAR_END_DATE_NAME = "default_promobar_end_date";
export var LOVA_PLUGIN_DEFAULT_PROMO_BAR_SHOW_TIMER_NAME = "default_promobar_show_timer";
export var LOVA_PLUGIN_DEFAULT_PROMO_BAR_URL_NAME = "default_promobar_url";
export var LOVA_PLUGIN_DEFAULT_PROMO_BAR_ADD_PRODUCT_VARIANT_NAME = "default_promobar_add_product_variant";
export var LOVA_PLUGIN_DEFAULT_PROMO_BAR_PRODUCT_VARIANT_ID_NAME = "default_promobar_product_variant_id";
export var LOVA_PLUGIN_DEFAULT_PROMO_BAR_PRODUCT_VARIANT_QUANTITY_NAME = "default_promobar_product_variant_quantity";
export var LOVA_PLUGIN_TEMPORARY1_PROMO_BAR_TEXT_NAME = "temporary1_promobar_text";
export var LOVA_PLUGIN_TEMPORARY1_PROMO_BAR_START_DATE_NAME = "temporary1_promobar_start_date";
export var LOVA_PLUGIN_TEMPORARY1_PROMO_BAR_END_DATE_NAME = "temporary1_promobar_end_date";
export var LOVA_PLUGIN_TEMPORARY1_PROMO_BAR_SHOW_TIMER_NAME = "temporary1_promobar_show_timer";
export var LOVA_PLUGIN_TEMPORARY1_PROMO_BAR_URL_NAME = "temporary1_promobar_url";
export var LOVA_PLUGIN_TEMPORARY1_PROMO_BAR_ADD_PRODUCT_VARIANT_NAME = "temporary1_promobar_add_product_variant";
export var LOVA_PLUGIN_TEMPORARY1_PROMO_BAR_PRODUCT_VARIANT_ID_NAME = "temporary1_promobar_product_variant_id";
export var LOVA_PLUGIN_TEMPORARY1_PROMO_BAR_PRODUCT_VARIANT_QUANTITY_NAME = "temporary1_promobar_product_variant_quantity";
export var LOVA_PLUGIN_TEMPORARY2_PROMO_BAR_TEXT_NAME = "temporary2_promobar_text";
export var LOVA_PLUGIN_TEMPORARY2_PROMO_BAR_START_DATE_NAME = "temporary2_promobar_start_date";
export var LOVA_PLUGIN_TEMPORARY2_PROMO_BAR_END_DATE_NAME = "temporary2_promobar_end_date";
export var LOVA_PLUGIN_TEMPORARY2_PROMO_BAR_SHOW_TIMER_NAME = "temporary2_promobar_show_timer";
export var LOVA_PLUGIN_TEMPORARY2_PROMO_BAR_URL_NAME = "temporary2_promobar_url";
export var LOVA_PLUGIN_TEMPORARY2_PROMO_BAR_ADD_PRODUCT_VARIANT_NAME = "temporary2_promobar_add_product_variant";
export var LOVA_PLUGIN_TEMPORARY2_PROMO_BAR_PRODUCT_VARIANT_ID_NAME = "temporary2_promobar_product_variant_id";
export var LOVA_PLUGIN_TEMPORARY2_PROMO_BAR_PRODUCT_VARIANT_QUANTITY_NAME = "temporary2_promobar_product_variant_quantity";
export var LOVA_PLUGIN_EMAIL_DOMAIN_SUGGESTION_LIST = "email_domain_suggestion_list";
export var LOVA_PLUGIN_IS_EMAIL_DOMAIN_LIST_CHECK_ACTIVE = "is_email_domain_list_check_active";
export var LOVA_PLUGIN_IS_EMAIL_DOMAIN_DNS_CHECK_ACTIVE = "is_email_domain_dns_check_active";
export var LOVA_PLUGIN_SHOW_PROMOBAR_INFO_ON_SHIPPABLE_PRODUCTS_DETAILS = "show_promobar_info_on_shippable_products_details";
export var LOVA_PLUGIN_SHOW_PROMOBAR_INFO_ON_NON_SHIPPABLE_PRODUCTS_DETAILS = "show_promobar_info_on_non_shippable_products_details";
export var LOVA_PLUGIN_IS_LOVA_APP_ACTIVE_NAME = "is_lova_app_active";