import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread({}, {
    "padding": "1rem"
  })),
  form: _css(_objectSpread({}, {
    "display": "flex",
    "flexDirection": "column",
    "> :not([hidden]) ~ :not([hidden])": {
      "--tw-space-y-reverse": "0",
      "marginTop": "calc(1.5rem * calc(1 - var(--tw-space-y-reverse)))",
      "marginBottom": "calc(1.5rem * var(--tw-space-y-reverse))"
    }
  })),
  inputAndButtonContainer: _css(_objectSpread(_objectSpread({}, {
    "display": "flex",
    "flexDirection": "column",
    "alignItems": "flex-start",
    "gap": "0.75rem"
  }), {}, {
    "@sm": _objectSpread({}, {
      "flexDirection": "row"
    }),
    "@md": _objectSpread({}, {
      "flexDirection": "column"
    }),
    "@xl": _objectSpread({}, {
      "flexDirection": "row"
    }),
    "& > div": _objectSpread({}, {
      "width": "100%",
      "flex": "1 1 0%"
    })
  })),
  input: _css(_objectSpread({}, {
    "margin": "0px !important",
    "height": "50px",
    "borderRadius": "9999px !important"
  })),
  button: _css(_objectSpread(_objectSpread({}, {
    "width": "100%"
  }), {}, {
    "@sm": _objectSpread({}, {
      "width": "auto"
    }),
    "@md": _objectSpread({}, {
      "width": "100%"
    }),
    "@xl": _objectSpread({}, {
      "width": "auto"
    })
  })),
  checkboxesContainer: _css(_objectSpread({}, {
    "display": "flex",
    "flexDirection": "column",
    "> :not([hidden]) ~ :not([hidden])": {
      "--tw-space-y-reverse": "0",
      "marginTop": "calc(0.5rem * calc(1 - var(--tw-space-y-reverse)))",
      "marginBottom": "calc(0.5rem * var(--tw-space-y-reverse))"
    }
  })),
  checkboxContainer: _css(_objectSpread(_objectSpread({}, {
    "display": "grid",
    "justifyContent": "space-between"
  }), {}, {
    gridTemplateColumns: "4fr 1fr",
    "& a": {
      textAlign: "right"
    }
  })),
  checkboxLink: _css(_objectSpread(_objectSpread({}, {
    "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke",
    "transitionTimingFunction": "linear",
    "transitionDuration": "300ms",
    "animationDuration": "300ms",
    "animationTimingFunction": "linear"
  }), {}, {
    textDecoration: "underline",
    "&:hover": {
      color: "$default"
    }
  }))
};
export default styles;