import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread(_objectSpread({}, {
    "display": "grid",
    "overflow": "hidden",
    "borderRadius": "1rem"
  }), {}, {
    "@sm": _objectSpread({}, {
      "gridTemplateColumns": "repeat(2, minmax(0, 1fr))"
    }),
    "@xl": _objectSpread({}, {
      "gridTemplateColumns": "repeat(4, minmax(0, 1fr))"
    })
  })),
  keypoint: _css(_objectSpread(_objectSpread({}, {
    "display": "flex",
    "flexDirection": "column",
    "alignItems": "center",
    "padding": "1rem",
    "textAlign": "center"
  }), {}, {
    backgroundColor: "$lighter",
    "@sm": _objectSpread({}, {
      "paddingLeft": "2rem",
      "paddingRight": "2rem",
      "paddingTop": "1.5rem",
      "paddingBottom": "1.5rem"
    }),
    "@md": _objectSpread({}, {
      "paddingLeft": "2.5rem",
      "paddingRight": "2.5rem"
    }),
    "@lg": _objectSpread({}, {
      "paddingLeft": "3.5rem",
      "paddingRight": "3.5rem",
      "paddingTop": "2.5rem",
      "paddingBottom": "2.5rem"
    }),
    "@xl": _objectSpread({}, {
      "paddingLeft": "2rem",
      "paddingRight": "2rem"
    }),
    variants: {
      mobileHorizontal: {
        "true": _objectSpread(_objectSpread({}, {
          "flexDirection": "row",
          "alignItems": "flex-start",
          "columnGap": "1rem",
          "textAlign": "left"
        }), {}, {
          "@sm": _objectSpread({}, {
            "flexDirection": "column",
            "alignItems": "center",
            "columnGap": "0px",
            "textAlign": "center"
          })
        })
      }
    }
  })),
  keypointIcon: _css({
    flex: "0 0 3.8125rem",
    "@sm": _objectSpread({}, {
      "marginBottom": "1.25rem"
    })
  }),
  keypointTitle: _css({
    fontSize: "$5",
    variants: {
      font: {
        tenor: {
          fontFamily: "$tenor"
        },
        inria: {
          fontFamily: "$inria"
        }
      }
    }
  }),
  keypointDescription: _css({
    color: "$8",
    lineHeight: "$8"
  })
};
export default styles;