import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children", "additionalContent", "resetSliderAfterRouteChange", "onSwiper"];
var __jsx = React.createElement;
import "swiper/css";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import NavigationItem from "./Slider.NavigationItem";
import Pagination from "./Slider.Pagination";
import styles from "./Slider.styles";

var Slider = function Slider(_ref) {
  var children = _ref.children,
      additionalContent = _ref.additionalContent,
      _ref$resetSliderAfter = _ref.resetSliderAfterRouteChange,
      resetSliderAfterRouteChange = _ref$resetSliderAfter === void 0 ? true : _ref$resetSliderAfter,
      _onSwiper = _ref.onSwiper,
      props = _objectWithoutProperties(_ref, _excluded);

  var router = useRouter();

  var _useState = useState(),
      slider = _useState[0],
      setSlider = _useState[1];

  useEffect(function () {
    if (resetSliderAfterRouteChange) {
      var handleRouteChange = function handleRouteChange() {
        if (slider && !slider.destroyed) {
          slider.update();
          slider.slideTo(0, 300);
        }
      };

      router.events.on("routeChangeComplete", handleRouteChange);
      return function () {
        router.events.off("routeChangeComplete", handleRouteChange);
      };
    }
  }, [resetSliderAfterRouteChange, router.events, slider]);
  return __jsx(Swiper, _extends({}, props, {
    onSwiper: function onSwiper(swiper) {
      setSlider(swiper);

      if (_onSwiper) {
        _onSwiper(swiper);
      }
    }
  }), children.map(function (child, key) {
    return __jsx(SwiperSlide, {
      className: styles.slide(),
      key: "slide-".concat(key)
    }, child);
  }), additionalContent);
};

Slider.NavigationItem = NavigationItem;
Slider.Pagination = Pagination;
Slider.displayName = "Slider";
export default Slider;