import _toConsumableArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { accountFieldErrorCodes, accountFieldErrorMessages } from "@/account/account.helpers";
import { checkoutFieldErrorCodes, checkoutFieldErrorMessages, checkoutNotificationErrorCodes, checkoutNotificationErrorMessages } from "@/checkout/checkout.helpers";
import { orderioNitificationErrorMessages, orderioNotificationErrorCodes } from "@/libraries/orderio.library";
export function parseFieldErrors(errors, intl) {
  return errors.reduce(function (acc, error) {
    if (error.field) {
      var currentFieldMessage = acc[error.field];

      if (currentFieldMessage) {
        return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, error.field, [currentFieldMessage, getErrorMessage(error, intl)].join(", ")));
      } else {
        return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, error.field, getErrorMessage(error, intl)));
      }
    }

    return acc;
  }, {});
}
export function isSupportedError(error, supportedErrorCodes) {
  return supportedErrorCodes.includes(error.code);
}
export var fieldErrorCodes = [].concat(_toConsumableArray(checkoutFieldErrorCodes), _toConsumableArray(accountFieldErrorCodes));
export var notificationErrorCodes = [].concat(_toConsumableArray(checkoutNotificationErrorCodes), _toConsumableArray(orderioNotificationErrorCodes), ["GRAPHQL_ERROR"]);
export function getErrorMessage(error, intl) {
  var errorMessage = errorMessages(error)[error.code];

  if (errorMessage) {
    if (typeof errorMessage === "string") {
      return errorMessage;
    } else {
      return intl.formatMessage(_objectSpread({}, errorMessage));
    }
  }

  return intl.formatMessage({
    id: "notification.error.unexpected",
    defaultMessage: "An unexpected error occurred. Please try again or contact technical support"
  });
}

var errorMessages = function errorMessages(error) {
  return _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, checkoutFieldErrorMessages(error)), accountFieldErrorMessages(error)), checkoutNotificationErrorMessages), orderioNitificationErrorMessages), {}, {
    GRAPHQL_ERROR: error.message || {
      id: "notification.error.unexpected",
      defaultMessage: "An unexpected error occurred. Please try again or contact technical support"
    }
  });
};