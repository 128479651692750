import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from "react";

var SvgOutlineTiktok = function SvgOutlineTiktok(props) {
  return __jsx("svg", _extends({
    width: 24,
    height: 25,
    viewBox: "0 0 24 25",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("g", {
    clipPath: "url(#clip0_6429_81921)"
  }, __jsx("path", {
    d: "M15.75 10.0625C17.279 11.1631 19.1161 11.7536 21 11.75V8C19.6076 8 18.2723 7.44688 17.2877 6.46231C16.3031 5.47774 15.75 4.14239 15.75 2.75H12V15.125C11.9998 15.5947 11.8736 16.0558 11.6345 16.4601C11.3954 16.8644 11.0522 17.1971 10.6407 17.4236C10.2291 17.6501 9.76439 17.762 9.2949 17.7477C8.8254 17.7333 8.36834 17.5933 7.97141 17.3421C7.57448 17.091 7.25221 16.7379 7.03822 16.3198C6.82423 15.9016 6.72636 15.4337 6.75482 14.9649C6.78328 14.496 6.93702 14.0434 7.20001 13.6542C7.463 13.265 7.82561 12.9535 8.25 12.7522V8.75C5.26594 9.28156 3 11.9881 3 15.125C3 16.8158 3.67165 18.4373 4.86719 19.6328C6.06274 20.8284 7.68424 21.5 9.375 21.5C11.0658 21.5 12.6873 20.8284 13.8828 19.6328C15.0784 18.4373 15.75 16.8158 15.75 15.125V10.0625Z",
    stroke: props.stroke || "#5D5656",
    strokeWidth: 1.4,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  })), __jsx("defs", null, __jsx("clipPath", {
    id: "clip0_6429_81921"
  }, __jsx("rect", {
    width: 24,
    height: 24,
    fill: "white",
    transform: "translate(0 0.5)"
  }))));
};

export default SvgOutlineTiktok;