var __jsx = React.createElement;
import { range } from "lodash";
import React, { useMemo } from "react";
import { Skeleton } from "@/components/ui";
import styles from "./TableSkeleton.styles";

var TableSkeleton = function TableSkeleton(_ref) {
  var rowCount = _ref.rowCount,
      cellCount = _ref.cellCount,
      _ref$hiddenColumns = _ref.hiddenColumns,
      hiddenColumns = _ref$hiddenColumns === void 0 ? [] : _ref$hiddenColumns;
  var rowItems = useMemo(function () {
    return range(rowCount);
  }, [rowCount]);
  var colItems = useMemo(function () {
    return range(cellCount);
  }, [cellCount]);
  return __jsx(React.Fragment, null, rowItems.map(function (rowItem) {
    return __jsx("tr", {
      key: "table-body-skeleton-row-".concat(rowItem)
    }, colItems.map(function (colItem, colKey) {
      return __jsx("td", {
        className: styles.col({
          hidden: (hiddenColumns === null || hiddenColumns === void 0 ? void 0 : hiddenColumns.indexOf(colKey + 1)) >= 0
        }),
        key: "table-body-skeleton-row-".concat(rowItem, "-col-").concat(colItem)
      }, __jsx(Skeleton, {
        width: 100,
        height: 10
      }));
    }));
  }));
};

export default TableSkeleton;