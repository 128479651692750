import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from "react";

var SvgNature = function SvgNature(props) {
  return __jsx("svg", _extends({
    width: 62,
    height: 61,
    viewBox: "0 0 62 61",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("mask", {
    id: "mask0_3497_5815",
    style: {
      maskType: "luminance"
    },
    maskUnits: "userSpaceOnUse",
    x: 5,
    y: 3,
    width: 51,
    height: 54
  }, __jsx("path", {
    d: "M6 3.5H55V56.5H6V3.5Z",
    fill: "white",
    stroke: "white"
  })), __jsx("g", {
    mask: "url(#mask0_3497_5815)"
  }, __jsx("path", {
    d: "M31.0161 34.7453C35.8526 15.1339 25.1216 5.38286 9.82899 3.69836C8.67479 3.5751 8.029 5.01309 8.88088 5.80741C15.0639 11.5594 12.6869 14.2026 16.589 20.9954C18.8974 24.9943 23.7201 26.3775 27.7597 30.5271C29.7245 32.5541 31.0298 34.7453 31.0298 34.7453",
    stroke: "url(#paint0_linear_3497_5815)",
    strokeWidth: 1.30833,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __jsx("path", {
    d: "M33.4069 38.1824C37.2128 36.1418 39.8097 37.4154 42.2004 33.8683C45.9789 28.2533 46.5973 27.9109 52.0521 24.7611C52.8077 24.3228 52.5741 23.1724 51.7086 23.0628C40.3044 21.7345 28.4604 27.0345 31.071 42.0855C31.181 42.7428 30.8099 40.9351 31.497 39.908C31.497 39.908 32.2114 38.8123 33.3931 38.1824H33.4069Z",
    stroke: "url(#paint1_linear_3497_5815)",
    strokeWidth: 1.30833,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __jsx("path", {
    d: "M30.8784 52.2062C30.8784 52.2062 32.3073 48.44 31.1257 42.2634",
    stroke: "url(#paint2_linear_3497_5815)",
    strokeWidth: 1.30833,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __jsx("path", {
    d: "M50.4663 10.0939C50.4663 12.3121 48.6617 14.1132 46.4321 14.1132C44.2026 14.1132 42.3979 12.3121 42.3979 10.0939C42.3979 7.87554 44.2026 6.07446 46.4321 6.07446C48.6617 6.07446 50.4663 7.87555 50.4663 10.0939Z",
    fill: "url(#paint3_linear_3497_5815)",
    stroke: "url(#paint4_linear_3497_5815)"
  }), __jsx("path", {
    d: "M54.813 56.3151C49.7705 49.7003 46.7613 55.5756 39.8638 55.2605C38.0914 55.1784 34.7388 53.0282 33.035 52.5489C27.4841 50.9877 25.4368 55.4797 19.4461 55.5481C15.8874 55.5892 13.0295 50.0564 6.18701 55.0551",
    stroke: "url(#paint5_linear_3497_5815)",
    strokeWidth: 1.30833,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  })), __jsx("defs", null, __jsx("linearGradient", {
    id: "paint0_linear_3497_5815",
    x1: 20.3394,
    y1: -1.20149,
    x2: 20.3394,
    y2: 34.7453,
    gradientUnits: "userSpaceOnUse"
  }, __jsx("stop", {
    stopColor: "#CF3161"
  }), __jsx("stop", {
    offset: 1,
    stopColor: "#DB7991"
  })), __jsx("linearGradient", {
    id: "paint1_linear_3497_5815",
    x1: 41.6064,
    y1: 19.8162,
    x2: 41.6064,
    y2: 42.2259,
    gradientUnits: "userSpaceOnUse"
  }, __jsx("stop", {
    stopColor: "#CF3161"
  }), __jsx("stop", {
    offset: 1,
    stopColor: "#DB7991"
  })), __jsx("linearGradient", {
    id: "paint2_linear_3497_5815",
    x1: 31.236,
    y1: 40.697,
    x2: 31.236,
    y2: 52.2062,
    gradientUnits: "userSpaceOnUse"
  }, __jsx("stop", {
    stopColor: "#CF3161"
  }), __jsx("stop", {
    offset: 1,
    stopColor: "#DB7991"
  })), __jsx("linearGradient", {
    id: "paint3_linear_3497_5815",
    x1: 46.4321,
    y1: 4.15046,
    x2: 46.4321,
    y2: 14.6132,
    gradientUnits: "userSpaceOnUse"
  }, __jsx("stop", {
    stopColor: "#CF3161"
  }), __jsx("stop", {
    offset: 1,
    stopColor: "#DB7991"
  })), __jsx("linearGradient", {
    id: "paint4_linear_3497_5815",
    x1: 46.4321,
    y1: 4.15046,
    x2: 46.4321,
    y2: 14.6132,
    gradientUnits: "userSpaceOnUse"
  }, __jsx("stop", {
    stopColor: "#CF3161"
  }), __jsx("stop", {
    offset: 1,
    stopColor: "#DB7991"
  })), __jsx("linearGradient", {
    id: "paint5_linear_3497_5815",
    x1: 30.5,
    y1: 51.5743,
    x2: 30.5,
    y2: 56.3151,
    gradientUnits: "userSpaceOnUse"
  }, __jsx("stop", {
    stopColor: "#CF3161"
  }), __jsx("stop", {
    offset: 1,
    stopColor: "#DB7991"
  }))));
};

export default SvgNature;