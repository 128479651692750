import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread(_objectSpread({}, {
    "overflow": "hidden",
    "paddingLeft": "1rem",
    "paddingRight": "1rem",
    "paddingTop": "1.5rem",
    "paddingBottom": "1.5rem"
  }), {}, {
    "@sm": _objectSpread({}, {
      "padding": "1.5rem"
    }),
    variants: {
      color: {
        flavor: {
          backgroundColor: "$default"
        },
        light: {
          backgroundColor: "$lighter"
        },
        white: {
          backgroundColor: "$white"
        }
      },
      rounded: {
        "true": _objectSpread({}, {
          "borderRadius": "0.5rem"
        })
      },
      withBorder: {
        "true": {
          "@sm": _objectSpread({}, {
            "borderWidth": "1px"
          })
        }
      },
      withShadow: {
        "true": _objectSpread({}, {
          "--tw-shadow": "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
          "--tw-shadow-colored": "0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color)",
          "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
        })
      }
    },
    compoundVariants: [{
      withBorder: true,
      color: "flavor",
      css: {
        "@sm": {
          borderColor: "$default"
        }
      }
    }, {
      withBorder: true,
      color: "light",
      css: {
        "@sm": {
          borderColor: "$light"
        }
      }
    }, {
      withBorder: true,
      color: "white",
      css: {
        "@sm": {
          borderColor: "$gray1"
        }
      }
    }]
  }))
};
export default styles;