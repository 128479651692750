import { PRODUCT_TAGS_BOTTOM_ATTRIBUTE, PRODUCT_TAGS_TOP_ATTRIBUTE } from "@/config/common.config";
import { translate } from "@/helpers/translation.helpers";
export var getTagIcon = function getTagIcon(slug) {
  switch (slug) {
    case "bestseller":
      return "ShoppingBag";

    case "free-app":
      return "Smartphone";

    case "free-shipping":
      return "Truck";

    case "sale":
      return "Procent";

    default:
      return null;
  }
};
export var generateTags = function generateTags(productAttributes, options) {
  var _productAttributes$fi, _productAttributes$fi2;

  if (!productAttributes) return {
    top: [],
    bottom: []
  };
  var top = (productAttributes === null || productAttributes === void 0 || (_productAttributes$fi = productAttributes.find(function (_ref) {
    var attribute = _ref.attribute;
    return attribute.slug === PRODUCT_TAGS_TOP_ATTRIBUTE;
  })) === null || _productAttributes$fi === void 0 || (_productAttributes$fi = _productAttributes$fi.values) === null || _productAttributes$fi === void 0 ? void 0 : _productAttributes$fi.map(function (value) {
    return {
      id: (value === null || value === void 0 ? void 0 : value.id) || "",
      label: translate(value, "name") || "",
      icon: getTagIcon(value.slug)
    };
  })) || [];
  var bottom = (productAttributes === null || productAttributes === void 0 || (_productAttributes$fi2 = productAttributes.find(function (_ref2) {
    var attribute = _ref2.attribute;
    return attribute.slug === PRODUCT_TAGS_BOTTOM_ATTRIBUTE;
  })) === null || _productAttributes$fi2 === void 0 || (_productAttributes$fi2 = _productAttributes$fi2.values) === null || _productAttributes$fi2 === void 0 ? void 0 : _productAttributes$fi2.map(function (value) {
    return {
      id: (value === null || value === void 0 ? void 0 : value.id) || "",
      label: translate(value, "name") || "",
      icon: getTagIcon(value.slug)
    };
  })) || [];

  if (typeof (options === null || options === void 0 ? void 0 : options.topLength) === "number") {
    top.length = options.topLength;
  }

  if (typeof (options === null || options === void 0 ? void 0 : options.bottomLength) === "number") {
    bottom.length = options.bottomLength;
  }

  return {
    top: top,
    bottom: bottom
  };
};