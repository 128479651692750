var __jsx = React.createElement;
import React from "react";
import { cn } from "@/libraries/clsx.library";
import styles from "./Tag.styles";

var Overlay = function Overlay(_ref) {
  var children = _ref.children,
      className = _ref.className,
      variant = _ref.variant;
  return __jsx("div", {
    className: cn(styles.overlay({
      variant: variant
    }), className === null || className === void 0 ? void 0 : className.overlay)
  }, __jsx("div", {
    className: cn(styles.content({
      variant: variant
    }), className === null || className === void 0 ? void 0 : className.content)
  }, children));
};

Overlay.displayName = "Overlay";
export default Overlay;