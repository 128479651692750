export var borders = {
  borderStyles: {
    solid: "solid"
  },
  borderWidths: {
    1: "1px",
    2: "2px"
  },
  radii: {
    1: ".25rem",
    2: ".375rem",
    3: ".5rem",
    4: ".75rem",
    round: "50%"
  }
};
export default borders;