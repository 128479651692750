import _toConsumableArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _slicedToArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import React from "react";
import { UTM_COOKIE_KEY, UTM_PARAMS } from "@/config/analytics.config";
import useCheckout from "@/hooks/useCheckout.hook";
import { useCookie } from "@/hooks/useCookie.hook";
import useCookieConsent from "@/hooks/useCookieConsent.hook";

var UtmParams = function UtmParams() {
  var _useCookie = useCookie(UTM_COOKIE_KEY),
      setUtmCookie = _useCookie.setCookie,
      removeUtmCookie = _useCookie.removeCookie,
      utmCookie = _useCookie.value;

  var _useCookieConsent = useCookieConsent(),
      allowedCookieGroups = _useCookieConsent.allowedCookieGroups;

  var _React$useState = React.useState([]),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      savedUtmParams = _React$useState2[0],
      setSavedUtmParams = _React$useState2[1];

  var _useCheckout = useCheckout(),
      setUtm = _useCheckout.setUtm,
      checkout = _useCheckout.checkout;

  var getUtmParams = React.useCallback(function () {
    var urlParams = new URLSearchParams(window.location.search);
    return UTM_PARAMS.reduce(function (utmParams, utmParamName) {
      var urlUtmParamValue = urlParams.get(utmParamName);
      return urlUtmParamValue ? [].concat(_toConsumableArray(utmParams), [{
        key: utmParamName,
        value: urlUtmParamValue
      }]) : utmParams;
    }, []);
  }, []);
  React.useEffect(function () {
    var utmParams = getUtmParams();

    if (utmParams.length > 0) {
      setSavedUtmParams(utmParams);
    }
  }, [getUtmParams]);
  React.useEffect(function () {
    if (allowedCookieGroups && allowedCookieGroups.includes("marketing")) {
      if (savedUtmParams.length > 0) {
        setUtmCookie(savedUtmParams);
        setSavedUtmParams([]);
      }
    } else {
      removeUtmCookie();
    }
  }, [allowedCookieGroups, removeUtmCookie, savedUtmParams, savedUtmParams.length, setUtmCookie]);
  React.useEffect(function () {
    if (checkout && utmCookie) {
      setUtm(checkout.id, utmCookie).then(function () {
        removeUtmCookie();
      });
    }
  }, [utmCookie, checkout, setUtm, savedUtmParams, removeUtmCookie]);
  return null;
};

UtmParams.displayName = "UtmParams";
export default UtmParams;