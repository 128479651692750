export { default as AdditionalInfo } from "./AdditionalInfo/AdditionalInfo.component";
export { default as AddToCart } from "./AddToCart/AddToCart.component";
export { default as AverageRating } from "./AverageRating/AverageRating.component";
export { default as FloatingAddToCart } from "./FloatingAddToCart/FloatingAddToCart.component";
export { default as Gallery } from "./Gallery/Gallery.component";
export { default as Header } from "./Header/Header.component";
export { default as MainInfo } from "./MainInfo/MainInfo.component";
export { default as OrderDetails } from "./OrderDetails/OrderDetails.component";
export { default as Overlay } from "./Overlay/Overlay.component";
export { default as ReviewForm } from "./ReviewForm/ReviewForm.component";
export { default as ReviewInfo } from "./ReviewInfo/ReviewInfo.component";
export { default as Variants } from "./Variants/Variants.component";
export { default as AddToWishlist } from "@/product/components/AddToWishlist/AddToWishlist.component";
export { default as VariantsInSetList } from "~/src/modules/product/components/VariantsInSetList/VariantsInSetList.component";
export { default as VariantsInSetSlider } from "~/src/modules/product/components/VariantsInSetSlider/VariantsInSetSlider.component";