import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from "react";

var SvgOutlineGooglePlay = function SvgOutlineGooglePlay(props) {
  return __jsx("svg", _extends({
    width: 24,
    height: 25,
    viewBox: "0 0 24 25",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("g", {
    clipPath: "url(#clip0_6429_81949)"
  }, __jsx("path", {
    d: "M3.75 21.5131C3.75201 21.6443 3.78842 21.7727 3.85559 21.8855C3.92276 21.9982 4.01834 22.0914 4.13278 22.1556C4.24722 22.2199 4.37652 22.253 4.50775 22.2516C4.63898 22.2502 4.76755 22.2144 4.88063 22.1478L20.6156 13.1413C20.7319 13.0793 20.8291 12.9869 20.897 12.874C20.9648 12.761 21.0006 12.6317 21.0006 12.5C21.0006 12.3683 20.9648 12.239 20.897 12.126C20.8291 12.0131 20.7319 11.9207 20.6156 11.8588L4.88063 2.85219C4.76755 2.78557 4.63898 2.74979 4.50775 2.74842C4.37652 2.74704 4.24722 2.78013 4.13278 2.84437C4.01834 2.90862 3.92276 3.00176 3.85559 3.11451C3.78842 3.22725 3.75201 3.35565 3.75 3.48688V21.5131Z",
    stroke: props.stroke || "#5D5656",
    strokeWidth: 1.4,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __jsx("path", {
    d: "M3.96851 2.96844L16.5001 15.5",
    stroke: props.stroke || "#5D5656",
    strokeWidth: 1.4,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __jsx("path", {
    d: "M3.96851 22.0316L16.4982 9.50188",
    stroke: props.stroke || "#5D5656",
    strokeWidth: 1.4,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  })), __jsx("defs", null, __jsx("clipPath", {
    id: "clip0_6429_81949"
  }, __jsx("rect", {
    width: 24,
    height: 24,
    fill: "white",
    transform: "translate(0 0.5)"
  }))));
};

export default SvgOutlineGooglePlay;