import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import { Disclosure as Source } from "@headlessui/react";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/solid";
import React, { Fragment } from "react";
import { cn } from "@/libraries/clsx.library";
import styles from "./Disclosure.styles";

var Disclosure = function Disclosure(_ref) {
  var data = _ref.data,
      className = _ref.className,
      _ref$disclosureProps = _ref.disclosureProps,
      disclosureProps = _ref$disclosureProps === void 0 ? {
    onlyFirstOpen: true
  } : _ref$disclosureProps,
      _ref$panelProps = _ref.panelProps,
      panelProps = _ref$panelProps === void 0 ? {} : _ref$panelProps,
      _ref$withoutBottomBor = _ref.withoutBottomBorder,
      withoutBottomBorder = _ref$withoutBottomBor === void 0 ? false : _ref$withoutBottomBor;
  if (!data || data.length === 0) return null;
  return __jsx("dl", {
    className: cn(styles.list({
      withoutBottomBorder: withoutBottomBorder
    }), className)
  }, data.map(function (dataItem, index) {
    return __jsx(Source, _extends({}, disclosureProps, {
      defaultOpen: disclosureProps !== null && disclosureProps !== void 0 && disclosureProps.defaultOpen || disclosureProps !== null && disclosureProps !== void 0 && disclosureProps.onlyFirstOpen ? index === 0 : false,
      key: index
    }), function (_ref2) {
      var open = _ref2.open;
      return __jsx(Fragment, null, __jsx("dt", {
        className: styles.itemHeader()
      }, __jsx(Source.Button, {
        className: styles.headerButton()
      }, __jsx("span", {
        className: styles.headerText()
      }, dataItem.title), open ? __jsx(MinusIcon, {
        className: styles.headerIcon(),
        "aria-hidden": "true"
      }) : __jsx(PlusIcon, {
        className: styles.headerIcon(),
        "aria-hidden": "true"
      }))), __jsx("div", {
        className: styles.itemContent()
      }, open ? __jsx(Source.Panel, _extends({
        "static": true,
        as: "dd",
        className: styles.itemPanel()
      }, panelProps), dataItem.content) : null));
    });
  }));
};

export default Disclosure;