export var accountFieldErrorCodes = ["NOT_FOUND", "REQUIRED", "INACTIVE", "INVALID_CREDENTIALS"];
export var accountFieldErrorMessages = function accountFieldErrorMessages(error) {
  return {
    REQUIRED: {
      id: "filed.error.required",
      defaultMessage: "This field is required"
    },
    INVALID_CREDENTIALS: error.field === "oldPassword" ? {
      id: "account.changePassword.error.invalidCredentials",
      defaultMessage: "Entered password is incorrect"
    } : {
      id: "field.error.invalid",
      defaultMessage: "This field is invalid"
    },
    PASSWORD_TOO_SHORT: {
      id: "field.error.passwordToShort",
      defaultMessage: "Given password is to short"
    },
    NOT_FOUND: error.field === "email" ? {
      id: "field.error.email.notFound",
      defaultMessage: "There is no account with this email"
    } : {
      id: "field.error.invalid",
      defaultMessage: "This field is invalid"
    },
    INACTIVE: error.field === "email" ? {
      id: "field.error.email.inactive",
      defaultMessage: "User with this email is inactive"
    } : {
      id: "field.error.invalid",
      defaultMessage: "This field is invalid"
    },
    UNIQUE: error.field === "email" ? {
      id: "field.error.email.unique",
      defaultMessage: "User with this email already exists"
    } : {
      id: "field.error.invalid",
      defaultMessage: "This field is invalid"
    }
  };
};