var __jsx = React.createElement;
import React from "react";
import { Text } from "@/components/common";
import { Check } from "@/components/ui/Icon/$";
import { PRODUCT_BENEFITS_ATTRIBUTE } from "@/config/common.config";
import { getAttribute } from "@/helpers/attribute.helper";
import { translate } from "@/helpers/translation.helpers";
import styles from "./MainInfo.styles";

var MainInfoComponent = function MainInfoComponent(_ref) {
  var _getAttribute;

  var product = _ref.product;
  var benefits = (_getAttribute = getAttribute(PRODUCT_BENEFITS_ATTRIBUTE, product.attributes)) === null || _getAttribute === void 0 ? void 0 : _getAttribute.value;
  var description = translate(product, "description");
  return __jsx("div", null, description ? __jsx("div", {
    className: styles.description()
  }, __jsx(Text, {
    text: description
  })) : null, benefits && benefits.length > 0 ? __jsx("ul", {
    className: styles.list()
  }, benefits.map(function (benefit, index) {
    return __jsx("li", {
      className: styles.listItem(),
      key: "product-benefit-".concat(index)
    }, __jsx(Check, {
      className: styles.listItemIcon()
    }), benefit);
  })) : null);
};

export default MainInfoComponent;