import _objectWithoutProperties from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["description"];
var __jsx = React.createElement;
import { styled as _styled } from "../../../libraries/stitches.library.ts";
import React from "react";
import styles from "./Placeholder.styles";

var _TwComponent = _styled("div", {});

var Placeholder = function Placeholder(props) {
  var description = props.description,
      rest = _objectWithoutProperties(props, _excluded);

  return __jsx(_TwComponent, {
    css: styles.root(rest)
  }, __jsx("p", {
    className: styles.description()
  }, description));
};

Placeholder.displayName = "Placeholder";
export default Placeholder;