var __jsx = React.createElement;
import React from "react";
import { useIntl } from "react-intl";
import { DummyProduct } from "@/checkout/components";
import { useGlobal } from "@/hooks/useGlobal.hook";
import { VariantSetListItem } from "@/product/components/VariantsInSetList/components";
import LovaAppProductThumbnail from "~/public/mobile-app-product-thumbnail.webp";
import styles from "./VariantsInSetList.styles";

var VariantsInSetList = function VariantsInSetList(_ref) {
  var variantsInSet = _ref.variantsInSet,
      variantsInSetSlider = _ref.variantsInSetSlider;
  var t = useIntl();

  var _useGlobal = useGlobal(),
      lovaAppForEveryOrder = _useGlobal.lovaAppForEveryOrder;

  if (!variantsInSet || !(variantsInSet !== null && variantsInSet !== void 0 && variantsInSet.length)) {
    return null;
  }

  return __jsx("div", {
    className: styles.root()
  }, __jsx("h4", {
    className: styles.title()
  }, " W sk\u0142ad zestawu wchodz\u0105"), __jsx("div", {
    className: styles.productsList()
  }, variantsInSet.map(function (variantInSet, index) {
    return __jsx(VariantSetListItem, {
      index: index,
      variantInSet: variantInSet,
      variantsInSetSlider: variantsInSetSlider,
      key: "variants-set-list-item-".concat(variantInSet.variant.id)
    });
  }), lovaAppForEveryOrder ? __jsx(DummyProduct, {
    name: t.formatMessage({
      id: "dummyProduct.lovaApp.name",
      defaultMessage: "LOVA app"
    }),
    imageSrc: LovaAppProductThumbnail,
    price: 0,
    priceUndiscounted: 19.99,
    detail: t.formatMessage({
      id: "dummyProduct.lovaApp.detail",
      defaultMessage: "30-day access"
    }),
    className: styles.dummyProduct(),
    type: "PRODUCT_SET"
  }) : null));
};

VariantsInSetList.displayName = "VariantsInSetList";
export default VariantsInSetList;