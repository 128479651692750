var __jsx = React.createElement;
import round from "lodash/round";
import React from "react";
import { useIntl } from "react-intl";
import { Rating } from "@/components/ui";
import colors from "@/theme/theme.colors.tw";
import styles from "./ReviewsSummary.styles";

var ReviewsSummary = function ReviewsSummary(_ref) {
  var reviews = _ref.reviews,
      type = _ref.type;
  var t = useIntl();
  var ratingDetails = React.useMemo(function () {
    var reviewsPerRating = {
      "1": 0,
      "2": 0,
      "3": 0,
      "4": 0,
      "5": 0
    };
    var ratingSum = reviews.reduce(function (acc, review) {
      if (review.rating) {
        var ratingStringValue = review.rating.toString();
        reviewsPerRating[ratingStringValue] = (reviewsPerRating[ratingStringValue] || 0) + 1;
        return Number(acc) + Number(review.rating);
      }

      return acc;
    }, 0);
    return {
      averageRating: round(ratingSum / reviews.length, 2),
      reviewsCount: reviews.length,
      reviewsPerRating: reviewsPerRating
    };
  }, [reviews]);
  return __jsx("div", {
    className: styles.root()
  }, __jsx("div", {
    className: styles.left()
  }, __jsx("span", {
    className: styles.averageRating()
  }, ratingDetails.averageRating), __jsx(Rating, {
    rating: ratingDetails.averageRating,
    iconMainColor: colors.flavor["default"].warning,
    iconSecondaryColor: colors.common.gray1,
    iconSize: 16
  }), __jsx("span", {
    className: styles.reviewsCount()
  }, type === "single-product" ? t.formatMessage({
    id: "reviews.summary.reviewsCount",
    defaultMessage: "{reviewsCount} {reviewsCount, plural, one {customer} other {customers}} recommend this product"
  }, {
    reviewsCount: ratingDetails.reviewsCount
  }) : t.formatMessage({
    id: "reviews.summary.reviewsCount.multiProduct",
    defaultMessage: "{reviewsCount} {reviewsCount, plural, one {customer} other {customers}} recommend our products"
  }, {
    reviewsCount: ratingDetails.reviewsCount
  }))), __jsx("div", {
    className: styles.right()
  }, Object.keys(ratingDetails.reviewsPerRating).map(Number).sort(function (a, b) {
    return b - a;
  }).map(function (reviewsPerRatingKey) {
    return __jsx("div", {
      className: styles.ratingProgressContainer(),
      key: reviewsPerRatingKey
    }, __jsx("span", {
      className: styles.rating()
    }, reviewsPerRatingKey), __jsx("div", {
      className: styles.ratingProgressBg()
    }, __jsx("div", {
      className: styles.ratingProgress(),
      style: {
        width: "".concat(ratingDetails.reviewsPerRating[reviewsPerRatingKey] / reviews.length * 100, "%")
      }
    })));
  })));
};

ReviewsSummary.displayName = "ReviewsSummary";
export default ReviewsSummary;