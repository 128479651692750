var __jsx = React.createElement;
import React from "react";
import { CheckCircle as CheckCircleIcon } from "@/components/ui/Icon/$";
import styles from "./ReviewInfo.styles";

var ReviewInfo = function ReviewInfo(_ref) {
  var title = _ref.title,
      description = _ref.description;
  return __jsx("div", {
    className: styles.root()
  }, __jsx(CheckCircleIcon, null), __jsx("h4", {
    className: styles.infoTitle()
  }, title), __jsx("p", {
    className: styles.infoDescription()
  }, description));
};

ReviewInfo.displayName = "ReviewInfo";
export default ReviewInfo;