import _toConsumableArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
var __jsx = React.createElement;
import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { generateTags } from "@/components/ui/Tag/Tag.helpers";
import { up } from "@/helpers/screens.helpers";
import { useMediaQuery } from "@/hooks/useMediaQuery.hook";
import { GalleryDesktop, GalleryMobile } from "@/product/components/Gallery/components";
import { ScreenEnum } from "@/theme/theme.enums";
import { Price } from "~/src/components/ui";
import { getVariantsSetDiscountPrice } from "~/src/helpers/product.helpers";
import useRegion from "~/src/hooks/useRegion.hook";

var Gallery = function Gallery(_ref) {
  var _activeVariant$media;

  var product = _ref.product,
      activeVariant = _ref.activeVariant,
      className = _ref.className;

  var _useState = useState(),
      slider = _useState[0],
      setSlider = _useState[1];

  var _useState2 = useState(0),
      activeSlideIndex = _useState2[0],
      setActiveSlideIndex = _useState2[1];

  var isUpMd = useMediaQuery(up(ScreenEnum.md), false);

  var _useRegion = useRegion(),
      currentChannel = _useRegion.currentChannel;

  var t = useIntl();
  var images = ((_activeVariant$media = activeVariant.media1024) === null || _activeVariant$media === void 0 ? void 0 : _activeVariant$media.reduce(function (accumulator, mediaElement) {
    if (mediaElement.type === "IMAGE") {
      var _activeVariant$media2;

      return [].concat(_toConsumableArray(accumulator), [{
        url: mediaElement.url,
        alt: mediaElement.alt,
        // blurDataURL: product?.media64?.find(m => m.id === mediaElement.id)?.url,
        thumbnail: activeVariant === null || activeVariant === void 0 || (_activeVariant$media2 = activeVariant.media256) === null || _activeVariant$media2 === void 0 || (_activeVariant$media2 = _activeVariant$media2.find(function (m) {
          return m.id === mediaElement.id;
        })) === null || _activeVariant$media2 === void 0 ? void 0 : _activeVariant$media2.url
      }]);
    } else {
      return accumulator;
    }
  }, [])) || [];
  var variantsSetDiscountPrice = getVariantsSetDiscountPrice(product, currentChannel.currencyCode);
  var tags = generateTags(product.attributes);
  var discountTag = variantsSetDiscountPrice.amount > 0 ? __jsx(React.Fragment, null, __jsx("span", null, t.formatMessage({
    id: "tag.variantsSetDiscount",
    defaultMessage: "In the set you save"
  })), __jsx(Price, {
    price: variantsSetDiscountPrice,
    priceOptions: {
      minimumFractionDigits: 2
    },
    bold: true,
    size: "xs"
  })) : null;
  var setSliderAction = useCallback(function (newSlider) {
    setSlider(newSlider);
  }, []);
  var setActiveSlideIndexAction = useCallback(function (newActiveSlideIndex) {
    setActiveSlideIndex(newActiveSlideIndex);
  }, []);
  return __jsx("div", {
    className: className
  }, isUpMd ? __jsx(GalleryDesktop, {
    images: images,
    discountTag: discountTag,
    tags: tags,
    activeSlideIndex: activeSlideIndex,
    setActiveSlideIndex: setActiveSlideIndexAction,
    slider: slider,
    setSlider: setSliderAction
  }) : __jsx(GalleryMobile, {
    images: images,
    discountTag: discountTag,
    tags: tags,
    activeSlideIndex: activeSlideIndex,
    setActiveSlideIndex: setActiveSlideIndexAction,
    setSlider: setSliderAction
  }));
};

export default Gallery;