import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _slicedToArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var __jsx = React.createElement;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React from "react";
import { LOVA_PLUGIN_DEFAULT_PROMO_BAR_ADD_PRODUCT_VARIANT_NAME, LOVA_PLUGIN_DEFAULT_PROMO_BAR_PRODUCT_VARIANT_QUANTITY_NAME, LOVA_PLUGIN_EMAIL_DOMAIN_SUGGESTION_LIST, LOVA_PLUGIN_IS_EMAIL_DOMAIN_DNS_CHECK_ACTIVE, LOVA_PLUGIN_IS_EMAIL_DOMAIN_LIST_CHECK_ACTIVE, LOVA_PLUGIN_IS_LOVA_APP_ACTIVE_NAME, LOVA_PLUGIN_PROMO_BAR_IS_ACTIVE_NAME, LOVA_PLUGIN_SHOW_PROMOBAR_INFO_ON_NON_SHIPPABLE_PRODUCTS_DETAILS, LOVA_PLUGIN_SHOW_PROMOBAR_INFO_ON_SHIPPABLE_PRODUCTS_DETAILS, LOVA_PLUGIN_TEMPORARY1_PROMO_BAR_ADD_PRODUCT_VARIANT_NAME, LOVA_PLUGIN_TEMPORARY1_PROMO_BAR_PRODUCT_VARIANT_QUANTITY_NAME, LOVA_PLUGIN_TEMPORARY1_PROMO_BAR_SHOW_TIMER_NAME, LOVA_PLUGIN_TEMPORARY2_PROMO_BAR_ADD_PRODUCT_VARIANT_NAME, LOVA_PLUGIN_TEMPORARY2_PROMO_BAR_PRODUCT_VARIANT_QUANTITY_NAME, LOVA_PLUGIN_TEMPORARY2_PROMO_BAR_SHOW_TIMER_NAME } from "@/config/plugin.config";
import { mappedDNSCheckValidationRuleElementNames, mappedListCheckValidationRuleElementNames, mappedPromoBarConfigElementNames } from "@/global/global.types";
import { useGlobalQuery } from "@/graphql/$graphql";
import useRegion from "@/hooks/useRegion.hook";
import { isAfterDate, isBeforeDate, isValidDate } from "@/libraries/dayjs.library";
import createContext from "@/libraries/react.library";
import { localeToCountryCode } from "@/region/region.helpers";
var INITIAL_STATE = {
  isLoading: true,
  promoBar: {
    type: "NOT_DEFINED",
    isActive: false,
    isVisible: false,
    text: "",
    url: "",
    addProductVariant: false,
    productVariantId: "",
    productVariantQuantity: 0,
    showOnShippableProductsDetails: false,
    showOnNonShippableProductsDetails: false
  },
  lovaAppForEveryOrder: false,
  validationRules: [],
  shippingMethods: [],
  setPromoBarVisibility: function setPromoBarVisibility() {
    return null;
  }
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_IS_LOADING":
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isLoading: action.payload
        });
      }

    case "SET_PROMO_BAR":
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          promoBar: action.payload
        });
      }

    case "SET_PROMO_BAR_IS_VISIBLE":
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          promoBar: _objectSpread(_objectSpread({}, state.promoBar), {}, {
            isVisible: action.payload
          })
        });
      }

    case "SET_LOVA_APP_FOR_EVERY_ORDER":
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          lovaAppForEveryOrder: action.payload
        });
      }

    case "SET_VALIDATION_RULES":
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          validationRules: action.payload
        });
      }

    case "SET_SHIPPING_METHODS":
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          shippingMethods: action.payload
        });
      }

    default:
      return state;
  }
}

var _createContext = createContext(),
    _createContext2 = _slicedToArray(_createContext, 2),
    useContext = _createContext2[0],
    Provider = _createContext2[1];

export { useContext, Provider };
export var Global = function Global(props) {
  var _useRegion = useRegion(),
      query = _useRegion.query;

  var _React$useReducer = React.useReducer(reducer, INITIAL_STATE),
      _React$useReducer2 = _slicedToArray(_React$useReducer, 2),
      state = _React$useReducer2[0],
      dispatch = _React$useReducer2[1];

  var _useGlobalQuery = useGlobalQuery({
    variables: _objectSpread(_objectSpread({}, query), {}, {
      country: localeToCountryCode(query.locale)
    })
  }),
      globalData = _useGlobalQuery.data,
      globalLoading = _useGlobalQuery.loading;

  var _React$useState = React.useState(),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      promoBarConfig = _React$useState2[0],
      setPromoBarConfig = _React$useState2[1];

  var _React$useState3 = React.useState(state.promoBar.type),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      activePromobarType = _React$useState4[0],
      setActivePromobarType = _React$useState4[1];

  var interval = React.useRef(null);
  var setPromoBarVisibility = React.useCallback(function (isVisible) {
    dispatch({
      type: "SET_PROMO_BAR_IS_VISIBLE",
      payload: isVisible
    });
  }, []);
  var getActivePromobarType = React.useCallback(function (config) {
    if (config.isActive) {
      var temporary1PromoBarStartDate = config.temporary1StartDate;
      var temporary1PromoBarEndDate = config.temporary1EndDate;
      var temporary2PromoBarStartDate = config.temporary2StartDate;
      var temporary2PromoBarEndDate = config.temporary2EndDate;

      if (isValidDate(temporary1PromoBarStartDate) && isValidDate(temporary1PromoBarEndDate) && isBeforeDate({
        sourceDate: temporary1PromoBarStartDate,
        targetDate: temporary1PromoBarEndDate
      }) && isAfterDate({
        targetDate: temporary1PromoBarStartDate
      }) && isBeforeDate({
        targetDate: temporary1PromoBarEndDate
      })) {
        return "TEMPORARY1";
      }

      if (isValidDate(temporary2PromoBarStartDate) && isValidDate(temporary2PromoBarEndDate) && isBeforeDate({
        sourceDate: temporary2PromoBarStartDate,
        targetDate: temporary2PromoBarEndDate
      }) && isAfterDate({
        targetDate: temporary2PromoBarStartDate
      }) && isBeforeDate({
        targetDate: temporary2PromoBarEndDate
      })) {
        return "TEMPORARY2";
      }

      return "DEFAULT";
    }

    return "NOT_DEFINED";
  }, []);
  var setPromoBar = React.useCallback(function (promobarType, config) {
    var commonPromoBarConfiguration = {
      isActive: true,
      showOnShippableProductsDetails: config.showOnShippableProductsDetails,
      showOnNonShippableProductsDetails: config.showOnNonShippableProductsDetails
    };

    switch (promobarType) {
      case "TEMPORARY1":
        {
          dispatch({
            type: "SET_PROMO_BAR",
            payload: _objectSpread(_objectSpread({}, commonPromoBarConfiguration), {}, {
              type: "TEMPORARY1",
              isVisible: !!config.temporary1Text,
              url: config.temporary1Url,
              text: config.temporary1Text,
              startDate: config.temporary1EndDate,
              endDate: config.temporary1EndDate,
              showTimer: config.temporary1ShowTimer,
              addProductVariant: config.temporary1AddProductVariant,
              productVariantId: config.temporary1ProductVariantId,
              productVariantQuantity: config.temporary1ProductVariantQuantity
            })
          });
          break;
        }

      case "TEMPORARY2":
        {
          dispatch({
            type: "SET_PROMO_BAR",
            payload: _objectSpread(_objectSpread({}, commonPromoBarConfiguration), {}, {
              type: "TEMPORARY2",
              isVisible: !!config.temporary2Text,
              url: config.temporary2Url,
              text: config.temporary2Text,
              startDate: config.temporary2EndDate,
              endDate: config.temporary2EndDate,
              showTimer: config.temporary2ShowTimer,
              addProductVariant: config.temporary2AddProductVariant,
              productVariantId: config.temporary2ProductVariantId,
              productVariantQuantity: config.temporary2ProductVariantQuantity
            })
          });
          return;
        }

      case "DEFAULT":
        {
          dispatch({
            type: "SET_PROMO_BAR",
            payload: _objectSpread(_objectSpread({}, commonPromoBarConfiguration), {}, {
              type: "DEFAULT",
              isVisible: !!config.defaultText,
              url: config.defaultUrl,
              text: config.defaultText,
              addProductVariant: config.defaultAddProductVariant,
              productVariantId: config.defaultProductVariantId,
              productVariantQuantity: config.defaultProductVariantQuantity
            })
          });
          return;
        }

      case "NOT_DEFINED":
        {
          dispatch({
            type: "SET_PROMO_BAR",
            payload: INITIAL_STATE.promoBar
          });
        }
    }
  }, []);
  React.useEffect(function () {
    var _globalData$lovaPlugi, _globalData$lovaPlugi2, _globalData$lovaPlugi3, _globalData$lovaPlugi4, _globalData$shop$avai, _globalData$shop;

    dispatch({
      type: "SET_IS_LOADING",
      payload: true
    });
    var promoBarConfiguration = globalData === null || globalData === void 0 || (_globalData$lovaPlugi = globalData.lovaPlugin.globalConfiguration) === null || _globalData$lovaPlugi === void 0 || (_globalData$lovaPlugi = _globalData$lovaPlugi.configuration) === null || _globalData$lovaPlugi === void 0 ? void 0 : _globalData$lovaPlugi.reduce(function (acc, configurationElement) {
      var configurationElementName = configurationElement.name;

      var configurationElementValue = function () {
        switch (configurationElementName) {
          case LOVA_PLUGIN_PROMO_BAR_IS_ACTIVE_NAME:
          case LOVA_PLUGIN_DEFAULT_PROMO_BAR_ADD_PRODUCT_VARIANT_NAME:
          case LOVA_PLUGIN_TEMPORARY1_PROMO_BAR_SHOW_TIMER_NAME:
          case LOVA_PLUGIN_TEMPORARY1_PROMO_BAR_ADD_PRODUCT_VARIANT_NAME:
          case LOVA_PLUGIN_TEMPORARY2_PROMO_BAR_SHOW_TIMER_NAME:
          case LOVA_PLUGIN_TEMPORARY2_PROMO_BAR_ADD_PRODUCT_VARIANT_NAME:
          case LOVA_PLUGIN_SHOW_PROMOBAR_INFO_ON_SHIPPABLE_PRODUCTS_DETAILS:
          case LOVA_PLUGIN_SHOW_PROMOBAR_INFO_ON_NON_SHIPPABLE_PRODUCTS_DETAILS:
            return configurationElement.value === "true";

          case LOVA_PLUGIN_DEFAULT_PROMO_BAR_PRODUCT_VARIANT_QUANTITY_NAME:
          case LOVA_PLUGIN_TEMPORARY1_PROMO_BAR_PRODUCT_VARIANT_QUANTITY_NAME:
          case LOVA_PLUGIN_TEMPORARY2_PROMO_BAR_PRODUCT_VARIANT_QUANTITY_NAME:
            return Number(configurationElement.value);

          default:
            return configurationElement.value;
        }
      }();

      var isPromoBarElement = Object.keys(mappedPromoBarConfigElementNames).includes(configurationElementName);
      if (!isPromoBarElement) return acc;
      return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, mappedPromoBarConfigElementNames[configurationElementName], configurationElementValue));
    }, {});

    if (promoBarConfiguration) {
      setPromoBarConfig(promoBarConfiguration);
    }

    var emailDomainListCheckValidationRule = globalData === null || globalData === void 0 || (_globalData$lovaPlugi2 = globalData.lovaPlugin.globalConfiguration) === null || _globalData$lovaPlugi2 === void 0 || (_globalData$lovaPlugi2 = _globalData$lovaPlugi2.configuration) === null || _globalData$lovaPlugi2 === void 0 ? void 0 : _globalData$lovaPlugi2.reduce(function (acc, configurationElement, index, arr) {
      var configurationElementValue;
      var configurationElementName = configurationElement.name;
      var isValidationRuleElement = Object.keys(mappedListCheckValidationRuleElementNames).includes(configurationElementName);
      if (!isValidationRuleElement) return acc;

      switch (configurationElementName) {
        case LOVA_PLUGIN_IS_EMAIL_DOMAIN_LIST_CHECK_ACTIVE:
          {
            configurationElementValue = configurationElement.value === "true";
            break;
          }

        case LOVA_PLUGIN_EMAIL_DOMAIN_SUGGESTION_LIST:
          {
            var _arr$find;

            var domains = (_arr$find = arr.find(function (e) {
              return e.name === LOVA_PLUGIN_EMAIL_DOMAIN_SUGGESTION_LIST;
            })) === null || _arr$find === void 0 ? void 0 : _arr$find.value;

            if (domains && domains.length) {
              configurationElementValue = {
                domains: domains.split(",").map(function (domain) {
                  return domain.trim();
                })
              };
              break;
            }

            configurationElementValue = {
              domains: []
            };
          }
      }

      return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, mappedListCheckValidationRuleElementNames[configurationElementName], configurationElementValue));
    }, {
      type: "EMAIL_DOMAIN_LIST_CHECK"
    });
    var emailDomainDNSCheckValidationRule = globalData === null || globalData === void 0 || (_globalData$lovaPlugi3 = globalData.lovaPlugin.globalConfiguration) === null || _globalData$lovaPlugi3 === void 0 || (_globalData$lovaPlugi3 = _globalData$lovaPlugi3.configuration) === null || _globalData$lovaPlugi3 === void 0 ? void 0 : _globalData$lovaPlugi3.reduce(function (acc, configurationElement) {
      var configurationElementValue;
      var configurationElementName = configurationElement.name;
      var isValidationRuleElement = Object.keys(mappedDNSCheckValidationRuleElementNames).includes(configurationElementName);
      if (!isValidationRuleElement) return acc;

      switch (configurationElementName) {
        case LOVA_PLUGIN_IS_EMAIL_DOMAIN_DNS_CHECK_ACTIVE:
          {
            configurationElementValue = configurationElement.value === "true";
            break;
          }
      }

      return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, mappedDNSCheckValidationRuleElementNames[configurationElementName], configurationElementValue));
    }, {
      type: "EMAIL_DOMAIN_DNS_CHECK"
    });

    if (emailDomainListCheckValidationRule && emailDomainDNSCheckValidationRule) {
      dispatch({
        type: "SET_VALIDATION_RULES",
        payload: [emailDomainListCheckValidationRule, emailDomainDNSCheckValidationRule]
      });
    }

    var lovaAppIsActive = Boolean((globalData === null || globalData === void 0 || (_globalData$lovaPlugi4 = globalData.lovaPlugin.globalConfiguration) === null || _globalData$lovaPlugi4 === void 0 || (_globalData$lovaPlugi4 = _globalData$lovaPlugi4.configuration) === null || _globalData$lovaPlugi4 === void 0 || (_globalData$lovaPlugi4 = _globalData$lovaPlugi4.find(function (configurationElement) {
      return configurationElement.name === LOVA_PLUGIN_IS_LOVA_APP_ACTIVE_NAME;
    })) === null || _globalData$lovaPlugi4 === void 0 ? void 0 : _globalData$lovaPlugi4.value) === "true");
    dispatch({
      type: "SET_LOVA_APP_FOR_EVERY_ORDER",
      payload: lovaAppIsActive
    });
    var shippingMethods = (_globalData$shop$avai = globalData === null || globalData === void 0 || (_globalData$shop = globalData.shop) === null || _globalData$shop === void 0 || (_globalData$shop = _globalData$shop.availableShippingMethods) === null || _globalData$shop === void 0 ? void 0 : _globalData$shop.filter(function (shippingMethod) {
      var _shippingMethod$metad;

      return ((_shippingMethod$metad = shippingMethod.metadata) === null || _shippingMethod$metad === void 0 || (_shippingMethod$metad = _shippingMethod$metad.find(function (meta) {
        return meta.key === "hideOnStorefront";
      })) === null || _shippingMethod$metad === void 0 ? void 0 : _shippingMethod$metad.value) !== "true";
    })) !== null && _globalData$shop$avai !== void 0 ? _globalData$shop$avai : [];
    dispatch({
      type: "SET_SHIPPING_METHODS",
      payload: shippingMethods
    });
    dispatch({
      type: "SET_IS_LOADING",
      payload: false
    });
  }, [globalData]);
  React.useEffect(function () {
    dispatch({
      type: "SET_IS_LOADING",
      payload: globalLoading
    });
  }, [globalLoading]);
  React.useEffect(function () {
    if (promoBarConfig) {
      if (interval.current) {
        clearInterval(interval.current);
      }

      interval.current = setInterval(function () {
        var currentActivePromoBarType = getActivePromobarType(promoBarConfig);

        if (currentActivePromoBarType) {
          setActivePromobarType(currentActivePromoBarType);
        }
      }, 1000);
    }

    return function () {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [promoBarConfig, getActivePromobarType]);
  React.useEffect(function () {
    if (activePromobarType !== state.promoBar.type && promoBarConfig) {
      setPromoBar(activePromobarType, promoBarConfig);
    }
  }, [activePromobarType, promoBarConfig, setPromoBar, state.promoBar.type]);
  var value = React.useMemo(function () {
    return _objectSpread(_objectSpread({}, state), {}, {
      setPromoBarVisibility: setPromoBarVisibility
    });
  }, [setPromoBarVisibility, state]);
  return __jsx(Provider, _extends({
    value: value
  }, props));
};