import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

var _DayJsLocale;

import "dayjs/locale/pl";
import "dayjs/locale/en";
import dayjs from "dayjs";
import { LOCALE } from "@/config/region.config";
var DayJsLocale = (_DayJsLocale = {}, _defineProperty(_DayJsLocale, LOCALE.plPL, "pl"), _defineProperty(_DayJsLocale, LOCALE.enUS, "en"), _DayJsLocale);
export var setLocale = function setLocale(locale) {
  return dayjs.locale(DayJsLocale[locale]);
};
export var buildDate = function buildDate(date) {
  return dayjs(date);
};
export var getDateFormat = function getDateFormat(mode) {
  switch (mode) {
    case "date":
      {
        return "DD/MM/YYYY";
      }

    case "time":
      {
        return "hh:mm";
      }

    case "dateTime":
      {
        return "DD/MM/YYYY hh:mm";
      }

    case "full":
      {
        return "dddd, D MMMM YYYY hh:mm";
      }

    case "appApi":
      {
        return "YYYY-MM-DD";
      }
  }
};
export var formatDate = function formatDate(_ref) {
  var _ref$date = _ref.date,
      date = _ref$date === void 0 ? buildDate() : _ref$date,
      _ref$mode = _ref.mode,
      mode = _ref$mode === void 0 ? "date" : _ref$mode,
      format = _ref.format;
  return dayjs(date).format(format || getDateFormat(format || mode));
};
export var isValidDate = function isValidDate(date) {
  return dayjs(date).isValid();
};
export var isBeforeDate = function isBeforeDate(_ref2) {
  var _ref2$sourceDate = _ref2.sourceDate,
      sourceDate = _ref2$sourceDate === void 0 ? buildDate() : _ref2$sourceDate,
      _ref2$targetDate = _ref2.targetDate,
      targetDate = _ref2$targetDate === void 0 ? buildDate() : _ref2$targetDate,
      unit = _ref2.unit;
  return dayjs(sourceDate).isBefore(targetDate, unit);
};
export var isAfterDate = function isAfterDate(_ref3) {
  var targetDate = _ref3.targetDate,
      unit = _ref3.unit;
  return dayjs().isAfter(targetDate, unit);
};
export var subtractFromDate = function subtractFromDate(_ref4) {
  var _ref4$date = _ref4.date,
      date = _ref4$date === void 0 ? buildDate() : _ref4$date,
      value = _ref4.value,
      unit = _ref4.unit;
  return dayjs(date).subtract(value, unit);
};
export var addToDate = function addToDate(_ref5) {
  var _ref5$date = _ref5.date,
      date = _ref5$date === void 0 ? buildDate() : _ref5$date,
      value = _ref5.value,
      unit = _ref5.unit;
  return dayjs(date).add(value, unit);
};
export var diff = function diff(_ref6) {
  var sourceDate = _ref6.sourceDate,
      targetDate = _ref6.targetDate;
  return dayjs(sourceDate).diff(dayjs(targetDate));
};