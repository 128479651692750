/**
 * NOTE: Keep this in sync with the (custom) Tailwind theme `fontFamily`, `fontWeight` config.
 */

module.exports = {
  families: {
    tenor: ["Tenor", "serif"],
    inria: ["Inria", "serif"],
    inter: ["Inter", "-apple-system", "sans-serif"],
  },
  fontWeights: {
    thin: "100",
    extralight: "200",
    light: "300",
    normal: "400",
    regular: "400",
    medium: "500",
    semibold: "600",
    bold: "700",
    extrabold: "800",
    black: "900",
  },
};
