var __jsx = React.createElement;
import React from "react";
import { Icon } from "@/components/ui";
import { cn } from "@/libraries/clsx.library";
import styles from "./Loader.styles";

var Loader = function Loader(_ref) {
  var _ref$size = _ref.size,
      size = _ref$size === void 0 ? "md" : _ref$size,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? "flavor" : _ref$color,
      _ref$type = _ref.type,
      type = _ref$type === void 0 ? "bounce" : _ref$type,
      _ref$single = _ref.single,
      single = _ref$single === void 0 ? false : _ref$single;
  var circleClassName = styles.circle({
    size: size,
    color: color,
    type: type
  });
  return type === "spinner" ? __jsx("div", {
    className: styles.spinner()
  }, __jsx(Icon, {
    name: "Spinner",
    className: styles.spinnerIcon()
  })) : __jsx("div", {
    className: styles.dots()
  }, __jsx("div", {
    className: cn(circleClassName)
  }), single ? null : __jsx("div", {
    className: cn(circleClassName)
  }), single ? null : __jsx("div", {
    className: cn(circleClassName)
  }));
};

export default Loader;