import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread(_objectSpread({}, {
    "paddingLeft": "1.25rem"
  }), {}, {
    "&:first-child": {
      "&>a": _objectSpread({}, {
        "paddingTop": "1.25rem"
      }),
      "&>span": _objectSpread({}, {
        "paddingTop": "1.25rem"
      })
    },
    "&:last-child": {
      "&>a": _objectSpread({}, {
        "paddingBottom": "1.25rem"
      }),
      "&>span": _objectSpread({}, {
        "paddingBottom": "1.25rem"
      })
    }
  })),
  submenuItem: _css(_objectSpread(_objectSpread({}, {
    "position": "relative",
    "display": "inline-flex",
    "height": "100%",
    "width": "100%",
    "cursor": "pointer",
    "alignItems": "center",
    "paddingTop": "1rem",
    "paddingBottom": "1rem",
    "paddingLeft": "0px",
    "paddingRight": "1.25rem",
    "transitionProperty": "all",
    "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "transitionDuration": "150ms",
    "animationDuration": "150ms"
  }), {}, {
    outline: "none",
    fontSize: "$3",
    "&::after": _objectSpread(_objectSpread({}, {
      "position": "absolute",
      "left": "0px",
      "top": "50%",
      "height": "0.375rem",
      "width": "0.375rem",
      "borderRadius": "9999px",
      "transitionProperty": "all",
      "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
      "transitionDuration": "150ms",
      "animationDuration": "150ms"
    }), {}, {
      content: "",
      transform: "translateY(-50%)"
    }),
    variants: {
      active: {
        "true": _objectSpread(_objectSpread({}, {
          "paddingLeft": "1.25rem"
        }), {}, {
          color: "$default",
          fontWeight: "$semibold",
          "&::after": {
            backgroundColor: "$default"
          }
        }),
        "false": {
          "&::after": {
            backgroundColor: "transparent"
          }
        }
      },
      highlighted: {
        "true": {
          color: "$default"
        }
      }
    },
    "& svg": _objectSpread({}, {
      "marginLeft": "1rem",
      "height": "1.5rem",
      "width": "1.5rem"
    })
  }))
};
export default styles;