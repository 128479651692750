var __jsx = React.createElement;
import React from "react";
import { Image } from "@/components/common";
import { ImagePlacecholder, Slider, Tag } from "@/components/ui";
import styles from "./GalleryDesktop.styles";

var GalleryDesktop = function GalleryDesktop(_ref) {
  var images = _ref.images,
      activeSlideIndex = _ref.activeSlideIndex,
      setActiveSlideIndex = _ref.setActiveSlideIndex,
      slider = _ref.slider,
      discountTag = _ref.discountTag,
      tags = _ref.tags,
      setSlider = _ref.setSlider;

  var handleImageChange = function handleImageChange(newImageIndex) {
    if (slider) {
      slider.slideTo(newImageIndex, 300);
    }
  };

  return __jsx("div", {
    className: styles.root()
  }, __jsx("div", {
    className: styles.container()
  }, __jsx(Tag, null, __jsx(Tag.Group, null, discountTag ? __jsx("div", {
    className: styles.discountTagContainer()
  }, __jsx(Tag.Item, {
    color: "white",
    className: styles.discountTag()
  }, discountTag)) : null, tags.top.map(function (_ref2) {
    var id = _ref2.id,
        label = _ref2.label,
        icon = _ref2.icon;
    return __jsx(Tag.Item, {
      color: "lighten",
      icon: icon,
      key: id
    }, label);
  })), __jsx(Tag.Group, null, tags.bottom.map(function (_ref3) {
    var id = _ref3.id,
        label = _ref3.label,
        icon = _ref3.icon;
    return __jsx(Tag.Item, {
      color: "light",
      icon: icon,
      key: id
    }, label);
  }))), images.length > 0 ? __jsx(Slider, {
    className: styles.slider(),
    onSwiper: function onSwiper(swiper) {
      setSlider(swiper);
    },
    onSlideChange: function onSlideChange(swiper) {
      setActiveSlideIndex(swiper.activeIndex);
    },
    onAfterInit: function onAfterInit() {
      setActiveSlideIndex(0);
    },
    initialSlide: 0
  }, images.map(function (image, imageIndex) {
    return __jsx("div", {
      className: styles.image(),
      key: imageIndex
    }, __jsx(Image, {
      id: "__product-image-".concat(imageIndex),
      src: image.url,
      alt: image.alt,
      placeholder: "blur" // blurDataURL={image.blurDataURL}
      ,
      unoptimized: true,
      fill: true,
      sizes: "100vw",
      style: {
        objectFit: "cover",
        objectPosition: "center"
      }
    }));
  })) : __jsx(ImagePlacecholder, null)), images.length > 1 ? __jsx("div", {
    className: styles.list()
  }, images.map(function (image, index) {
    return __jsx("div", {
      className: styles.listImageWrapper({
        active: activeSlideIndex === index
      }),
      key: "gallery-image-".concat(index)
    }, __jsx("div", {
      className: styles.listImageContainer()
    }, __jsx(Image, {
      src: image.thumbnail || image.url,
      alt: image.alt,
      placeholder: "blur" // blurDataURL={image.blurDataURL}
      ,
      unoptimized: true,
      onClick: function onClick() {
        return handleImageChange(index);
      },
      sizes: "100vw",
      fill: true,
      style: {
        objectFit: "cover",
        objectPosition: "center"
      }
    })));
  })) : null);
};

export default GalleryDesktop;