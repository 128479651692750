var __jsx = React.createElement;
import React from "react";
import { Icon } from "@/components/ui";
import { cn } from "@/libraries/clsx.library";
import styles from "./Tag.styles";

var Item = function Item(_ref) {
  var children = _ref.children,
      className = _ref.className,
      icon = _ref.icon,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? "light" : _ref$color;
  return __jsx("div", {
    className: cn(styles.item({
      color: color
    }), className)
  }, icon ? __jsx(Icon, {
    name: icon,
    className: styles.icon()
  }) : null, __jsx("div", {
    className: styles.children()
  }, children));
};

Item.displayName = "Item";
export default Item;