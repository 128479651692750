var __jsx = React.createElement;
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { useIntl } from "react-intl";
import { Image, Text } from "@/components/common";
import { Button, ImagePlacecholder, Price, Spacer, Tag } from "@/components/ui";
import { generateTags } from "@/components/ui/Tag/Tag.helpers";
import { scrollIntoView } from "@/helpers/scroll.helper";
import { translate } from "@/helpers/translation.helpers";
import useCheckout from "@/hooks/useCheckout.hook";
import { AverageRating } from "@/product/components";
import { getVariantsSetDiscountPrice } from "~/src/helpers/product.helpers";
import useRegion from "~/src/hooks/useRegion.hook";
import { useProductVariant } from "~/src/modules/product/hooks/useProductVariant.hook";
import styles from "./FeaturedProduct.styles";

var Overview = function Overview(_ref) {
  var _product$seoDescripti;

  var category = _ref.category,
      product = _ref.product,
      clientReviewPages = _ref.clientReviewPages,
      _ref$action = _ref.action,
      action = _ref$action === void 0 ? "add-to-cart" : _ref$action;
  var t = useIntl();
  var router = useRouter();

  var _useRegion = useRegion(),
      currentChannel = _useRegion.currentChannel;

  var _useCheckout = useCheckout(),
      loading = _useCheckout.loading,
      submitting = _useCheckout.submitting;

  var _useProductVariant = useProductVariant(product.defaultVariant),
      name = _useProductVariant.name,
      availableQuantity = _useProductVariant.availableQuantity,
      isAvailableForPurchase = _useProductVariant.isAvailableForPurchase,
      volume = _useProductVariant.volume,
      priceUndiscounted = _useProductVariant.priceUndiscounted,
      price = _useProductVariant.price,
      benefits = _useProductVariant.benefits,
      clientReviews = _useProductVariant.clientReviews,
      media1024Url = _useProductVariant.media.media1024Url,
      variantHref = _useProductVariant.href,
      addToCart = _useProductVariant.addToCart;

  var activeVariant = product.defaultVariant;
  var description = translate(product, "description");
  var filteredClientReviewPages = clientReviewPages.filter(function (clientReviewPage) {
    return clientReviews.some(function (clientReviewAttribute) {
      return clientReviewAttribute.values.some(function (value) {
        return value.reference === clientReviewPage.id;
      });
    });
  });
  var variantsSetDiscountPrice = getVariantsSetDiscountPrice(product, currentChannel.currencyCode);
  var discountTag = variantsSetDiscountPrice.amount > 0 ? __jsx(React.Fragment, null, __jsx("span", null, t.formatMessage({
    id: "tag.variantsSetDiscount",
    defaultMessage: "In the set you save"
  })), __jsx(Price, {
    price: variantsSetDiscountPrice,
    priceOptions: {
      minimumFractionDigits: 2
    },
    bold: true,
    size: "xs"
  })) : null;
  var tags = generateTags(product.attributes, {
    topLength: 1,
    bottomLength: 1
  });

  var handleClickRating = function handleClickRating() {
    router.push(variantHref).then(function () {
      scrollIntoView("__product-customers", {
        block: "center"
      });
    });
  };

  return __jsx("div", {
    className: styles.root()
  }, __jsx("div", {
    className: styles.imageContainer()
  }, __jsx(Link, {
    href: variantHref,
    passHref: true
  }, __jsx(Tag, null, __jsx(Tag.Group, null, discountTag ? __jsx("div", {
    className: styles.discountTagContainer()
  }, __jsx(Tag.Item, {
    color: "white",
    className: styles.discountTag()
  }, discountTag)) : null, tags.top.map(function (_ref2) {
    var id = _ref2.id,
        label = _ref2.label,
        icon = _ref2.icon;
    return __jsx(Tag.Item, {
      color: "lighten",
      icon: icon,
      key: id
    }, label);
  })), __jsx(Tag.Group, null, tags.bottom.map(function (_ref3) {
    var id = _ref3.id,
        label = _ref3.label,
        icon = _ref3.icon;
    return __jsx(Tag.Item, {
      color: "light",
      icon: icon,
      key: id
    }, label);
  }))), media1024Url ? __jsx(Image, {
    src: media1024Url,
    placeholder: "blur",
    alt: (_product$seoDescripti = product.seoDescription) !== null && _product$seoDescripti !== void 0 ? _product$seoDescripti : "Lova Care. Featured product",
    unoptimized: true,
    fill: true,
    sizes: "(max-width: 768px) 100vw, 50vw",
    style: {
      objectFit: "cover",
      objectPosition: "center"
    }
  }) : __jsx(ImagePlacecholder, {
    size: "sm"
  }))), __jsx("div", {
    className: styles.infoContainer()
  }, __jsx("p", {
    className: styles.category()
  }, category), __jsx(Link, {
    href: variantHref,
    passHref: true
  }, __jsx("h3", {
    className: styles.name()
  }, name)), __jsx(AverageRating, {
    pages: filteredClientReviewPages,
    iconSize: 16,
    clickAction: handleClickRating
  }), __jsx(Spacer, {
    height: 20
  }), __jsx("div", {
    className: styles.description()
  }, description && __jsx(Text, {
    text: description
  })), benefits && (benefits === null || benefits === void 0 ? void 0 : benefits.length) > 0 ? __jsx(React.Fragment, null, __jsx("ul", {
    className: styles.list()
  }, benefits.map(function (benefit, index) {
    return __jsx("li", {
      key: "product-overview-".concat(index)
    }, benefit);
  }))) : null, price ? __jsx(Price, {
    price: price,
    priceUndiscounted: priceUndiscounted,
    fontFamily: "tenor",
    size: "lg"
  }) : null, volume ? __jsx("p", {
    className: styles.details()
  }, volume) : null, __jsx(Spacer, {
    height: 30
  }), action === "link" ? __jsx(Link, {
    href: variantHref,
    passHref: true,
    legacyBehavior: true
  }, __jsx(Button, {
    color: "flavorLight",
    variant: "oval",
    className: styles.button()
  }, t.formatMessage({
    defaultMessage: "Find out more",
    id: "storefront.sections.bestseller.button"
  }))) : action === "add-to-cart" ? __jsx(Button, {
    variant: "oval",
    color: "flavor",
    disabled: !isAvailableForPurchase || !(availableQuantity > 0) || submitting || loading,
    onClick: function onClick() {
      return addToCart(activeVariant, 1, "list");
    },
    loading: false,
    className: styles.button()
  }, t.formatMessage({
    defaultMessage: "Add to cart",
    id: "cart.addToCart"
  })) : null));
};

Overview.displayName = "Overview";
export default Overview;