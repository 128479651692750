import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../libraries/stitches.library.ts";
var styles = {
  dots: _css(_objectSpread({}, {
    "position": "absolute",
    "left": "50%",
    "top": "50%",
    "display": "flex",
    "--tw-translate-x": "-50%",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
    "--tw-translate-y": "-50%",
    "gap": "0.5rem"
  })),
  circle: _css(_objectSpread(_objectSpread({}, {
    "borderRadius": "9999px",
    "transitionProperty": "all",
    "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "transitionDuration": "300ms",
    "animationDuration": "300ms",
    "animationTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)"
  }), {}, {
    "&:nth-child(1)": {
      animationDelay: "0.1s"
    },
    "&:nth-child(2)": {
      animationDelay: "0.3s"
    },
    "&:nth-child(3)": {
      animationDelay: "0.5s"
    },
    variants: {
      size: {
        sm: _objectSpread({}, {
          "height": "0.75rem",
          "width": "0.75rem"
        }),
        md: _objectSpread({}, {
          "height": "1rem",
          "width": "1rem"
        }),
        lg: _objectSpread({}, {
          "height": "1.25rem",
          "width": "1.25rem"
        })
      },
      color: {
        dark: {
          backgroundColor: "$black"
        },
        transparent: {
          backgroundColor: "transparent"
        },
        success: {
          backgroundColor: "$success"
        },
        light: {
          backgroundColor: "$white"
        },
        flavor: {
          backgroundColor: "$default"
        },
        gray: {
          backgroundColor: "$gray5"
        }
      },
      type: {
        bounce: _objectSpread({}, {
          "animation": "custom-bounce 1.5s ease-in-out infinite"
        }),
        pulse: _objectSpread({}, {
          "animation": "pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite"
        }),
        ping: _objectSpread({}, {
          "animation": "ping 1s cubic-bezier(0, 0, 0.2, 1) infinite"
        }),
        spinner: _objectSpread({}, {
          "animation": "spin 1s linear infinite"
        })
      }
    }
  })),
  spinner: _css(_objectSpread({}, {
    "position": "absolute",
    "left": "50%",
    "top": "50%",
    "display": "flex",
    "--tw-translate-x": "-50%",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
    "--tw-translate-y": "-50%",
    "gap": "0.5rem"
  })),
  spinnerIcon: _css(_objectSpread({}, {
    "height": "1.25rem",
    "width": "1.25rem",
    "animation": "spin 1s linear infinite"
  }))
};
export default styles;