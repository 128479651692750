import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from "react";

var SvgCheckCircle = function SvgCheckCircle(props) {
  return __jsx("svg", _extends({
    width: 49,
    height: 48,
    viewBox: "0 0 49 48",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("path", {
    d: "M15.8594 24.9602L21.6194 30.7202L33.1394 19.2002",
    stroke: "#C24163",
    strokeWidth: 2.88,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __jsx("rect", {
    x: 1.748,
    y: 1.248,
    width: 45.504,
    height: 45.504,
    rx: 22.752,
    stroke: "#C24163",
    strokeWidth: 2.496
  }));
};

export default SvgCheckCircle;