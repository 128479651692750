var __jsx = React.createElement;
import NextHead from "next/head";
import { DefaultSeo } from "next-seo";
import React, { Fragment } from "react";
import { STOREFRONT_NAME } from "@/config/common.config";
var defaultSeo = {
  title: STOREFRONT_NAME,
  titleTemplate: "%s",
  description: "".concat(STOREFRONT_NAME, " e-commerce"),
  twitter: {
    cardType: "summary_large_image"
  },
  openGraph: {
    type: "website",
    images: [{
      url: "/social.png"
    }]
  }
};

var Head = function Head() {
  return __jsx(Fragment, null, __jsx(DefaultSeo, defaultSeo), __jsx(NextHead, null, __jsx("meta", {
    name: "viewport",
    content: "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0",
    key: "viewport"
  }), __jsx("link", {
    rel: "apple-touch-icon",
    sizes: "180x180",
    href: "/apple-touch-icon.png",
    key: "apple"
  }), __jsx("link", {
    rel: "mask-icon",
    href: "/safari-pinned-tab.svg",
    color: "#C24264",
    key: "mask"
  }), __jsx("link", {
    rel: "icon",
    type: "image/png",
    href: "/favicon-16x16.png"
  }), __jsx("link", {
    rel: "icon",
    type: "image/png",
    href: "/favicon-32x32.png"
  }), __jsx("link", {
    rel: "icon",
    href: "/favicon-16x16.svg",
    type: "image/svg+xml",
    key: "icon16"
  }), __jsx("link", {
    rel: "icon",
    href: "/favicon-32x32.svg",
    type: "image/svg+xml",
    key: "icon32"
  }), __jsx("meta", {
    name: "msapplication-TileColor",
    content: "#C24264",
    key: "tile"
  }), __jsx("meta", {
    name: "theme-color",
    content: "#F3F2F2",
    key: "theme"
  }), __jsx("link", {
    rel: "manifest",
    href: "/site.webmanifest",
    crossOrigin: "use-credentials"
  })));
};

export default Head;