import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from "react";

var SvgAppStore = function SvgAppStore(props) {
  return __jsx("svg", _extends({
    width: 144,
    height: 48,
    viewBox: "0 0 144 48",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("rect", {
    width: 144,
    height: 48,
    rx: 8,
    fill: "black"
  }), __jsx("path", {
    d: "M97.832 23.0404V25.7897H96.1087V27.5926H97.832V33.7186C97.832 35.8104 98.7783 36.6471 101.159 36.6471C101.577 36.6471 101.976 36.5973 102.324 36.5375V34.7545C102.026 34.7844 101.836 34.8043 101.508 34.8043C100.442 34.8043 99.9736 34.3063 99.9736 33.1707V27.5926H102.324V25.7897H99.9736V23.0404H97.832Z",
    fill: "white"
  }), __jsx("path", {
    d: "M108.389 36.7965C111.557 36.7965 113.499 34.6748 113.499 31.1586C113.499 27.6623 111.547 25.5307 108.389 25.5307C105.221 25.5307 103.269 27.6623 103.269 31.1586C103.269 34.6748 105.211 36.7965 108.389 36.7965ZM108.389 34.894C106.526 34.894 105.48 33.5293 105.48 31.1586C105.48 28.8078 106.526 27.4332 108.389 27.4332C110.242 27.4332 111.298 28.8078 111.298 31.1586C111.298 33.5194 110.242 34.894 108.389 34.894Z",
    fill: "white"
  }), __jsx("path", {
    d: "M115.161 36.5873H117.302V30.1824C117.302 28.6584 118.448 27.6324 120.072 27.6324C120.45 27.6324 121.088 27.7022 121.267 27.7619V25.6502C121.038 25.5904 120.629 25.5606 120.311 25.5606C118.896 25.5606 117.691 26.3375 117.382 27.4033H117.223V25.7399H115.161V36.5873Z",
    fill: "white"
  }), __jsx("path", {
    d: "M126.584 27.3535C128.168 27.3535 129.204 28.4592 129.254 30.1625H123.775C123.895 28.4692 125.001 27.3535 126.584 27.3535ZM129.244 33.6588C128.846 34.5055 127.959 34.9736 126.664 34.9736C124.951 34.9736 123.845 33.7684 123.775 31.8658V31.7463H131.435V30.9992C131.435 27.5926 129.613 25.5307 126.594 25.5307C123.536 25.5307 121.594 27.732 121.594 31.1985C121.594 34.6649 123.497 36.7965 126.604 36.7965C129.085 36.7965 130.818 35.6012 131.306 33.6588H129.244Z",
    fill: "white"
  }), __jsx("path", {
    d: "M83.7877 32.5815C83.9529 35.2452 86.1726 36.9487 89.4763 36.9487C93.0072 36.9487 95.2166 35.1626 95.2166 32.3131C95.2166 30.0727 93.957 28.8338 90.9011 28.1215L89.2595 27.7188C87.3186 27.2646 86.5339 26.6554 86.5339 25.592C86.5339 24.2499 87.7522 23.3723 89.5796 23.3723C91.314 23.3723 92.5117 24.2292 92.7285 25.6024H94.9791C94.8449 23.0936 92.6355 21.3281 89.6106 21.3281C86.3584 21.3281 84.1903 23.0936 84.1903 25.7469C84.1903 27.9356 85.4189 29.2365 88.1135 29.8663L90.0338 30.3308C92.0058 30.7954 92.873 31.4768 92.873 32.6125C92.873 33.934 91.5102 34.8941 89.6518 34.8941C87.6593 34.8941 86.2758 33.9959 86.0797 32.5815H83.7877Z",
    fill: "white"
  }), __jsx("path", {
    d: "M61.6029 25.5606C60.1287 25.5606 58.8537 26.2977 58.1962 27.5328H58.0369V25.7399H55.975V40.1932H58.1166V34.9438H58.2859C58.8537 36.0893 60.0789 36.7666 61.6228 36.7666C64.3621 36.7666 66.1052 34.6051 66.1052 31.1586C66.1052 27.7121 64.3621 25.5606 61.6029 25.5606ZM60.9953 34.8442C59.2023 34.8442 58.0767 33.4297 58.0767 31.1686C58.0767 28.8975 59.2023 27.483 61.0052 27.483C62.8181 27.483 63.9039 28.8676 63.9039 31.1586C63.9039 33.4596 62.8181 34.8442 60.9953 34.8442Z",
    fill: "white"
  }), __jsx("path", {
    d: "M73.599 25.5606C72.1248 25.5606 70.8498 26.2977 70.1924 27.5328H70.033V25.7399H67.9711V40.1932H70.1127V34.9438H70.2821C70.8498 36.0893 72.075 36.7666 73.619 36.7666C76.3582 36.7666 78.1014 34.6051 78.1014 31.1586C78.1014 27.7121 76.3582 25.5606 73.599 25.5606ZM72.9914 34.8442C71.1985 34.8442 70.0729 33.4297 70.0729 31.1686C70.0729 28.8975 71.1985 27.483 73.0014 27.483C74.8143 27.483 75.9 28.8676 75.9 31.1586C75.9 33.4596 74.8143 34.8442 72.9914 34.8442Z",
    fill: "white"
  }), __jsx("path", {
    d: "M52.1326 36.5873H54.5897L49.2108 21.6895H46.7227L41.3438 36.5873H43.7183L45.0914 32.6331H50.7698L52.1326 36.5873ZM47.848 24.3944H48.0235L50.1813 30.6922H45.6799L47.848 24.3944Z",
    fill: "white"
  }), __jsx("path", {
    d: "M42.7812 10.4513V17.6381H45.3761C47.5177 17.6381 48.7578 16.3183 48.7578 14.0223C48.7578 11.7611 47.5077 10.4513 45.3761 10.4513H42.7812ZM43.8969 11.4673H45.2516C46.7407 11.4673 47.6223 12.4136 47.6223 14.0372C47.6223 15.6857 46.7557 16.6221 45.2516 16.6221H43.8969V11.4673Z",
    fill: "white"
  }), __jsx("path", {
    d: "M52.5559 17.7427C54.1397 17.7427 55.1109 16.6818 55.1109 14.9237C55.1109 13.1756 54.1347 12.1098 52.5559 12.1098C50.9721 12.1098 49.996 13.1756 49.996 14.9237C49.996 16.6818 50.9671 17.7427 52.5559 17.7427ZM52.5559 16.7914C51.6246 16.7914 51.1016 16.1091 51.1016 14.9237C51.1016 13.7483 51.6246 13.061 52.5559 13.061C53.4823 13.061 54.0102 13.7483 54.0102 14.9237C54.0102 16.1041 53.4823 16.7914 52.5559 16.7914Z",
    fill: "white"
  }), __jsx("path", {
    d: "M63.3815 12.2144H62.3107L61.3445 16.3531H61.2598L60.1442 12.2144H59.1182L58.0026 16.3531H57.9229L56.9517 12.2144H55.8659L57.3601 17.6381H58.4608L59.5764 13.6437H59.6611L60.7817 17.6381H61.8923L63.3815 12.2144Z",
    fill: "white"
  }), __jsx("path", {
    d: "M64.6146 17.6381H65.6854V14.4655C65.6854 13.6188 66.1885 13.0909 66.9803 13.0909C67.7722 13.0909 68.1508 13.5242 68.1508 14.3958V17.6381H69.2216V14.1269C69.2216 12.8369 68.5542 12.1098 67.3439 12.1098C66.5271 12.1098 65.9892 12.4733 65.7253 13.076H65.6456V12.2144H64.6146V17.6381Z",
    fill: "white"
  }), __jsx("path", {
    d: "M70.9079 17.6381H71.9787V10.0977H70.9079V17.6381Z",
    fill: "white"
  }), __jsx("path", {
    d: "M76.006 17.7427C77.5897 17.7427 78.5609 16.6818 78.5609 14.9237C78.5609 13.1756 77.5848 12.1098 76.006 12.1098C74.4222 12.1098 73.446 13.1756 73.446 14.9237C73.446 16.6818 74.4172 17.7427 76.006 17.7427ZM76.006 16.7914C75.0746 16.7914 74.5517 16.1091 74.5517 14.9237C74.5517 13.7483 75.0746 13.061 76.006 13.061C76.9323 13.061 77.4603 13.7483 77.4603 14.9237C77.4603 16.1041 76.9323 16.7914 76.006 16.7914Z",
    fill: "white"
  }), __jsx("path", {
    d: "M81.7514 16.8263C81.1687 16.8263 80.7454 16.5424 80.7454 16.0543C80.7454 15.5762 81.084 15.3222 81.8311 15.2724L83.1559 15.1877V15.6409C83.1559 16.3133 82.5583 16.8263 81.7514 16.8263ZM81.4775 17.7277C82.1897 17.7277 82.7824 17.4189 83.1061 16.8761H83.1908V17.6381H84.2217V13.9326C84.2217 12.7871 83.4547 12.1098 82.0951 12.1098C80.8649 12.1098 79.9883 12.7074 79.8788 13.6388H80.9147C81.0342 13.2553 81.4476 13.0361 82.0453 13.0361C82.7774 13.0361 83.1559 13.3599 83.1559 13.9326V14.4008L81.6867 14.4854C80.3967 14.5651 79.6696 15.1279 79.6696 16.1041C79.6696 17.0952 80.4316 17.7277 81.4775 17.7277Z",
    fill: "white"
  }), __jsx("path", {
    d: "M87.8555 17.7277C88.6026 17.7277 89.2351 17.3741 89.5588 16.7814H89.6435V17.6381H90.6694V10.0977H89.5986V13.076H89.519C89.2251 12.4783 88.5976 12.1247 87.8555 12.1247C86.4859 12.1247 85.6043 13.2104 85.6043 14.9237C85.6043 16.642 86.4759 17.7277 87.8555 17.7277ZM88.1593 13.0859C89.0558 13.0859 89.6186 13.7981 89.6186 14.9287C89.6186 16.0643 89.0608 16.7665 88.1593 16.7665C87.2528 16.7665 86.71 16.0742 86.71 14.9237C86.71 13.7832 87.2578 13.0859 88.1593 13.0859Z",
    fill: "white"
  }), __jsx("path", {
    d: "M97.6132 17.7427C99.197 17.7427 100.168 16.6818 100.168 14.9237C100.168 13.1756 99.192 12.1098 97.6132 12.1098C96.0294 12.1098 95.0533 13.1756 95.0533 14.9237C95.0533 16.6818 96.0244 17.7427 97.6132 17.7427ZM97.6132 16.7914C96.6819 16.7914 96.1589 16.1091 96.1589 14.9237C96.1589 13.7483 96.6819 13.061 97.6132 13.061C98.5396 13.061 99.0675 13.7483 99.0675 14.9237C99.0675 16.1041 98.5396 16.7914 97.6132 16.7914Z",
    fill: "white"
  }), __jsx("path", {
    d: "M101.586 17.6381H102.656V14.4655C102.656 13.6188 103.159 13.0909 103.951 13.0909C104.743 13.0909 105.122 13.5242 105.122 14.3958V17.6381H106.193V14.1269C106.193 12.8369 105.525 12.1098 104.315 12.1098C103.498 12.1098 102.96 12.4733 102.696 13.076H102.617V12.2144H101.586V17.6381Z",
    fill: "white"
  }), __jsx("path", {
    d: "M111.124 10.8646V12.2393H110.263V13.1407H111.124V16.2037C111.124 17.2496 111.597 17.668 112.788 17.668C112.997 17.668 113.196 17.6431 113.37 17.6132V16.7217C113.221 16.7366 113.126 16.7466 112.962 16.7466C112.429 16.7466 112.195 16.4976 112.195 15.9298V13.1407H113.37V12.2393H112.195V10.8646H111.124Z",
    fill: "white"
  }), __jsx("path", {
    d: "M114.808 17.6381H115.879V14.4705C115.879 13.6487 116.367 13.0959 117.243 13.0959C118 13.0959 118.404 13.5342 118.404 14.4008V17.6381H119.474V14.1368C119.474 12.8469 118.762 12.1147 117.607 12.1147C116.79 12.1147 116.217 12.4783 115.953 13.0859H115.869V10.0977H114.808V17.6381Z",
    fill: "white"
  }), __jsx("path", {
    d: "M123.337 13.0212C124.129 13.0212 124.647 13.574 124.672 14.4257H121.933C121.993 13.579 122.545 13.0212 123.337 13.0212ZM124.667 16.1738C124.468 16.5972 124.025 16.8312 123.377 16.8312C122.521 16.8312 121.968 16.2286 121.933 15.2773V15.2176H125.763V14.844C125.763 13.1407 124.851 12.1098 123.342 12.1098C121.813 12.1098 120.842 13.2104 120.842 14.9437C120.842 16.6769 121.793 17.7427 123.347 17.7427C124.587 17.7427 125.454 17.145 125.698 16.1738H124.667Z",
    fill: "white"
  }), __jsx("path", {
    d: "M29.7255 24.3613C29.7515 22.3441 30.8348 20.4354 32.5534 19.3788C31.4692 17.8304 29.6533 16.8487 27.764 16.7896C25.7489 16.5781 23.7954 17.9954 22.7685 17.9954C21.7218 17.9954 20.1408 16.8106 18.4383 16.8456C16.2192 16.9173 14.1505 18.179 13.0708 20.1191C10.75 24.1372 12.4811 30.0424 14.7043 33.2904C15.8165 34.8808 17.1165 36.6573 18.8174 36.5943C20.4819 36.5253 21.1035 35.5329 23.1127 35.5329C25.1032 35.5329 25.6864 36.5943 27.4219 36.5543C29.208 36.5253 30.3333 34.9568 31.4065 33.3513C32.2057 32.2181 32.8206 30.9656 33.2286 29.6403C31.1297 28.7525 29.728 26.6402 29.7255 24.3613Z",
    fill: "white"
  }), __jsx("path", {
    d: "M26.4475 14.6536C27.4214 13.4845 27.9011 11.9819 27.785 10.4648C26.2972 10.6211 24.9228 11.3322 23.9359 12.4564C22.9707 13.5548 22.4684 15.031 22.5634 16.49C24.0517 16.5054 25.5149 15.8136 26.4475 14.6536Z",
    fill: "white"
  }));
};

export default SvgAppStore;