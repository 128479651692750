import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread(_objectSpread({}, {
    "display": "flex",
    "width": "100%"
  }), {}, {
    "@xs": _objectSpread({}, {
      "width": "auto"
    })
  })),
  button: _css(_objectSpread(_objectSpread({}, {
    "flexShrink": "0",
    "borderWidth": "1px",
    "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
    "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "transitionDuration": "150ms"
  }), {}, {
    backgroundColor: "$white",
    borderColor: "$gray2",
    "&:hover": {
      color: "$default"
    },
    "&:disabled": _objectSpread(_objectSpread({}, {
      "cursor": "default"
    }), {}, {
      color: "$gray5"
    }),
    variants: {
      variant: {
        rounded: {},
        oval: {}
      },
      size: {
        sm: _objectSpread({}, {
          "height": "2rem",
          "width": "1.75rem"
        }),
        md: _objectSpread({}, {
          "height": "2.5rem",
          "width": "2rem"
        }),
        lg: _objectSpread({}, {
          "height": "3rem",
          "width": "2.5rem"
        })
      },
      width: {
        full: _objectSpread({}, {
          "width": "5rem"
        })
      },
      type: {
        increment: {
          borderLeft: "none"
        },
        decrement: {
          borderRight: "none"
        }
      }
    },
    compoundVariants: [{
      variant: "rounded",
      type: "increment",
      css: _objectSpread({}, {
        "borderTopRightRadius": "0.5rem",
        "borderBottomRightRadius": "0.5rem"
      })
    }, {
      variant: "rounded",
      type: "decrement",
      css: _objectSpread({}, {
        "borderTopLeftRadius": "0.5rem",
        "borderBottomLeftRadius": "0.5rem"
      })
    }, {
      variant: "oval",
      type: "increment",
      css: _objectSpread({}, {
        "borderTopRightRadius": "9999px",
        "borderBottomRightRadius": "9999px"
      })
    }, {
      variant: "oval",
      type: "decrement",
      css: _objectSpread({}, {
        "borderTopLeftRadius": "9999px",
        "borderBottomLeftRadius": "9999px"
      })
    }]
  })),
  valueContainer: _css(_objectSpread(_objectSpread({}, {
    "position": "relative",
    "display": "flex",
    "alignItems": "center",
    "justifyContent": "center",
    "borderBottomWidth": "1px",
    "borderTopWidth": "1px"
  }), {}, {
    fontSize: "$2",
    backgroundColor: "$white",
    borderColor: "$gray2",
    variants: {
      size: {
        sm: _objectSpread({}, {
          "minWidth": "28px"
        }),
        md: _objectSpread({}, {
          "minWidth": "30px"
        }),
        lg: _objectSpread({}, {
          "minWidth": "35px"
        })
      },
      width: {
        full: _objectSpread({}, {
          "width": "100%"
        })
      }
    }
  })),
  value: _css({
    variants: {
      loading: {
        "true": _objectSpread(_objectSpread({}, {
          "animation": "pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite"
        }), {}, {
          color: "$default"
        })
      },
      disabled: {
        "true": {
          color: "$gray5"
        }
      }
    }
  })
};
export default styles;