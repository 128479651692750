import _toConsumableArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _slicedToArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
import React from "react";
import { useIntl } from "react-intl";
import { Button } from "@/components/ui";
import { useDraggableScroll } from "@/hooks/useDraggableScroll.hook";
import { diff } from "@/libraries/dayjs.library";
import styles from "./ReviewsFilters.styles";

var ReviewsFilters = function ReviewsFilters(_ref) {
  var allReviews = _ref.allReviews,
      filterReviews = _ref.filterReviews,
      slider = _ref.slider,
      type = _ref.type;
  var t = useIntl();

  var _React$useState = React.useState(),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      activeFilter = _React$useState2[0],
      setActiveFilter = _React$useState2[1];

  var rootRef = React.useRef(null);
  useDraggableScroll(rootRef);
  var productVariants = React.useMemo(function () {
    if (type !== "multi-product") {
      return [];
    }

    var variants = new Map();
    allReviews.filter(function (review) {
      return review.variant !== undefined;
    }).forEach(function (review) {
      var _review$variant, _review$variant2, _review$variant3;

      var id = (_review$variant = review.variant) === null || _review$variant === void 0 ? void 0 : _review$variant.id;
      var name = ((_review$variant2 = review.variant) === null || _review$variant2 === void 0 || (_review$variant2 = _review$variant2.product) === null || _review$variant2 === void 0 ? void 0 : _review$variant2.name) || ((_review$variant3 = review.variant) === null || _review$variant3 === void 0 ? void 0 : _review$variant3.name);

      if (!variants.has(id)) {
        variants.set(id, {
          id: id,
          name: name
        });
      }
    });
    return Array.from(variants.values());
  }, [allReviews, type]);
  var sortByDateDesc = React.useCallback(function (reviews) {
    return _toConsumableArray(reviews).sort(function (a, b) {
      return diff({
        sourceDate: b.createDate,
        targetDate: a.createDate
      });
    });
  }, []);
  var sortReviewsByRatingDesc = React.useCallback(function (reviews) {
    return _toConsumableArray(reviews).sort(function (a, b) {
      return b.rating - a.rating;
    });
  }, []);
  var filterVerifiedReviews = React.useCallback(function (reviews) {
    return _toConsumableArray(reviews).filter(function (review) {
      return review.verifiedCustomer;
    });
  }, []);
  var filterByProductVariant = React.useCallback(function (variantId, reviews) {
    return _toConsumableArray(reviews).filter(function (review) {
      var _review$variant4;

      return review.variant && ((_review$variant4 = review.variant) === null || _review$variant4 === void 0 ? void 0 : _review$variant4.id) === variantId;
    });
  }, []);
  var handleSortAndFilter = React.useCallback(function (sortType, reviews) {
    if (sortType && productVariants.find(function (v) {
      return v.id === sortType;
    })) {
      filterReviews(filterByProductVariant(sortType, reviews));
    } else {
      switch (sortType) {
        case "dateDesc":
          {
            filterReviews(sortByDateDesc(reviews));
            break;
          }

        case "ratingDesc":
          {
            filterReviews(sortReviewsByRatingDesc(reviews));
            break;
          }

        case "verified":
          {
            filterReviews(filterVerifiedReviews(reviews));
            break;
          }
      }
    }

    setActiveFilter(sortType);

    if (slider) {
      slider.slideTo(0, 300);
    }
  }, [filterByProductVariant, filterReviews, filterVerifiedReviews, productVariants, slider, sortByDateDesc, sortReviewsByRatingDesc]);
  React.useEffect(function () {
    handleSortAndFilter("dateDesc", allReviews); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return __jsx("div", {
    ref: rootRef,
    className: styles.root()
  }, __jsx(Button, {
    variant: "oval",
    color: "light",
    labelSize: "xs",
    className: styles.button({
      active: activeFilter === "dateDesc"
    }),
    onClick: function onClick() {
      return handleSortAndFilter("dateDesc", allReviews);
    }
  }, t.formatMessage({
    id: "reviews.filters.latest",
    defaultMessage: "Latest"
  })), __jsx(Button, {
    variant: "oval",
    color: "light",
    labelSize: "xs",
    className: styles.button({
      active: activeFilter === "ratingDesc"
    }),
    onClick: function onClick() {
      return handleSortAndFilter("ratingDesc", allReviews);
    }
  }, t.formatMessage({
    id: "reviews.filters.best",
    defaultMessage: "Best"
  })), __jsx(Button, {
    variant: "oval",
    color: "light",
    labelSize: "xs",
    className: styles.button({
      active: activeFilter === "verified"
    }),
    onClick: function onClick() {
      return handleSortAndFilter("verified", allReviews);
    }
  }, t.formatMessage({
    id: "reviews.filters.verified",
    defaultMessage: "Verified"
  })), productVariants.map(function (_ref2, index) {
    var id = _ref2.id,
        name = _ref2.name;
    return __jsx(Button, {
      key: id || "product-variant-".concat(index),
      variant: "oval",
      color: "light",
      labelSize: "xs",
      className: styles.button({
        active: activeFilter === id
      }),
      onClick: function onClick() {
        return handleSortAndFilter(id, allReviews);
      }
    }, name || id);
  }));
};

ReviewsFilters.displayName = "ReviewsFilters";
export default ReviewsFilters;