import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread({}, {
    "marginTop": "1.5rem"
  })),
  title: _css({
    fontSize: "$5",
    fontFamily: "$tenor"
  }),
  productsList: _css(_objectSpread({}, {
    "marginTop": "1rem",
    "display": "flex",
    "flexDirection": "column",
    "gap": "1rem"
  })),
  dummyProduct: _css({
    ">:nth-child(2)": _objectSpread(_objectSpread({}, {
      "display": "flex",
      "flexDirection": "column",
      "justifyContent": "center"
    }), {}, {
      ">:nth-child(1)": {
        span: {
          "&:nth-child(2)": {
            color: "$black",
            fontWeight: "$normal"
          }
        }
      }
    })
  })
};
export default styles;