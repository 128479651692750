var __jsx = React.createElement;
import React from "react";
import StarIcon from "@/components/ui/Icon/$/Star.icon";
import { cn } from "@/libraries/clsx.library";
import style from "./Rating.styles";

var Rating = function Rating(_ref) {
  var rating = _ref.rating,
      className = _ref.className,
      iconSize = _ref.iconSize,
      iconClassName = _ref.iconClassName,
      iconMainColor = _ref.iconMainColor,
      iconSecondaryColor = _ref.iconSecondaryColor;

  var getPercentFillValue = function getPercentFillValue(currentRating, fullRating) {
    if (currentRating <= fullRating) {
      return 100;
    } else if (currentRating - fullRating > 0 && currentRating - fullRating < 1) {
      return (fullRating - Math.floor(fullRating)) * 100;
    }

    return 0;
  };

  return __jsx("div", {
    className: cn(style.root(), className)
  }, [1, 2, 3, 4, 5].map(function (currentRating, currentRatingIndex) {
    return __jsx(StarIcon, {
      percentFill: getPercentFillValue(currentRating, rating),
      className: cn(style.starIcon(), iconClassName),
      "aria-hidden": "true",
      key: "rating-".concat(currentRatingIndex),
      mainColor: iconMainColor,
      secondaryColor: iconSecondaryColor,
      size: iconSize
    });
  }));
};

Rating.displayName = "Rating";
export default Rating;