import _slicedToArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
import React from "react";
import { useIntl } from "react-intl";
import { Slider } from "@/components/ui";
import { down } from "@/helpers/screens.helpers";
import { useMediaQuery } from "@/hooks/useMediaQuery.hook";
import { ScreenEnum } from "@/theme/theme.enums";
import { PRODUCTS_CATEGORY_ADITIONS_OF_SETS } from "~/src/config/common.config";
import { VariantSetSliderItem } from "~/src/modules/product/components/VariantsInSetSlider/components";
import styles from "./VariantsInSetSlider.styles";

var VariantsInSetSlider = function VariantsInSetSlider(_ref) {
  var variantsInSet = _ref.variantsInSet,
      productsPages = _ref.productsPages,
      setVariantsInSetSlider = _ref.setVariantsInSetSlider;
  var t = useIntl();
  var isDownLg = useMediaQuery(down(ScreenEnum.lg), false);
  var isDownMd = useMediaQuery(down(ScreenEnum.md), false);

  var _React$useState = React.useState(),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      sliderHeight = _React$useState2[0],
      setSliderHeight = _React$useState2[1];

  var _React$useState3 = React.useState(0),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      activeSlideIndex = _React$useState4[0],
      setActiveSlideIndex = _React$useState4[1];

  var filteredVariantsInSet = React.useMemo(function () {
    return variantsInSet.filter(function (variantInSet) {
      var _variantInSet$variant;

      return ((_variantInSet$variant = variantInSet.variant.product.category) === null || _variantInSet$variant === void 0 ? void 0 : _variantInSet$variant.slug) !== PRODUCTS_CATEGORY_ADITIONS_OF_SETS;
    });
  }, [variantsInSet]);
  React.useEffect(function () {
    var observer = new ResizeObserver(function (entries) {
      if (entries && entries[0]) {
        var height = entries[0].contentRect.height;
        setSliderHeight(height + (isDownLg ? 0 : 64));
      }
    });
    var activeSlideContent = document.querySelector("#__products-in-set-slider .swiper-slide-active > div");

    if (activeSlideContent) {
      setSliderHeight(activeSlideContent.clientHeight + (isDownLg ? 0 : 64));
      observer.observe(activeSlideContent);
    }

    return function () {
      observer.disconnect();
    };
  }, [activeSlideIndex, isDownLg]);
  return __jsx(Slider, {
    id: "__products-in-set-slider",
    className: styles.root(),
    spaceBetween: isDownLg ? isDownMd ? 16 : 24 : 36,
    slidesPerView: isDownLg ? isDownMd ? 1.2 : 1.15 : 1,
    centeredSlides: isDownLg,
    onSwiper: function onSwiper(swiper) {
      setVariantsInSetSlider(swiper);
    },
    onSlideChange: function onSlideChange(_ref2) {
      var activeIndex = _ref2.activeIndex;
      setActiveSlideIndex(activeIndex);
    },
    observeSlideChildren: false,
    style: {
      height: sliderHeight || "auto"
    },
    initialSlide: 0,
    additionalContent: filteredVariantsInSet.length > 1 ? __jsx("div", {
      className: styles.navigationContainer()
    }, __jsx(Slider.NavigationItem, {
      type: "prev",
      text: t.formatMessage({
        defaultMessage: "Previous",
        id: "common.previous"
      })
    }), __jsx("div", {
      className: styles.paginationContainer()
    }, __jsx(Slider.Pagination, null)), __jsx(Slider.NavigationItem, {
      type: "next",
      text: t.formatMessage({
        defaultMessage: "Next",
        id: "common.next"
      })
    })) : null
  }, filteredVariantsInSet.map(function (variantInSet) {
    return __jsx(VariantSetSliderItem, {
      variantInSet: variantInSet,
      pages: productsPages[variantInSet.variant.product.id],
      key: "variant-set-slider-item-".concat(variantInSet.variant.id)
    });
  }));
};

VariantsInSetSlider.displayName = "VariantsInSetSlider";
export default VariantsInSetSlider;