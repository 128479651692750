import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { borders } from "./theme.borders";
import { variants } from "./theme.colors";
import { fonts } from "./theme.fonts";
import { zIndices } from "./theme.indices";
import { media } from "./theme.media";
import { shadows } from "./theme.shadows";
import { sizes } from "./theme.sizes";
import { transitions } from "./theme.transitions";
import { utils } from "./theme.utils";
export var base = {
  borders: borders,
  fonts: fonts,
  media: media,
  shadows: shadows,
  sizes: sizes,
  transitions: transitions,
  utils: utils,
  zIndices: zIndices
};
export var light = _objectSpread(_objectSpread({}, base), variants.light);
export var dark = _objectSpread(_objectSpread({}, base), variants.dark);
export var theme = {
  light: light,
  dark: dark
};
export default theme;