import { useEffect } from "react";
/**
 * React hook to make an element scrollable via dragging
 *
 * @param ref the ref of the element to make draggable
 */

export function useDraggableScroll(ref) {
  useEffect(function () {
    var isDown = false;
    var startX;
    var scrollLeft;

    var handleMouseDown = function handleMouseDown(e) {
      isDown = true;
      startX = e.pageX - ref.current.offsetLeft;
      scrollLeft = ref.current.scrollLeft;
    };

    var handleMouseLeave = function handleMouseLeave() {
      isDown = false;
    };

    var handleMouseUp = function handleMouseUp() {
      isDown = false;
    };

    var handleMouseMove = function handleMouseMove(e) {
      if (!isDown) return;
      e.preventDefault();
      var x = e.pageX - ref.current.offsetLeft;
      var walk = (x - startX) * 2; //scroll-fast

      ref.current.scrollLeft = scrollLeft - walk;
    };

    var currentRef = ref.current;

    if (currentRef) {
      currentRef.addEventListener("mousedown", handleMouseDown);
      currentRef.addEventListener("mouseleave", handleMouseLeave);
      currentRef.addEventListener("mouseup", handleMouseUp);
      currentRef.addEventListener("mousemove", handleMouseMove);
    }

    return function () {
      if (currentRef) {
        currentRef.removeEventListener("mousedown", handleMouseDown);
        currentRef.removeEventListener("mouseleave", handleMouseLeave);
        currentRef.removeEventListener("mouseup", handleMouseUp);
        currentRef.removeEventListener("mousemove", handleMouseMove);
      }
    };
  }, [ref]);
}