import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["name", "options", "error", "inputColor", "labelColor", "labelSize", "center", "inputClassName", "children"];
var __jsx = React.createElement;
import { truncate } from "lodash";
import React, { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { cn } from "~/src/libraries/clsx.library";
import styles from "./Checkbox.styles";

var Checkbox = function Checkbox(_ref) {
  var _ctx$formState$errors;

  var name = _ref.name,
      options = _ref.options,
      _ref$error = _ref.error,
      error = _ref$error === void 0 ? {
    placement: "bottom"
  } : _ref$error,
      _ref$inputColor = _ref.inputColor,
      inputColor = _ref$inputColor === void 0 ? "light" : _ref$inputColor,
      _ref$labelColor = _ref.labelColor,
      labelColor = _ref$labelColor === void 0 ? "light" : _ref$labelColor,
      _ref$labelSize = _ref.labelSize,
      labelSize = _ref$labelSize === void 0 ? "sm" : _ref$labelSize,
      _ref$center = _ref.center,
      center = _ref$center === void 0 ? false : _ref$center,
      inputClassName = _ref.inputClassName,
      children = _ref.children,
      rest = _objectWithoutProperties(_ref, _excluded);

  var ctx = useFormContext();
  var ctxError = ctx === null || ctx === void 0 || (_ctx$formState$errors = ctx.formState.errors[name]) === null || _ctx$formState$errors === void 0 ? void 0 : _ctx$formState$errors.message;
  var value = ctx === null || ctx === void 0 ? void 0 : ctx.watch(name);
  var renderError = useCallback(function (errorText, placement, maxLength) {
    return (errorText === null || errorText === void 0 ? void 0 : errorText.length) > 0 ? __jsx("span", {
      className: styles.error({
        placement: placement
      })
    }, maxLength ? truncate(errorText, {
      length: maxLength,
      omission: " (...)"
    }) : errorText) : null;
  }, []);
  return __jsx("div", null, __jsx("div", {
    className: styles.checkboxWrapper({
      center: center
    })
  }, __jsx("input", _extends({
    type: "checkbox",
    className: cn(styles.input({
      color: inputColor,
      error: !!ctxError
    }), inputClassName)
  }, ctx === null || ctx === void 0 ? void 0 : ctx.register(name, options), rest)), __jsx("div", {
    onClick: function onClick() {
      return ctx === null || ctx === void 0 ? void 0 : ctx.setValue(name, !value);
    },
    className: styles.content()
  }, error.placement === "top" && renderError(ctxError, error.placement, error.maxLength), __jsx("div", {
    className: styles.label({
      color: labelColor,
      labelSize: labelSize
    })
  }, children), error.placement === "bottom" && renderError(ctxError, error.placement, error.maxLength))));
};

Checkbox.displayName = "Checkbox";
export default Checkbox;