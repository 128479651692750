export var mapQueryToModifications = function mapQueryToModifications(query) {
  switch (query === null || query === void 0 ? void 0 : query.toLowerCase()) {
    case "remove-internal-linking":
    case "remove_internal_linking":
    case "removeinternallinking":
    case "ril":
      return ["REMOVE_INTERNAL_LINKING"];

    default:
      return ["UNKNOWN"];
  }
};