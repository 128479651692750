import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _slicedToArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var __jsx = React.createElement;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React from "react";
import createContext from "@/libraries/react.library";
var INITIAL_STATE = {
  isReady: false,
  isVisible: false,
  isOpen: false,
  chatElement: null,
  showChat: function showChat() {
    return null;
  },
  hideChat: function hideChat() {
    return null;
  },
  openChat: function openChat() {
    return null;
  },
  closeChat: function closeChat() {
    return null;
  }
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_CHAT_READY":
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isReady: true
        });
      }

    case "SET_CHAT_ELEMENT":
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          chatElement: action.payload.chatElement
        });
      }

    case "SHOW_CHAT":
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isVisible: true
        });
      }

    case "HIDE_CHAT":
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isVisible: false
        });
      }

    case "OPEN_CHAT":
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isOpen: true
        });
      }

    case "CLOSE_CHAT":
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isOpen: false
        });
      }

    default:
      return state;
  }
}

var _createContext = createContext(),
    _createContext2 = _slicedToArray(_createContext, 2),
    useContext = _createContext2[0],
    Provider = _createContext2[1];

export { useContext, Provider };
export var Chat = function Chat(props) {
  var _React$useReducer = React.useReducer(reducer, INITIAL_STATE),
      _React$useReducer2 = _slicedToArray(_React$useReducer, 2),
      state = _React$useReducer2[0],
      dispatch = _React$useReducer2[1];

  var chatApiErrorHandler = function chatApiErrorHandler(action, errorMessage) {
    try {
      if (window.tidioChatApi) {
        action();
      } else {
        console.warn("tidioChatApi is unavailable");
      }
    } catch (_unused) {
      console.warn(errorMessage || "Error occurs while chat action running");
    }
  };

  function waitForElement(selectorId) {
    var intervalTime = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 100;
    return new Promise(function (resolve) {
      var intervalId = setInterval(function () {
        var element = document.getElementById(selectorId);

        if (element) {
          clearInterval(intervalId);
          resolve(element);
        }
      }, intervalTime);
    });
  }

  var showChat = React.useCallback(function () {
    chatApiErrorHandler(function () {
      window.tidioChatApi.show();
      dispatch({
        type: "SHOW_CHAT"
      });
    }, "Error occurs while chat opening");
  }, [dispatch]);
  var hideChat = React.useCallback(function () {
    chatApiErrorHandler(function () {
      window.tidioChatApi.hide();
      dispatch({
        type: "HIDE_CHAT"
      });
    }, "Error occurs while chat hiding");
  }, [dispatch]);
  var openChat = React.useCallback(function () {
    chatApiErrorHandler(function () {
      window.tidioChatApi.open();
      dispatch({
        type: "OPEN_CHAT"
      });
    }, "Error occurs while chat opening");
  }, [dispatch]);
  var closeChat = React.useCallback(function () {
    chatApiErrorHandler(function () {
      window.tidioChatApi.close();
      dispatch({
        type: "CLOSE_CHAT"
      });
    }, "Error occurs while chat closing");
  }, [dispatch]);
  React.useEffect(function () {
    if (state.isVisible) {
      waitForElement("tidio-chat-iframe").then(function (chatElement) {
        chatElement.style.zIndex = "20";
        chatElement.style.transition = "transform 0.3s ease";
        dispatch({
          type: "SET_CHAT_ELEMENT",
          payload: {
            chatElement: chatElement
          }
        });
      });
    }
  }, [dispatch, state.isVisible]);
  React.useEffect(function () {
    var onChatApiReady = function onChatApiReady() {
      hideChat();
      dispatch({
        type: "SET_CHAT_READY"
      });
    };

    if (window.tidioChatApi) {
      window.tidioChatApi.on("ready", onChatApiReady);
    } else {
      document.addEventListener("tidioChat-ready", onChatApiReady);
    }

    return function () {
      document.removeEventListener("tidioChat-ready", onChatApiReady);
    };
  }, [hideChat]);
  var value = React.useMemo(function () {
    return _objectSpread(_objectSpread({}, state), {}, {
      showChat: showChat,
      hideChat: hideChat,
      openChat: openChat,
      closeChat: closeChat
    });
  }, [closeChat, hideChat, openChat, showChat, state]);
  return __jsx(Provider, _extends({
    value: value
  }, props));
};