import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import tw from "./theme.fonts.tw";
export var fonts = {
  fonts: {
    inter: tw.families.inter.join(","),
    tenor: tw.families.tenor.join(","),
    inria: tw.families.inria.join(",")
  },
  fontSizes: {
    1: ".75rem",
    2: ".875rem",
    3: "1rem",
    4: "1.125rem",
    5: "1.5rem",
    6: "2rem",
    7: "3.5rem",
    8: "4.125rem",
    9: "5.5rem",
    10: "6.5rem"
  },
  fontWeights: _objectSpread({}, tw.fontWeights),
  letterSpacings: {},
  lineHeights: {
    1: "100%",
    2: "110%",
    3: "120%",
    4: "130%",
    5: "140%",
    6: "150%",
    7: "160%",
    8: "170%",
    9: "180%",
    10: "190%",
    11: "200%"
  }
};
export default fonts;