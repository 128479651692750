import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../libraries/stitches.library.ts";
var styles = {
  wrapper: _css(_objectSpread(_objectSpread({}, {
    "position": "relative",
    "zIndex": "10",
    "marginLeft": "auto",
    "marginRight": "auto",
    "display": "flex",
    "height": "100%",
    "minHeight": "calc(100vh - 120px)",
    "width": "100%",
    "maxWidth": "80rem",
    "flexDirection": "column",
    "alignItems": "center",
    "justifyContent": "center"
  }), {}, {
    gap: "$6",
    "@md": _objectSpread({}, {
      "position": "fixed",
      "inset": "0px"
    })
  })),
  loader: _css(_objectSpread(_objectSpread({}, {
    "position": "relative",
    "display": "flex",
    "width": "100%",
    "alignItems": "center",
    "justifyContent": "center",
    "transitionProperty": "all",
    "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "transitionDuration": "300ms",
    "animationDuration": "300ms",
    "animationTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)"
  }), {}, {
    height: 40,
    "@md": {
      height: 120
    },
    variants: {
      active: {
        "false": {
          height: 0
        }
      }
    }
  })),
  loaderContent: _css(_objectSpread(_objectSpread({}, {
    "position": "relative",
    "display": "flex",
    "flexDirection": "column",
    "alignItems": "center",
    "justifyContent": "center",
    "padding": "2rem"
  }), {}, {
    gap: "$6",
    maxWidth: 680,
    "@md": {
      gap: "$8"
    }
  })),
  loaderTitle: _css(_objectSpread(_objectSpread({}, {
    "textAlign": "center"
  }), {}, {
    fontFamily: "$tenor",
    fontSize: "$6",
    lineHeight: "$3"
  })),
  loaderDescription: _css(_objectSpread(_objectSpread({}, {
    "textAlign": "center"
  }), {}, {
    fontFamily: "$inter",
    color: "$gray7"
  }))
};
export default styles;