import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread({}, {
    "display": "flex",
    "> :not([hidden]) ~ :not([hidden])": {
      "--tw-divide-x-reverse": "0",
      "borderRightWidth": "calc(1px * var(--tw-divide-x-reverse))",
      "borderLeftWidth": "calc(1px * calc(1 - var(--tw-divide-x-reverse)))"
    }
  })),
  left: _css(_objectSpread(_objectSpread({}, {
    "display": "flex",
    "width": "180px",
    "flexDirection": "column",
    "alignItems": "center",
    "justifyContent": "center",
    "gap": "0.5rem",
    "padding": "1rem"
  }), {}, {
    borderColor: "$gray2"
  })),
  averageRating: _css(_objectSpread(_objectSpread({}, {}), {}, {
    fontFamily: "$tenor",
    fontSize: "$6",
    lineHeight: "$1"
  })),
  reviewsCount: _css(_objectSpread(_objectSpread({}, {
    "textAlign": "center"
  }), {}, {
    color: "$gray6",
    fontSize: "$2"
  })),
  right: _css(_objectSpread(_objectSpread({}, {
    "display": "flex",
    "width": "100%",
    "flexDirection": "column",
    "gap": "0.5rem",
    "padding": "1rem"
  }), {}, {
    borderColor: "$gray2"
  })),
  ratingProgressContainer: _css(_objectSpread({}, {
    "display": "flex",
    "width": "100%",
    "alignItems": "center"
  })),
  rating: _css(_objectSpread(_objectSpread({}, {
    "marginRight": "0.5rem",
    "width": "20px",
    "textAlign": "center"
  }), {}, {
    fontSize: "$2",
    color: "$gray6"
  })),
  ratingProgressBg: _css(_objectSpread(_objectSpread({}, {
    "position": "relative",
    "height": "0.5rem",
    "width": "100%",
    "overflow": "hidden",
    "borderRadius": "0.375rem"
  }), {}, {
    backgroundColor: "$gray1"
  })),
  ratingProgress: _css(_objectSpread(_objectSpread({}, {
    "position": "absolute",
    "inset": "0px"
  }), {}, {
    backgroundColor: "$warning"
  }))
};
export default styles;