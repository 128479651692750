import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread(_objectSpread({}, {
    "position": "relative",
    "> :not([hidden]) ~ :not([hidden])": {
      "--tw-space-y-reverse": "0",
      "marginTop": "calc(-1px * calc(1 - var(--tw-space-y-reverse)))",
      "marginBottom": "calc(-1px * var(--tw-space-y-reverse))"
    },
    "borderRadius": "0.375rem"
  }), {}, {
    backgroundColor: "$white"
  })),
  label: _css(_objectSpread({}, {
    "position": "absolute",
    "width": "1px",
    "height": "1px",
    "padding": "0",
    "margin": "-1px",
    "overflow": "hidden",
    "clip": "rect(0, 0, 0, 0)",
    "whiteSpace": "nowrap",
    "borderWidth": "0"
  })),
  option: _css(_objectSpread(_objectSpread({}, {
    "position": "relative",
    "display": "flex",
    "cursor": "pointer",
    "flexDirection": "column",
    "gap": "1rem",
    "borderWidth": "1px",
    "paddingLeft": "1.5rem",
    "paddingRight": "1.5rem",
    "paddingTop": "1rem",
    "paddingBottom": "1rem",
    "&:focus": {
      "outline": "2px solid transparent",
      "outlineOffset": "2px"
    }
  }), {}, {
    borderColor: "$gray2",
    variants: {
      checked: {
        "true": _objectSpread(_objectSpread({}, {
          "zIndex": "10"
        }), {}, {
          backgroundColor: "$lighter",
          borderColor: "$default"
        })
      },
      disabled: {
        "true": _objectSpread({}, {
          "pointerEvents": "none",
          "cursor": "default"
        })
      },
      first: {
        "true": _objectSpread({}, {
          "borderTopLeftRadius": "0.375rem",
          "borderTopRightRadius": "0.375rem"
        })
      },
      last: {
        "true": _objectSpread({}, {
          "borderBottomLeftRadius": "0.375rem",
          "borderBottomRightRadius": "0.375rem"
        })
      }
    },
    compoundVariants: [{
      checked: true,
      disabled: true,
      css: {
        borderColor: "$gray5"
      }
    }]
  })),
  main: _css(_objectSpread({}, {
    "display": "flex",
    "flex": "1 1 0%",
    "alignItems": "center",
    "gap": "1rem"
  })),
  select: _css(_objectSpread({}, {
    "display": "flex",
    "alignItems": "center"
  })),
  dot: _css(_objectSpread(_objectSpread({}, {
    "display": "flex",
    "height": "1rem",
    "width": "1rem",
    "flex": "none",
    "alignItems": "center",
    "justifyContent": "center",
    "borderRadius": "9999px",
    "borderWidth": "1px"
  }), {}, {
    background: "$white",
    borderColor: "$gray5",
    variants: {
      checked: {
        "true": _objectSpread(_objectSpread({}, {
          "borderColor": "transparent"
        }), {}, {
          background: "$default"
        })
      },
      active: {
        "true": _objectSpread({}, {
          "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
          "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
          "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
          "--tw-ring-opacity": "1",
          "--tw-ring-color": "hsl(344 51% 43% / var(--tw-ring-opacity))",
          "--tw-ring-offset-width": "2px"
        })
      },
      disabled: {
        "true": {}
      }
    },
    compoundVariants: [{
      checked: true,
      disabled: true,
      css: {
        backgroundColor: "$gray5"
      }
    }, {
      active: true,
      disabled: true,
      css: _objectSpread({}, {
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgb(194 189 189 / var(--tw-ring-opacity))"
      })
    }]
  })),
  dotInner: _css(_objectSpread(_objectSpread({}, {
    "height": "0.375rem",
    "width": "0.375rem",
    "borderRadius": "9999px"
  }), {}, {
    backgroundColor: "$white"
  })),
  content: _css(_objectSpread({}, {
    "display": "flex",
    "flex": "1 1 0%"
  })),
  caption: _css(_objectSpread({}, {
    "display": "flex",
    "flex": "1 1 0%"
  }))
};
export default styles;