import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from "react";

var SvgProcent = function SvgProcent(props) {
  return __jsx("svg", _extends({
    width: 16,
    height: 16,
    viewBox: "0 0 16 16",
    fill: "none",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("path", {
    d: "M11.6667 13.3333C12.5871 13.3333 13.3333 12.5871 13.3333 11.6667C13.3333 10.7462 12.5871 10 11.6667 10C10.7462 10 10 10.7462 10 11.6667C10 12.5871 10.7462 13.3333 11.6667 13.3333Z",
    stroke: props.color || "#C24163"
  }), __jsx("path", {
    d: "M4.33268 5.99984C5.25316 5.99984 5.99935 5.25364 5.99935 4.33317C5.99935 3.4127 5.25316 2.6665 4.33268 2.6665C3.41221 2.6665 2.66602 3.4127 2.66602 4.33317C2.66602 5.25364 3.41221 5.99984 4.33268 5.99984Z",
    stroke: props.color || "#C24163"
  }), __jsx("path", {
    d: "M12 2.6665L4 13.3332",
    stroke: props.color || "#C24163"
  }));
};

export default SvgProcent;