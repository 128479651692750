import _slicedToArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
import { partition } from "lodash";
import React, { Fragment } from "react";
import useInterface from "@/hooks/useInterface.hook";
import useNotifications from "@/hooks/useNotification.hook";
import { useOffsetObserver } from "@/notification/notification.helpers";
import Icon from "./Notification.Icon";
import Item from "./Notification.Item";
import Shell from "./Notification.Shell";
import styles from "./Notification.styles"; //prettier-ignore

var Notification = function Notification() {
  var _useInterface = useInterface(),
      navbar = _useInterface.navbar;

  var _useNotifications = useNotifications(),
      notifications = _useNotifications.notifications;

  var cookieBarOffset = useOffsetObserver("__cookie-bar");
  var addToCartOffset = useOffsetObserver("__floating-add-to-cart");
  var bottomOffset = React.useMemo(function () {
    var bonusOffset = 16;

    if (cookieBarOffset.isIntersecting && addToCartOffset.isIntersecting) {
      return cookieBarOffset.targetHeight + addToCartOffset.targetHeight + bonusOffset;
    }

    if (cookieBarOffset.isIntersecting) {
      return cookieBarOffset.targetHeight + bonusOffset;
    }

    if (addToCartOffset.isIntersecting) {
      return addToCartOffset.targetHeight + bonusOffset;
    }

    return 0;
  }, [cookieBarOffset, addToCartOffset]);

  var _partition = partition(notifications, function (notification) {
    return notification.position.placement === "bottom";
  }),
      _partition2 = _slicedToArray(_partition, 2),
      bottomNotifications = _partition2[0],
      topNotifications = _partition2[1];

  return __jsx(Fragment, null, __jsx(Shell, {
    zMax: !!notifications.find(function (notification) {
      return notification.position.zMax === true;
    })
  }, __jsx("div", {
    className: styles.top()
  }, topNotifications.length > 0 ? topNotifications.map(function (notification, index) {
    return __jsx(Item, {
      key: notification.id,
      notification: notification,
      offset: {
        top: notification.position.witOffset && index === 0 ? navbar.navTopPosition + 16 : 0,
        bottom: 0
      }
    });
  }) : null), __jsx("div", {
    className: styles.bottom()
  }, bottomNotifications.length > 0 ? bottomNotifications.map(function (notification, index) {
    return __jsx(Item, {
      key: notification.id,
      notification: notification,
      offset: {
        top: 0,
        bottom: notification.position.witOffset && index === bottomNotifications.length - 1 ? bottomOffset : 0
      }
    });
  }) : null)));
};

Notification.Shell = Shell;
Notification.Item = Item;
Notification.Icon = Icon;
Notification.displayName = "Notification";
export default Notification;