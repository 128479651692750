function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

import React from "react";
import { GOOGLE_MERCHANT_CENTER_ID } from "~/src/config/googleMerchantCenter.config";
import { getGoogleMerchantCenterLanguageCode } from "~/src/helpers/language.helper";
import { getMetadataValue, OrderMetadataKey, ProductMedatadaKey } from "~/src/helpers/metadata.helpers";
import useRegion from "~/src/hooks/useRegion.hook";
import { addToDate } from "~/src/libraries/dayjs.library";

var GoogleCustomerReviews = function GoogleCustomerReviews(_ref) {
  var order = _ref.order,
      paymentMethod = _ref.paymentMethod;

  var _useRegion = useRegion(),
      currentLocale = _useRegion.currentLocale;

  var surveyHasBeenRendered = React.useRef(false);
  var renderOptInSurvey = React.useCallback(function (orderData) {
    var _orderData$billingAdd;

    var email = orderData.userEmail;
    var deliveryCountry = (_orderData$billingAdd = orderData.billingAddress) === null || _orderData$billingAdd === void 0 || (_orderData$billingAdd = _orderData$billingAdd.country) === null || _orderData$billingAdd === void 0 ? void 0 : _orderData$billingAdd.code;
    var estimatedDeliveryDate = addToDate({
      date: orderData.created,
      value: 1,
      unit: "day"
    }).format("YYYY-MM-DD");
    var allProducts = orderData.lines.reduce(function (acc, line) {
      var _line$variant, _line$variant3;

      var isVariantsSet = !!((_line$variant = line.variant) !== null && _line$variant !== void 0 && (_line$variant = _line$variant.product) !== null && _line$variant !== void 0 && (_line$variant = _line$variant.variantsInSet) !== null && _line$variant !== void 0 && _line$variant.length);

      if (isVariantsSet) {
        var _line$variant2;

        var _iterator = _createForOfIteratorHelper(((_line$variant2 = line.variant) === null || _line$variant2 === void 0 || (_line$variant2 = _line$variant2.product) === null || _line$variant2 === void 0 ? void 0 : _line$variant2.variantsInSet) || []),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var variantInSet = _step.value;
            var gtin = getMetadataValue(variantInSet.variant.metadata, ProductMedatadaKey.GOOGLE_GTIN) || getMetadataValue(variantInSet.variant.metadata, ProductMedatadaKey.EAN);

            if (gtin) {
              acc.push({
                gtin: gtin
              });
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      } else if ((_line$variant3 = line.variant) !== null && _line$variant3 !== void 0 && _line$variant3.metadata) {
        var _line$variant4;

        var _gtin = getMetadataValue(line.variant.metadata, ProductMedatadaKey.GOOGLE_GTIN) || getMetadataValue((_line$variant4 = line.variant) === null || _line$variant4 === void 0 ? void 0 : _line$variant4.metadata, ProductMedatadaKey.EAN);

        if (_gtin) {
          acc.push({
            gtin: _gtin
          });
        }
      }

      return acc;
    }, []);
    var uniqueProducts = allProducts.filter(function (product, index, self) {
      return index === self.map(function (mapProduct) {
        return mapProduct.gtin;
      }).indexOf(product.gtin);
    });

    if (email && deliveryCountry) {
      var script = document.createElement("script");
      script.src = "https://apis.google.com/js/platform.js?onload=renderOptIn";
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);

      window.renderOptIn = function () {
        window.gapi.load("surveyoptin", function () {
          window.gapi.surveyoptin.render({
            merchant_id: GOOGLE_MERCHANT_CENTER_ID,
            order_id: orderData.id,
            email: email,
            delivery_country: deliveryCountry,
            estimated_delivery_date: estimatedDeliveryDate,
            products: uniqueProducts
          });
        });
      };

      surveyHasBeenRendered.current = true;
      return function () {
        document.body.removeChild(script);
      };
    }
  }, []);
  React.useEffect(function () {
    if (!order || surveyHasBeenRendered.current) {
      return;
    }

    var isOrderExluded = getMetadataValue(order.metadata, OrderMetadataKey.GOOGLE_MERCHANT_CENTER_OPT_IN_SURVEY_EXCLUDE) === "true";

    if (isOrderExluded) {
      return;
    }

    if (order.status === "CANCELED" || order.status === "RETURNED" || order.status === "PARTIALLY_RETURNED") {
      return;
    }

    if ((paymentMethod === "blik" || paymentMethod === "payu" || paymentMethod === "paypo") && (order.paymentStatus === "FULLY_REFUNDED" || order.paymentStatus === "CANCELLED" || order.paymentStatus === "PARTIALLY_REFUNDED" || order.paymentStatus === "NOT_CHARGED" || order.paymentStatus === "PENDING" || order.paymentStatus === "REFUSED")) {
      return;
    }

    renderOptInSurvey(order);
  }, [order, paymentMethod, renderOptInSurvey]);
  React.useEffect(function () {
    var lang = getGoogleMerchantCenterLanguageCode(currentLocale);
    window.___gcfg = {
      lang: lang
    };
  }, [currentLocale]);
  return null;
};

export default GoogleCustomerReviews;