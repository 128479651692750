import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["src", "blurDataURL", "blurDataRGB", "className", "placeholder"];
var __jsx = React.createElement;
import NextImage from "next/image";
import React from "react";
import { rgbDataURL } from "@/helpers/image.helpers";

var Image = function Image(_ref) {
  var src = _ref.src,
      blurDataURL = _ref.blurDataURL,
      blurDataRGB = _ref.blurDataRGB,
      className = _ref.className,
      placeholder = _ref.placeholder,
      props = _objectWithoutProperties(_ref, _excluded);

  return __jsx(NextImage, _extends({}, props, {
    src: src,
    className: className,
    placeholder: blurDataRGB ? "blur" : placeholder,
    blurDataURL: blurDataRGB ? rgbDataURL(blurDataRGB) : placeholder === "blur" && typeof src === "string" && !(blurDataURL !== null && blurDataURL !== void 0 && blurDataURL.length) ? rgbDataURL({
      r: 234,
      g: 234,
      b: 234
    }) : blurDataURL
  }));
};

export default Image;