var __jsx = React.createElement;
import React from "react";
import { cn } from "@/libraries/clsx.library";
import styles from "./Tag.styles";

var Group = function Group(_ref) {
  var children = _ref.children,
      className = _ref.className,
      variant = _ref.variant;
  return __jsx("div", {
    className: cn(styles.group({
      variant: variant
    }), className)
  }, children);
};

Group.displayName = "Group";
export default Group;