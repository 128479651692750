import _slicedToArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
import isFunction from "lodash/isFunction";
import round from "lodash/round";
import React from "react";
import { useIntl } from "react-intl";
import { Rating } from "@/components/ui";
import { PAGE_TYPE_CLIENT_REVIEW_CUSTOMER_NAME_ATTRIBUTE, PAGE_TYPE_CLIENT_REVIEW_RATING_ATTRIBUTE, PAGE_TYPE_CLIENT_REVIEW_SLUG } from "@/config/common.config";
import { getPageAttributes } from "@/helpers/attribute.helper";
import colors from "@/theme/theme.colors.tw";
import styles from "./AverageRating.styles";

var AverageRating = function AverageRating(_ref) {
  var pages = _ref.pages,
      iconSize = _ref.iconSize,
      clickAction = _ref.clickAction;
  var t = useIntl();

  var _React$useState = React.useState({
    averageRating: 0,
    reviewsCount: 0
  }),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      ratingDetails = _React$useState2[0],
      setRatingDetails = _React$useState2[1];

  React.useEffect(function () {
    if (pages) {
      var reviews = getPageAttributes(pages, PAGE_TYPE_CLIENT_REVIEW_SLUG, {
        rating: PAGE_TYPE_CLIENT_REVIEW_RATING_ATTRIBUTE,
        customer: PAGE_TYPE_CLIENT_REVIEW_CUSTOMER_NAME_ATTRIBUTE
      }, function (attributes, content) {
        return {
          rating: attributes.rating,
          customer: attributes.customer,
          text: content
        };
      });

      if (reviews.length > 0) {
        var ratingSum = reviews.reduce(function (acc, review) {
          if (review.rating) {
            return Number(acc) + Number(review.rating);
          }

          return acc;
        }, 0);
        setRatingDetails({
          averageRating: round(ratingSum / reviews.length, 2),
          reviewsCount: reviews.length
        });
      }
    }
  }, [pages]);

  var handleClick = function handleClick() {
    if (isFunction(clickAction)) {
      clickAction();
    }
  };

  if (ratingDetails.reviewsCount === 0) {
    return __jsx("div", {
      className: styles.root()
    });
  }

  return __jsx("div", {
    className: styles.root({
      cursorPointer: isFunction(clickAction)
    }),
    onClick: handleClick
  }, __jsx(Rating, {
    rating: ratingDetails.averageRating,
    iconMainColor: colors.flavor["default"].warning,
    iconSize: iconSize
  }), __jsx("span", {
    className: styles.reviewsCount()
  }, t.formatMessage({
    id: "averageRating.reviewsCount",
    defaultMessage: "({reviewsCount} {reviewsCount, plural, one {opinion} other {opinions}})"
  }, {
    reviewsCount: ratingDetails.reviewsCount
  })));
};

export default AverageRating;