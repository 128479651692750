var __jsx = React.createElement;
import React from "react";
import { cn } from "@/libraries/clsx.library";
import styles from "./Skeleton.styles";

var Skeleton = function Skeleton(_ref) {
  var className = _ref.className,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? "gray" : _ref$color,
      width = _ref.width,
      height = _ref.height;
  return __jsx("div", {
    className: cn(styles.root({
      color: color
    }), className),
    style: {
      width: width,
      height: height
    }
  });
};

export default Skeleton;