import _objectWithoutProperties from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["status"];
var __jsx = React.createElement;
import { CheckCircleIcon, ExclamationTriangleIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import React from "react";
export var Icon = function Icon(_ref) {
  var status = _ref.status,
      props = _objectWithoutProperties(_ref, _excluded);

  switch (status) {
    case "information":
      return __jsx(InformationCircleIcon, props);

    case "error":
      return __jsx(ExclamationTriangleIcon, props);

    case "success":
      return __jsx(CheckCircleIcon, props);

    default:
      return __jsx(InformationCircleIcon, props);
  }
};
Icon.displayName = "Icon";
export default Icon;