import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from "react";

var SvgYoutube = function SvgYoutube(props) {
  return __jsx("svg", _extends({
    width: 24,
    height: 17,
    viewBox: "0 0 24 17",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("path", {
    d: "M23.4304 5.51591C23.4304 2.74593 21.1845 0.5 18.4145 0.5H5.58574C2.81576 0.5 0.569824 2.74593 0.569824 5.51591V11.4841C0.569824 14.2541 2.81576 16.5 5.58574 16.5H18.4145C21.1845 16.5 23.4304 14.2541 23.4304 11.4841V5.51591ZM15.8871 8.94769L10.1345 11.7925C9.90989 11.9153 9.14333 11.7506 9.14333 11.4961V5.65365C9.14333 5.39313 9.91588 5.23142 10.1405 5.36019L15.6475 8.35477C15.8781 8.48653 16.1206 8.81892 15.8871 8.94769Z",
    fill: "#FAFAFA"
  }));
};

export default SvgYoutube;