var __jsx = React.createElement;
import React from "react";
import { useIntl } from "react-intl";
import { Button } from "@/components/ui";
import { up } from "@/helpers/screens.helpers";
import { useMediaQuery } from "@/hooks/useMediaQuery.hook";
import { cn } from "@/libraries/clsx.library";
import { ScreenEnum } from "@/theme/theme.enums";
import Icon from "./Links.Icon";
import styles from "./Links.styles";

var Links = function Links(_ref) {
  var links = _ref.links,
      className = _ref.className,
      _ref$style = _ref.style,
      style = _ref$style === void 0 ? "list" : _ref$style;
  var t = useIntl();
  var isUpLg = useMediaQuery(up(ScreenEnum.lg), true);
  if (links.length === 0) return null;
  return __jsx("li", {
    className: cn(styles.main({
      style: style
    }), className)
  }, links.map(function (_ref2, index) {
    var icon = _ref2.icon,
        link = _ref2.link,
        label = _ref2.label,
        linkShortLabel = _ref2.linkShortLabel;
    return __jsx("ul", {
      key: index,
      className: styles.element({
        style: style
      })
    }, __jsx("div", null, __jsx(Icon, {
      icon: icon,
      className: styles.icon({
        style: style
      }),
      stroke: "#5D5656"
    })), __jsx("div", null, __jsx("span", {
      className: styles.label({
        style: style
      })
    }, label), __jsx("a", {
      "aria-label": t.formatMessage({
        defaultMessage: "Manifest link: {link}",
        id: "manifest.links.aria"
      }, {
        link: link
      }),
      href: link,
      target: "_blank",
      rel: "noopener noreferrer"
    }, __jsx(Button, {
      color: "flavor",
      variant: "naked"
    }, style === "list" && isUpLg ? link : linkShortLabel))));
  }));
};

Links.Icon = Icon;
Links.displayName = "Links";
export default Links;