import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var __jsx = React.createElement;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { RadioGroup as Source } from "@headlessui/react";
import React from "react";
import Item from "./Radio.Item";
import styles from "./Radio.styles";
/**
 * HeadlessUI "Radio Group"
 * https://headlessui.dev/react/radio-group
 */

var Radio = function Radio(_ref) {
  var children = _ref.children,
      items = _ref.items,
      value = _ref.value,
      label = _ref.label,
      onChange = _ref.onChange;
  if (items.length === 0) return null;
  return __jsx(Source, {
    value: value,
    onChange: onChange
  }, label ? __jsx(Source.Label, {
    className: styles.label()
  }, label) : null, __jsx("div", {
    className: styles.root()
  }, children ? children : items.map(function (item, index) {
    return Item(_objectSpread(_objectSpread({}, item), {}, {
      first: index === 0,
      last: index === items.length - 1
    }));
  })));
};

Radio.Item = Item;
Radio.displayName = "Radio";
export default Radio;