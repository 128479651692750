import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from "react";

var SvgOutlineInstagram = function SvgOutlineInstagram(props) {
  return __jsx("svg", _extends({
    width: 24,
    height: 25,
    viewBox: "0 0 24 25",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("g", {
    clipPath: "url(#clip0_6429_81906)"
  }, __jsx("path", {
    d: "M12 16.25C14.0711 16.25 15.75 14.5711 15.75 12.5C15.75 10.4289 14.0711 8.75 12 8.75C9.92893 8.75 8.25 10.4289 8.25 12.5C8.25 14.5711 9.92893 16.25 12 16.25Z",
    stroke: props.stroke || "#5D5656",
    strokeWidth: 1.4,
    strokeMiterlimit: 10
  }), __jsx("path", {
    d: "M16.5 3.5H7.5C5.01472 3.5 3 5.51472 3 8V17C3 19.4853 5.01472 21.5 7.5 21.5H16.5C18.9853 21.5 21 19.4853 21 17V8C21 5.51472 18.9853 3.5 16.5 3.5Z",
    stroke: props.stroke || "#5D5656",
    strokeWidth: 1.4,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __jsx("path", {
    d: "M16.875 8.75C17.4963 8.75 18 8.24632 18 7.625C18 7.00368 17.4963 6.5 16.875 6.5C16.2537 6.5 15.75 7.00368 15.75 7.625C15.75 8.24632 16.2537 8.75 16.875 8.75Z",
    fill: props.stroke || "#5D5656"
  })), __jsx("defs", null, __jsx("clipPath", {
    id: "clip0_6429_81906"
  }, __jsx("rect", {
    width: 24,
    height: 24,
    fill: "white",
    transform: "translate(0 0.5)"
  }))));
};

export default SvgOutlineInstagram;