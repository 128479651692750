import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from "react";

var SvgOutlineShop = function SvgOutlineShop(props) {
  return __jsx("svg", _extends({
    width: 24,
    height: 25,
    viewBox: "0 0 24 25",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("path", {
    d: "M20.3611 6.5H4.47222C4.07335 6.5 3.75 6.79848 3.75 7.16667V19.1667C3.75 19.5349 4.07335 19.8333 4.47222 19.8333H20.3611C20.76 19.8333 21.0833 19.5349 21.0833 19.1667V7.16667C21.0833 6.79848 20.76 6.5 20.3611 6.5Z",
    stroke: props.stroke || "#5D5656",
    strokeWidth: 1.3,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __jsx("path", {
    d: "M3.75 9.16666H21.0833",
    stroke: props.stroke || "#5D5656",
    strokeWidth: 1.3,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __jsx("path", {
    d: "M16.0279 11.8333C16.0279 12.7174 15.6474 13.5652 14.9702 14.1904C14.293 14.8155 13.3745 15.1667 12.4168 15.1667C11.459 15.1667 10.5405 14.8155 9.86333 14.1904C9.18612 13.5652 8.80566 12.7174 8.80566 11.8333",
    stroke: props.stroke || "#5D5656",
    strokeWidth: 1.3,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }));
};

export default SvgOutlineShop;