import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["percentFill", "mainColor", "secondaryColor", "size"];
var __jsx = React.createElement;
import * as React from "react";

var SvgStar = function SvgStar(_ref) {
  var _ref$percentFill = _ref.percentFill,
      percentFill = _ref$percentFill === void 0 ? 100 : _ref$percentFill,
      _ref$mainColor = _ref.mainColor,
      mainColor = _ref$mainColor === void 0 ? "#D33262" : _ref$mainColor,
      _ref$secondaryColor = _ref.secondaryColor,
      secondaryColor = _ref$secondaryColor === void 0 ? "#DAD7D7" : _ref$secondaryColor,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? 22 : _ref$size,
      props = _objectWithoutProperties(_ref, _excluded);

  var linearGradientId = React.useId();
  return __jsx("svg", _extends({
    width: size,
    height: size,
    viewBox: "0 0 24 22",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("defs", null, __jsx("linearGradient", {
    id: linearGradientId
  }, __jsx("stop", {
    offset: "".concat(percentFill, "%"),
    stopColor: mainColor
  }), __jsx("stop", {
    offset: 0,
    stopColor: secondaryColor
  }))), __jsx("path", {
    fill: "url(#".concat(linearGradientId, ")"),
    d: "M12 0L14.6942 8.2918H23.4127L16.3593 13.4164L19.0534 21.7082L12 16.5836L4.94658 21.7082L7.64074 13.4164L0.587322 8.2918H9.30583L12 0Z"
  }));
};

export default SvgStar;