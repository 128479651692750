var __jsx = React.createElement;
import React from "react";
import { Icon } from "@/components/ui";
import { cn } from "@/libraries/clsx.library";
import styles from "./Keypoints.styles";

var Keypoints = function Keypoints(_ref) {
  var className = _ref.className,
      keypoints = _ref.keypoints,
      _ref$font = _ref.font,
      font = _ref$font === void 0 ? "tenor" : _ref$font,
      mobileHorizontal = _ref.mobileHorizontal;
  return __jsx("div", {
    className: cn(styles.root(), className)
  }, keypoints.map(function (keypoint, key) {
    return __jsx("div", {
      className: styles.keypoint({
        mobileHorizontal: mobileHorizontal
      }),
      key: "keypoint-".concat(key)
    }, __jsx(Icon, {
      name: keypoint.iconName,
      className: styles.keypointIcon()
    }), __jsx("div", null, __jsx("h4", {
      className: styles.keypointTitle({
        font: font
      })
    }, keypoint.title), __jsx("p", {
      className: styles.keypointDescription()
    }, keypoint.description)));
  }));
};

Keypoints.displayName = "Keypoints";
export default Keypoints;