import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from "react";

var SvgPayPoLogo = function SvgPayPoLogo(props) {
  return __jsx("svg", _extends({
    id: "Warstwa_1",
    xmlns: "http://www.w3.org/2000/svg",
    xmlnsXlink: "http://www.w3.org/1999/xlink",
    x: "0px",
    y: "0px",
    viewBox: "0 0 1023 280.1" // style={{
    //   enableBackground: "new 0 0 1023 280.1",
    // }}
    ,
    enableBackground: "new 0 0 1023 280.1",
    xmlSpace: "preserve"
  }, props), __jsx("style", {
    type: "text/css"
  }, ".st0{fill:#A70584;}\n\t.st1{fill:#36B588;}\n\t.st2{fill:#FAD05B;}"), __jsx("g", null, __jsx("g", null, __jsx("rect", {
    x: 80.5,
    y: 158.9,
    transform: "matrix(-1.836970e-16 1 -1 -1.836970e-16 299.1147 78.6144)",
    className: "st0",
    width: 59.5,
    height: 60
  }), __jsx("rect", {
    x: 80,
    y: 78.4,
    transform: "matrix(-1.836970e-16 1 -1 -1.836970e-16 218.3723 -1.6347)",
    className: "st1",
    width: 60,
    height: 60
  }), __jsx("rect", {
    x: 0,
    y: 158.4,
    transform: "matrix(-1.836970e-16 1 -1 -1.836970e-16 218.3723 158.3634)",
    className: "st2",
    width: 60,
    height: 60
  })), __jsx("path", {
    d: "M319.1,74.6c0,41.4-33.3,74.6-76.2,74.6h-32.7v68.4h-49.8V0h82.4C285.8,0,319.1,33.3,319.1,74.6z M269.4,74.6 c0-15.9-11.2-28-26.4-28h-32.7v56h32.7C258.2,102.6,269.4,90.5,269.4,74.6z"
  }), __jsx("path", {
    d: "M492.7,58v159.6h-47.9v-15c-10.5,12.1-26.2,19.5-47.6,19.5c-41.8,0-76.3-36.7-76.3-84.3s34.5-84.3,76.3-84.3 c21.4,0,37,7.3,47.6,19.5V58H492.7z M444.8,137.8c0-23.9-16-39-38-39c-22,0-38,15-38,39c0,23.9,16,39,38,39 C428.8,176.7,444.8,161.7,444.8,137.8z"
  }), __jsx("path", {
    d: "M674.6,58l-54.2,153.8c-17.8,50.4-46,70.4-90.4,68.2v-44.4c22.2,0,32.7-7,39.6-26.3L506.6,58h52.3l35.2,97l29.8-97H674.6z"
  }), __jsx("path", {
    d: "M851.9,74.6c0,41.4-33.3,74.6-76.2,74.6h-32.7v68.4h-49.8V0h82.4C818.6,0,851.9,33.3,851.9,74.6z M802.2,74.6 c0-15.9-11.2-28-26.4-28h-32.7v56h32.7C791,102.6,802.2,90.5,802.2,74.6z"
  }), __jsx("path", {
    d: "M858.1,140c0-46.3,36.7-82.1,82.4-82.1c45.7,0,82.4,35.8,82.4,82.1s-36.7,82.1-82.4,82.1 C894.8,222.1,858.1,186.3,858.1,140z M976.3,140c0-22.1-15.6-36.7-35.8-36.7c-20.2,0-35.8,14.6-35.8,36.7 c0,22.1,15.6,36.7,35.8,36.7C960.8,176.7,976.3,162.1,976.3,140z"
  })));
};

export default SvgPayPoLogo;