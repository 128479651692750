import _slicedToArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import isFunction from "lodash/isFunction";
import truncate from "lodash/truncate";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { cn } from "@/libraries/clsx.library";
import styles from "./Autocomplete.styles";

var Autocomplete = function Autocomplete(_ref) {
  var _ctx$formState$errors;

  var name = _ref.name,
      options = _ref.options,
      optionItem = _ref.optionItem,
      onOptionClick = _ref.onOptionClick,
      onInputChange = _ref.onInputChange,
      inputKey = _ref.inputKey,
      valueKey = _ref.valueKey,
      warning = _ref.warning,
      label = _ref.label,
      labelClassName = _ref.labelClassName,
      errorClassName = _ref.errorClassName,
      disabled = _ref.disabled,
      _ref$error = _ref.error,
      error = _ref$error === void 0 ? {
    placement: "bottom",
    custom: null
  } : _ref$error,
      registerOptions = _ref.registerOptions,
      _ref$withOptionsList = _ref.withOptionsList,
      withOptionsList = _ref$withOptionsList === void 0 ? true : _ref$withOptionsList,
      _ref$withOpenOptionsB = _ref.withOpenOptionsButton,
      withOpenOptionsButton = _ref$withOpenOptionsB === void 0 ? false : _ref$withOpenOptionsB;
  var ctx = useFormContext();
  var t = useIntl();

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      isOpen = _React$useState2[0],
      setIsOpen = _React$useState2[1];

  var _React$useState3 = React.useState(null),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      closeTimeout = _React$useState4[0],
      setCloseTimeout = _React$useState4[1];

  var e = error.custom ? error.custom : ctx === null || ctx === void 0 || (_ctx$formState$errors = ctx.formState.errors[name]) === null || _ctx$formState$errors === void 0 ? void 0 : _ctx$formState$errors.message;
  var inputRef = React.useRef(null);
  var showOptionList = React.useCallback(function () {
    setIsOpen(true);

    if (closeTimeout !== null) {
      clearTimeout(closeTimeout);
    }
  }, [closeTimeout]);
  var closeOptionList = React.useCallback(function () {
    var timeoutId = setTimeout(function () {
      setIsOpen(false);
    }, 100);
    setCloseTimeout(timeoutId);
  }, []);

  var handleOnOptionClick = function handleOnOptionClick(option) {
    if (isFunction(onOptionClick)) {
      return onOptionClick(option);
    }

    ctx.setValue(name, option[valueKey]);
  };

  var getInputValue = function getInputValue(value) {
    var _options$find;

    return ((_options$find = options.find(function (option) {
      return option[valueKey] === value;
    })) === null || _options$find === void 0 ? void 0 : _options$find[inputKey]) || value;
  };

  var renderError = React.useCallback(function (errorMessage, errorMessageLength) {
    return __jsx("span", {
      className: cn(styles.text(), errorClassName)
    }, errorMessageLength ? truncate(errorMessage, {
      length: errorMessageLength,
      omission: " (...)"
    }) : errorMessage);
  }, [errorClassName]);
  var renderWarning = React.useCallback(function (activeWarning) {
    return __jsx("span", {
      className: styles.text({
        warning: !!activeWarning
      })
    }, activeWarning.maxLength ? truncate(activeWarning.message, {
      length: activeWarning.maxLength,
      omission: " (...)"
    }) : activeWarning.message);
  }, []);
  return __jsx("div", {
    className: styles.root()
  }, __jsx("div", {
    className: styles.info()
  }, label && __jsx("label", {
    className: cn(styles.label(), labelClassName)
  }, label), error.placement === "top" && renderError(e, error.maxLength)), __jsx(Controller, {
    name: name,
    rules: registerOptions,
    render: function render(_ref2) {
      var field = _ref2.field;
      return __jsx("div", {
        className: styles.root()
      }, __jsx("div", {
        className: styles.triggerContainer()
      }, __jsx("input", {
        ref: function ref(_ref3) {
          inputRef.current = _ref3;
          field.ref(_ref3);
        },
        type: "text",
        name: field.name,
        value: getInputValue(field.value),
        className: styles.triggerInput({
          warning: !!warning,
          error: !!e
        }),
        onChange: function onChange(event) {
          field.onChange(event.target.value);

          if (onInputChange) {
            onInputChange(event.target.value);
          }
        },
        onFocus: function onFocus() {
          return showOptionList();
        },
        onBlur: function onBlur() {
          return closeOptionList();
        },
        onKeyPress: function onKeyPress(event) {
          if (event.key === "Enter") {
            event.preventDefault();
            setIsOpen(false);
          }
        },
        autoComplete: "off",
        disabled: disabled
      }), withOpenOptionsButton && __jsx("button", {
        type: "button",
        className: styles.triggerButton({
          disabled: disabled
        }),
        onClick: function onClick() {
          var _inputRef$current;

          return (_inputRef$current = inputRef.current) === null || _inputRef$current === void 0 ? void 0 : _inputRef$current.focus();
        }
      }, __jsx(ChevronUpDownIcon, {
        className: styles.triggerButtonIcon(),
        "aria-hidden": "true"
      }))), isOpen && withOptionsList ? __jsx("ul", {
        className: styles.options()
      }, options.length === 0 ? __jsx("li", {
        className: styles.optionsEmpty()
      }, t.formatMessage({
        id: "autocomplete.emptyInfo",
        defaultMessage: "No data"
      })) : options.map(function (option, optionIndex) {
        return __jsx("li", {
          key: "autocomplete-option-".concat(optionIndex),
          className: styles.option({
            selected: option[valueKey] === field.value
          }),
          onClick: function onClick() {
            return handleOnOptionClick(option);
          }
        }, optionItem(option, option[valueKey] === field.value));
      })) : null);
    }
  }), warning && warning.placement === "bottom" ? __jsx("div", null, renderWarning(warning)) : null);
};

export default Autocomplete;