import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../libraries/stitches.library.ts";
var styles = {
  wrapper: _css(_objectSpread(_objectSpread({}, {
    "width": "100%"
  }), {}, {
    variants: {
      color: {
        gray: {
          backgroundColor: "$gray0"
        },
        white: {
          backgroundColor: "$white"
        },
        light: {
          backgroundColor: "$lighter"
        },
        flavor: {
          backgroundColor: "$default"
        },
        dark: {
          backgroundColor: "$gray9"
        },
        transparent: {
          backgroundColor: "transparent"
        }
      }
    }
  })),
  box: _css(_objectSpread(_objectSpread({}, {
    "marginLeft": "auto",
    "marginRight": "auto",
    "width": "100%",
    "paddingLeft": "1rem",
    "paddingRight": "1rem"
  }), {}, {
    maxWidth: "xl",
    "@xs": _objectSpread({}, {
      "maxWidth": "100%"
    }),
    "@sm": _objectSpread({}, {
      "paddingLeft": "1.5rem",
      "paddingRight": "1.5rem"
    }),
    "@md": {
      maxWidth: "$md"
    },
    "@lg": _objectSpread(_objectSpread({}, {
      "paddingLeft": "2rem",
      "paddingRight": "2rem"
    }), {}, {
      maxWidth: "$lg"
    }),
    "@xl": _objectSpread(_objectSpread({}, {
      "paddingLeft": "2.5rem",
      "paddingRight": "2.5rem"
    }), {}, {
      maxWidth: "$xl"
    })
  }))
};
export default styles;