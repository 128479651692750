import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../libraries/stitches.library.ts";
var styles = {
  root: function root(_ref) {
    var height = _ref.height;
    return _objectSpread(_objectSpread({}, {
      "position": "relative",
      "width": "100%",
      "transitionProperty": "height",
      "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
      "transitionDuration": "300ms",
      "animationDuration": "300ms",
      "transitionDelay": "150ms",
      "animationDelay": "150ms",
      "animationTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)"
    }), {}, {
      height: typeof height === "number" ? "".concat(height, "px") : "auto"
    });
  },
  line: _css(_objectSpread(_objectSpread({}, {
    "position": "absolute",
    "inset": "0px",
    "display": "flex",
    "alignItems": "center",
    "justifyContent": "center",
    "transitionProperty": "height",
    "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "transitionDuration": "300ms",
    "animationDuration": "300ms",
    "transitionDelay": "150ms",
    "animationDelay": "150ms",
    "animationTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)"
  }), {}, {
    "& hr": _objectSpread(_objectSpread({}, {
      "width": "100%",
      "borderTopWidth": "1px"
    }), {}, {
      borderColor: "$light"
    }),
    variants: {
      color: {
        light: {
          "& hr": {
            borderColor: "$light"
          }
        },
        gray: {
          "& hr": {
            borderColor: "$gray3"
          }
        }
      }
    }
  })),
  label: _css(_objectSpread(_objectSpread({}, {
    "position": "relative",
    "display": "flex",
    "justifyContent": "center",
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem"
  }), {}, {
    "& span": _objectSpread(_objectSpread({}, {
      "paddingLeft": "0.5rem",
      "paddingRight": "0.5rem"
    }), {}, {
      color: "$gray7",
      backgroundColor: "$white"
    })
  }))
};
export default styles;