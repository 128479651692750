var __jsx = React.createElement;
import { DevicePhoneMobileIcon, ExclamationCircleIcon, ExclamationTriangleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import DOMPurify from "isomorphic-dompurify";
import Link from "next/link";
import React, { Fragment } from "react";
import { useIntl } from "react-intl";
import { Price, Timer } from "@/components/ui";
import { BlikLogo, Check, Clock, InPostLogo, PayPoLogo, PayuLogo, ShoppingBag, Truck } from "@/components/ui/Icon/$";
import { FREE_DELIVERY_PRICE } from "@/config/common.config";
import { useGlobal } from "@/hooks/useGlobal.hook";
import useInterface from "@/hooks/useInterface.hook";
import useRegion from "@/hooks/useRegion.hook";
import { cn } from "@/libraries/clsx.library";
import { isValidDate } from "@/libraries/dayjs.library";
import { AddToCart, AddToWishlist, Overlay } from "@/product/components";
import useCheckout from "~/src/hooks/useCheckout.hook";
import { useProductVariant } from "~/src/modules/product/hooks/useProductVariant.hook";
import styles from "./OrderDetails.styles";

var OrderDetails = function OrderDetails(_ref) {
  var activeVariant = _ref.activeVariant,
      purchasedInLastDays = _ref.purchasedInLastDays,
      isShippingRequired = _ref.isShippingRequired,
      testIdPrefix = _ref.testIdPrefix;
  var t = useIntl();

  var _useRegion = useRegion(),
      currentLocale = _useRegion.currentLocale,
      formatPrice = _useRegion.formatPrice,
      currentChannel = _useRegion.currentChannel;

  var _useProductVariant = useProductVariant(activeVariant),
      priceUndiscounted = _useProductVariant.priceUndiscounted,
      price = _useProductVariant.price,
      priceHistory = _useProductVariant.priceHistory,
      variantStockQuantity = _useProductVariant.variantStockQuantity,
      showDeliveryTime = _useProductVariant.showDeliveryTime,
      isAvailableForPurchase = _useProductVariant.isAvailableForPurchase,
      availableQuantity = _useProductVariant.availableQuantity;

  var _useGlobal = useGlobal(),
      lovaAppForEveryOrder = _useGlobal.lovaAppForEveryOrder,
      promoBar = _useGlobal.promoBar;

  var _useInterface = useInterface(),
      openDialog = _useInterface.openDialog,
      activeDialog = _useInterface.activeDialog,
      closeDialog = _useInterface.closeDialog;

  var _useCheckout = useCheckout(),
      addItems = _useCheckout.addItems;

  var freeDeliveryPrice = formatPrice({
    currency: currentChannel.currencyCode,
    amount: FREE_DELIVERY_PRICE[currentLocale]
  }, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
  var renderPromoText = React.useCallback(function (children) {
    var addProductVariant = promoBar.addProductVariant,
        productVariantId = promoBar.productVariantId,
        productVariantQuantity = promoBar.productVariantQuantity,
        url = promoBar.url;
    return addProductVariant ? __jsx("span", {
      className: styles.promoText({
        pointer: productVariantId !== "" && productVariantQuantity > 0
      }),
      onClick: function onClick() {
        if (productVariantId && productVariantQuantity) {
          addItems({
            id: productVariantId,
            quantity: productVariantQuantity
          });
          openDialog("CART");
        }
      }
    }, children) : url ? __jsx(Link, {
      href: url,
      legacyBehavior: true,
      passHref: true
    }, __jsx("a", {
      className: styles.promoText()
    }, children)) : __jsx("span", {
      className: styles.promoText()
    }, children);
  }, [addItems, promoBar, openDialog]);
  return __jsx(Fragment, null, __jsx("div", {
    className: styles.root()
  }, promoBar.isVisible && promoBar.isActive && (isShippingRequired ? promoBar.showOnShippableProductsDetails : promoBar.showOnNonShippableProductsDetails) ? __jsx("div", {
    className: styles.promoContainer()
  }, renderPromoText(__jsx("span", {
    dangerouslySetInnerHTML: {
      __html: DOMPurify.sanitize(promoBar.text)
    }
  })), __jsx("div", {
    className: styles.promoTimerContainer()
  }, isValidDate(promoBar.endDate) && promoBar.showTimer ? __jsx(Timer, {
    targetDate: promoBar.endDate
  }) : null)) : null, price ? __jsx("div", {
    className: styles.price()
  }, __jsx(Price, {
    priceUndiscounted: priceUndiscounted,
    price: price,
    size: "xl",
    fontFamily: "tenor",
    testIdPrefix: testIdPrefix !== null && testIdPrefix !== void 0 ? testIdPrefix : "",
    classPrefix: "product-"
  }), priceHistory.amount > 0 ? __jsx("div", {
    className: styles.history()
  }, __jsx("span", null, t.formatMessage({
    defaultMessage: "Lowest price from 30 days before discount: {price}",
    id: "product.orderDetails.priceHistory"
  }, {
    price: formatPrice(priceHistory)
  }))) : null) : null, __jsx("div", {
    className: styles.addToCart()
  }, purchasedInLastDays >= 20 ? __jsx("span", {
    className: styles.purchasedInLatDays()
  }, t.formatMessage({
    defaultMessage: "Bought {productCount} {productCount, plural, one {piece} few {pieces} other {pieces}} in the last days",
    id: "product.orderDetails.purchasedInLastDays"
  }, {
    productCount: purchasedInLastDays
  })) : null, __jsx(AddToCart, {
    activeVariant: activeVariant,
    testIdPrefix: testIdPrefix !== null && testIdPrefix !== void 0 ? testIdPrefix : ""
  })), __jsx("div", {
    className: styles.availabilityContainer()
  }, __jsx("div", {
    className: styles.availabilityInfo()
  }, !isAvailableForPurchase || !!(activeVariant !== null && activeVariant !== void 0 && activeVariant.trackInventory) && availableQuantity === 0 ? __jsx(React.Fragment, null, __jsx(XMarkIcon, {
    className: styles.availabilityIcon()
  }), __jsx("span", {
    className: styles.availabilityText()
  }, t.formatMessage({
    defaultMessage: "Non available",
    id: "product.orderDetails.nonAvailable"
  }))) : variantStockQuantity >= 500 ? __jsx(React.Fragment, null, __jsx(Check, {
    className: styles.availabilityIcon({
      variant: "success"
    })
  }), __jsx("span", {
    className: styles.availabilityText({
      variant: "success"
    })
  }, t.formatMessage({
    defaultMessage: "High availability",
    id: "product.orderDetails.highAvailability"
  }))) : variantStockQuantity >= 200 ? __jsx(React.Fragment, null, __jsx(ExclamationTriangleIcon, {
    className: styles.availabilityIcon({
      variant: "warning"
    })
  }), __jsx("span", {
    className: styles.availabilityText({
      variant: "warning"
    })
  }, t.formatMessage({
    defaultMessage: "Medium availability",
    id: "product.orderDetails.mediumAvailability"
  }))) : __jsx(React.Fragment, null, __jsx(ExclamationCircleIcon, {
    className: styles.availabilityIcon({
      variant: "danger"
    })
  }), __jsx("span", {
    className: styles.availabilityText({
      variant: "danger"
    })
  }, t.formatMessage({
    defaultMessage: "Last items",
    id: "product.orderDetails.lowAvailability"
  })))), __jsx("div", {
    className: styles.availabilityProgressBarContainer()
  }, !isAvailableForPurchase || !!(activeVariant !== null && activeVariant !== void 0 && activeVariant.trackInventory) && availableQuantity === 0 ? __jsx("div", {
    className: styles.availabilityProgressBar({
      variant: "success"
    }),
    style: {
      width: "0%"
    }
  }) : variantStockQuantity >= 500 ? __jsx("div", {
    className: styles.availabilityProgressBar({
      variant: "success"
    }),
    style: {
      width: "100%"
    }
  }) : variantStockQuantity >= 200 ? __jsx("div", {
    className: styles.availabilityProgressBar({
      variant: "warning"
    }),
    style: {
      width: "50%"
    }
  }) : __jsx("div", {
    className: styles.availabilityProgressBar({
      variant: "danger"
    }),
    style: {
      width: "15%"
    }
  }))), activeVariant && (!isAvailableForPurchase || !!(activeVariant !== null && activeVariant !== void 0 && activeVariant.trackInventory) && availableQuantity === 0) ? __jsx(AddToWishlist, {
    variant: activeVariant
  }) : null, __jsx("ul", {
    className: styles.detailsList()
  }, isShippingRequired && showDeliveryTime ? __jsx("li", {
    className: styles.listItem()
  }, __jsx(Clock, {
    className: styles.listItemIcon()
  }), __jsx("span", {
    className: styles.listItemText()
  }, t.formatMessage({
    id: "product.orderDetails.shipment",
    defaultMessage: "Shipping in 24h"
  }))) : null, isShippingRequired ? __jsx("li", {
    className: styles.listItem()
  }, __jsx(Truck, {
    className: styles.listItemIcon()
  }), __jsx("span", {
    className: styles.listItemText()
  }, t.formatMessage({
    id: "product.orderDetails.delivery",
    defaultMessage: "Free delivery from {price}"
  }, {
    price: freeDeliveryPrice
  }))) : null, isShippingRequired ? __jsx("li", {
    className: styles.listItem()
  }, __jsx(ShoppingBag, {
    className: styles.listItemIcon()
  }), __jsx("span", {
    className: styles.listItemText()
  }, t.formatMessage({
    id: "product.orderDetails.return",
    defaultMessage: "Free return within 14 days"
  }))) : null, lovaAppForEveryOrder ? __jsx("li", {
    className: styles.listItem()
  }, __jsx(DevicePhoneMobileIcon, {
    className: cn(styles.listItemIcon(), styles.mobileIcon())
  }), __jsx("span", {
    className: styles.listItemText()
  }, t.formatMessage({
    id: "product.orderDetails.app",
    defaultMessage: "Free access to the LOVA app for 30 days"
  }))) : null), __jsx("div", {
    className: styles.paypoProviderContainer()
  }, __jsx("div", {
    className: styles.paypoProvider(),
    onClick: function onClick() {
      return openDialog("PAYPO_OVERLAY");
    }
  }, __jsx(PayPoLogo, {
    width: 104,
    height: 28
  }), __jsx("span", {
    className: styles.paypoProviderText()
  }, t.formatMessage({
    id: "product.orderDetails.paypoText",
    defaultMessage: "Buy now \nand pay {textHighlighted}"
  }, {
    textHighlighted: __jsx("span", {
      className: styles.paypoProviderTextHighlighted()
    }, t.formatMessage({
      id: "product.orderDetails.paypoTextHighlighted",
      defaultMessage: "in 30 days"
    }))
  })))), __jsx("div", {
    className: styles.otherProvidersContainer()
  }, isShippingRequired ? __jsx(InPostLogo, {
    width: 70,
    height: 40
  }) : null, __jsx(BlikLogo, {
    width: 50,
    height: 25
  }), __jsx(PayuLogo, {
    width: 50,
    height: 25
  }))), __jsx(Overlay, {
    onClose: function onClose() {
      return closeDialog();
    },
    show: activeDialog === "PAYPO_OVERLAY",
    maxWidth: "37rem"
  }, __jsx("iframe", {
    className: styles.overlayContentContainer(),
    src: "https://popup.paypo.pl",
    title: "PayPo"
  })));
};

export default OrderDetails;