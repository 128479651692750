/*
 * https://shadows.brumm.af/
 * ft - final transparency
 * fvd - final vertical distance
 * fbs - final blur strengrh
 * */
export var createShadow = function createShadow(fvd, fbs, ft) {
  return ["0 ".concat(fvd * 0.02, "px ").concat(fbs * 0.02, "px rgba(0, 0, 0, ").concat(ft * 0.3, ")"), "0 ".concat(fvd * 0.06, "px ").concat(fbs * 0.06, "px rgba(0, 0, 0, ").concat(ft * 0.4, ")"), "0 ".concat(fvd * 0.12, "px ").concat(fbs * 0.12, "px rgba(0, 0, 0, ").concat(ft * 0.5, ")"), "0 ".concat(fvd * 0.22, "px ").concat(fbs * 0.22, "px rgba(0, 0, 0, ").concat(ft * 0.6, ")"), "0 ".concat(fvd * 0.42, "px ").concat(fbs * 0.42, "px rgba(0, 0, 0, ").concat(ft * 0.7, ")"), "0 ".concat(fvd, "px ").concat(fbs, "px rgba(0, 0, 0, ").concat(ft, ")")].join(",");
};
export var shadows = {
  1: createShadow(0, 4, 0.04),
  2: createShadow(0, 4, 0.1),
  3: createShadow(2, 8, 0.04),
  4: createShadow(2, 8, 0.1),
  5: createShadow(8, 16, 0.04),
  6: createShadow(8, 16, 0.1),
  7: createShadow(8, 40, 0.04),
  8: createShadow(8, 40, 0.1),
  9: createShadow(10, 60, 0.04),
  10: createShadow(10, 60, 0.1),
  11: createShadow(10, 80, 0.04),
  12: createShadow(10, 80, 0.1),
  13: createShadow(12, 100, 0.04),
  14: createShadow(12, 100, 0.1),
  15: createShadow(16, 120, 0.04),
  16: createShadow(16, 120, 0.1),
  17: createShadow(20, 140, 0.04),
  18: createShadow(20, 140, 0.1),
  19: createShadow(40, 160, 0.04),
  20: createShadow(40, 160, 0.1),
  21: createShadow(50, 180, 0.04),
  22: createShadow(50, 180, 0.1),
  23: createShadow(60, 200, 0.04),
  24: createShadow(60, 200, 0.1)
};
export default shadows;