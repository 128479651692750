var __jsx = React.createElement;
import { round } from "lodash";
import React from "react";
import { Label } from "@/components/ui";
import useRegion from "@/hooks/useRegion.hook";
import { cn } from "@/libraries/clsx.library";
import styles from "./Price.styles";

var Price = function Price(_ref) {
  var priceUndiscounted = _ref.priceUndiscounted,
      priceUndiscointedOptions = _ref.priceUndiscointedOptions,
      price = _ref.price,
      priceOptions = _ref.priceOptions,
      _ref$discountedPriceC = _ref.discountedPriceColor,
      discountedPriceColor = _ref$discountedPriceC === void 0 ? "default" : _ref$discountedPriceC,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? "sm" : _ref$size,
      _ref$fontFamily = _ref.fontFamily,
      fontFamily = _ref$fontFamily === void 0 ? "inter" : _ref$fontFamily,
      _ref$bold = _ref.bold,
      bold = _ref$bold === void 0 ? false : _ref$bold,
      _ref$negative = _ref.negative,
      negative = _ref$negative === void 0 ? false : _ref$negative,
      _ref$withPercentLabel = _ref.withPercentLabel,
      withPercentLabel = _ref$withPercentLabel === void 0 ? true : _ref$withPercentLabel,
      className = _ref.className,
      testIdPrefix = _ref.testIdPrefix,
      classPrefix = _ref.classPrefix;

  var _useRegion = useRegion(),
      formatPrice = _useRegion.formatPrice;

  var discountPercent = priceUndiscounted ? round(100 - price.amount * 100 / priceUndiscounted.amount) : null;
  var hasUndiscountedPrice = React.useMemo(function () {
    return !!(priceUndiscounted && priceUndiscounted.amount > 0 && priceUndiscounted.amount !== price.amount);
  }, [priceUndiscounted, price.amount]);
  return __jsx("div", {
    className: cn(styles.root({
      size: size
    }), className)
  }, hasUndiscountedPrice ? __jsx("span", {
    "data-price-undiscounted": priceUndiscounted === null || priceUndiscounted === void 0 ? void 0 : priceUndiscounted.amount,
    "data-price-undiscounted-currency": priceUndiscounted === null || priceUndiscounted === void 0 ? void 0 : priceUndiscounted.currency,
    className: cn(styles.priceUndiscounted({
      size: size,
      fontFamily: fontFamily,
      bold: bold
    }), "".concat(classPrefix !== null && classPrefix !== void 0 ? classPrefix : "", "price-undiscounted")),
    "data-testid": "".concat(testIdPrefix !== null && testIdPrefix !== void 0 ? testIdPrefix : "", "price-undiscounted")
  }, formatPrice(priceUndiscounted, priceUndiscointedOptions)) : null, __jsx("span", {
    "data-price": price === null || price === void 0 ? void 0 : price.amount,
    "data-price-currency": price === null || price === void 0 ? void 0 : price.currency,
    className: cn(styles.price({
      size: size,
      fontFamily: fontFamily,
      bold: bold,
      discounted: hasUndiscountedPrice,
      discountedColor: discountedPriceColor
    }), "".concat(classPrefix !== null && classPrefix !== void 0 ? classPrefix : "", "price")),
    "data-testid": "".concat(testIdPrefix !== null && testIdPrefix !== void 0 ? testIdPrefix : "", "price")
  }, negative ? "- " : null, formatPrice(price, priceOptions)), discountPercent && withPercentLabel ? __jsx(Label, {
    size: size === "sm" ? size : "md"
  }, "-", discountPercent, "%") : null);
};

export default Price;