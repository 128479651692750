export var ORDER_COMPLETION_PROMO_CODE = process.env.NEXT_PUBLIC_ORDER_COMPLETION_PROMO_CODE || "";
export var CHECKOUT_RECOVERY_PROMO_CODE = process.env.NEXT_PUBLIC_CHECKOUT_RECOVERY_PROMO_CODE || "";
export var CART_RECOVERY_PROMO_CODE = process.env.NEXT_PUBLIC_CART_RECOVERY_PROMO_CODE || "";
export var NEWSLETTER_SUBSCRIPTION_PROMO_CODE = process.env.NEXT_PUBLIC_NEWSLETTER_SUBSCRIPTION_PROMO_CODE || "";
export var popups = [{
  name: "ORDER_COMPLETION",
  priority: 1,
  quantityPerSession: 1,
  excludedUrls: [{
    route: "/[channel]/[locale]/checkout"
  }, {
    route: "/[channel]/[locale]/collect"
  }, {
    route: "/[channel]/[locale]/restore"
  }, {
    route: "/[channel]/[locale]/order/[id]"
  }, {
    route: "/[channel]/[locale]/login"
  }, {
    route: "/[channel]/[locale]/register"
  }, {
    route: "/[channel]/[locale]/password-reset"
  }, {
    route: "/[channel]/[locale]/set-password"
  }, {
    route: "/[channel]/[locale]/account/profile"
  }, {
    route: "/[channel]/[locale]/account/orders"
  }, {
    route: "/[channel]/[locale]/activate-account"
  }, {
    route: "/[channel]/[locale]/page/[slug]",
    pathContains: "/terms-of-use"
  }, {
    route: "/[channel]/[locale]/page/[slug]",
    pathContains: "/privacy-policy"
  }, {
    route: "/[channel]/[locale]/page/[slug]",
    pathContains: "/gwarancja-satysfakcji"
  }],
  onAddToCart: true,
  pageTime: 30000,
  inactionTime: 10000,
  closeTime: 15000
}, {
  name: "CHECKOUT_RECOVERY",
  priority: 2,
  quantityPerSession: 1,
  excludedUrls: [{
    route: "/[channel]/[locale]/checkout"
  }, {
    route: "/[channel]/[locale]/collect"
  }, {
    route: "/[channel]/[locale]/restore"
  }, {
    route: "/[channel]/[locale]/order/[id]"
  }, {
    route: "/[channel]/[locale]/login"
  }, {
    route: "/[channel]/[locale]/register"
  }, {
    route: "/[channel]/[locale]/password-reset"
  }, {
    route: "/[channel]/[locale]/set-password"
  }, {
    route: "/[channel]/[locale]/account/profile"
  }, {
    route: "/[channel]/[locale]/account/orders"
  }, {
    route: "/[channel]/[locale]/activate-account"
  }, {
    route: "/[channel]/[locale]/page/[slug]",
    pathContains: "/terms-of-use"
  }, {
    route: "/[channel]/[locale]/page/[slug]",
    pathContains: "/privacy-policy"
  }, {
    route: "/[channel]/[locale]/page/[slug]",
    pathContains: "/gwarancja-satysfakcji"
  }],
  prevoiusUrl: {
    route: "/[channel]/[locale]/checkout"
  },
  productsInCart: true,
  pageTime: 5000,
  closeTime: 15000
}, {
  name: "CART_RECOVERY",
  priority: 3,
  quantityPerSession: 1,
  excludedUrls: [{
    route: "/[channel]/[locale]/collect"
  }, {
    route: "/[channel]/[locale]/restore"
  }, {
    route: "/[channel]/[locale]/order/[id]"
  }, {
    route: "/[channel]/[locale]/login"
  }, {
    route: "/[channel]/[locale]/register"
  }, {
    route: "/[channel]/[locale]/password-reset"
  }, {
    route: "/[channel]/[locale]/set-password"
  }, {
    route: "/[channel]/[locale]/account/profile"
  }, {
    route: "/[channel]/[locale]/account/orders"
  }, {
    route: "/[channel]/[locale]/activate-account"
  }, {
    route: "/[channel]/[locale]/page/[slug]",
    pathContains: "/terms-of-use"
  }, {
    route: "/[channel]/[locale]/page/[slug]",
    pathContains: "/privacy-policy"
  }, {
    route: "/[channel]/[locale]/page/[slug]",
    pathContains: "/gwarancja-satysfakcji"
  }],
  onRemoveFromCart: true,
  productsInCart: false,
  pageTime: 3000,
  closeTime: 15000
} // {
//   name: "NEWSLETTER_SUBSCRIPTION",
//   priority: 4,
//   quantityPerSession: 1,
//   excludedUrls: [
//     {
//       route: "/[channel]/[locale]/checkout",
//     },
//     {
//       route: "/[channel]/[locale]/[slug]",
//     },
//     {
//       route: "/[channel]/[locale]/products",
//     },
//     {
//       route: "/[channel]/[locale]/low-price-zone",
//     },
//     {
//       route: "/[channel]/[locale]/collect",
//     },
//     {
//       route: "/[channel]/[locale]/restore",
//     },
//     {
//       route: "/[channel]/[locale]/order/[id]",
//     },
//     {
//       route: "/[channel]/[locale]/login",
//     },
//     {
//       route: "/[channel]/[locale]/register",
//     },
//     {
//       route: "/[channel]/[locale]/password-reset",
//     },
//     {
//       route: "/[channel]/[locale]/set-password",
//     },
//     {
//       route: "/[channel]/[locale]/account/profile",
//     },
//     {
//       route: "/[channel]/[locale]/account/orders",
//     },
//     {
//       route: "/[channel]/[locale]/activate-account",
//     },
//     {
//       route: "/[channel]/[locale]/page/[slug]",
//       pathContains: "/terms-of-use",
//     },
//     {
//       route: "/[channel]/[locale]/page/[slug]",
//       pathContains: "/privacy-policy",
//     },
//     {
//       route: "/[channel]/[locale]/page/[slug]",
//       pathContains: "/gwarancja-satysfakcji",
//     },
//   ],
//   productsInCart: false,
//   pageTime: 30000,
//   inactionTime: 10000,
// },
];