var __jsx = React.createElement;
import { styled as _styled } from "../../../libraries/stitches.library.ts";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSwiper } from "swiper/react";
import Icon from "@/components/ui/Icon/Icon.component";
import style from "./Slider.styles";

var _TwComponent = _styled("div", {});

var _TwComponent2 = _styled("div", {});

var NavigationItem = function NavigationItem(_ref) {
  var type = _ref.type,
      _ref$loop = _ref.loop,
      loop = _ref$loop === void 0 ? false : _ref$loop,
      text = _ref.text,
      children = _ref.children;
  var swiper = useSwiper();
  var t = useIntl();

  var _useState = useState({
    isBeginning: true,
    isEnd: false
  }),
      currentSlideProps = _useState[0],
      setCurrentSlideProps = _useState[1];

  useEffect(function () {
    swiper.on("slideChange", function (currenSwiper) {
      setCurrentSlideProps({
        isBeginning: currenSwiper.isBeginning,
        isEnd: currenSwiper.isEnd
      });
    });
    swiper.on("resize", function (currenSwiper) {
      setCurrentSlideProps({
        isBeginning: currenSwiper.isBeginning,
        isEnd: currenSwiper.isEnd
      });
    });
  }, [swiper]);
  var renderItem = React.useCallback(function (slidePrev, isDisabled, content) {
    return __jsx("button", {
      "aria-label": t.formatMessage({
        id: "common.previous",
        defaultMessage: "Previous"
      }),
      onClick: slidePrev,
      disabled: isDisabled
    }, content(isDisabled));
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  switch (type) {
    case "prev":
      return renderItem(function () {
        return swiper.slidePrev();
      }, loop ? false : currentSlideProps.isBeginning, function (isDisabled) {
        return __jsx(React.Fragment, null, children || __jsx(_TwComponent, {
          css: style.itemContent({
            isDisabled: loop ? false : isDisabled
          })
        }, __jsx(Icon, {
          name: "ChevronLeft",
          className: "itemIcon"
        }), __jsx("span", {
          className: "itemText"
        }, text)));
      });

    case "next":
      return renderItem(function () {
        return swiper.slideNext();
      }, loop ? false : currentSlideProps.isEnd, function (isDisabled) {
        return __jsx(React.Fragment, null, children || __jsx(_TwComponent2, {
          css: style.itemContent({
            isDisabled: loop ? false : isDisabled
          })
        }, __jsx("span", {
          className: "itemText"
        }, text), __jsx(Icon, {
          name: "ChevronRight",
          className: "itemIcon"
        })));
      });
  }
};

NavigationItem.displayName = "NavigationItem";
export default NavigationItem;