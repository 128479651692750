var __jsx = React.createElement;
import { createSaleorClient, SaleorProvider } from "@saleor/sdk";
import React from "react";
import { API_URI } from "@/config/common.config";
import { DEFAULT_CHANNEL } from "@/config/region.config";
import { useRegion } from "@/hooks/useRegion.hook";
export var client = createSaleorClient({
  apiUrl: API_URI,
  channel: DEFAULT_CHANNEL.slug
});
export function Saleor(_ref) {
  var children = _ref.children;

  var _useRegion = useRegion(),
      currentChannel = _useRegion.currentChannel;

  var setChannel = client.config.setChannel;
  React.useEffect(function () {
    setChannel(currentChannel.slug);
  }, [currentChannel, setChannel]); // @ts-expect-error React 17 <-> 18 types mismatch

  return __jsx(SaleorProvider, {
    client: client
  }, children);
}
export default Saleor;