var __jsx = React.createElement;

/* eslint-disable simple-import-sort/imports */
import { ApolloProvider as Apollo } from "@apollo/client";
import React from "react";
import { Provider as Balancer } from "react-wrap-balancer";
import { Chat } from "@/chat/chat.context";
import { Checkout } from "@/checkout/checkout.context";
import { Global } from "@/global/global.context";
import { CookieConsent } from "@/cookieConsent//context/cookieConsent.context";
import { Messenger } from "@/messenger/messenger.context";
import { Interface } from "@/interface/interface.context";
import { client } from "@/libraries/apollo.library";
import Saleor from "@/libraries/saleor.library";
import { Region } from "@/region/region.context";
import { Notifications } from "@/notification/notification.context";
import { PopupManager } from "@/popupManager/popupManager.context";
import { Search } from "@/search/search.context";
export var Context = function Context(_ref) {
  var children = _ref.children;
  return __jsx(Apollo, {
    client: client
  }, __jsx(Region, null, __jsx(Saleor, null, __jsx(CookieConsent, null, __jsx(Notifications, null, __jsx(Global, null, __jsx(Messenger, null, __jsx(Checkout, null, __jsx(Interface, null, __jsx(PopupManager, null, __jsx(Search, null, __jsx(Chat, null, __jsx(Balancer, null, children)))))))))))));
};
export default Context;