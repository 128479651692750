var __jsx = React.createElement;
import React, { Fragment } from "react";
import { Text } from "@/components/common";
import { Loader, Skeleton } from "@/components/ui";
import styles from "./Content.styles";

var Content = function Content(_ref) {
  var content = _ref.content,
      title = _ref.title,
      _ref$loadingLines = _ref.loadingLines,
      loadingLines = _ref$loadingLines === void 0 ? 10 : _ref$loadingLines;
  return __jsx(Fragment, null, __jsx("div", {
    className: styles.root()
  }, title ? __jsx("h2", {
    className: styles.title()
  }, title) : __jsx("div", {
    className: styles.loader()
  }, __jsx(Loader, null)), __jsx("div", {
    className: styles.content()
  }, content ? __jsx(Text, {
    text: content,
    spacing: "medium"
  }) : __jsx("div", {
    className: styles.skeleton()
  }, Array(loadingLines).fill(0).map(function (_, i) {
    return __jsx(Skeleton, {
      height: 10,
      key: i
    });
  })))));
};

Content.displayName = "Content";
export default Content;