import _toConsumableArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
var __jsx = React.createElement;
import { useRouter } from "next/router";
import React, { useMemo } from "react";
import { Button } from "@/components/ui";
import { PRODUCT_VARIANT_VOLUME_ATTRIBUTE } from "@/config/common.config";
import { getAttribute } from "@/helpers/attribute.helper";
import { isLowPriceZoneVariant } from "~/src/helpers/product.helpers";
import styles from "./Variants.styles";

var Variants = function Variants(_ref) {
  var activeVariant = _ref.activeVariant,
      variants = _ref.variants,
      changeVariant = _ref.changeVariant;
  var router = useRouter();
  var lowPriceZoneQueryParam = router.query["low-price-zone"];
  var filteredVariants = useMemo(function () {
    return variants === null || variants === void 0 ? void 0 : variants.filter(function (variant) {
      var lowPriceZoneVariant = isLowPriceZoneVariant(variant);
      return lowPriceZoneQueryParam === "true" ? lowPriceZoneVariant : !lowPriceZoneVariant;
    });
  }, [lowPriceZoneQueryParam, variants]);
  var variantLabels = useMemo(function () {
    if (!filteredVariants || !activeVariant) {
      return [];
    }

    return filteredVariants.reduce(function (labels, singleVariant) {
      var _getAttribute;

      var variantVolume = (_getAttribute = getAttribute(PRODUCT_VARIANT_VOLUME_ATTRIBUTE, singleVariant.attributes)) === null || _getAttribute === void 0 ? void 0 : _getAttribute.value[0];

      if (variantVolume) {
        return [].concat(_toConsumableArray(labels), [{
          variant: singleVariant,
          volume: variantVolume,
          isActive: singleVariant.id === activeVariant.id
        }]);
      }

      return labels;
    }, []);
  }, [activeVariant, filteredVariants]);

  if (variantLabels.length === 0) {
    return null;
  }

  return __jsx("div", {
    className: styles.root()
  }, variantLabels.map(function (variantLabel, key) {
    return __jsx(Button, {
      color: variantLabel.isActive ? "flavorLight" : "light",
      variant: "oval",
      className: styles.button({
        active: variantLabel.isActive
      }),
      onClick: function onClick() {
        return changeVariant(variantLabel.variant);
      },
      key: key
    }, variantLabel.volume);
  }));
};

export default Variants;