import _slicedToArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
export var useOffsetObserver = function useOffsetObserver(element) {
  var router = useRouter();

  var _useState = useState(false),
      isIntersecting = _useState[0],
      setIsIntersecting = _useState[1];

  var _useState2 = useState(0),
      targetHeight = _useState2[0],
      setTargetHeight = _useState2[1];

  useEffect(function () {
    var targetElement = document.getElementById(element);
    if (!targetElement) return;
    var observer = new IntersectionObserver(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 1),
          entry = _ref2[0];

      setIsIntersecting(entry.isIntersecting);
    }, {
      root: null,
      rootMargin: "-".concat(targetElement.clientHeight, "px 0px"),
      threshold: 0
    });
    observer.observe(targetElement);
    setTargetHeight(targetElement.clientHeight);
    return function () {
      observer.disconnect();
    };
  }, [element, router.pathname]);
  return {
    isIntersecting: isIntersecting,
    targetHeight: targetHeight
  };
};