/**
 * NOTE: Keep this in sync with the (custom) Tailwind theme `colors` config.
 * @see https://tailwindcss.com/docs/customizing-colors
 */

module.exports = {
  common: {
    black: "hsl(0, 4%, 15%)",
    white: "hsl(0, 0%, 100%)",
    gray0: "hsl(359, 4%, 98%)",
    gray1: "hsl(359, 4%, 95%)",
    gray2: "hsl(359, 4%, 85%)",
    gray3: "hsl(359, 4%, 82%)",
    gray4: "hsl(359, 4%, 80%)",
    gray5: "hsl(359, 4%, 75%)",
    gray6: "hsl(359, 4%, 45%)",
    gray7: "hsl(359, 4%, 35%)",
    gray8: "hsl(359, 4%, 25%)",
    gray9: "hsl(359, 4%, 15%)",
  },
  flavor: {
    default: {
      lighter: "hsl(0, 11%, 98%)",
      lighten: "hsl(341, 62%, 96%)",
      light: "hsl(344, 51%, 87%)",
      default: "hsl(344, 51%, 51%)",
      dark: "hsl(344, 51%, 43%)",
      success: "hsl(138, 70%, 27%)",
      successLight: "hsl(138, 70%, 38%)",
      information: "hsl(218, 100%, 64%)",
      warning: "hsl(47, 88%, 53%)",
      danger: "hsl(357, 75%, 44%)",
    },
  },
};
