import _toConsumableArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
var __jsx = React.createElement;
import Link from "next/link";
import React, { Fragment, useMemo } from "react";
import { useIntl } from "react-intl";
import { Icon } from "@/components/ui";
import usePath from "@/hooks/usePath.hook";
import { cn } from "@/libraries/clsx.library";
import styles from "./Breadcrumbs.styles";

var Breadcrumbs = function Breadcrumbs(_ref) {
  var breadcrumbs = _ref.breadcrumbs;
  var path = usePath();
  var t = useIntl();
  var breadcrumbsArray = useMemo(function () {
    return [{
      path: path.$url(),
      name: __jsx(Fragment, null, __jsx(Icon, {
        name: "Home",
        className: styles.icon()
      }), t.formatMessage({
        id: "breadcrumbs.homePage",
        defaultMessage: "Home page"
      }))
    }].concat(_toConsumableArray(breadcrumbs));
  }, [breadcrumbs, path, t]);
  return __jsx("div", {
    className: styles.wrapper()
  }, __jsx("div", {
    className: styles.root()
  }, breadcrumbsArray.map(function (breadcrumb, index) {
    return __jsx(Fragment, {
      key: "breadcrumb-item-".concat(index)
    }, breadcrumb !== null && breadcrumb !== void 0 && breadcrumb.path ? __jsx(Link, {
      href: breadcrumb.path,
      passHref: true,
      className: cn(styles.breadcrumb(), styles.breadcrumbLink())
    }, breadcrumb.name) : __jsx("span", {
      className: styles.breadcrumb()
    }, breadcrumb.name), index < breadcrumbsArray.length - 1 ? __jsx(Icon, {
      name: "ChevronRight",
      className: styles.separatorIcon()
    }) : null);
  })));
};

export default Breadcrumbs;