import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread({}, {
    "marginTop": "1.5rem",
    "> :not([hidden]) ~ :not([hidden])": {
      "--tw-divide-y-reverse": "0",
      "borderTopWidth": "calc(1px * calc(1 - var(--tw-divide-y-reverse)))",
      "borderBottomWidth": "calc(1px * var(--tw-divide-y-reverse))"
    },
    "borderRadius": "1rem",
    "borderWidth": "1px"
  })),
  promoContainer: _css(_objectSpread(_objectSpread({}, {
    "padding": "1rem"
  }), {}, {
    color: "$default"
  })),
  promoText: _css({
    variants: {
      pointer: {
        "true": {
          "cursor": "pointer"
        }
      }
    }
  }),
  promoTimerContainer: _css({
    maxWidth: "150px",
    "& > div": _objectSpread(_objectSpread({}, {
      "marginTop": "0.5rem",
      "gap": "0.5rem"
    }), {}, {
      "& > div": _objectSpread(_objectSpread({}, {
        "borderRadius": "0.375rem",
        "padding": "0.375rem"
      }), {}, {
        backgroundColor: "$lighten",
        minWidth: "40px",
        "& > div": {
          "&::before": {
            backgroundColor: "$default"
          }
        },
        "& > p": _objectSpread({}, {
          "fontWeight": "400 !important"
        })
      })
    })
  }),
  price: _css(_objectSpread({}, {
    "padding": "1rem"
  })),
  history: _css(_objectSpread(_objectSpread({}, {
    "marginTop": "0.5rem",
    "fontSize": "0.75rem",
    "lineHeight": "1rem"
  }), {}, {
    color: "$gray6",
    lineHeight: "$2",
    "@xs": _objectSpread({}, {
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem"
    })
  })),
  addToCart: _css(_objectSpread({}, {
    "padding": "1rem"
  })),
  purchasedInLatDays: _css(_objectSpread(_objectSpread({}, {
    "marginBottom": "0.75rem",
    "display": "inline-block",
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem"
  }), {}, {
    color: "$gray6"
  })),
  availabilityContainer: _css(_objectSpread({}, {
    "padding": "1rem"
  })),
  availabilityInfo: _css(_objectSpread({}, {
    "display": "flex",
    "alignItems": "center",
    "gap": "0.5rem"
  })),
  availabilityIcon: _css(_objectSpread(_objectSpread({}, {
    "position": "relative",
    "height": "18px",
    "width": "18px",
    "flex": "none"
  }), {}, {
    path: {
      strokeWidth: 2,
      stroke: "black"
    },
    variants: {
      variant: {
        success: _objectSpread(_objectSpread({}, {
          "height": "1rem",
          "width": "1rem"
        }), {}, {
          path: {
            stroke: "$success"
          }
        }),
        warning: {
          path: {
            stroke: "$warning"
          }
        },
        danger: {
          path: {
            stroke: "$danger"
          }
        }
      }
    }
  })),
  availabilityText: _css({
    color: "$black",
    variants: {
      variant: {
        success: {
          color: "$success"
        },
        warning: {
          color: "$warning"
        },
        danger: {
          color: "$danger"
        }
      }
    }
  }),
  availabilityProgressBarContainer: _css(_objectSpread(_objectSpread({}, {
    "position": "relative",
    "marginTop": "0.5rem",
    "height": "0.25rem",
    "width": "100%",
    "overflow": "hidden",
    "borderRadius": "0.125rem"
  }), {}, {
    backgroundColor: "$gray3"
  })),
  availabilityProgressBar: _css(_objectSpread(_objectSpread({}, {
    "position": "absolute",
    "inset": "0px",
    "height": "100%"
  }), {}, {
    variants: {
      variant: {
        success: {
          backgroundColor: "$success"
        },
        warning: {
          backgroundColor: "$warning"
        },
        danger: {
          backgroundColor: "$danger"
        }
      }
    }
  })),
  detailsList: _css(_objectSpread({}, {
    "padding": "1rem"
  })),
  listItem: _css(_objectSpread(_objectSpread({}, {
    "display": "flex",
    "gap": "0.5rem"
  }), {}, {
    color: "$gray9",
    "&:not(:last-child)": _objectSpread({}, {
      "marginBottom": "0.75rem"
    })
  })),
  listItemIcon: _css(_objectSpread(_objectSpread({}, {
    "position": "relative",
    "top": "0.25rem",
    "height": "1rem",
    "width": "1rem",
    "flex": "none"
  }), {}, {
    circle: {
      strokeWidth: "1.2",
      stroke: "$gray6"
    },
    path: {
      strokeWidth: "1.2",
      stroke: "$gray6"
    }
  })),
  mobileIcon: _css({
    path: {
      stroke: "$gray8"
    }
  }),
  listItemText: _css({
    color: "$gray6"
  }),
  paypoProviderContainer: _css(_objectSpread(_objectSpread({}, {
    "padding": "1rem"
  }), {}, {
    "@xs": _objectSpread({}, {
      "gap": "1.5rem"
    })
  })),
  paypoProvider: _css(_objectSpread(_objectSpread({}, {
    "marginLeft": "auto",
    "marginRight": "auto",
    "display": "flex",
    "cursor": "pointer",
    "alignItems": "center",
    "justifyContent": "center",
    "gap": "0.75rem"
  }), {}, {
    "@xs": _objectSpread({}, {
      "gap": "1.5rem"
    })
  })),
  paypoProviderText: _css(_objectSpread(_objectSpread({}, {
    "position": "relative",
    "paddingLeft": "0.75rem"
  }), {}, {
    fontWeight: "$bold",
    lineHeight: "$2",
    whiteSpace: "pre-wrap",
    "@xs": _objectSpread({}, {
      "paddingLeft": "1rem"
    }),
    "&::before": _objectSpread(_objectSpread({}, {
      "position": "absolute",
      "inset": "0px",
      "display": "block",
      "height": "100%",
      "width": "0.125rem",
      "--tw-content": "\"\"",
      "content": "var(--tw-content)"
    }), {}, {
      backgroundColor: "#36b588"
    })
  })),
  paypoProviderTextHighlighted: _css({
    color: "#36b588"
  }),
  otherProvidersContainer: _css(_objectSpread(_objectSpread({}, {
    "display": "flex",
    "flexWrap": "wrap",
    "alignItems": "center",
    "justifyContent": "center",
    "gap": "3rem",
    "padding": "1rem"
  }), {}, {
    "@xs": _objectSpread({}, {
      "gap": "4rem"
    })
  })),
  overlayContentContainer: _css(_objectSpread({}, {
    "marginLeft": "auto",
    "marginRight": "auto",
    "height": "100%",
    "width": "100%"
  }))
};
export default styles;