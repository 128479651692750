import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _toConsumableArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _slicedToArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
// import { onMessage } from "firebase/messaging";
import React from "react";
import { NOTIFICATION_SHOW_TIME } from "@/config/common.config"; // import { useFirebase } from "@/hooks/useFirebase.hook";
// import { messaging } from "@/libraries/firebase.library";

import createContext from "@/libraries/react.library";

var _createContext = createContext(),
    _createContext2 = _slicedToArray(_createContext, 2),
    useContext = _createContext2[0],
    Provider = _createContext2[1];

export { useContext, Provider };
export var Notifications = function Notifications(props) {
  var notificationTimer = React.useRef(0);
  var notificationTimers = React.useRef([]);

  var _React$useState = React.useState([]),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      notifications = _React$useState2[0],
      setNotifications = _React$useState2[1];
  /**
   * removeNotification
   * @param id notification id
   */


  var removeNotification = React.useCallback(function (id) {
    setNotifications(function (currentNotifications) {
      return currentNotifications.filter(function (n) {
        return n.id !== id;
      });
    });
  }, []);
  /**
   * filter timers by notification id
   * @param notification Notification
   */

  var timerCallback = React.useCallback(function (notification) {
    removeNotification(notification.id);
    notificationTimers.current = notificationTimers.current.filter(function (timer) {
      return timer.id !== notification.id;
    });
  }, [removeNotification]);
  /**
   * get timer by notification id
   * @param notification Notification
   */

  var getCurrentNotificationTimer = React.useCallback(function (notification) {
    var currentTimerIndex = notificationTimers.current.findIndex(function (timer) {
      return timer.id === notification.id;
    });
    return notificationTimers.current[currentTimerIndex];
  }, []);
  /**
   * push new notification
   * @param status status of notification
   * @param message Message for notification
   * @param position Position of notification
   * @param style Style of notification
   * @param timeout timer to hide notification
   */

  var pushNotification = React.useCallback(function (_ref) {
    var message = _ref.message,
        position = _ref.position,
        style = _ref.style,
        status = _ref.status;
    var timeout = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : NOTIFICATION_SHOW_TIME;
    var id = notificationTimer.current;
    notificationTimer.current += 1;
    var notification = {
      onClose: function onClose() {
        return removeNotification(id);
      },
      id: id,
      status: status !== null && status !== void 0 ? status : "information",
      message: message !== null && message !== void 0 ? message : "",
      position: position !== null && position !== void 0 ? position : {
        justify: "end",
        placement: "top",
        witOffset: true,
        zMax: false
      },
      style: style !== null && style !== void 0 ? style : {
        size: "fit"
      },
      timeout: timeout
    };

    if (timeout !== null) {
      var timeoutId = window.setTimeout(function () {
        timerCallback(notification);
      }, timeout);
      notificationTimers.current.push({
        id: notification.id,
        notification: notification,
        remaining: timeout,
        start: new Date().getTime(),
        timeoutId: timeoutId
      });
    }

    setNotifications(function (s) {
      return [notification].concat(_toConsumableArray(s));
    });
    return notification;
  }, [removeNotification, timerCallback]);
  /**
   * pause timer for given notification
   * @param notification Notification
   */

  var pauseNotificationTimer = React.useCallback(function (notification) {
    var currentTimer = getCurrentNotificationTimer(notification);

    if (currentTimer) {
      currentTimer.remaining = currentTimer.remaining - (new Date().getTime() - currentTimer.start);
      window.clearTimeout(currentTimer.timeoutId);
    }
  }, [getCurrentNotificationTimer]);
  /**
   * resume timer for given notification
   * @param notification Notification
   */

  var resumeNotificationTimer = React.useCallback(function (notification) {
    var currentTimer = getCurrentNotificationTimer(notification);

    if (currentTimer) {
      currentTimer.start = new Date().getTime();
      currentTimer.timeoutId = window.setTimeout(function () {
        return timerCallback(notification);
      }, currentTimer.remaining);
    }
  }, [getCurrentNotificationTimer, timerCallback]);
  /**
   * firebase cloud messaging -> client side notification
   */
  // const { token } = useFirebase();
  // React.useEffect(() => {
  //   if (!messaging || !token) return;
  //   onMessage(messaging, payload => {
  //     pushNotification(
  //       {
  //         message: payload?.notification?.body,
  //         status: "success",
  //         position: {
  //           placement: "bottom",
  //           justify: "end",
  //           witOffset: true,
  //         },
  //         style: {
  //           size: "half",
  //         },
  //       },
  //       10000,
  //     );
  //   });
  // }, [pushNotification, token]);

  var value = React.useMemo(function () {
    return {
      notifications: notifications,
      getCurrentNotificationTimer: getCurrentNotificationTimer,
      pauseNotificationTimer: pauseNotificationTimer,
      pushNotification: pushNotification,
      removeNotification: removeNotification,
      resumeNotificationTimer: resumeNotificationTimer
    };
  }, [notifications, pauseNotificationTimer, pushNotification, removeNotification, resumeNotificationTimer, getCurrentNotificationTimer]);
  return __jsx(Provider, _extends({
    value: value
  }, props));
};