export function quantityPerSessionFilter(popup, showedPopups) {
  var _showedPopups$find;

  var quantityPerSessionConfig = popup.quantityPerSession;
  var showedPopupQuantity = (showedPopups === null || showedPopups === void 0 || (_showedPopups$find = showedPopups.find(function (showedPopup) {
    return showedPopup.name === popup.name;
  })) === null || _showedPopups$find === void 0 ? void 0 : _showedPopups$find.quantity) || 0;

  if (quantityPerSessionConfig) {
    return quantityPerSessionConfig > showedPopupQuantity;
  }

  return true;
}
export function excludedUrlsFilter(popup, router) {
  var excludedUrlsConfig = popup === null || popup === void 0 ? void 0 : popup.excludedUrls;

  if (excludedUrlsConfig) {
    return !excludedUrlsConfig.some(function (excludedUrl) {
      if (excludedUrl !== null && excludedUrl !== void 0 && excludedUrl.route) {
        if (excludedUrl !== null && excludedUrl !== void 0 && excludedUrl.pathContains) {
          var _router$asPath;

          return router.route === excludedUrl.route && ((_router$asPath = router.asPath) === null || _router$asPath === void 0 ? void 0 : _router$asPath.includes(excludedUrl === null || excludedUrl === void 0 ? void 0 : excludedUrl.pathContains));
        }

        return router.route === excludedUrl.route;
      }

      return true;
    });
  }

  return true;
}
export function previousUrlsFilter(popup, previousRouterInfo) {
  var previousUrlConfig = popup === null || popup === void 0 ? void 0 : popup.prevoiusUrl;

  if (previousUrlConfig) {
    if (!previousRouterInfo) {
      return false;
    }

    if (previousUrlConfig.route) {
      if (previousUrlConfig.pathContains) {
        return previousRouterInfo.route === previousUrlConfig.route && previousRouterInfo.pathname.includes(previousUrlConfig.pathContains);
      }

      return previousRouterInfo.route === previousUrlConfig.route;
    }
  }

  return true;
}
export function timeFilter(popup, pageTimer, inactionTimer) {
  var pageTimeConfig = popup === null || popup === void 0 ? void 0 : popup.pageTime;
  var inactionTimeConfig = popup === null || popup === void 0 ? void 0 : popup.inactionTime;

  if (pageTimeConfig && inactionTimeConfig) {
    return pageTimer >= pageTimeConfig || inactionTimer >= inactionTimeConfig;
  }

  if (pageTimeConfig) {
    return pageTimer >= pageTimeConfig;
  }

  if (inactionTimeConfig) {
    return inactionTimer >= inactionTimeConfig;
  }

  return true;
}
export function onAddToCartFilter(popup, checkoutInfo) {
  var onAddToCartConfig = popup === null || popup === void 0 ? void 0 : popup.onAddToCart;

  if (onAddToCartConfig !== undefined) {
    return onAddToCartConfig && checkoutInfo.productAdded || !onAddToCartConfig && !checkoutInfo.productAdded;
  }

  return true;
}
export function onRemoveFromCartFilter(popup, checkoutInfo) {
  var onRemoveFromCartConfig = popup === null || popup === void 0 ? void 0 : popup.onRemoveFromCart;

  if (onRemoveFromCartConfig !== undefined) {
    return onRemoveFromCartConfig && checkoutInfo.productRemoved || !onRemoveFromCartConfig && !checkoutInfo.productRemoved;
  }

  return true;
}
export function productsInCartFilter(popup, checkoutInfo) {
  var productsInCartConfig = popup === null || popup === void 0 ? void 0 : popup.productsInCart;

  if (productsInCartConfig !== undefined) {
    return productsInCartConfig && checkoutInfo.numberOfProducts > 0 || !productsInCartConfig && checkoutInfo.numberOfProducts === 0;
  }

  return true;
}