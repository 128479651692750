import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread(_objectSpread({}, {
    "display": "flex",
    "flexWrap": "wrap",
    "alignItems": "center",
    "gap": "0.75rem"
  }), {}, {
    variants: {
      size: {
        xs: _objectSpread({}, {
          "gap": "0.375rem"
        }),
        sm: _objectSpread({}, {
          "gap": "0.375rem"
        }),
        md: _objectSpread({}, {
          "gap": "0.5rem"
        }),
        lg: _objectSpread({}, {
          "gap": "0.75rem"
        }),
        xl: _objectSpread({}, {
          "gap": "0.75rem"
        })
      }
    }
  })),
  priceUndiscounted: _css({
    fontFamily: "$tenor",
    color: "$gray6",
    textDecoration: "line-through",
    lineHeight: "$1",
    variants: {
      size: {
        xs: {
          fontSize: "$2",
          "@md": {
            fontSize: "$2"
          }
        },
        sm: {
          fontSize: "$2",
          "@md": {
            fontSize: "$3"
          }
        },
        md: {
          fontSize: "$3",
          "@md": {
            fontSize: "$4"
          }
        },
        lg: {
          fontSize: "$4",
          "@md": {
            fontSize: "$5"
          }
        },
        xl: {
          fontSize: "$4",
          "@md": {
            fontSize: "$5"
          }
        }
      },
      fontFamily: {
        inter: {
          fontFamily: "$inter"
        },
        tenor: {
          fontFamily: "$tenor"
        }
      }
    }
  }),
  price: _css({
    fontFamily: "$tenor",
    lineHeight: "$1",
    variants: {
      size: {
        xs: {
          fontSize: "$2"
        },
        sm: {
          fontSize: "$3"
        },
        md: {
          fontSize: "$4"
        },
        lg: {
          fontSize: "$5"
        },
        xl: {
          fontSize: "$6"
        }
      },
      fontFamily: {
        inter: {
          fontFamily: "$inter"
        },
        tenor: {
          fontFamily: "$tenor"
        }
      },
      bold: {
        "true": {
          fontWeight: "$bold"
        }
      },
      discounted: {
        "true": {
          color: "$default"
        }
      },
      discountedColor: {
        "default": {},
        black: {}
      }
    },
    compoundVariants: [{
      discounted: true,
      discountedColor: "default",
      css: {
        color: "$default"
      }
    }, {
      discounted: true,
      discountedColor: "black",
      css: {
        color: "$black"
      }
    }]
  })
};
export default styles;