var __jsx = React.createElement;
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import React, { Fragment } from "react";
import { useIntl } from "react-intl";
import { Container, Icon } from "@/components/ui";
import { NavbarItem } from "@/components/ui/Navbar/components";
import usePath from "@/hooks/usePath.hook";
import { cn } from "@/libraries/clsx.library";
import styles from "./Navbar.styles";

var Navbar = function Navbar(_ref) {
  var navbarLeft = _ref.navbarLeft,
      navbarRight = _ref.navbarRight,
      navbarBottom = _ref.navbarBottom,
      withBorder = _ref.withBorder,
      removeLinks = _ref.removeLinks;
  var path = usePath();
  var t = useIntl();
  return __jsx(Fragment, null, __jsx("div", {
    className: styles.root({
      withBorder: withBorder
    }),
    id: "__nav"
  }, __jsx(Container, {
    color: "white"
  }, __jsx("nav", {
    className: cn(styles.nav(), styles.simple())
  }, __jsx("div", {
    className: styles.navLeft()
  }, navbarLeft ? navbarLeft : removeLinks ? null : __jsx(NavbarItem, {
    linkHref: path.$url()
  }, __jsx(ChevronLeftIcon, {
    className: styles.icon()
  }), t.formatMessage({
    defaultMessage: "Return",
    id: "common.return"
  }))), removeLinks ? __jsx(Icon, {
    name: "LovaHorizontal"
  }) : __jsx(Link, {
    href: path.$url(),
    passHref: true
  }, __jsx(Icon, {
    name: "LovaHorizontal"
  })), __jsx("div", {
    className: styles.navRight()
  }, navbarRight))), navbarBottom));
};

Navbar.displayName = "Navbar";
export default Navbar;