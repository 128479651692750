var __jsx = React.createElement;
import React from "react";
import { Text } from "@/components/common";
import { cn } from "@/libraries/clsx.library";
import style from "./Section.styles";

var Section = function Section(_ref) {
  var id = _ref.id,
      title = _ref.title,
      description = _ref.description,
      children = _ref.children,
      className = _ref.className,
      descriptionClassName = _ref.descriptionClassName;
  return __jsx("section", {
    className: cn(style.root(), className),
    id: id
  }, (title || description) && __jsx("div", {
    className: style.header()
  }, title && __jsx("h2", {
    className: style.title()
  }, title), description && __jsx("div", {
    className: cn(style.description(), descriptionClassName)
  }, __jsx(Text, {
    text: description
  }))), children);
};

Section.displayName = "Section";
export default Section;