import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread({}, {
    "@media (min-width: 768px)": {
      "paddingTop": "0.75rem"
    }
  })),
  name: _css(_objectSpread(_objectSpread({}, {
    "marginBottom": "0.5rem",
    "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke",
    "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "transitionDuration": "300ms",
    "animationDuration": "300ms"
  }), {}, {
    fontFamily: "$tenor",
    fontSize: "$6",
    variants: {
      link: {
        "true": {
          "&:hover": {
            color: "$default"
          }
        }
      }
    }
  })),
  volume: _css(_objectSpread(_objectSpread({}, {
    "marginTop": "1rem",
    "display": "flex",
    "alignItems": "center",
    "gap": "0.5rem"
  }), {}, {
    color: "$gray7"
  })),
  volumeIcon: _css({
    path: {
      stroke: "$gray6"
    }
  }),
  highligtedDescription: _css(_objectSpread(_objectSpread({}, {
    "marginTop": "1rem"
  }), {}, {
    color: "$default"
  })),
  slogan: _css(_objectSpread({}, {
    "marginTop": "1rem"
  }))
};
export default styles;