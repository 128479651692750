import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { CHANNELS, DEFAULT_CHANNEL, DEFAULT_COUNTRY_CODE, DEFAULT_LOCALE, LOCALE, LOCALES } from "@/config/region.config";
import * as en from "@/locale/en-US.json";
import * as pl from "@/locale/pl-PL.json";
export function importMessages(locale) {
  switch (locale) {
    case "en-US":
      return en;

    case "pl-PL":
      return pl;

    default:
      return en;
  }
}
export var regionCombinations = function regionCombinations() {
  var combinations = [];
  CHANNELS.forEach(function (channel) {
    LOCALES.forEach(function (locale) {
      combinations.push({
        channelSlug: channel.slug,
        localeSlug: locale.slug
      });
    });
  });
  return combinations;
};
export var formatAsMoney = function formatAsMoney() {
  var amount = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var currency = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_CHANNEL.currencyCode;
  var locale = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DEFAULT_LOCALE;
  var options = arguments.length > 3 ? arguments[3] : undefined;
  return new Intl.NumberFormat(locale, _objectSpread({
    style: "currency",
    unitDisplay: "narrow",
    currency: currency
  }, options)).format(amount);
};
export var localeToEnum = function localeToEnum(localeSlug) {
  var _LOCALES$find, _LOCALES$find2;

  var chosenLocale = (_LOCALES$find = LOCALES.find(function (_ref) {
    var slug = _ref.slug;
    return slug === localeSlug;
  })) === null || _LOCALES$find === void 0 ? void 0 : _LOCALES$find.code;

  if (chosenLocale) {
    return chosenLocale;
  }

  return ((_LOCALES$find2 = LOCALES.find(function (_ref2) {
    var slug = _ref2.slug;
    return slug === DEFAULT_LOCALE;
  })) === null || _LOCALES$find2 === void 0 ? void 0 : _LOCALES$find2.code) || "EN_US";
};
export var contextToRegionQuery = function contextToRegionQuery(context) {
  var _context$params, _context$params2;

  return {
    channel: ((_context$params = context.params) === null || _context$params === void 0 || (_context$params = _context$params.channel) === null || _context$params === void 0 ? void 0 : _context$params.toString()) || DEFAULT_CHANNEL.slug,
    locale: localeToEnum(((_context$params2 = context.params) === null || _context$params2 === void 0 || (_context$params2 = _context$params2.locale) === null || _context$params2 === void 0 ? void 0 : _context$params2.toString()) || DEFAULT_LOCALE)
  };
}; // Converts locale from EN_US to en-US

export var languageCodeToLocale = function languageCodeToLocale(locale) {
  var splitted = locale.split("_");
  splitted[0] = splitted[0].toLowerCase();
  return splitted.join("-");
};
export var countryCodeToLocale = function countryCodeToLocale(code) {
  if (!code) return DEFAULT_LOCALE;

  switch (code) {
    case "US":
      return LOCALE.enUS;

    case "PL":
      return LOCALE.plPL;

    default:
      return DEFAULT_LOCALE;
  }
};
export var localeToCountryCode = function localeToCountryCode(languageCode) {
  switch (languageCode) {
    case "PL":
      return "PL";

    case "EN_US":
      return "US";

    default:
      return DEFAULT_COUNTRY_CODE;
  }
};