import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _toConsumableArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { MEDIA_BUCKET_URI } from "@/config/common.config";
import { translate } from "@/helpers/translation.helpers";
import { parseJsonString } from "../components/common/Text/Text.helpers";

function parseAttribute(attributeObject, withTranslate) {
  var name = withTranslate ? translate(attributeObject.attribute, "name") : attributeObject.attribute.name;
  var valueType = attributeObject.attribute.inputType;
  var slug = attributeObject.attribute.slug;

  switch (valueType) {
    case "MULTISELECT":
    case "DROPDOWN":
    case "PLAIN_TEXT":
      {
        return {
          name: name,
          slug: slug,
          value: attributeObject.values.map(function (attributeValue) {
            return withTranslate ? translate(attributeValue, "name") : attributeValue.name;
          }),
          valueType: valueType
        };
      }

    case "FILE":
      {
        return {
          name: name,
          slug: slug,
          value: attributeObject.values.map(function (attributeValue) {
            return "".concat(MEDIA_BUCKET_URI, "/file_upload/").concat(attributeValue.name);
          }),
          valueType: valueType
        };
      }

    case "RICH_TEXT":
      {
        return {
          name: name,
          slug: slug,
          value: attributeObject.values.map(function (attributeValue) {
            return withTranslate ? parseJsonString(translate(attributeValue, "richText") || undefined) ? translate(attributeValue, "richText") : attributeValue.richText : attributeValue.richText;
          }),
          valueType: valueType
        };
      }

    case "REFERENCE":
      {
        return {
          name: name,
          slug: slug,
          value: attributeObject.values.flatMap(function (value) {
            return value.reference;
          }),
          valueType: valueType
        };
      }

    case "NUMERIC":
      {
        return {
          name: name,
          slug: slug,
          value: attributeObject.values.map(function (attributeValue) {
            return attributeValue.name;
          }),
          valueType: valueType
        };
      }

    case "DATE":
      {
        return {
          name: name,
          slug: slug,
          value: attributeObject.values.map(function (attributeValue) {
            return attributeValue.date;
          }),
          valueType: valueType
        };
      }

    case "DATE_TIME":
      {
        return {
          name: name,
          slug: slug,
          value: attributeObject.values.map(function (attributeValue) {
            return attributeValue.dateTime;
          }),
          valueType: valueType
        };
      }

    case "BOOLEAN":
      {
        return {
          name: name,
          slug: slug,
          value: attributeObject.values.map(function (attributeValue) {
            return attributeValue["boolean"];
          }),
          valueType: valueType
        };
      }

    default:
      return null;
  }
}

export function getAttribute(slug, attributes) {
  var withTranslate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var attributeObject = attributes === null || attributes === void 0 ? void 0 : attributes.find(function (_ref) {
    var attribute = _ref.attribute;
    return attribute.slug === slug;
  });

  if (attributeObject) {
    return parseAttribute(attributeObject, withTranslate);
  }

  return null;
}
export function getAttributeGroup(metadataValue, attributes) {
  var withTranslate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  return attributes.reduce(function (acc, attributeObject) {
    var belongsToGroup = attributeObject.attribute.metadata.findIndex(function (metadataItem) {
      return metadataItem.value === metadataValue;
    }) >= 0;

    if (belongsToGroup) {
      var newAttribute = parseAttribute(attributeObject, withTranslate);

      if (newAttribute) {
        return [].concat(_toConsumableArray(acc), [newAttribute]);
      }
    }

    return acc;
  }, []);
}
export function getAttributesOfType(attributes, type) {
  if (!attributes || attributes.length === 0) return [];
  return attributes.filter(function (_ref2) {
    var attribute = _ref2.attribute;
    return attribute.inputType === type;
  });
}
export function getAttributesBySlug(attributes, slug) {
  if (!attributes || attributes.length === 0) return [];
  return attributes.reduce(function (filteredAttributes, selectedAttribute) {
    if (selectedAttribute.attribute.slug === slug) {
      return [].concat(_toConsumableArray(filteredAttributes), [selectedAttribute]);
    }

    return filteredAttributes;
  }, []);
}
export function getPageAttributes(pages, pageTypeSlug, pageAttributes, attributesShape) {
  var filteredPages = pages.filter(function (page) {
    return page.pageType.slug === pageTypeSlug;
  });
  return filteredPages.map(function (page) {
    var attributes = Object.keys(pageAttributes).map(function (attribute) {
      var _getAttribute;

      return _defineProperty({}, attribute, (_getAttribute = getAttribute(pageAttributes[attribute], page.attributes)) === null || _getAttribute === void 0 ? void 0 : _getAttribute.value[0]);
    }).reduce(function (o, attribute) {
      return _objectSpread(_objectSpread({}, o), attribute);
    }, {});
    return attributesShape(attributes, translate(page, "content") || "", page.variant);
  });
}