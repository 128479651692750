import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../../libraries/stitches.library.ts";
var styles = {
  dialog: _css(_objectSpread({}, {
    "position": "relative",
    "zIndex": "40",
    "@media (min-width: 1024px)": {
      "display": "none"
    }
  })),
  dialogPanel: _css(_objectSpread(_objectSpread({}, {
    "scrollbarWidth": "none",
    "MsOverflowStyle": "none",
    "&::-webkit-scrollbar": {
      "display": "none"
    },
    "position": "relative",
    "display": "flex",
    "width": "100%",
    "flexDirection": "column",
    "overflowY": "auto"
  }), {}, {
    backgroundColor: "$white"
  })),
  sidebar: _css(_objectSpread({}, {
    "position": "fixed",
    "inset": "0px",
    "zIndex": "40",
    "display": "flex"
  })),
  sidebarContent: _css(_objectSpread(_objectSpread({}, {
    "display": "flex",
    "height": "100%",
    "width": "100%",
    "flexDirection": "column",
    "justifyContent": "space-between",
    "overflowX": "hidden",
    "padding": "1rem"
  }), {}, {
    "@sm": _objectSpread(_objectSpread({}, {
      "marginLeft": "auto",
      "marginRight": "auto",
      "paddingLeft": "1.5rem",
      "paddingRight": "1.5rem"
    }), {}, {
      maxWidth: "$sm"
    }),
    "@md": {
      maxWidth: "$md"
    }
  })),
  navList: _css(_objectSpread({}, {
    "> :not([hidden]) ~ :not([hidden])": {
      "--tw-space-y-reverse": "0",
      "marginTop": "calc(1rem * calc(1 - var(--tw-space-y-reverse)))",
      "marginBottom": "calc(1rem * var(--tw-space-y-reverse))"
    },
    "paddingTop": "1rem",
    "paddingBottom": "1rem",
    "&:focus": {
      "outline": "2px solid transparent",
      "outlineOffset": "2px"
    }
  })),
  navListItem: _css({
    variants: {
      hidden: {
        "true": _objectSpread({}, {
          "display": "none"
        })
      }
    }
  }),
  navListItemElement: _css(_objectSpread(_objectSpread({}, {
    "display": "flex",
    "cursor": "pointer",
    "alignItems": "center",
    "justifyContent": "space-between",
    "&:focus": {
      "outline": "2px solid transparent",
      "outlineOffset": "2px"
    }
  }), {}, {
    lineHeight: "3rem",
    variants: {
      disabled: {
        "true": _objectSpread({
          color: "$gray5"
        }, {
          "pointerEvents": "none",
          "cursor": "default"
        })
      },
      highlighted: {
        "true": {
          color: "$default"
        }
      }
    }
  })),
  navListItemButton: _css({
    span: {
      lineHeight: "3rem"
    }
  }),
  navListIcon: _css(_objectSpread({}, {
    "position": "relative",
    "height": "1.5rem",
    "width": "1.5rem"
  }))
};
export default styles;