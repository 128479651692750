var keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

var triplet = function triplet(e1, e2, e3) {
  return keyStr.charAt(e1 >> 2) + keyStr.charAt((e1 & 3) << 4 | e2 >> 4) + keyStr.charAt((e2 & 15) << 2 | e3 >> 6) + keyStr.charAt(e3 & 63);
};

export var rgbDataURL = function rgbDataURL(color) {
  return "data:image/gif;base64,R0lGODlhAQABAPAA".concat(triplet(0, color.r, color.g) + triplet(color.b, 255, 255), "/yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==");
};
export var makeBaseURL = function makeBaseURL(src, width, quality) {
  var baseURL = "/_next/image";
  var urlParams = new URLSearchParams({
    url: src,
    w: width.toString(),
    q: quality.toString()
  });
  return "".concat(baseURL, "?").concat(urlParams.toString());
};
export var makeSrcSet = function makeSrcSet(payload) {
  return payload.reduce(function (acc, _ref) {
    var src = _ref.src,
        width = _ref.width,
        quality = _ref.quality;
    return "".concat(acc, " ").concat(makeBaseURL(src, width, quality), " ").concat(width, "w,");
  }, "");
};