import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";
import { createFetch } from "@saleor/sdk";
import { API_URI } from "@/config/common.config";
import { isServer } from "@/helpers/common.helpers";
export var typePolicies = {
  User: {
    fields: {
      orders: relayStylePagination()
    }
  },
  Query: {
    fields: {
      products: relayStylePagination(["filter", "sortBy"])
    }
  },
  Checkout: {
    fields: {
      lines: {
        merge: function merge(existing, incoming) {
          return incoming;
        }
      }
    }
  }
};
var httpLink = createHttpLink({
  uri: API_URI,
  fetch: createFetch()
});
export var client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache({
    typePolicies: typePolicies
  }),
  ssrMode: isServer
});
export default client;