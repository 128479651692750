import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread(_objectSpread({}, {
    "scrollbarWidth": "none",
    "MsOverflowStyle": "none",
    "&::-webkit-scrollbar": {
      "display": "none"
    },
    "display": "flex",
    "width": "100%",
    "cursor": "grab",
    "flexWrap": "nowrap",
    "alignItems": "center",
    "gap": "1rem",
    "overflowX": "scroll",
    "padding": "1rem"
  }), {}, {
    borderColor: "$gray2",
    scrollBehavior: "smooth",
    scrollSnapType: "x mandatory",
    "-webkit-overflow-scrolling": "touch"
  })),
  button: _css(_objectSpread(_objectSpread({}, {
    "whiteSpace": "nowrap",
    "borderWidth": "1px",
    "paddingTop": "0.5rem !important",
    "paddingBottom": "0.5rem !important"
  }), {}, {
    borderColor: "$gray2",
    variants: {
      active: {
        "true": _objectSpread(_objectSpread({}, {
          "pointerEvents": "none"
        }), {}, {
          borderColor: "$default",
          "&:hover": {
            backgroundColor: "$transparent"
          },
          span: {
            color: "$default"
          }
        })
      }
    },
    span: {
      color: "$gray6"
    }
  }))
};
export default styles;