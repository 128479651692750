import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread({}, {
    "position": "relative",
    "width": "100%"
  })),
  info: _css(_objectSpread({}, {
    "display": "flex",
    "alignItems": "flex-end",
    "justifyContent": "space-between"
  })),
  label: _css(_objectSpread(_objectSpread({}, {
    "display": "block",
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem",
    "fontWeight": "500"
  }), {}, {
    color: "$gray7"
  })),
  text: _css(_objectSpread(_objectSpread({}, {
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem"
  }), {}, {
    color: "$danger",
    variants: {
      warning: {
        "true": {
          color: "$information"
        }
      }
    }
  })),
  triggerContainer: _css(_objectSpread({}, {
    "position": "relative",
    "marginTop": "0.25rem",
    "width": "100%",
    "cursor": "default",
    "borderRadius": "0.5rem",
    "--tw-bg-opacity": "1",
    "backgroundColor": "hsl(0 0% 100% / var(--tw-bg-opacity))",
    "textAlign": "left"
  })),
  triggerInput: _css(_objectSpread(_objectSpread({}, {
    "display": "block",
    "width": "100%",
    "borderRadius": "0.5rem",
    "borderWidth": "1px",
    "paddingLeft": "1.5rem",
    "paddingRight": "1.5rem",
    "paddingTop": "0.75rem",
    "paddingBottom": "0.75rem",
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem"
  }), {}, {
    borderColor: "$gray2",
    color: "$gray8",
    "&:focus": _objectSpread({}, {
      "--tw-border-opacity": "1",
      "borderColor": "hsl(344 51% 43% / var(--tw-border-opacity))",
      "outline": "2px solid transparent",
      "outlineOffset": "2px",
      "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
      "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
      "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
      "--tw-ring-opacity": "1",
      "--tw-ring-color": "hsl(344 51% 43% / var(--tw-ring-opacity))"
    }),
    "&::placeholder": {
      color: "$gray5"
    },
    "&:disabled": _objectSpread(_objectSpread({}, {
      "pointerEvents": "none"
    }), {}, {
      backgroundColor: "$gray1",
      borderColor: "$gray2",
      color: "$gray5",
      "&:focus": _objectSpread({}, {
        "--tw-border-opacity": "1",
        "borderColor": "hsl(344 51% 43% / var(--tw-border-opacity))",
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "hsl(344 51% 43% / var(--tw-ring-opacity))"
      })
    }),
    variants: {
      warning: {
        "true": {
          borderColor: "$information",
          "&:focus": _objectSpread({}, {
            "--tw-border-opacity": "1",
            "borderColor": "hsl(218 100% 64% / var(--tw-border-opacity))",
            "outline": "2px solid transparent",
            "outlineOffset": "2px",
            "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
            "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
            "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
            "--tw-ring-opacity": "1",
            "--tw-ring-color": "hsl(218 100% 64% / var(--tw-ring-opacity))"
          })
        }
      },
      error: {
        "true": {
          borderColor: "$danger",
          "&:focus": _objectSpread({}, {
            "--tw-border-opacity": "1",
            "borderColor": "hsl(357 75% 44% / var(--tw-border-opacity))",
            "outline": "2px solid transparent",
            "outlineOffset": "2px",
            "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
            "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
            "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
            "--tw-ring-opacity": "1",
            "--tw-ring-color": "hsl(357 75% 44% / var(--tw-ring-opacity))"
          })
        }
      }
    }
  })),
  triggerButton: _css(_objectSpread(_objectSpread({}, {
    "position": "absolute",
    "top": "0px",
    "bottom": "0px",
    "right": "0px",
    "display": "flex",
    "alignItems": "center",
    "paddingRight": "0.5rem"
  }), {}, {
    variants: {
      disabled: {
        "true": _objectSpread({}, {
          "pointerEvents": "none",
          "cursor": "default"
        })
      }
    }
  })),
  triggerButtonIcon: _css(_objectSpread({}, {
    "height": "1.25rem",
    "width": "1.25rem",
    "--tw-text-opacity": "1",
    "color": "rgb(156 163 175 / var(--tw-text-opacity))"
  })),
  options: _css(_objectSpread(_objectSpread({}, {
    "position": "absolute",
    "zIndex": "10",
    "marginTop": "0.25rem",
    "display": "flex",
    "maxHeight": "15rem",
    "width": "100%",
    "flexDirection": "column",
    "gap": "0.5rem",
    "overflowY": "auto",
    "borderRadius": "0.375rem",
    "borderWidth": "1px",
    "--tw-bg-opacity": "1",
    "backgroundColor": "hsl(0 0% 100% / var(--tw-bg-opacity))",
    "padding": "0.5rem",
    "--tw-shadow": "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
    "--tw-shadow-colored": "0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color)",
    "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
  }), {}, {
    borderColor: "$gray2"
  })),
  optionsEmpty: _css(_objectSpread(_objectSpread({}, {
    "position": "relative",
    "cursor": "default",
    "userSelect": "none",
    "paddingLeft": "1rem",
    "paddingRight": "1rem",
    "paddingTop": "0.5rem",
    "paddingBottom": "0.5rem",
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem"
  }), {}, {
    color: "$gray6"
  })),
  option: _css(_objectSpread(_objectSpread({}, {
    "position": "relative",
    "cursor": "pointer",
    "userSelect": "none",
    "borderRadius": "0.5rem",
    "paddingLeft": "1rem",
    "paddingRight": "1rem",
    "paddingTop": "0.5rem",
    "paddingBottom": "0.5rem",
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem",
    "--tw-text-opacity": "1",
    "color": "rgb(17 24 39 / var(--tw-text-opacity))",
    "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
    "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "transitionDuration": "150ms"
  }), {}, {
    "&:hover": {
      backgroundColor: "hsl(0, 0%, 96%)"
    },
    variants: {
      selected: {
        "true": {
          backgroundColor: "$default",
          color: "$white",
          "&:hover": {
            backgroundColor: "$default",
            color: "$white"
          }
        }
      }
    }
  }))
};
export default styles;