import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _slicedToArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useRouter } from "next/router";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { CheckoutView } from "@/checkout/checkout.types";
import { pushGtmEvent } from "@/helpers/analytics.helper";
import { useGlobal } from "@/hooks/useGlobal.hook";
import createContext from "@/libraries/react.library";
import { mapQueryToModifications } from "./interface.helpers";
export var CHECKOUT_VIEWS = [{
  index: 0,
  view: CheckoutView.ADDRESS,
  disabled: false
}, {
  index: 1,
  view: CheckoutView.DETAILS,
  disabled: true
}, {
  index: 2,
  view: CheckoutView.PAYMENT,
  disabled: true
}, {
  index: null,
  view: CheckoutView.LOADER,
  disabled: false
}];
var INITIAL_STATE = {
  activeDialog: null,
  locked: false,
  navbar: {
    navTopPosition: 0,
    navScrolled: false
  },
  checkout: {
    active: CHECKOUT_VIEWS.find(function (view) {
      return view.index === 0;
    }) || CHECKOUT_VIEWS[0],
    next: CHECKOUT_VIEWS.find(function (view) {
      return view.index === 1;
    }) || CHECKOUT_VIEWS[1],
    previous: null,
    views: CHECKOUT_VIEWS
  },
  scrollY: 0,
  modifications: [],
  openDialog: function openDialog() {
    return null;
  },
  setLocked: function setLocked() {
    return null;
  },
  closeDialog: function closeDialog() {
    return null;
  },
  toggleDialog: function toggleDialog() {
    return null;
  },
  enableCheckoutView: function enableCheckoutView() {
    return null;
  },
  disableCheckoutView: function disableCheckoutView() {
    return null;
  },
  resetCheckoutViews: function resetCheckoutViews() {
    return null;
  },
  setCheckoutView: function setCheckoutView() {
    return null;
  },
  setModifications: function setModifications() {
    return null;
  }
};

var _createContext = createContext(),
    _createContext2 = _slicedToArray(_createContext, 2),
    useContext = _createContext2[0],
    Provider = _createContext2[1];

export { useContext, Provider };

function reducer(state, action) {
  var _state$checkout;

  switch (action.type) {
    case "LOCK_INTERFACE":
      return _objectSpread(_objectSpread({}, state), {}, {
        locked: action.payload
      });

    case "OPEN_DIALOG":
      return _objectSpread(_objectSpread({}, state), {}, {
        activeDialog: action.payload
      });

    case "CLOSE_DIALOG":
      return _objectSpread(_objectSpread({}, state), {}, {
        activeDialog: null
      });

    case "RESET_CHECKOUT_VIEWS":
      return _objectSpread(_objectSpread({}, state), {}, {
        checkout: INITIAL_STATE.checkout
      });

    case "SET_CHECKOUT_VIEW":
      return ((_state$checkout = state.checkout) === null || _state$checkout === void 0 || (_state$checkout = _state$checkout.views.find(function (v) {
        return v.view === action.payload;
      })) === null || _state$checkout === void 0 ? void 0 : _state$checkout.disabled) === true ? _objectSpread({}, state) : _objectSpread(_objectSpread({}, state), {}, {
        checkout: _objectSpread(_objectSpread({}, state.checkout), {}, {
          active: CHECKOUT_VIEWS.find(function (target) {
            return target.view === action.payload;
          }) || CHECKOUT_VIEWS[0],
          next: CHECKOUT_VIEWS.find(function (target) {
            var _CHECKOUT_VIEWS$find;

            return target.index === (((_CHECKOUT_VIEWS$find = CHECKOUT_VIEWS.find(function (v) {
              return v.view === action.payload;
            })) === null || _CHECKOUT_VIEWS$find === void 0 ? void 0 : _CHECKOUT_VIEWS$find.index) || 0) + 1;
          }) || CHECKOUT_VIEWS[0],
          previous: CHECKOUT_VIEWS.find(function (target) {
            var _CHECKOUT_VIEWS$find2;

            return target.index === (((_CHECKOUT_VIEWS$find2 = CHECKOUT_VIEWS.find(function (v) {
              return v.view === action.payload;
            })) === null || _CHECKOUT_VIEWS$find2 === void 0 ? void 0 : _CHECKOUT_VIEWS$find2.index) || 0) - 1;
          }) || null
        })
      });

    case "ENABLE_CHECKOUT_VIEW":
      return _objectSpread(_objectSpread({}, state), {}, {
        checkout: _objectSpread(_objectSpread({}, state.checkout), {}, {
          views: state.checkout.views.map(function (v) {
            if (v.view === action.payload) {
              return _objectSpread(_objectSpread({}, v), {}, {
                disabled: false
              });
            }

            return v;
          })
        })
      });

    case "DISABLE_CHECKOUT_VIEW":
      return _objectSpread(_objectSpread({}, state), {}, {
        checkout: _objectSpread(_objectSpread({}, state.checkout), {}, {
          views: state.checkout.views.map(function (v) {
            if (v.view === action.payload) {
              return _objectSpread(_objectSpread({}, v), {}, {
                disabled: true
              });
            }

            return v;
          })
        })
      });

    case "SET_SCROLL_Y":
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          scrollY: action.payload
        });
      }

    case "SET_MODIFICATIONS":
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          modifications: action.payload
        });
      }

    default:
      return state;
  }
}

export var Interface = function Interface(props) {
  var _React$useReducer = React.useReducer(reducer, INITIAL_STATE),
      _React$useReducer2 = _slicedToArray(_React$useReducer, 2),
      state = _React$useReducer2[0],
      dispatch = _React$useReducer2[1];

  var router = useRouter();

  var _useGlobal = useGlobal(),
      promoBar = _useGlobal.promoBar;

  var timeoutId = React.useRef(null);
  /*
   * *
   * Lock interface context from outside
   * *
   */

  var setLocked = React.useCallback(function (payload) {
    return dispatch({
      type: "LOCK_INTERFACE",
      payload: payload
    });
  }, [dispatch]);
  /*
   * *
   * Dialog
   * *
   */

  var openDialog = React.useCallback(function (payload) {
    if (!state.locked) {
      if (state.activeDialog) {
        dispatch({
          type: "CLOSE_DIALOG"
        });
      }

      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      timeoutId.current = setTimeout(function () {
        dispatch({
          type: "OPEN_DIALOG",
          payload: payload
        });
      });
    }
  }, [dispatch, state.activeDialog, state.locked]);
  var closeDialog = React.useCallback(function () {
    return !state.locked && dispatch({
      type: "CLOSE_DIALOG"
    });
  }, [dispatch, state.locked]);
  var toggleDialog = React.useCallback(function (payload) {
    return !!state.activeDialog && state.activeDialog === payload ? !state.locked && dispatch({
      type: "CLOSE_DIALOG"
    }) : !state.locked && dispatch({
      type: "OPEN_DIALOG",
      payload: payload
    });
  }, [dispatch, state.activeDialog, state.locked]);
  /*
   * *
   * Checkout
   * *
   */

  var setCheckoutView = React.useCallback(function (payload) {
    var scrollToTop = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    dispatch({
      type: "SET_CHECKOUT_VIEW",
      payload: payload
    });

    if (scrollToTop) {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: "smooth"
      });
    }
  }, [dispatch]);
  var enableCheckoutView = React.useCallback(function (payload) {
    return dispatch({
      type: "ENABLE_CHECKOUT_VIEW",
      payload: payload
    });
  }, [dispatch]);
  var disableCheckoutView = React.useCallback(function (payload) {
    return dispatch({
      type: "DISABLE_CHECKOUT_VIEW",
      payload: payload
    });
  }, [dispatch]);
  var resetCheckoutViews = React.useCallback(function () {
    return dispatch({
      type: "RESET_CHECKOUT_VIEWS"
    });
  }, [dispatch]);
  /*
   * *
   * WP Widget Remover
   * *
   */

  React.useEffect(function () {
    var FOOTER_ID = "__footer";
    var FOOTER_WIDGET_SELECTOR = '[data-widget-id="5006"]';

    var removeDuplicateWidgets = function removeDuplicateWidgets(selector) {
      var widgets = document.querySelectorAll(selector);

      if (widgets.length > 1) {
        for (var i = 1; i < widgets.length; i++) {
          widgets[i].remove();
        }
      }
    };

    removeDuplicateWidgets(FOOTER_WIDGET_SELECTOR);
    var footer = document.getElementById(FOOTER_ID);

    if (footer) {
      var footerWidgetObserver = new MutationObserver(function () {
        return removeDuplicateWidgets(FOOTER_WIDGET_SELECTOR);
      });
      footerWidgetObserver.observe(footer, {
        childList: true,
        subtree: true
      });
      return function () {
        return footerWidgetObserver.disconnect();
      };
    }
  }, []);
  /*
   * *
   * Scroll observer
   * *
   */

  var _React$useState = React.useState(INITIAL_STATE.navbar),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      navbar = _React$useState2[0],
      setNavbar = _React$useState2[1];

  React.useEffect(function () {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      var handleScroll = function handleScroll() {
        var _document$querySelect, _document$querySelect2;

        var windowScrollY = window.scrollY; // const bodyMarginTop = document.body.style.marginTop
        //   ? Number(document.body.style.marginTop.match(/\d+/)?.[0])
        //   : 0;

        var promoBarHeight = ((_document$querySelect = document.querySelector("#__bar")) === null || _document$querySelect === void 0 ? void 0 : _document$querySelect.clientHeight) || 0;
        var navigationHeight = ((_document$querySelect2 = document.querySelector("#__nav")) === null || _document$querySelect2 === void 0 ? void 0 : _document$querySelect2.clientHeight) || 0;
        dispatch({
          type: "SET_SCROLL_Y",
          payload: windowScrollY
        });

        if (windowScrollY <= promoBarHeight) {
          setNavbar({
            navTopPosition: (promoBar.isVisible ? promoBarHeight : 0) - windowScrollY + navigationHeight,
            navScrolled: false
          });
          return;
        }

        setNavbar({
          navTopPosition: navigationHeight,
          navScrolled: true
        });
      };

      window.addEventListener("scroll", function () {
        return handleScroll();
      });
      handleScroll();
      return window.removeEventListener("scroll", function () {
        return handleScroll();
      });
    }
  }, [promoBar.isVisible]);
  /*
   * *
   * Router
   * *
   */

  var setModifications = React.useCallback(function (payload) {
    return dispatch({
      type: "SET_MODIFICATIONS",
      payload: payload
    });
  }, [dispatch]);
  React.useEffect(function () {
    var _router$query = router.query,
        interface_modification = _router$query.interface_modification,
        mod = _router$query.mod;

    if (interface_modification || mod) {
      if (Array.isArray(interface_modification)) {
        interface_modification = interface_modification[0];
      }

      if (Array.isArray(mod)) {
        mod = mod[0];
      }

      setModifications(mapQueryToModifications(interface_modification || mod));
    }
  }, [router.query, setModifications]);
  React.useEffect(function () {
    var handleRouteChangeComplete = function handleRouteChangeComplete() {
      setLocked(false);
      dispatch({
        type: "CLOSE_DIALOG"
      });
      pushGtmEvent({
        event: "route_change_complete",
        event_id: uuidv4()
      });
    };

    var handleRouteChangeStart = function handleRouteChangeStart() {
      setLocked(true);
      pushGtmEvent({
        event: "route_change_start",
        event_id: uuidv4()
      });
    };

    router.events.on("routeChangeStart", handleRouteChangeStart);
    router.events.on("routeChangeComplete", handleRouteChangeComplete);
    return function () {
      router.events.off("routeChangeStart", handleRouteChangeStart);
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, [router.events, setLocked]);
  React.useEffect(function () {
    return function () {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);
  var value = React.useMemo(function () {
    return _objectSpread(_objectSpread({}, state), {}, {
      navbar: navbar,
      setLocked: setLocked,
      closeDialog: closeDialog,
      enableCheckoutView: enableCheckoutView,
      disableCheckoutView: disableCheckoutView,
      openDialog: openDialog,
      resetCheckoutViews: resetCheckoutViews,
      setCheckoutView: setCheckoutView,
      setModifications: setModifications,
      toggleDialog: toggleDialog
    });
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [state, navbar]);
  return __jsx(Provider, _extends({
    value: value
  }, props));
};