var __jsx = React.createElement;
import React from "react";
import styles from "./Label.styles";

var Label = function Label(_ref) {
  var children = _ref.children,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? "sm" : _ref$size,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? "default" : _ref$color;
  return __jsx("div", {
    className: styles.root({
      size: size,
      color: color
    })
  }, children);
};

export default Label;