import _slicedToArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
import React, { useCallback, useState } from "react";
import ReviewsFilters from "@/components/ui/Reviews/components/ReviewsFilters/ReviewsFilters.component";
import ReviewsList from "@/components/ui/Reviews/components/ReviewsList/ReviewsList.component";
import ReviewsSummary from "@/components/ui/Reviews/components/ReviewsSummary/ReviewsSummary.component";
import styles from "./Reviews.styles";

var Reviews = function Reviews(_ref) {
  var reviews = _ref.reviews,
      itemsPerPage = _ref.itemsPerPage,
      withSummary = _ref.withSummary,
      withFilters = _ref.withFilters,
      _ref$type = _ref.type,
      type = _ref$type === void 0 ? "single-product" : _ref$type,
      _ref$showReviewDate = _ref.showReviewDate,
      showReviewDate = _ref$showReviewDate === void 0 ? false : _ref$showReviewDate;

  var _useState = useState(),
      slider = _useState[0],
      setSlider = _useState[1];

  var _React$useState = React.useState(reviews),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      filteredReviews = _React$useState2[0],
      setFilteredReviews = _React$useState2[1];

  var setSliderAction = useCallback(function (newSlider) {
    setSlider(newSlider);
  }, []);

  var filterReviews = function filterReviews(newFilteredReviews) {
    setFilteredReviews(newFilteredReviews);
  };

  return __jsx("div", {
    className: styles.root()
  }, withSummary ? __jsx(ReviewsSummary, {
    reviews: reviews,
    type: type
  }) : null, withFilters ? __jsx(ReviewsFilters, {
    allReviews: reviews,
    filterReviews: filterReviews,
    slider: slider,
    type: type
  }) : null, __jsx(ReviewsList, {
    reviews: filteredReviews,
    setSlider: setSliderAction,
    itemsPerPage: itemsPerPage,
    type: type,
    showReviewDate: showReviewDate
  }));
};

Reviews.displayName = "Reviews";
export default Reviews;