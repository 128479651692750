var __jsx = React.createElement;
import { Dialog } from "@headlessui/react";
import React from "react";
import styles from "./Popup.styles";

var Popup = function Popup(_ref) {
  var children = _ref.children,
      show = _ref.show,
      onClose = _ref.onClose;
  return __jsx(Dialog, {
    as: "div",
    className: styles.dialog(),
    open: show,
    onClose: onClose
  }, __jsx("div", {
    className: styles.background()
  }), __jsx("div", {
    className: styles.wrapper()
  }, __jsx(Dialog.Panel, {
    className: styles.panel()
  }, __jsx("div", {
    className: styles.panelContent()
  }, children))));
};

Popup.displayName = "Popup";
export default Popup;