import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread({}, {
    "position": "relative"
  })),
  slider: _css(_objectSpread(_objectSpread({}, {
    "pointerEvents": "auto",
    "marginLeft": "-1rem",
    "marginRight": "-1rem"
  }), {}, {
    "@sm": _objectSpread({}, {
      "marginLeft": "-1.5rem",
      "marginRight": "-1.5rem"
    })
  })),
  tags: _css(_objectSpread(_objectSpread({}, {
    "marginLeft": "-1rem",
    "marginRight": "-1rem"
  }), {}, {
    "@sm": _objectSpread({}, {
      "marginLeft": "-1.5rem",
      "marginRight": "-1.5rem"
    }),
    variants: {
      withMarginBottom: {
        "true": {
          marginBottom: 46
        }
      }
    }
  })),
  imageContainer: _css(_objectSpread({}, {
    "--tw-aspect-h": "1",
    "position": "relative",
    "paddingBottom": "calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%)",
    "--tw-aspect-w": "1",
    "> *": {
      "position": "absolute",
      "height": "100%",
      "width": "100%",
      "top": "0",
      "right": "0",
      "bottom": "0",
      "left": "0"
    }
  })),
  imagePlaceholderContainer: _css(_objectSpread(_objectSpread({}, {
    "--tw-aspect-h": "1",
    "position": "relative",
    "paddingBottom": "calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%)",
    "--tw-aspect-w": "1",
    "> *": {
      "position": "absolute",
      "height": "100%",
      "width": "100%",
      "top": "0",
      "right": "0",
      "bottom": "0",
      "left": "0"
    },
    "marginLeft": "-1rem",
    "marginRight": "-1rem"
  }), {}, {
    "@sm": _objectSpread({}, {
      "marginLeft": "-1.5rem",
      "marginRight": "-1.5rem"
    })
  })),
  paginationContainer: _css(_objectSpread(_objectSpread({}, {
    "display": "flex",
    "justifyContent": "center",
    "padding": "1rem"
  }), {}, {
    height: 46
  })),
  container: _css(_objectSpread({}, {
    "pointerEvents": "none",
    "position": "relative"
  })),
  discountTagContainer: _css({
    width: "100%"
  }),
  discountTag: _css({
    width: "fit-content"
  })
};
export default styles;