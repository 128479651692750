var __jsx = React.createElement;
import { styled as _styled } from "../../../libraries/stitches.library.ts";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useSwiper } from "swiper/react";
import style from "./Slider.styles";

var _TwComponent = _styled("button", {});

var Pagination = function Pagination(_ref) {
  var _ref$clicable = _ref.clicable,
      clicable = _ref$clicable === void 0 ? true : _ref$clicable,
      _ref$initialSlide = _ref.initialSlide,
      initialSlide = _ref$initialSlide === void 0 ? 0 : _ref$initialSlide;
  var swiper = useSwiper();
  var t = useIntl();

  var _useState = useState(),
      activeSlideIndex = _useState[0],
      setActiveSlideIndex = _useState[1];

  var paginationItems = useMemo(function () {
    var paginationItemsArray = [];

    for (var i = 0; i < swiper.slides.length; i++) {
      paginationItemsArray.push({
        slideIndex: i,
        isActive: i === activeSlideIndex
      });
    }

    return paginationItemsArray;
  }, [swiper.slides.length, activeSlideIndex]);
  var goToSlide = useCallback(function (slideIndex) {
    return swiper.slideTo(slideIndex, 300);
  }, [swiper]);
  useEffect(function () {
    swiper.on("slideChange", function (currenSwiper) {
      return setActiveSlideIndex(currenSwiper.activeIndex);
    });
  }, [swiper]);
  useEffect(function () {
    if (initialSlide === 0) {
      setActiveSlideIndex(initialSlide);
    } else {
      goToSlide(initialSlide);
    }
  }, [goToSlide, initialSlide]);
  return __jsx("div", {
    className: style.root({
      isClicable: clicable
    })
  }, paginationItems.map(function (item, index) {
    return __jsx(_TwComponent, {
      "aria-label": t.formatMessage({
        id: "slider.pagination",
        defaultMessage: "slider item {index}"
      }, {
        index: index + 1
      }),
      css: style.item({
        isActive: item.isActive,
        isClicable: clicable
      }),
      onClick: function onClick() {
        return goToSlide(item.slideIndex);
      },
      key: "pagination-item-".concat(index)
    });
  }));
};

Pagination.displayName = "Pagination";
export default Pagination;