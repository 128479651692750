import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from "react";

var SvgTruck = function SvgTruck(props) {
  return __jsx("svg", _extends({
    width: 16,
    height: 16,
    viewBox: "0 0 16 16",
    fill: "none",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("path", {
    d: "M10.666 2H0.666016V10.6667H10.666V2Z",
    stroke: props.color || "#C24163"
  }), __jsx("path", {
    d: "M10.666 5.3335H13.3327L15.3327 7.3335V10.6668H10.666V5.3335Z",
    stroke: props.color || "#C24163"
  }), __jsx("path", {
    d: "M3.66667 13.9998C4.58714 13.9998 5.33333 13.2536 5.33333 12.3332C5.33333 11.4127 4.58714 10.6665 3.66667 10.6665C2.74619 10.6665 2 11.4127 2 12.3332C2 13.2536 2.74619 13.9998 3.66667 13.9998Z",
    stroke: props.color || "#C24163"
  }), __jsx("path", {
    d: "M12.3327 13.9998C13.2532 13.9998 13.9993 13.2536 13.9993 12.3332C13.9993 11.4127 13.2532 10.6665 12.3327 10.6665C11.4122 10.6665 10.666 11.4127 10.666 12.3332C10.666 13.2536 11.4122 13.9998 12.3327 13.9998Z",
    stroke: props.color || "#C24163"
  }));
};

export default SvgTruck;