export var zIndices = {
  1: "10",
  2: "20",
  3: "30",
  4: "40",
  5: "50",
  6: "60",
  7: "70",
  8: "80",
  9: "90",
  max: "99"
};
export default zIndices;