var __jsx = React.createElement;
import React, { Fragment } from "react";
import { useIntl } from "react-intl";
import LovaAppProductThumbnail from "/public/mobile-app-product-thumbnail.webp";
import { DummyProduct } from "@/checkout/components";
import { Icon, Loader } from "@/components/ui";
import usePath from "@/hooks/usePath.hook";
import { cn } from "@/libraries/clsx.library";
import styles from "./List.styles";

var List = function List(_ref) {
  var loading = _ref.loading,
      lines = _ref.lines,
      product = _ref.product,
      _ref$withDummyProduct = _ref.withDummyProduct,
      withDummyProduct = _ref$withDummyProduct === void 0 ? false : _ref$withDummyProduct,
      type = _ref.type;
  var t = useIntl();
  var path = usePath();
  if (loading) return __jsx("div", {
    className: cn(styles.container(), styles.loader())
  }, __jsx(Loader, null));
  var hasLines = lines && lines.length > 0;
  if (!hasLines) return __jsx("div", {
    id: "__".concat(type.toLowerCase(), "-products"),
    className: cn(styles.container(), styles.empty(), "".concat(type.toLowerCase(), "-products"))
  }, __jsx(Icon, {
    name: "Cart",
    className: styles.emptyIcon()
  }), __jsx("span", {
    className: styles.emptyInfo()
  }, t.formatMessage({
    id: "cart.list.emptyInfo",
    defaultMessage: "Your shopping cart is empty"
  })));
  return __jsx("div", {
    id: "__".concat(type.toLowerCase(), "-products"),
    className: cn(styles.container(), styles.list(), "".concat(type.toLowerCase(), "-products"))
  }, lines.map(function (line, index) {
    return __jsx(Fragment, {
      key: line.id
    }, product(line, line.variant, index));
  }), withDummyProduct ? __jsx(DummyProduct, {
    name: t.formatMessage({
      id: "dummyProduct.lovaApp.name",
      defaultMessage: "LOVA app"
    }),
    imageSrc: LovaAppProductThumbnail,
    linkHref: path.aplikacja_lova_opowiadania_erotyczne.$url(),
    price: 0,
    priceUndiscounted: 19.99,
    detail: t.formatMessage({
      id: "dummyProduct.lovaApp.detail",
      defaultMessage: "30-day access"
    }),
    type: type
  }) : null);
};

List.displayName = "List";
export default List;