var __jsx = React.createElement;
import React from "react";
import { Image } from "@/components/common";
import { scrollIntoView } from "@/helpers/scroll.helper";
import useRegion from "@/hooks/useRegion.hook";
import { ImagePlacecholder } from "~/src/components/ui";
import { useProductVariant } from "~/src/modules/product/hooks/useProductVariant.hook";
import styles from "./VariantSetListItem.styles";

var VariantSetListItem = function VariantSetListItem(_ref) {
  var index = _ref.index,
      _ref$variantInSet = _ref.variantInSet,
      variant = _ref$variantInSet.variant,
      variantQuantity = _ref$variantInSet.variantQuantity,
      grossPriceInSet = _ref$variantInSet.grossPriceInSet,
      variantsInSetSlider = _ref.variantsInSetSlider;

  var _useRegion = useRegion(),
      formatPrice = _useRegion.formatPrice;

  var _useProductVariant = useProductVariant(variant),
      name = _useProductVariant.name,
      price = _useProductVariant.price,
      media128Url = _useProductVariant.media.media128Url,
      volume = _useProductVariant.volume,
      isAdditionOfSet = _useProductVariant.isAdditionOfSet;

  var priceOutOfSet = {
    amount: (price === null || price === void 0 ? void 0 : price.amount) * variantQuantity,
    currency: price === null || price === void 0 ? void 0 : price.currency
  };
  var formatedPrice = formatPrice(grossPriceInSet);
  var formatedPriceUndiscounted = formatPrice(priceOutOfSet);
  var shouldDisplayPriceUndiscounted = grossPriceInSet && price ? grossPriceInSet.amount < priceOutOfSet.amount : false;

  var scrollIntoProductDetails = function scrollIntoProductDetails(slideIndex) {
    scrollIntoView("__productsInSet", {
      block: "nearest"
    });
    variantsInSetSlider === null || variantsInSetSlider === void 0 || variantsInSetSlider.slideTo(slideIndex);
  };

  return __jsx("div", {
    className: styles.root({
      disabled: isAdditionOfSet
    })
  }, __jsx("div", {
    className: styles.imageContainer(),
    onClick: function onClick() {
      return scrollIntoProductDetails(index);
    }
  }, media128Url ? __jsx(Image, {
    src: media128Url || "",
    alt: "".concat(name, " product image"),
    placeholder: "blur",
    unoptimized: true,
    fill: true,
    sizes: "100vw",
    style: {
      objectFit: "cover",
      objectPosition: "center"
    }
  }) : __jsx(ImagePlacecholder, {
    size: "lg"
  })), __jsx("div", {
    className: styles.info()
  }, __jsx("div", {
    className: styles.mainInfo()
  }, __jsx("span", {
    className: styles.name(),
    onClick: function onClick() {
      return scrollIntoProductDetails(index);
    }
  }, name, " ", variantQuantity > 1 ? "x".concat(variantQuantity) : ""), __jsx("p", {
    className: styles.price()
  }, formatedPrice)), __jsx("div", {
    className: styles.details()
  }, __jsx("span", {
    className: styles.detail()
  }, volume), shouldDisplayPriceUndiscounted ? __jsx("span", {
    className: styles.priceUndiscounted()
  }, formatedPriceUndiscounted) : null)));
};

VariantSetListItem.displayName = "VariantSetListItem";
export default VariantSetListItem;