import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from "react";

var SvgLink = function SvgLink(props) {
  return __jsx("svg", _extends({
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("path", {
    d: "M8.72852 15.2712L15.2713 8.71875",
    stroke: "black",
    strokeWidth: 1.5,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __jsx("path", {
    d: "M13.6404 16.9118L10.9095 19.6428C10.4792 20.073 9.96845 20.4143 9.40632 20.6471C8.84419 20.88 8.2417 20.9998 7.63325 20.9998C6.40444 20.9998 5.22595 20.5117 4.35705 19.6428C3.48814 18.7739 3 17.5954 3 16.3666C3 15.1377 3.48814 13.9593 4.35705 13.0903L7.08802 10.3594",
    stroke: "black",
    strokeWidth: 1.5,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __jsx("path", {
    d: "M16.9118 13.6404L19.6428 10.9095C20.5117 10.0406 20.9998 8.86207 20.9998 7.63325C20.9998 6.40444 20.5117 5.22595 19.6428 4.35705C18.7739 3.48814 17.5954 3 16.3666 3C15.1377 3 13.9593 3.48814 13.0903 4.35705L10.3594 7.08802",
    stroke: "black",
    strokeWidth: 1.5,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }));
};

export default SvgLink;