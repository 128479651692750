import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from "react";

var SvgFacebook = function SvgFacebook(props) {
  return __jsx("svg", _extends({
    width: 22,
    height: 22,
    viewBox: "0 0 22 22",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("path", {
    d: "M11.0004 0.44043C5.16843 0.44043 0.44043 5.16843 0.44043 11.0004C0.44043 16.2948 4.34043 20.6662 9.42219 21.4299V13.7993H6.80955V11.0235H9.42219V9.17643C9.42219 6.11835 10.9121 4.77579 13.4537 4.77579C14.671 4.77579 15.3147 4.86603 15.6195 4.90731V7.33035H13.8857C12.8067 7.33035 12.4299 8.35323 12.4299 9.50619V11.0235H15.5921L15.163 13.7993H12.4299V21.4524C17.5841 20.7531 21.5604 16.3462 21.5604 11.0004C21.5604 5.16843 16.8324 0.44043 11.0004 0.44043Z",
    fill: "white"
  }));
};

export default SvgFacebook;