var __jsx = React.createElement;
import React from "react";
import { Image } from "@/components/common";
import { up } from "~/src/helpers/screens.helpers";
import { useMediaQuery } from "~/src/hooks/useMediaQuery.hook";
import { cn } from "~/src/libraries/clsx.library";
import { ScreenEnum } from "~/src/theme/theme.enums";
import styles from "./PopupContentImage.styles";

var PopupContentImage = function PopupContentImage(_ref) {
  var children = _ref.children,
      imageMobile = _ref.imageMobile,
      imageDesktop = _ref.imageDesktop,
      className = _ref.className;
  var isUpLg = useMediaQuery(up(ScreenEnum.lg), false);
  return __jsx("div", {
    className: cn(styles.root(), className)
  }, __jsx("div", {
    className: styles.text()
  }, children), __jsx(Image, {
    src: isUpLg ? imageDesktop : imageMobile,
    alt: "Popup contetn image",
    className: styles.image(),
    placeholder: "blur",
    fill: true,
    style: {
      objectFit: "cover",
      objectPosition: "center right"
    }
  }));
};

PopupContentImage.displayName = "PopupContentImage";
export default PopupContentImage;