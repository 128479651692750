export var pagesPath = {
  "$404": {
    $url: function $url(url) {
      return {
        pathname: '/404',
        hash: url === null || url === void 0 ? void 0 : url.hash
      };
    }
  },
  "$500": {
    $url: function $url(url) {
      return {
        pathname: '/500',
        hash: url === null || url === void 0 ? void 0 : url.hash
      };
    }
  },
  _channel: function _channel(channel) {
    return {
      _locale: function _locale(locale) {
        return {
          _slug: function _slug(slug) {
            return {
              $url: function $url(url) {
                return {
                  pathname: '/[channel]/[locale]/[slug]',
                  query: {
                    channel: channel,
                    locale: locale,
                    slug: slug
                  },
                  hash: url === null || url === void 0 ? void 0 : url.hash
                };
              }
            };
          },
          "about_us": {
            $url: function $url(url) {
              return {
                pathname: '/[channel]/[locale]/about-us',
                query: {
                  channel: channel,
                  locale: locale
                },
                hash: url === null || url === void 0 ? void 0 : url.hash
              };
            }
          },
          "account": {
            "orders": {
              $url: function $url(url) {
                return {
                  pathname: '/[channel]/[locale]/account/orders',
                  query: {
                    channel: channel,
                    locale: locale
                  },
                  hash: url === null || url === void 0 ? void 0 : url.hash
                };
              }
            },
            "profile": {
              "change_password": {
                $url: function $url(url) {
                  return {
                    pathname: '/[channel]/[locale]/account/profile/change-password',
                    query: {
                      channel: channel,
                      locale: locale
                    },
                    hash: url === null || url === void 0 ? void 0 : url.hash
                  };
                }
              },
              "edit": {
                $url: function $url(url) {
                  return {
                    pathname: '/[channel]/[locale]/account/profile/edit',
                    query: {
                      channel: channel,
                      locale: locale
                    },
                    hash: url === null || url === void 0 ? void 0 : url.hash
                  };
                }
              },
              $url: function $url(url) {
                return {
                  pathname: '/[channel]/[locale]/account/profile',
                  query: {
                    channel: channel,
                    locale: locale
                  },
                  hash: url === null || url === void 0 ? void 0 : url.hash
                };
              }
            }
          },
          "activate_account": {
            $url: function $url(url) {
              return {
                pathname: '/[channel]/[locale]/activate-account',
                query: {
                  channel: channel,
                  locale: locale
                },
                hash: url === null || url === void 0 ? void 0 : url.hash
              };
            }
          },
          "aplikacja_lova_opowiadania_erotyczne": {
            $url: function $url(url) {
              return {
                pathname: '/[channel]/[locale]/aplikacja-lova-opowiadania-erotyczne',
                query: {
                  channel: channel,
                  locale: locale
                },
                hash: url === null || url === void 0 ? void 0 : url.hash
              };
            }
          },
          "checkout": {
            $url: function $url(url) {
              return {
                pathname: '/[channel]/[locale]/checkout',
                query: {
                  channel: channel,
                  locale: locale
                },
                hash: url === null || url === void 0 ? void 0 : url.hash
              };
            }
          },
          "collect": {
            $url: function $url(url) {
              return {
                pathname: '/[channel]/[locale]/collect',
                query: {
                  channel: channel,
                  locale: locale
                },
                hash: url === null || url === void 0 ? void 0 : url.hash
              };
            }
          },
          "contact": {
            $url: function $url(url) {
              return {
                pathname: '/[channel]/[locale]/contact',
                query: {
                  channel: channel,
                  locale: locale
                },
                hash: url === null || url === void 0 ? void 0 : url.hash
              };
            }
          },
          "gift_card_details": {
            $url: function $url(url) {
              return {
                pathname: '/[channel]/[locale]/gift-card-details',
                query: {
                  channel: channel,
                  locale: locale
                },
                hash: url === null || url === void 0 ? void 0 : url.hash
              };
            }
          },
          "login": {
            $url: function $url(url) {
              return {
                pathname: '/[channel]/[locale]/login',
                query: {
                  channel: channel,
                  locale: locale
                },
                hash: url === null || url === void 0 ? void 0 : url.hash
              };
            }
          },
          "low_price_zone": {
            $url: function $url(url) {
              return {
                pathname: '/[channel]/[locale]/low-price-zone',
                query: {
                  channel: channel,
                  locale: locale
                },
                hash: url === null || url === void 0 ? void 0 : url.hash
              };
            }
          },
          "manifest": {
            $url: function $url(url) {
              return {
                pathname: '/[channel]/[locale]/manifest',
                query: {
                  channel: channel,
                  locale: locale
                },
                hash: url === null || url === void 0 ? void 0 : url.hash
              };
            }
          },
          "order": {
            _id: function _id(id) {
              return {
                $url: function $url(url) {
                  return {
                    pathname: '/[channel]/[locale]/order/[id]',
                    query: {
                      channel: channel,
                      locale: locale,
                      id: id
                    },
                    hash: url === null || url === void 0 ? void 0 : url.hash
                  };
                }
              };
            }
          },
          "page": {
            _slug: function _slug(slug) {
              return {
                $url: function $url(url) {
                  return {
                    pathname: '/[channel]/[locale]/page/[slug]',
                    query: {
                      channel: channel,
                      locale: locale,
                      slug: slug
                    },
                    hash: url === null || url === void 0 ? void 0 : url.hash
                  };
                }
              };
            }
          },
          "partnership": {
            $url: function $url(url) {
              return {
                pathname: '/[channel]/[locale]/partnership',
                query: {
                  channel: channel,
                  locale: locale
                },
                hash: url === null || url === void 0 ? void 0 : url.hash
              };
            }
          },
          "password_reset": {
            "success": {
              $url: function $url(url) {
                return {
                  pathname: '/[channel]/[locale]/password-reset/success',
                  query: {
                    channel: channel,
                    locale: locale
                  },
                  hash: url === null || url === void 0 ? void 0 : url.hash
                };
              }
            },
            $url: function $url(url) {
              return {
                pathname: '/[channel]/[locale]/password-reset',
                query: {
                  channel: channel,
                  locale: locale
                },
                hash: url === null || url === void 0 ? void 0 : url.hash
              };
            }
          },
          "podcast": {
            $url: function $url(url) {
              return {
                pathname: '/[channel]/[locale]/podcast',
                query: {
                  channel: channel,
                  locale: locale
                },
                hash: url === null || url === void 0 ? void 0 : url.hash
              };
            }
          },
          "products": {
            _collectionId: function _collectionId(collectionId) {
              return {
                $url: function $url(url) {
                  return {
                    pathname: '/[channel]/[locale]/products/[collectionId]',
                    query: {
                      channel: channel,
                      locale: locale,
                      collectionId: collectionId
                    },
                    hash: url === null || url === void 0 ? void 0 : url.hash
                  };
                }
              };
            },
            $url: function $url(url) {
              return {
                pathname: '/[channel]/[locale]/products',
                query: {
                  channel: channel,
                  locale: locale
                },
                hash: url === null || url === void 0 ? void 0 : url.hash
              };
            }
          },
          "register": {
            "success": {
              $url: function $url(url) {
                return {
                  pathname: '/[channel]/[locale]/register/success',
                  query: {
                    channel: channel,
                    locale: locale
                  },
                  hash: url === null || url === void 0 ? void 0 : url.hash
                };
              }
            },
            $url: function $url(url) {
              return {
                pathname: '/[channel]/[locale]/register',
                query: {
                  channel: channel,
                  locale: locale
                },
                hash: url === null || url === void 0 ? void 0 : url.hash
              };
            }
          },
          "restore": {
            $url: function $url(url) {
              return {
                pathname: '/[channel]/[locale]/restore',
                query: {
                  channel: channel,
                  locale: locale
                },
                hash: url === null || url === void 0 ? void 0 : url.hash
              };
            }
          },
          "reviews": {
            $url: function $url(url) {
              return {
                pathname: '/[channel]/[locale]/reviews',
                query: {
                  channel: channel,
                  locale: locale
                },
                hash: url === null || url === void 0 ? void 0 : url.hash
              };
            }
          },
          "set_password": {
            $url: function $url(url) {
              return {
                pathname: '/[channel]/[locale]/set-password',
                query: {
                  channel: channel,
                  locale: locale
                },
                hash: url === null || url === void 0 ? void 0 : url.hash
              };
            }
          },
          $url: function $url(url) {
            return {
              pathname: '/[channel]/[locale]',
              query: {
                channel: channel,
                locale: locale
              },
              hash: url === null || url === void 0 ? void 0 : url.hash
            };
          }
        };
      }
    };
  },
  "coming_soon": {
    $url: function $url(url) {
      return {
        pathname: '/coming-soon',
        hash: url === null || url === void 0 ? void 0 : url.hash
      };
    }
  },
  $url: function $url(url) {
    return {
      pathname: '/',
      hash: url === null || url === void 0 ? void 0 : url.hash
    };
  }
};