import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../libraries/stitches.library.ts";
var styles = {
  dialog: _css(_objectSpread({}, {
    "position": "relative",
    "zIndex": "35"
  })),
  background: _css(_objectSpread({}, {
    "position": "fixed",
    "inset": "0px",
    "--tw-bg-opacity": "0.5",
    "backgroundColor": "hsl(0 0% 100% / var(--tw-bg-opacity))",
    "transitionProperty": "opacity",
    "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "transitionDuration": "150ms"
  })),
  wrapper: _css(_objectSpread({}, {
    "position": "fixed",
    "inset": "0px",
    "zIndex": "40",
    "display": "flex",
    "alignItems": "center",
    "padding": "1rem",
    "backdropFilter": "var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)",
    "@media (min-width: 640px)": {
      "padding": "1.5rem"
    },
    "@media (min-width: 1024px)": {
      "padding": "5rem"
    }
  })),
  panel: _css(_objectSpread(_objectSpread({}, {
    "position": "relative",
    "marginLeft": "auto",
    "marginRight": "auto",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
    "overflow": "hidden",
    "borderRadius": "0.5rem",
    "--tw-shadow": "0 25px 50px -12px rgb(0 0 0 / 0.25)",
    "--tw-shadow-colored": "0 25px 50px -12px var(--tw-shadow-color)",
    "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
    "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
    "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
    "--tw-ring-opacity": "0.1",
    "--tw-ring-color": "hsl(359 4% 25% / var(--tw-ring-opacity))",
    "transitionProperty": "all",
    "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "transitionDuration": "150ms"
  }), {}, {
    height: "calc(100% - 2rem)",
    maxWidth: "1000px",
    flex: "1",
    "@media screen and (min-height: 680px)": {
      height: "600px"
    },
    "@lg": {
      maxHeight: "500px"
    }
  })),
  panelContent: _css(_objectSpread(_objectSpread({}, {
    "height": "100%"
  }), {}, {
    "& ::-webkit-scrollbar": _css({
      width: "10px"
    }),
    "& > ::-webkit-scrollbar-track": _css({
      backgroundColor: "#f1f1f1 !importatn"
    }),
    "::-webkit-scrollbar-thumb": _css({
      backgroundColor: "#888"
    }),
    "::-webkit-scrollbar-thumb:hover": _css({
      backgroundColor: "#555"
    }),
    "& scrollbar-width": "thin",
    "& scrollbar-color": "#888 #f1f1f1"
  }))
};
export default styles;