import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from "react";

var SvgTikTok = function SvgTikTok(props) {
  return __jsx("svg", _extends({
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("path", {
    d: "M17.658 6.84956C17.5365 6.78677 17.4182 6.71794 17.3036 6.64332C16.9704 6.423 16.6648 6.1634 16.3935 5.87011C15.7148 5.09352 15.4613 4.30568 15.368 3.75409H15.3717C15.2937 3.29624 15.326 3 15.3308 3H12.2395V14.9537C12.2395 15.1141 12.2395 15.2728 12.2328 15.4295C12.2328 15.449 12.2309 15.467 12.2298 15.488C12.2298 15.4966 12.2298 15.5056 12.2279 15.5146C12.2279 15.5169 12.2279 15.5191 12.2279 15.5214C12.1953 15.9503 12.0578 16.3646 11.8275 16.7279C11.5972 17.0912 11.2812 17.3923 10.9072 17.6048C10.5174 17.8265 10.0766 17.9428 9.62814 17.9423C8.18783 17.9423 7.02052 16.7678 7.02052 15.3174C7.02052 13.867 8.18783 12.6925 9.62814 12.6925C9.90078 12.6923 10.1717 12.7352 10.431 12.8196L10.4347 9.67204C9.64777 9.57039 8.84829 9.63293 8.08673 9.85572C7.32516 10.0785 6.61803 10.4567 6.00994 10.9665C5.47712 11.4294 5.02917 11.9818 4.68626 12.5988C4.55576 12.8238 4.06341 13.7278 4.00379 15.1951C3.96629 16.028 4.21641 16.8908 4.33565 17.2474V17.2549C4.41065 17.4649 4.70126 18.1815 5.17486 18.7856C5.55675 19.2702 6.00795 19.6958 6.51392 20.0489V20.0414L6.52142 20.0489C8.01797 21.0658 9.67726 20.9991 9.67726 20.9991C9.96449 20.9875 10.9267 20.9991 12.0194 20.4813C13.2313 19.9072 13.9213 19.0518 13.9213 19.0518C14.3621 18.5408 14.7126 17.9583 14.9577 17.3295C15.2375 16.5942 15.3308 15.7122 15.3308 15.3598V9.01807C15.3683 9.04057 15.8678 9.37093 15.8678 9.37093C15.8678 9.37093 16.5874 9.83216 17.7101 10.1325C18.5156 10.3463 19.6008 10.3913 19.6008 10.3913V7.32241C19.2205 7.36366 18.4484 7.24366 17.658 6.84956Z",
    fill: "white"
  }));
};

export default SvgTikTok;