import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread(_objectSpread({}, {
    "display": "block",
    "borderBottomWidth": "1px",
    "borderTopWidth": "1px"
  }), {}, {
    borderColor: "$gray2"
  })),
  progress: _css(_objectSpread(_objectSpread({}, {
    "position": "absolute",
    "bottom": "0px",
    "left": "0px",
    "transitionProperty": "width",
    "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "transitionDuration": "500ms",
    "animationDuration": "500ms",
    "animationTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)"
  }), {}, {
    "& hr": _objectSpread(_objectSpread({}, {
      "width": "100%",
      "borderTopWidth": "2px"
    }), {}, {
      borderColor: "$default"
    })
  })),
  nav: _css(_objectSpread(_objectSpread({}, {
    "scrollbarWidth": "none",
    "MsOverflowStyle": "none",
    "&::-webkit-scrollbar": {
      "display": "none"
    },
    "display": "flex",
    "width": "100%",
    "flexWrap": "nowrap",
    "alignItems": "center",
    "overflowX": "scroll",
    "scrollBehavior": "smooth"
  }), {}, {
    background: "$white",
    "@md": _objectSpread({}, {
      "justifyContent": "center",
      "overflowX": "hidden"
    })
  })),
  list: _css(_objectSpread(_objectSpread({}, {
    "display": "flex",
    "alignItems": "center",
    "> :not([hidden]) ~ :not([hidden])": {
      "--tw-space-x-reverse": "0",
      "marginRight": "calc(0.5rem * var(--tw-space-x-reverse))",
      "marginLeft": "calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))"
    }
  }), {}, {
    "@sm": _objectSpread({}, {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": "0",
        "marginRight": "calc(0.25rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(0.25rem * calc(1 - var(--tw-space-x-reverse)))"
      }
    })
  })),
  element: _css(_objectSpread(_objectSpread({}, {
    "pointerEvents": "none",
    "display": "flex",
    "cursor": "default",
    "alignItems": "center",
    "paddingTop": "0.75rem",
    "paddingBottom": "0.75rem"
  }), {}, {
    "@sm": _objectSpread({}, {
      "paddingLeft": "0.5rem",
      "paddingRight": "0.5rem",
      "paddingTop": "1rem",
      "paddingBottom": "1rem"
    }),
    variants: {
      disabled: {
        "true": _objectSpread({}, {
          "pointerEvents": "none",
          "cursor": "default"
        })
      },
      last: {
        "true": _objectSpread(_objectSpread({}, {
          "marginRight": "1.5rem"
        }), {}, {
          "@md": _objectSpread({}, {
            "marginRight": "0px"
          })
        })
      },
      first: {
        "true": _objectSpread(_objectSpread({}, {
          "marginLeft": "1.5rem"
        }), {}, {
          "@md": _objectSpread({}, {
            "marginLeft": "0px"
          })
        })
      }
    }
  })),
  button: _css(_objectSpread(_objectSpread({}, {
    "marginLeft": "0.5rem",
    "display": "inline-flex",
    "gap": "0.5rem",
    "whiteSpace": "nowrap",
    "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
    "transitionTimingFunction": "linear",
    "transitionDuration": "100ms",
    "animationDuration": "100ms",
    "animationTimingFunction": "linear"
  }), {}, {
    outline: "none",
    fontSize: "$2",
    "@md": {
      fontSize: "$3"
    },
    "@sm": _objectSpread({}, {
      "marginLeft": "1rem"
    }),
    "&:hover": {
      color: "$default"
    },
    variants: {
      active: {
        "true": {
          color: "$dark"
        }
      },
      disabled: {
        "true": {
          color: "$gray5"
        }
      }
    }
  })),
  icon: _css(_objectSpread(_objectSpread({}, {
    "height": "1rem",
    "width": "1rem",
    "flexShrink": "0"
  }), {}, {
    "@sm": _objectSpread({}, {
      "height": "1.25rem",
      "width": "1.25rem"
    }),
    color: "$gray5"
  })),
  badge: _css(_objectSpread(_objectSpread({}, {
    "display": "flex",
    "height": "1.25rem",
    "width": "1.25rem",
    "alignItems": "center",
    "justifyContent": "center",
    "borderRadius": "9999px",
    "fontSize": "0.75rem",
    "lineHeight": "1rem",
    "fontWeight": "700"
  }), {}, {
    background: "$gray2",
    color: "$white",
    "@sm": _objectSpread({}, {
      "height": "1.5rem",
      "width": "1.5rem",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem"
    }),
    variants: {
      active: {
        "true": {
          background: "linear-gradient(180deg, #CF3161 -15.75%, #DB7991 100%)",
          color: "$white"
        }
      },
      completed: {
        "true": {
          background: "$default",
          color: "$white"
        }
      },
      disabled: {
        "true": {
          background: "$gray2",
          color: "$white"
        }
      }
    }
  }))
};
export default styles;