export var isServer = typeof window === "undefined";
export var isAnalyzing = process.env.ANALYZE === "true";
export var isDev = process.env.NODE_ENV === "development";
export var isProd = process.env.NODE_ENV === "production";
export var isEnvStaging = process.env.NEXT_PUBLIC_ENV_STAGING === "true";
export function cleanEmail(email) {
  return email.trim().toLowerCase();
}
export function cleanName(name) {
  var words = name.split(/\s+/);
  var capitalized = words.map(function (word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return capitalized.join(" ").replace(/[^a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ\u00C0-\u024F]+$/, "");
}
export function getGenderByFirstName(firstName) {
  var gender = "female";

  if (firstName && firstName.length && !cleanName(firstName).endsWith("a")) {
    gender = "male";
  }

  return gender;
}
export var copyToClipboard = function copyToClipboard(text, callback) {
  if (!isServer) {
    try {
      window.navigator.clipboard.writeText(text);

      if (callback) {
        callback();
      }
    } catch (e) {
      console.error("Failed to copy to clipboard", e);
    }
  }
};