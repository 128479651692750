var __jsx = React.createElement;
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import React, { Fragment } from "react";
import { useIntl } from "react-intl";
import { getViewLabel } from "@/checkout/checkout.helpers";
import useCheckout from "@/hooks/useCheckout.hook";
import useInterface from "@/hooks/useInterface.hook";
import styles from "./Progress.styles";

var Progress = function Progress() {
  var _checkout$isShippingR;

  var t = useIntl();
  var elementsRef = React.useRef([]);
  var navRef = React.useRef(null);

  var _useCheckout = useCheckout(),
      checkout = _useCheckout.checkout;

  var isShippingRequired = (_checkout$isShippingR = checkout === null || checkout === void 0 ? void 0 : checkout.isShippingRequired) !== null && _checkout$isShippingR !== void 0 ? _checkout$isShippingR : true;

  var _useInterface = useInterface(),
      _useInterface$checkou = _useInterface.checkout,
      active = _useInterface$checkou.active,
      views = _useInterface$checkou.views,
      setCheckoutView = _useInterface.setCheckoutView;

  var handleClick = function handleClick(view) {
    setCheckoutView(view);
  };

  React.useEffect(function () {
    var nav = navRef.current;
    var elements = elementsRef.current;

    if (nav && elements.length > 0) {
      var element = elements[active.index === null ? 0 : active.index];
      nav.scrollLeft = (element === null || element === void 0 ? void 0 : element.offsetLeft) - ((nav === null || nav === void 0 ? void 0 : nav.offsetWidth) - (element === null || element === void 0 ? void 0 : element.offsetWidth)) / 2;
    }
  }, [active]);
  return __jsx(Fragment, null, __jsx("div", {
    className: styles.root()
  }, __jsx("nav", {
    ref: navRef,
    className: styles.nav()
  }, __jsx("ol", {
    role: "list",
    className: styles.list()
  }, views.filter(function (v) {
    return v.index !== null;
  }).map(function (v, i, a) {
    return __jsx("li", {
      key: v.view
    }, __jsx("div", {
      ref: function ref(el) {
        if (el) {
          elementsRef.current[i] = el;
        }
      },
      className: styles.element({
        first: i === 0,
        last: i === a.length - 1,
        disabled: v.disabled
      })
    }, i !== 0 ? __jsx(ChevronRightIcon, {
      className: styles.icon(),
      "aria-hidden": "true"
    }) : null, __jsx("button", {
      className: styles.button({
        active: active.view === v.view,
        disabled: v.disabled
      }),
      onClick: function onClick() {
        return handleClick(v.view);
      }
    }, __jsx("span", {
      className: styles.badge({
        active: active.view === v.view,
        completed: active.index !== null && v.index !== null && active.view !== v.view && active.index >= v.index,
        disabled: v.disabled
      })
    }, i + 1), getViewLabel(t, v.view, isShippingRequired))));
  }))), __jsx("div", {
    className: styles.progress(),
    style: {
      width: "".concat(active.index === null ? 100 : Math.round((active.index + 1) / views.length * 100), "%")
    }
  }, __jsx("hr", null))));
};

Progress.displayName = "Progress";
export default Progress;