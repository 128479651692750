import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from "react";

var SvgChevronLeft = function SvgChevronLeft(props) {
  return __jsx("svg", _extends({
    width: 8,
    height: 13,
    viewBox: "0 0 8 13",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("path", {
    d: "M7 12L1 6.5L7 1",
    stroke: "#423D3D",
    strokeWidth: 1.3,
    strokeLinecap: "square"
  }));
};

export default SvgChevronLeft;