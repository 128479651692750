import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["label"];
var __jsx = React.createElement;
import { Menu as Source } from "@headlessui/react";
import React from "react";
import styles from "./Menu.styles";

var Item = function Item(_ref) {
  var label = _ref.label,
      rest = _objectWithoutProperties(_ref, _excluded);

  return __jsx(Source.Item, _extends({
    key: label
  }, rest), function (_ref2) {
    var active = _ref2.active;
    return __jsx("button", {
      "aria-label": label,
      className: styles.item({
        active: active
      })
    }, label);
  });
};

Item.displayName = "Item";
export default Item;