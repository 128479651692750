import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

var _messages;

import { LOCALE } from "@/config/region.config";
import enUS from "@/locale/en-US.json";
import plPL from "@/locale/pl-PL.json";
export function translate(obj, key) {
  var _obj$translation;

  var result = ((_obj$translation = obj.translation) === null || _obj$translation === void 0 ? void 0 : _obj$translation[key]) || obj[key];
  return result;
}
export var messages = (_messages = {}, _defineProperty(_messages, LOCALE.plPL, plPL), _defineProperty(_messages, LOCALE.enUS, enUS), _messages);