import { useCallback, useEffect, useState } from "react";
export function useLocalStorage(key, initialValue) {
  var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
      sync = _ref.sync;

  var _useState = useState(function () {
    var result;

    try {
      var item = window.localStorage.getItem(key);
      result = item ? JSON.parse(item) : initialValue;
    } catch (_unused) {
      result = initialValue;
    }

    return result;
  }),
      storedValue = _useState[0],
      setStoredValue = _useState[1];

  var setValue = function setValue(valueOrCb) {
    setStoredValue(valueOrCb);
    var valueToStore = valueOrCb instanceof Function ? valueOrCb(storedValue) : valueOrCb;

    try {
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (_unused2) {
      console.warn("Could not save ".concat(key, " to localStorage"));
    }
  };

  var onStorage = useCallback(function (event) {
    if (event.key !== key) return;

    try {
      var item = event.newValue;

      if (item) {
        setStoredValue(JSON.parse(item));
      }
    } catch (_unused3) {
      console.warn("Could not update value for ".concat(key));
    }
  }, [key]);
  useEffect(function () {
    if (sync) {
      window.addEventListener("storage", onStorage);
      return function () {
        window.removeEventListener("storage", onStorage);
      };
    }

    return undefined;
  }, [onStorage, sync]);
  return [storedValue, setValue];
}