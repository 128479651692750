import isNumber from "lodash/isNumber";
import React, { useCallback, useMemo } from "react";
import { PRODUCT_BENEFITS_ATTRIBUTE, PRODUCT_VARIANT_HIGHLIGHTED_DESCRIPTION_ATTRIBUTE, PRODUCT_VARIANT_PRICE_HISTORY_ATTRIBUTE, PRODUCT_VARIANT_SHOW_DELIVERY_TIME_METHOD_ATTRIBUTE, PRODUCT_VARIANT_VOLUME_ATTRIBUTE, PRODUCTS_CATEGORY_ADITIONS_OF_SETS } from "@/config/common.config";
import { getAttribute } from "@/helpers/attribute.helper";
import useRegion from "@/hooks/useRegion.hook";
import { useProductListVariant } from "@/products/hooks/useProductListVariant.hook";
import { isHiddenOnStorefront } from "~/src/helpers/product.helpers";
export function useProductVariant(productVariant) {
  var _productVariant$produ, _productVariant$stock, _productVariant$produ2, _getAttribute4, _getAttribute5, _productVariant$produ3;

  var _useProductListVarian = useProductListVariant(productVariant),
      name = _useProductListVarian.name,
      variantName = _useProductListVarian.variantName,
      isGiftCard = _useProductListVarian.isGiftCard,
      isAvailableForPurchase = _useProductListVarian.isAvailableForPurchase,
      _useProductListVarian2 = _useProductListVarian.media,
      media512Url = _useProductListVarian2.media512Url,
      media128Url = _useProductListVarian2.media128Url,
      availableQuantity = _useProductListVarian.availableQuantity,
      priceUndiscounted = _useProductListVarian.priceUndiscounted,
      price = _useProductListVarian.price,
      volume = _useProductListVarian.volume,
      shortDescription = _useProductListVarian.shortDescription,
      clientReviews = _useProductListVarian.clientReviews,
      href = _useProductListVarian.href,
      isLowPriceZoneVariant = _useProductListVarian.isLowPriceZoneVariant,
      addToCart = _useProductListVarian.addToCart;

  var _useRegion = useRegion(),
      currentChannel = _useRegion.currentChannel;

  var isVariantsSet = !!(productVariant !== null && productVariant !== void 0 && (_productVariant$produ = productVariant.product) !== null && _productVariant$produ !== void 0 && (_productVariant$produ = _productVariant$produ.variantsInSet) !== null && _productVariant$produ !== void 0 && _productVariant$produ.length);
  var isShippingRequired = !!(productVariant !== null && productVariant !== void 0 && productVariant.product.productType.isShippingRequired);
  var isDigital = !!(productVariant !== null && productVariant !== void 0 && productVariant.product.productType.isDigital);
  var findMediaUrl = useCallback(function (productMedia) {
    var _productMedia$find;

    return productMedia === null || productMedia === void 0 || (_productMedia$find = productMedia.find(function (m) {
      return m.type === "IMAGE";
    })) === null || _productMedia$find === void 0 ? void 0 : _productMedia$find.url;
  }, []);
  var media32Url = findMediaUrl(productVariant === null || productVariant === void 0 ? void 0 : productVariant.media32);
  var media64Url = findMediaUrl(productVariant === null || productVariant === void 0 ? void 0 : productVariant.media64);
  var media256Url = findMediaUrl(productVariant === null || productVariant === void 0 ? void 0 : productVariant.media256);
  var media1024Url = findMediaUrl(productVariant === null || productVariant === void 0 ? void 0 : productVariant.media1024);
  var volumeIconName = useMemo(function () {
    var _getAttribute;

    var rawVolume = (_getAttribute = getAttribute(PRODUCT_VARIANT_VOLUME_ATTRIBUTE, (productVariant === null || productVariant === void 0 ? void 0 : productVariant.attributes) || [], false)) === null || _getAttribute === void 0 ? void 0 : _getAttribute.value[0];

    if (rawVolume !== null && rawVolume !== void 0 && rawVolume.includes("ml")) {
      return "Droplet";
    }

    if (rawVolume !== null && rawVolume !== void 0 && rawVolume.includes("capsules")) {
      return "Pill";
    }

    if (rawVolume !== null && rawVolume !== void 0 && rawVolume.includes("pln")) {
      return "GiftCard";
    }

    return null;
  }, [productVariant === null || productVariant === void 0 ? void 0 : productVariant.attributes]);
  var variantAvailableQuantity = React.useMemo(function () {
    if (!productVariant) return 0;
    var quantity;

    if (isNumber(productVariant === null || productVariant === void 0 ? void 0 : productVariant.quantityAvailable) && isNumber(productVariant === null || productVariant === void 0 ? void 0 : productVariant.quantityLimitPerCustomer)) {
      if (productVariant.quantityAvailable > productVariant.quantityLimitPerCustomer) {
        quantity = productVariant.quantityLimitPerCustomer;
      } else {
        quantity = productVariant.quantityAvailable;
      }
    } else {
      quantity = (productVariant === null || productVariant === void 0 ? void 0 : productVariant.quantityLimitPerCustomer) || (productVariant === null || productVariant === void 0 ? void 0 : productVariant.quantityAvailable) || 0;
    }

    return quantity;
  }, [productVariant]);
  var variantStockQuantity = productVariant !== null && productVariant !== void 0 && productVariant.stocks ? productVariant === null || productVariant === void 0 || (_productVariant$stock = productVariant.stocks[0]) === null || _productVariant$stock === void 0 ? void 0 : _productVariant$stock.quantity : 0;
  var priceRange = productVariant === null || productVariant === void 0 || (_productVariant$produ2 = productVariant.product.pricing) === null || _productVariant$produ2 === void 0 ? void 0 : _productVariant$produ2.priceRange;
  var priceHistory = React.useMemo(function () {
    var amount = 0;

    if (productVariant) {
      var _getAttribute$value$, _getAttribute2;

      amount = Number((_getAttribute$value$ = (_getAttribute2 = getAttribute(PRODUCT_VARIANT_PRICE_HISTORY_ATTRIBUTE, productVariant.attributes)) === null || _getAttribute2 === void 0 ? void 0 : _getAttribute2.value[0]) !== null && _getAttribute$value$ !== void 0 ? _getAttribute$value$ : 0);
    }

    return {
      __typename: "Money",
      amount: amount,
      currency: currentChannel.currencyCode
    };
  }, [currentChannel, productVariant]);
  var showDeliveryTime = React.useMemo(function () {
    if (productVariant) {
      var _getAttribute3;

      return !!((_getAttribute3 = getAttribute(PRODUCT_VARIANT_SHOW_DELIVERY_TIME_METHOD_ATTRIBUTE, productVariant.attributes)) !== null && _getAttribute3 !== void 0 && _getAttribute3.value[0]);
    }

    return false;
  }, [productVariant]);
  var benefits = (_getAttribute4 = getAttribute(PRODUCT_BENEFITS_ATTRIBUTE, (productVariant === null || productVariant === void 0 ? void 0 : productVariant.product.attributes) || [])) === null || _getAttribute4 === void 0 ? void 0 : _getAttribute4.value;
  var highligtedDescription = (_getAttribute5 = getAttribute(PRODUCT_VARIANT_HIGHLIGHTED_DESCRIPTION_ATTRIBUTE, (productVariant === null || productVariant === void 0 ? void 0 : productVariant.attributes) || [])) === null || _getAttribute5 === void 0 ? void 0 : _getAttribute5.value[0];
  var hiddenOnStorefront = productVariant !== null && productVariant !== void 0 && productVariant.product ? isHiddenOnStorefront(productVariant === null || productVariant === void 0 ? void 0 : productVariant.product) : true;
  var isAdditionOfSet = (productVariant === null || productVariant === void 0 || (_productVariant$produ3 = productVariant.product) === null || _productVariant$produ3 === void 0 || (_productVariant$produ3 = _productVariant$produ3.category) === null || _productVariant$produ3 === void 0 ? void 0 : _productVariant$produ3.slug) === PRODUCTS_CATEGORY_ADITIONS_OF_SETS || false;
  return {
    name: name,
    variantName: variantName,
    isVariantsSet: isVariantsSet,
    isGiftCard: isGiftCard,
    isShippingRequired: isShippingRequired,
    isDigital: isDigital,
    isAvailableForPurchase: isAvailableForPurchase,
    media: {
      media32Url: media32Url,
      media64Url: media64Url,
      media128Url: media128Url,
      media256Url: media256Url,
      media512Url: media512Url,
      media1024Url: media1024Url
    },
    variantAvailableQuantity: variantAvailableQuantity,
    variantStockQuantity: variantStockQuantity,
    availableQuantity: availableQuantity,
    priceUndiscounted: priceUndiscounted,
    price: price,
    priceRange: priceRange,
    priceHistory: priceHistory,
    volume: volume,
    volumeIconName: volumeIconName,
    showDeliveryTime: showDeliveryTime,
    shortDescription: shortDescription,
    clientReviews: clientReviews,
    benefits: benefits,
    highligtedDescription: highligtedDescription,
    isLowPriceZoneVariant: isLowPriceZoneVariant,
    href: href,
    hiddenOnStorefront: hiddenOnStorefront,
    isAdditionOfSet: isAdditionOfSet,
    addToCart: addToCart
  };
}