var __jsx = React.createElement;
import Link from "next/link";
import React from "react";
import { Text } from "@/components/common";
import { Icon } from "@/components/ui";
import { PRODUCT_SLOGAN_ATTRIBUTE } from "@/config/common.config";
import { getAttribute } from "@/helpers/attribute.helper";
import { up } from "@/helpers/screens.helpers";
import { scrollIntoView } from "@/helpers/scroll.helper";
import { translate } from "@/helpers/translation.helpers";
import { useMediaQuery } from "@/hooks/useMediaQuery.hook";
import { AverageRating } from "@/product/components";
import { ScreenEnum } from "@/theme/theme.enums";
import { parseJsonString } from "~/src/components/common/Text/Text.helpers";
import { useProductVariant } from "~/src/modules/product/hooks/useProductVariant.hook";
import styles from "./Header.styles";

var Header = function Header(_ref) {
  var _getAttribute;

  var product = _ref.product,
      activeVariant = _ref.activeVariant,
      pages = _ref.pages,
      link = _ref.link,
      ratingClickAction = _ref.ratingClickAction,
      testIdPrefix = _ref.testIdPrefix;

  var _useProductVariant = useProductVariant(activeVariant),
      volumeIconName = _useProductVariant.volumeIconName,
      volume = _useProductVariant.volume,
      highligtedDescription = _useProductVariant.highligtedDescription;

  var isUpMd = useMediaQuery(up(ScreenEnum.md), false);
  var name = translate(product, "name");
  var slogan = (_getAttribute = getAttribute(PRODUCT_SLOGAN_ATTRIBUTE, product.attributes)) === null || _getAttribute === void 0 ? void 0 : _getAttribute.value[0];

  var defaultRatingClickAction = function defaultRatingClickAction() {
    scrollIntoView("__product-customers", {
      block: isUpMd ? "center" : "end"
    });
  };

  var renderName = function renderName(children, href) {
    return href ? __jsx(Link, {
      href: href
    }, children) : children;
  };

  return __jsx("div", {
    className: styles.root()
  }, renderName(__jsx("h1", {
    id: "__product-name",
    "data-product-name": name,
    "data-variant-name": volume ? "".concat(name, " ").concat(volume) : activeVariant === null || activeVariant === void 0 ? void 0 : activeVariant.name,
    className: styles.name({
      link: !!link
    }),
    "data-testid": "".concat(testIdPrefix !== null && testIdPrefix !== void 0 ? testIdPrefix : "", "header-name")
  }, name), link), __jsx(AverageRating, {
    pages: pages,
    iconSize: isUpMd ? 24 : 16,
    clickAction: ratingClickAction || defaultRatingClickAction
  }), typeof highligtedDescription === "string" && parseJsonString(highligtedDescription) ? __jsx("div", {
    className: styles.highligtedDescription()
  }, __jsx(Text, {
    text: highligtedDescription
  })) : null, parseJsonString(slogan) ? __jsx("div", {
    className: styles.slogan()
  }, __jsx(Text, {
    text: slogan
  })) : null, volume ? __jsx("div", {
    className: styles.volume()
  }, volumeIconName ? __jsx(Icon, {
    name: volumeIconName,
    className: styles.volumeIcon()
  }) : null, __jsx("span", null, volume)) : null);
};

export default Header;