import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["size"];
var __jsx = React.createElement;
import * as React from "react";

var ImagePlaceholder = function ImagePlaceholder(_ref) {
  var _ref$size = _ref.size,
      size = _ref$size === void 0 ? "md" : _ref$size,
      rest = _objectWithoutProperties(_ref, _excluded);

  var getViewBox = function getViewBox(viewBoxSize) {
    switch (viewBoxSize) {
      case "sm":
        return "0 0 1000 1000";

      case "md":
        return "0 0 800 800";

      case "lg":
        return "0 0 600 600";

      case "xl":
        return "0 0 500 500";
    }
  };

  var getTranslate = function getTranslate(translateSize) {
    switch (translateSize) {
      case "sm":
        return "-100, -100";

      case "md":
        return "-200, -200";

      case "lg":
        return "-300, -300";

      case "xl":
        return "-350, -350";
    }
  };

  return __jsx("div", {
    style: {
      width: "100%",
      height: "100%",
      backgroundColor: "#efcdd6",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
  }, __jsx("svg", _extends({
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    width: "100%",
    height: "100%",
    viewBox: getViewBox(size)
  }, rest), __jsx("g", {
    opacity: 0.5,
    transform: "translate(".concat(getTranslate(size), ")")
  }, __jsx("g", {
    opacity: 0.5
  }, __jsx("path", {
    fill: "#FAFAFA",
    d: "M600.709 736.5c-75.454 0-136.621-61.167-136.621-136.62 0-75.454 61.167-136.621 136.621-136.621 75.453 0 136.62 61.167 136.62 136.621 0 75.453-61.167 136.62-136.62 136.62Z"
  }), __jsx("path", {
    stroke: "#d46582",
    strokeWidth: 2.418,
    d: "M600.709 736.5c-75.454 0-136.621-61.167-136.621-136.62 0-75.454 61.167-136.621 136.621-136.621 75.453 0 136.62 61.167 136.62 136.621 0 75.453-61.167 136.62-136.62 136.62Z"
  })), __jsx("path", {
    stroke: "url(#a)",
    strokeWidth: 2.418,
    d: "M0-1.209h553.581",
    transform: "scale(1 -1) rotate(45 1163.11 91.165)"
  }), __jsx("path", {
    stroke: "url(#b)",
    strokeWidth: 2.418,
    d: "M404.846 598.671h391.726"
  }), __jsx("path", {
    stroke: "url(#c)",
    strokeWidth: 2.418,
    d: "M599.5 795.742V404.017"
  }), __jsx("path", {
    stroke: "url(#d)",
    strokeWidth: 2.418,
    d: "m795.717 796.597-391.441-391.44"
  }), __jsx("path", {
    fill: "#fff",
    d: "M600.709 656.704c-31.384 0-56.825-25.441-56.825-56.824 0-31.384 25.441-56.825 56.825-56.825 31.383 0 56.824 25.441 56.824 56.825 0 31.383-25.441 56.824-56.824 56.824Z"
  }), __jsx("g", {
    clipPath: "url(#e)"
  }, __jsx("path", {
    fill: "#c24264",
    fillRule: "evenodd",
    d: "M616.426 586.58h-31.434v16.176l3.553-3.554.531-.531h9.068l.074-.074 8.463-8.463h2.565l7.18 7.181V586.58Zm-15.715 14.654 3.698 3.699 1.283 1.282-2.565 2.565-1.282-1.283-5.2-5.199h-6.066l-5.514 5.514-.073.073v2.876a2.418 2.418 0 0 0 2.418 2.418h26.598a2.418 2.418 0 0 0 2.418-2.418v-8.317l-8.463-8.463-7.181 7.181-.071.072Zm-19.347 5.442v4.085a6.045 6.045 0 0 0 6.046 6.045h26.598a6.044 6.044 0 0 0 6.045-6.045v-7.108l1.356-1.355-1.282-1.283-.074-.073v-17.989h-38.689v23.43l-.146.146.146.147Z",
    clipRule: "evenodd"
  })), __jsx("path", {
    stroke: "#c24264",
    strokeWidth: 2.418,
    d: "M600.709 656.704c-31.384 0-56.825-25.441-56.825-56.824 0-31.384 25.441-56.825 56.825-56.825 31.383 0 56.824 25.441 56.824 56.825 0 31.383-25.441 56.824-56.824 56.824Z"
  })), __jsx("defs", null, __jsx("linearGradient", {
    id: "a",
    x1: 554.061,
    x2: -0.48,
    y1: 0.083,
    y2: 0.087,
    gradientUnits: "userSpaceOnUse"
  }, __jsx("stop", {
    stopColor: "#d46582",
    stopOpacity: 0
  }), __jsx("stop", {
    offset: 0.208,
    stopColor: "#d46582"
  }), __jsx("stop", {
    offset: 0.792,
    stopColor: "#d46582"
  }), __jsx("stop", {
    offset: 1,
    stopColor: "#d46582",
    stopOpacity: 0
  })), __jsx("linearGradient", {
    id: "b",
    x1: 796.912,
    x2: 404.507,
    y1: 599.963,
    y2: 599.965,
    gradientUnits: "userSpaceOnUse"
  }, __jsx("stop", {
    stopColor: "#d46582",
    stopOpacity: 0
  }), __jsx("stop", {
    offset: 0.208,
    stopColor: "#d46582"
  }), __jsx("stop", {
    offset: 0.792,
    stopColor: "#d46582"
  }), __jsx("stop", {
    offset: 1,
    stopColor: "#d46582",
    stopOpacity: 0
  })), __jsx("linearGradient", {
    id: "c",
    x1: 600.792,
    x2: 600.794,
    y1: 403.677,
    y2: 796.082,
    gradientUnits: "userSpaceOnUse"
  }, __jsx("stop", {
    stopColor: "#d46582",
    stopOpacity: 0
  }), __jsx("stop", {
    offset: 0.208,
    stopColor: "#d46582"
  }), __jsx("stop", {
    offset: 0.792,
    stopColor: "#d46582"
  }), __jsx("stop", {
    offset: 1,
    stopColor: "#d46582",
    stopOpacity: 0
  })), __jsx("linearGradient", {
    id: "d",
    x1: 404.85,
    x2: 796.972,
    y1: 403.903,
    y2: 796.02,
    gradientUnits: "userSpaceOnUse"
  }, __jsx("stop", {
    stopColor: "#d46582",
    stopOpacity: 0
  }), __jsx("stop", {
    offset: 0.208,
    stopColor: "#d46582"
  }), __jsx("stop", {
    offset: 0.792,
    stopColor: "#d46582"
  }), __jsx("stop", {
    offset: 1,
    stopColor: "#d46582",
    stopOpacity: 0
  })), __jsx("clipPath", {
    id: "e"
  }, __jsx("path", {
    fill: "#fff",
    d: "M581.364 580.535h38.689v38.689h-38.689z"
  })))));
};

ImagePlaceholder.displayName = "ImagePlaceholder";
export default ImagePlaceholder;