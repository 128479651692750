import _slicedToArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import React from "react";
var __jsx = React.createElement;
import { useRouter } from "next/router";
import { useState } from "react";
import { IntlProvider } from "react-intl";
import { CHANNELS, DEFAULT_CHANNEL, DEFAULT_LOCALE // LOCALE
} from "@/config/region.config";
import { setLocale as setDatesLocale } from "@/libraries/dayjs.library";
import createContext from "@/libraries/react.library";
import { formatAsMoney, importMessages, localeToEnum } from "./region.helpers";

var _createContext = createContext(),
    _createContext2 = _slicedToArray(_createContext, 2),
    useContext = _createContext2[0],
    Provider = _createContext2[1];

export { useContext, Provider };
export var Region = function Region(_ref) {
  var children = _ref.children;
  var router = useRouter();

  var _useState = useState(router.query.channel),
      currentChannelSlug = _useState[0],
      setCurrentChannelSlug = _useState[1];

  var setCurrentChannel = function setCurrentChannel(channel) {
    setCurrentChannelSlug(channel);
  };

  var locale = // (router.query.locale?.toString() as LOCALE) ||
  DEFAULT_LOCALE;
  var currentChannel = CHANNELS.find(function (_ref2) {
    var slug = _ref2.slug;
    return slug === currentChannelSlug;
  }) || DEFAULT_CHANNEL;

  var formatPrice = function formatPrice(price, options) {
    return formatAsMoney((price === null || price === void 0 ? void 0 : price.amount) || 0, (price === null || price === void 0 ? void 0 : price.currency) || currentChannel.currencyCode, locale, options);
  };

  var providerValues = {
    channels: CHANNELS,
    defaultChannel: DEFAULT_CHANNEL,
    currentChannel: currentChannel,
    setCurrentChannel: setCurrentChannel,
    currentLocale: locale,
    query: {
      channel: currentChannel.slug,
      locale: localeToEnum(locale)
    },
    formatPrice: formatPrice
  };
  var msgs = importMessages(locale);
  setDatesLocale(locale);
  return __jsx(Provider, {
    value: providerValues
  }, __jsx(IntlProvider, {
    messages: msgs,
    locale: locale,
    defaultLocale: DEFAULT_LOCALE
  }, children));
};