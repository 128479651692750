var __jsx = React.createElement;
import DOMPurify from "isomorphic-dompurify";
import React from "react";
import { Image, Text } from "@/components/common";
import styles from "./Expert.styles";

var Item = function Item(_ref) {
  var photo = _ref.photo,
      name = _ref.name,
      description = _ref.description,
      storefront = _ref.storefront,
      reversed = _ref.reversed,
      _ref$font = _ref.font,
      font = _ref$font === void 0 ? "tenor" : _ref$font,
      _ref$objectFit = _ref.objectFit,
      objectFit = _ref$objectFit === void 0 ? "cover" : _ref$objectFit,
      _ref$objectPosition = _ref.objectPosition,
      objectPosition = _ref$objectPosition === void 0 ? "center" : _ref$objectPosition;
  return __jsx("div", {
    className: styles.itemRoot()
  }, __jsx("div", {
    className: styles.imageContainer()
  }, __jsx(Image, {
    src: photo,
    alt: name,
    placeholder: "blur",
    fill: true,
    quality: 100,
    sizes: "(max-width: 768px) 100vw, 50vw",
    style: {
      objectFit: objectFit,
      objectPosition: objectPosition
    }
  })), __jsx("div", {
    className: styles.infoContainer({
      reversed: reversed
    })
  }, __jsx("h4", {
    className: styles.name({
      font: font
    }),
    dangerouslySetInnerHTML: {
      __html: DOMPurify.sanitize(name)
    }
  }), __jsx("div", {
    className: styles.description()
  }, storefront ? __jsx("p", {
    dangerouslySetInnerHTML: {
      __html: DOMPurify.sanitize(description)
    }
  }) : __jsx(Text, {
    text: description
  }))));
};

Item.displayName = "ExpertItem";
export default Item;