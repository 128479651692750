var __jsx = React.createElement;
import { styled as _styled } from "../../../libraries/stitches.library.ts";
import React from "react";
import { cn } from "@/libraries/clsx.library";
import styles from "./Line.styles";

var _TwComponent = _styled("div", {});

var Line = function Line(_ref) {
  var className = _ref.className,
      containerHeight = _ref.containerHeight,
      label = _ref.label,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? "light" : _ref$color,
      _ref$width = _ref.width,
      width = _ref$width === void 0 ? "100%" : _ref$width;
  return __jsx(_TwComponent, {
    css: styles.root({
      height: containerHeight
    })
  }, __jsx("div", {
    className: cn(styles.line({
      color: color
    }), className === null || className === void 0 ? void 0 : className.lineContainer)
  }, __jsx("hr", {
    style: {
      width: width
    }
  })), label && __jsx("div", {
    className: cn(styles.label(), className === null || className === void 0 ? void 0 : className.label)
  }, __jsx("span", null, label)));
};

Line.displayName = "Line";
export default Line;