import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _toConsumableArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
var __jsx = React.createElement;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { Fragment } from "react";
import { Text } from "@/components/common";
import { parseJsonString } from "@/components/common/Text/Text.helpers";
import { Disclosure } from "@/components/ui";
import { PRODUCT_ADDITIONAL_INFO_ATTRIBUTE_GROUP } from "@/config/common.config";
import { getAttribute, getAttributeGroup } from "@/helpers/attribute.helper";
import styles from "./AdditionalInfo.styles";

var AdditionalInfo = function AdditionalInfo(_ref) {
  var product = _ref.product,
      productsInSet = _ref.productsInSet,
      _ref$onlyFirstElement = _ref.onlyFirstElementOpen,
      onlyFirstElementOpen = _ref$onlyFirstElement === void 0 ? true : _ref$onlyFirstElement;
  var additionalInfoData = React.useMemo(function () {
    if (!product) return [];
    var productAdditionalInfoData = getAttributeGroup(PRODUCT_ADDITIONAL_INFO_ATTRIBUTE_GROUP, product.attributes).map(function (attribute) {
      return {
        slug: attribute.slug,
        title: attribute.name,
        content: attribute.value,
        valueType: attribute.valueType
      };
    });

    if (productsInSet) {
      var productSetAdditionalInfoData = productAdditionalInfoData.reduce(function (acc, singleAdditionalInfo) {
        var content = singleAdditionalInfo.content,
            slug = singleAdditionalInfo.slug,
            valueType = singleAdditionalInfo.valueType;

        if (valueType === "RICH_TEXT" && content && typeof content[0] === "string" && parseJsonString(content[0]) || valueType !== "RICH_TEXT" && content.length || !slug) {
          return [].concat(_toConsumableArray(acc), [singleAdditionalInfo]);
        }

        var contentFromProducts = productsInSet.map(function (productSetItem) {
          var _getAttribute;

          var productAttributeValue = (_getAttribute = getAttribute(slug, productSetItem.attributes)) === null || _getAttribute === void 0 ? void 0 : _getAttribute.value;
          return {
            title: productSetItem.name,
            description: productAttributeValue
          };
        }).filter(function (item) {
          return item.description.length > 0;
        });
        return [].concat(_toConsumableArray(acc), [_objectSpread(_objectSpread({}, singleAdditionalInfo), {}, {
          content: contentFromProducts
        })]);
      }, []);
      return productSetAdditionalInfoData;
    }

    return productAdditionalInfoData;
  }, [product, productsInSet]);

  var parseIntoDisclosureData = function parseIntoDisclosureData(data) {
    return data.reduce(function (mainAcc, singleAdditionalInfoData) {
      var filteredValue = singleAdditionalInfoData.content.reduce(function (acc, item) {
        if (typeof item === "string") {
          acc.strings.push(item);
        } else if (item && typeof item === "object") {
          acc.objects.push(item);
        }

        return acc;
      }, {
        strings: [],
        objects: []
      });

      if (filteredValue.strings.length > 0) {
        return [].concat(_toConsumableArray(mainAcc), [{
          title: singleAdditionalInfoData.title,
          content: renderContentData(singleAdditionalInfoData.valueType, filteredValue.strings)
        }]);
      }

      if (filteredValue.objects.length > 0) {
        return [].concat(_toConsumableArray(mainAcc), [{
          title: singleAdditionalInfoData.title,
          content: __jsx(Fragment, null, filteredValue.objects.map(function (filteredValueObject, index) {
            return __jsx("div", {
              className: styles.content(),
              key: "additional-info-item-".concat(index)
            }, __jsx("h4", {
              className: styles.title()
            }, filteredValueObject === null || filteredValueObject === void 0 ? void 0 : filteredValueObject.title), renderContentData(singleAdditionalInfoData.valueType, filteredValueObject === null || filteredValueObject === void 0 ? void 0 : filteredValueObject.description));
          }))
        }]);
      }

      return mainAcc;
    }, []);
  };

  var renderContentData = function renderContentData(valueType, value) {
    switch (valueType) {
      case "DROPDOWN":
      case "MULTISELECT":
        {
          return __jsx("ul", {
            className: styles.list()
          }, value.map(function (valueItem, index) {
            return __jsx("li", {
              key: "additional-info-list-item-".concat(index)
            }, valueItem);
          }));
        }

      case "RICH_TEXT":
        {
          return value[0] && parseJsonString(value[0]) ? __jsx(Text, {
            text: value[0]
          }) : null;
        }

      default:
        return null;
    }
  };

  return __jsx("div", {
    className: styles.root()
  }, __jsx(Disclosure, {
    data: parseIntoDisclosureData(additionalInfoData),
    disclosureProps: {
      onlyFirstOpen: onlyFirstElementOpen
    },
    withoutBottomBorder: true
  }));
};

export default AdditionalInfo;