var __jsx = React.createElement;
import React from "react";
import styles from "./Root.styles";

var Root = function Root(_ref) {
  var children = _ref.children;
  return __jsx("div", {
    id: "__root",
    className: styles.root()
  }, children);
};

Root.displayName = "Root";
export default Root;