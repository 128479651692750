import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../libraries/stitches.library.ts";

var button = _css(_objectSpread(_objectSpread({}, {
  "display": "inline-flex",
  "cursor": "pointer",
  "alignItems": "center",
  "borderWidth": "1px",
  "borderColor": "transparent",
  "textAlign": "center",
  "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "150ms",
  "animationDuration": "150ms",
  "animationTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)"
}), {}, {
  "&:focus": _objectSpread({}, {
    "outline": "2px solid transparent",
    "outlineOffset": "2px"
  }),
  "&:focus-visible": _objectSpread({}, {
    "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
    "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
    "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
    "--tw-ring-opacity": "1",
    "--tw-ring-color": "hsl(344 51% 51% / var(--tw-ring-opacity))",
    "--tw-ring-offset-width": "2px",
    "--tw-ring-offset-color": "hsl(359, 4%, 85%)"
  }),
  variants: {
    color: {
      flavor: {
        backgroundColor: "$default",
        color: "$white",
        "&:hover": {
          backgroundColor: "$dark",
          color: "$white"
        }
      },
      flavorLight: {
        backgroundColor: "$lighten",
        color: "$default",
        "&:hover": {
          backgroundColor: "$light"
        }
      },
      dark: {
        backgroundColor: "$black",
        color: "$white",
        "&:hover": {
          backgroundColor: "$default",
          color: "$white"
        }
      },
      gray: {
        backgroundColor: "$gray2",
        color: "$black",
        "&:hover": {
          backgroundColor: "$default",
          color: "$white"
        }
      },
      light: {
        backgroundColor: "$white",
        color: "$black",
        "&:hover": {
          backgroundColor: "$gray1"
        }
      },
      danger: {
        color: "$danger"
      }
    },
    variant: {
      "default": _objectSpread({}, {
        "paddingLeft": "1.25rem",
        "paddingRight": "1.25rem",
        "paddingTop": "0.75rem",
        "paddingBottom": "0.75rem"
      }),
      rounded: _objectSpread({}, {
        "borderRadius": "0.5rem",
        "paddingLeft": "1.25rem",
        "paddingRight": "1.25rem",
        "paddingTop": "0.75rem",
        "paddingBottom": "0.75rem"
      }),
      oval: _objectSpread({}, {
        "borderRadius": "9999px",
        "paddingLeft": "1.25rem",
        "paddingRight": "1.25rem",
        "paddingTop": "0.75rem",
        "paddingBottom": "0.75rem"
      }),
      slim: _objectSpread({}, {
        "borderRadius": "0.5rem",
        "paddingLeft": "0.75rem",
        "paddingRight": "0.75rem",
        "paddingTop": "0.5rem",
        "paddingBottom": "0.5rem"
      }),
      circle: _objectSpread({}, {
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "center",
        "borderRadius": "9999px",
        "padding": "0.5rem"
      }),
      naked: _objectSpread(_objectSpread({}, {
        "borderWidth": "0px",
        "padding": "0px"
      }), {}, {
        "&:hover": _objectSpread({}, {
          "borderWidth": "0px"
        }),
        "&:focus": _objectSpread({}, {
          "outline": "2px solid transparent",
          "outlineOffset": "2px"
        }),
        "&:focus-visible": _objectSpread({}, {
          "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
          "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
          "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
          "--tw-ring-offset-width": "0px"
        })
      })
    },
    outlined: {
      "true": _objectSpread(_objectSpread({}, {
        "backgroundColor": "transparent"
      }), {}, {
        "&:hover": _objectSpread({}, {
          "backgroundColor": "transparent"
        })
      })
    },
    disabled: {
      "true": _objectSpread(_objectSpread({}, {
        "cursor": "default",
        "--tw-bg-opacity": "0.75"
      }), {}, {
        pointerEvents: "none",
        backgroundColor: "$gray1",
        color: "$gray5",
        "&:hover": {
          backgroundColor: "$gray1",
          color: "$gray5"
        }
      })
    },
    withShadow: {
      "true": _objectSpread({}, {
        "--tw-shadow": "0 1px 2px 0 rgb(0 0 0 / 0.05)",
        "--tw-shadow-colored": "0 1px 2px 0 var(--tw-shadow-color)",
        "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
      })
    }
  },
  compoundVariants: [{
    color: "dark",
    outlined: true,
    css: _objectSpread(_objectSpread({}, {
      "backgroundColor": "transparent"
    }), {}, {
      color: "$black",
      borderColor: "$black",
      "&:hover": {
        backgroundColor: "$lighter",
        color: "$gray8",
        borderColor: "$gray8"
      }
    })
  }, {
    color: "light",
    outlined: true,
    css: _objectSpread(_objectSpread({}, {
      "backgroundColor": "transparent"
    }), {}, {
      color: "$default",
      borderColor: "$default",
      "&:hover": {
        backgroundColor: "$lighter",
        color: "$default",
        borderColor: "$default"
      }
    })
  }, {
    color: "gray",
    disabled: true,
    css: _objectSpread(_objectSpread({}, {
      "cursor": "default",
      "--tw-bg-opacity": "0.75"
    }), {}, {
      backgroundColor: "$gray1",
      color: "$gray5",
      "&:hover": {
        backgroundColor: "$gray1",
        color: "$gray5"
      }
    })
  }, {
    color: "flavor",
    disabled: true,
    css: _objectSpread(_objectSpread({}, {
      "cursor": "default",
      "--tw-bg-opacity": "0.75"
    }), {}, {
      backgroundColor: "$gray1",
      color: "$gray5",
      "&:hover": {
        backgroundColor: "$gray1",
        color: "$gray5"
      },
      "& svg": {
        fill: "$gray5 !important"
      }
    })
  }, {
    color: "flavorLight",
    disabled: true,
    css: _objectSpread(_objectSpread({}, {
      "cursor": "default",
      "--tw-bg-opacity": "0.75"
    }), {}, {
      backgroundColor: "$gray1",
      color: "$gray5",
      "&:hover": {
        backgroundColor: "$gray1",
        color: "$gray5"
      }
    })
  }, {
    color: "dark",
    disabled: true,
    css: _objectSpread(_objectSpread({}, {
      "cursor": "default",
      "--tw-bg-opacity": "0.75"
    }), {}, {
      backgroundColor: "$gray1",
      color: "$gray5",
      "&:hover": {
        backgroundColor: "$gray1",
        color: "$gray5"
      }
    })
  }, {
    withShadow: true,
    variant: "slim",
    css: _objectSpread({}, {
      "--tw-shadow": "0 1px 2px 0 rgb(0 0 0 / 0.05)",
      "--tw-shadow-colored": "0 1px 2px 0 var(--tw-shadow-color)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
    })
  }, {
    disabled: true,
    variant: "naked",
    css: _objectSpread(_objectSpread({}, {
      "backgroundColor": "transparent"
    }), {}, {
      "&:hover": _objectSpread({}, {
        "backgroundColor": "transparent"
      })
    })
  }, {
    color: "flavor",
    variant: "naked",
    css: _objectSpread(_objectSpread({}, {
      "backgroundColor": "transparent"
    }), {}, {
      color: "$default",
      fill: "$default",
      "&:hover": _objectSpread(_objectSpread({}, {
        "backgroundColor": "transparent"
      }), {}, {
        color: "$dark",
        fill: "$dark"
      })
    })
  }, {
    color: "dark",
    variant: "naked",
    css: _objectSpread(_objectSpread({}, {
      "backgroundColor": "transparent"
    }), {}, {
      color: "$black",
      fill: "$black",
      "&:hover": _objectSpread(_objectSpread({}, {
        "backgroundColor": "transparent"
      }), {}, {
        color: "$default",
        fill: "$default"
      })
    })
  }, {
    color: "gray",
    variant: "naked",
    css: _objectSpread(_objectSpread({}, {
      "backgroundColor": "transparent"
    }), {}, {
      color: "$gray6",
      fill: "$gray6",
      stroke: "$gray6",
      path: {
        stroke: "$gray6"
      },
      "&:hover": _objectSpread(_objectSpread({}, {
        "backgroundColor": "transparent"
      }), {}, {
        color: "$default",
        fill: "$default",
        stroke: "$default",
        path: {
          stroke: "$default"
        }
      })
    })
  }, {
    color: "light",
    variant: "naked",
    css: _objectSpread(_objectSpread({}, {
      "backgroundColor": "transparent"
    }), {}, {
      color: "$white",
      fill: "$white",
      "&:hover": _objectSpread(_objectSpread({}, {
        "backgroundColor": "transparent"
      }), {}, {
        color: "$default",
        fill: "$default"
      })
    })
  }, {
    color: "danger",
    variant: "naked",
    css: _objectSpread(_objectSpread({}, {
      "backgroundColor": "transparent"
    }), {}, {
      color: "$danger",
      fill: "$danger",
      "&:hover": _objectSpread(_objectSpread({}, {
        "backgroundColor": "transparent"
      }), {}, {
        color: "$danger",
        fill: "$danger"
      }),
      "&:focus": {
        color: "$danger",
        opacity: 0.8
      },
      "&:focus-visible": {
        color: "$danger",
        opacity: 0.8
      }
    })
  }, {
    color: "danger",
    variant: "naked",
    disabled: true,
    css: {
      color: "$gray5",
      "&:hover": {
        color: "$gray5"
      }
    }
  }]
}));

var before = _css(_objectSpread({}, {
  "position": "relative",
  "left": "0.5rem"
}));

var content = _css(_objectSpread(_objectSpread({}, {
  "position": "relative",
  "display": "inline-flex",
  "flex": "1 1 0%",
  "alignItems": "center",
  "justifyContent": "center",
  "justifySelf": "center",
  "textAlign": "center"
}), {}, {
  variants: {
    labelSize: {
      xs: {
        fontSize: "$2"
      },
      sm: {
        fontSize: "$2",
        "@md": {
          fontSize: "$3"
        }
      },
      md: {
        fontSize: "$3"
      },
      lg: {
        fontSize: "$4"
      },
      xl: {
        fontSize: "$5"
      }
    }
  }
}));

var styles = {
  button: button,
  before: before,
  content: content
};
export default styles;