import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../libraries/stitches.library.ts";
import { keyframes } from "@stitches/react";
var progress = keyframes({
  "0%": {
    width: "0%"
  },
  "100%": {
    width: "100%"
  }
});
var styles = {
  wrapper: _css(_objectSpread(_objectSpread({}, {
    "display": "flex",
    "paddingBottom": "1rem"
  }), {}, {
    "@md": _objectSpread({}, {
      "paddingBottom": "1.5rem"
    }),
    variants: {
      position: {
        start: _objectSpread({}, {
          "justifyContent": "flex-start"
        }),
        end: _objectSpread({}, {
          "justifyContent": "flex-end"
        }),
        center: _objectSpread({}, {
          "justifyContent": "center"
        })
      },
      withoutOffset: {
        "true": _objectSpread({}, {
          "&:first-child": {
            "paddingTop": "1.5rem"
          }
        })
      }
    }
  })),
  item: _css(_objectSpread(_objectSpread({}, {
    "pointerEvents": "auto",
    "position": "relative",
    "width": "100%",
    "cursor": "pointer",
    "overflow": "hidden",
    "borderRadius": "9999px",
    "--tw-bg-opacity": "0.9",
    "--tw-shadow": "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
    "--tw-shadow-colored": "0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color)",
    "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
    "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
    "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
    "--tw-ring-opacity": "0.05",
    "--tw-ring-color": "hsl(359 4% 95% / var(--tw-ring-opacity))",
    "--tw-backdrop-blur": "blur(8px)",
    "backdropFilter": "var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)"
  }), {}, {
    backgroundColor: "$gray0",
    variants: {
      size: {
        small: _objectSpread({}, {
          "maxWidth": "24rem"
        }),
        medium: _objectSpread({}, {
          "maxWidth": "28rem"
        }),
        large: _objectSpread({}, {
          "maxWidth": "32rem"
        }),
        max: _objectSpread({}, {
          "maxWidth": "100%"
        }),
        half: {
          "@lg": _objectSpread({}, {
            "maxWidth": "50%"
          })
        },
        fit: _objectSpread({}, {
          "maxWidth": "fit-content"
        })
      }
    }
  })),
  padding: _css(_objectSpread({}, {
    "position": "relative",
    "paddingLeft": "1rem",
    "paddingRight": "1rem",
    "paddingTop": "0.75rem",
    "paddingBottom": "0.75rem"
  })),
  inside: _css(_objectSpread({}, {
    "position": "relative",
    "display": "flex",
    "alignItems": "center"
  })),
  status: _css(_objectSpread({}, {
    "flexShrink": "0"
  })),
  content: _css(_objectSpread({}, {
    "position": "relative",
    "marginLeft": "0.75rem",
    "flex": "1 1 0%"
  })),
  message: _css(_objectSpread(_objectSpread({}, {
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem",
    "fontWeight": "500"
  }), {}, {
    color: "$gray7"
  })),
  action: _css(_objectSpread({}, {
    "marginLeft": "1rem",
    "display": "flex",
    "flexShrink": "0"
  })),
  icon: _css(_objectSpread(_objectSpread({}, {
    "height": "1.5rem",
    "width": "1.5rem"
  }), {}, {
    variants: {
      status: {
        error: {
          color: "$danger"
        },
        success: {
          color: "$success"
        },
        information: {
          color: "$information"
        },
        none: {
          display: "none"
        }
      }
    }
  })),
  shell: _css(_objectSpread(_objectSpread({}, {
    "pointerEvents": "none",
    "position": "fixed",
    "inset": "0px",
    "marginLeft": "auto",
    "marginRight": "auto",
    "display": "flex",
    "height": "100%",
    "width": "100%",
    "flexDirection": "column",
    "justifyContent": "space-between",
    "paddingLeft": "1rem",
    "paddingRight": "1rem"
  }), {}, {
    zIndex: 30,
    maxWidth: "xl",
    "@md": _objectSpread({}, {
      "paddingLeft": "1.5rem",
      "paddingRight": "1.5rem"
    }),
    "@lg": _objectSpread({}, {
      "paddingLeft": "2rem",
      "paddingRight": "2rem"
    }),
    variants: {
      zMax: {
        "true": {
          zIndex: 100
        }
      },
      bottom: {
        "true": _objectSpread({}, {
          "flexDirection": "column-reverse"
        })
      }
    }
  })),
  top: _css(_objectSpread({}, {
    "position": "relative",
    "display": "flex",
    "flexDirection": "column"
  })),
  bottom: _css(_objectSpread({}, {
    "position": "relative",
    "display": "flex",
    "flexDirection": "column"
  })),
  progress: _css(_objectSpread(_objectSpread({}, {
    "position": "absolute",
    "left": "0px",
    "top": "0px",
    "height": "100%",
    "width": "0px"
  }), {}, {
    backgroundColor: "$lighten",
    animation: "".concat(progress, " linear forwards")
  }))
};
export default styles;