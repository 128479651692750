import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread({}, {
    "display": "flex",
    "flexDirection": "column",
    "justifyContent": "space-between"
  })),
  imageContainer: _css(_objectSpread({}, {
    "--tw-aspect-h": "3",
    "position": "relative",
    "paddingBottom": "calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%)",
    "--tw-aspect-w": "4",
    "> *": {
      "position": "absolute",
      "height": "100%",
      "width": "100%",
      "top": "0",
      "right": "0",
      "bottom": "0",
      "left": "0"
    },
    "overflow": "hidden",
    "borderRadius": "1rem",
    "&:focus": {
      "outline": "2px solid transparent",
      "outlineOffset": "2px"
    }
  })),
  title: _css(_objectSpread(_objectSpread({}, {
    "marginBottom": "0.5rem",
    "marginTop": "0.75rem",
    "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke",
    "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "transitionDuration": "150ms",
    "animationTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)"
  }), {}, {
    fontFamily: "$tenor",
    fontSize: "$5",
    "&:hover": {
      color: "$default"
    }
  })),
  description: _css(_objectSpread(_objectSpread({}, {
    "marginBottom": "1rem"
  }), {}, {
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
    maxHeight: "4.5rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "pre-line",
    color: "$gray7"
  })),
  linkIcon: _css(_objectSpread(_objectSpread({}, {
    "marginRight": "0.5rem"
  }), {}, {
    path: {
      stroke: "$default"
    }
  }))
};
export default styles;