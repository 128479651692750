import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { createStitches } from "@stitches/react";
import { base } from "@/theme/theme";
import { colors, variants } from "@/theme/theme.colors";
export var stitches = createStitches({
  prefix: "",
  theme: {
    fonts: _objectSpread({}, base.fonts.fonts),
    letterSpacings: _objectSpread({}, base.fonts.letterSpacings),
    fontWeights: _objectSpread({}, base.fonts.fontWeights),
    lineHeights: _objectSpread({}, base.fonts.lineHeights),
    fontSizes: _objectSpread({}, base.fonts.fontSizes),
    radii: _objectSpread({}, base.borders.radii),
    borderStyles: _objectSpread({}, base.borders.borderStyles),
    borderWidths: _objectSpread({}, base.borders.borderWidths),
    zIndices: _objectSpread({}, base.zIndices),
    space: _objectSpread({}, base.sizes.space),
    sizes: _objectSpread({}, base.sizes.sizes),
    transitions: _objectSpread({}, base.transitions),
    shadows: _objectSpread({}, base.shadows),
    colors: _objectSpread(_objectSpread({}, colors), variants.light.colors.semantic)
  },
  media: base.media,
  utils: base.utils
});
var config = stitches.config,
    createTheme = stitches.createTheme,
    css = stitches.css,
    getCssText = stitches.getCssText,
    globalCss = stitches.globalCss,
    keyframes = stitches.keyframes,
    prefix = stitches.prefix,
    reset = stitches.reset,
    styled = stitches.styled,
    theme = stitches.theme;
export { config, createTheme, css, getCssText, globalCss, keyframes, prefix, reset, styled, theme };
export var darkTheme = createTheme("dark-theme", {
  colors: _objectSpread({}, variants.dark.colors.semantic)
}); // export const lightTheme = createTheme("light-theme", {
//   colors: {
//     ...variants.light.colors.semantic,
//   },
// });