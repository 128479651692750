import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread(_objectSpread({}, {
    "position": "relative",
    "zIndex": "30",
    "display": "flex",
    "alignItems": "center",
    "justifyContent": "center",
    "paddingTop": "0.5rem",
    "paddingBottom": "0.5rem",
    "textAlign": "center",
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem",
    "fontWeight": "500"
  }), {}, {
    backgroundColor: "$default"
  })),
  text: _css(_objectSpread(_objectSpread({}, {
    "position": "relative",
    "display": "flex",
    "flexDirection": "row",
    "flexWrap": "wrap",
    "alignItems": "center",
    "justifyContent": "center",
    "columnGap": "0.25rem",
    "rowGap": "0px"
  }), {}, {
    color: "$white",
    fontSize: "$1",
    "@sm": {
      fontSize: "$2"
    },
    variants: {
      pointer: {
        "true": {
          "cursor": "pointer"
        }
      }
    }
  }))
};
export default styles;