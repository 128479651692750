import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["color"];
var __jsx = React.createElement;
import React from "react";
import { cn } from "@/libraries/clsx.library";
import styles from "./Container.styles";

var Wrapper = function Wrapper(_ref) {
  var children = _ref.children,
      className = _ref.className,
      color = _ref.color;
  return __jsx("div", {
    className: cn(styles.wrapper({
      color: color
    }), className === null || className === void 0 ? void 0 : className.wrapper)
  }, children);
};

var Box = function Box(_ref2) {
  var children = _ref2.children,
      className = _ref2.className;
  return __jsx("div", {
    className: cn(styles.box(), className === null || className === void 0 ? void 0 : className.box)
  }, children);
};

var Container = function Container(_ref3) {
  var color = _ref3.color,
      rest = _objectWithoutProperties(_ref3, _excluded);

  if (!!color) {
    return __jsx(Wrapper, _extends({
      color: color
    }, rest), __jsx(Box, rest));
  }

  return __jsx(Box, rest);
};

Container.displayName = "Container";
export default Container;