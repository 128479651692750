var __jsx = React.createElement;
import { useRouter } from "next/router";
import React from "react";
import { Image } from "@/components/common";
import { up } from "@/helpers/screens.helpers";
import { scrollIntoView } from "@/helpers/scroll.helper";
import { useMediaQuery } from "@/hooks/useMediaQuery.hook";
import { AdditionalInfo, Header, MainInfo } from "@/product/components";
import { ScreenEnum } from "@/theme/theme.enums";
import { ImagePlacecholder } from "~/src/components/ui";
import { useProductVariant } from "~/src/modules/product/hooks/useProductVariant.hook";
import styles from "./VariantSetSliderItem.styles";

var VariantSetSliderItem = function VariantSetSliderItem(_ref) {
  var variant = _ref.variantInSet.variant,
      pages = _ref.pages;
  var router = useRouter();
  var isUpMd = useMediaQuery(up(ScreenEnum.md), false);

  var _useProductVariant = useProductVariant(variant),
      media1024Url = _useProductVariant.media.media1024Url,
      name = _useProductVariant.name,
      variantHref = _useProductVariant.href;

  var ratingClickAction = function ratingClickAction() {
    router.push(variantHref).then(function () {
      scrollIntoView("__product-customers", {
        block: isUpMd ? "center" : "end"
      });
    });
  };

  return __jsx("div", {
    className: styles.root()
  }, __jsx("div", {
    className: styles.image()
  }, media1024Url ? __jsx(Image, {
    src: media1024Url,
    alt: name || "",
    placeholder: "blur",
    unoptimized: true,
    fill: true,
    style: {
      objectFit: "cover",
      objectPosition: "center"
    }
  }) : __jsx(ImagePlacecholder, null)), __jsx("div", {
    className: styles.details()
  }, __jsx(Header, {
    product: variant.product,
    activeVariant: variant,
    pages: pages,
    link: variantHref,
    ratingClickAction: ratingClickAction
  }), __jsx(MainInfo, {
    product: variant.product
  }), __jsx(AdditionalInfo, {
    product: variant.product,
    onlyFirstElementOpen: false
  })));
};

VariantSetSliderItem.displayName = "VariantSetSliderItem";
export default VariantSetSliderItem;