import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from "react";

var SvgKnowledge = function SvgKnowledge(props) {
  return __jsx("svg", _extends({
    width: 62,
    height: 61,
    viewBox: "0 0 62 61",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("mask", {
    id: "mask0_3497_5844",
    style: {
      maskType: "luminance"
    },
    maskUnits: "userSpaceOnUse",
    x: 10,
    y: 3,
    width: 42,
    height: 54
  }, __jsx("path", {
    d: "M11 56.5V3.5H51V56.5H11Z",
    fill: "white",
    stroke: "white"
  })), __jsx("g", {
    mask: "url(#mask0_3497_5844)"
  }, __jsx("path", {
    d: "M22.3066 21.3474L19.5225 18.3904",
    stroke: "url(#paint0_linear_3497_5844)",
    strokeWidth: 1.14145,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __jsx("path", {
    d: "M42.4195 19.3535L39.8086 22.2321",
    stroke: "url(#paint1_linear_3497_5844)",
    strokeWidth: 1.14145,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __jsx("path", {
    d: "M30.96 18.3903L31.0285 14.3579",
    stroke: "url(#paint2_linear_3497_5844)",
    strokeWidth: 1.14145,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __jsx("path", {
    d: "M46.5754 6.69633C46.5754 8.44716 45.107 9.89267 43.263 9.89267C41.419 9.89267 39.9507 8.44716 39.9507 6.69633C39.9507 4.94551 41.419 3.5 43.263 3.5C45.107 3.5 46.5754 4.94551 46.5754 6.69633Z",
    fill: "url(#paint3_linear_3497_5844)",
    stroke: "url(#paint4_linear_3497_5844)"
  }), __jsx("path", {
    d: "M18.0205 28.0231C19.5339 22.8706 23.0574 20.3391 27.3665 19.2975C33.1197 17.9085 40.9639 20.1936 43.2744 28.2022",
    stroke: "url(#paint5_linear_3497_5844)",
    strokeWidth: 1.14145,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __jsx("path", {
    d: "M30.9944 34.7549C30.9944 34.7549 31.9418 27.0711 50.9111 28.124L50.2526 50.2684C50.2526 50.2684 39.8437 48.2969 30.9829 56.4288",
    stroke: "url(#paint6_linear_3497_5844)",
    strokeWidth: 1.14145,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __jsx("path", {
    d: "M30.9946 56.44C22.1338 48.3081 11.7249 50.2795 11.7249 50.2795L11.0664 28.1351C30.0357 27.0822 30.983 34.7661 30.983 34.7661V56.4512L30.9946 56.44Z",
    stroke: "url(#paint7_linear_3497_5844)",
    strokeWidth: 1.14145,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  })), __jsx("defs", null, __jsx("linearGradient", {
    id: "paint0_linear_3497_5844",
    x1: 20.9145,
    y1: 17.9245,
    x2: 20.9145,
    y2: 21.3474,
    gradientUnits: "userSpaceOnUse"
  }, __jsx("stop", {
    stopColor: "#CF3161"
  }), __jsx("stop", {
    offset: 1,
    stopColor: "#DB7991"
  })), __jsx("linearGradient", {
    id: "paint1_linear_3497_5844",
    x1: 41.114,
    y1: 18.9,
    x2: 41.114,
    y2: 22.2321,
    gradientUnits: "userSpaceOnUse"
  }, __jsx("stop", {
    stopColor: "#CF3161"
  }), __jsx("stop", {
    offset: 1,
    stopColor: "#DB7991"
  })), __jsx("linearGradient", {
    id: "paint2_linear_3497_5844",
    x1: 30.9942,
    y1: 13.7226,
    x2: 30.9942,
    y2: 18.3903,
    gradientUnits: "userSpaceOnUse"
  }, __jsx("stop", {
    stopColor: "#CF3161"
  }), __jsx("stop", {
    offset: 1,
    stopColor: "#DB7991"
  })), __jsx("linearGradient", {
    id: "paint3_linear_3497_5844",
    x1: 43.263,
    y1: 1.83533,
    x2: 43.263,
    y2: 10.3927,
    gradientUnits: "userSpaceOnUse"
  }, __jsx("stop", {
    stopColor: "#CF3161"
  }), __jsx("stop", {
    offset: 1,
    stopColor: "#DB7991"
  })), __jsx("linearGradient", {
    id: "paint4_linear_3497_5844",
    x1: 43.263,
    y1: 1.83533,
    x2: 43.263,
    y2: 10.3927,
    gradientUnits: "userSpaceOnUse"
  }, __jsx("stop", {
    stopColor: "#CF3161"
  }), __jsx("stop", {
    offset: 1,
    stopColor: "#DB7991"
  })), __jsx("linearGradient", {
    id: "paint5_linear_3497_5844",
    x1: 30.6475,
    y1: 17.4536,
    x2: 30.6475,
    y2: 28.2022,
    gradientUnits: "userSpaceOnUse"
  }, __jsx("stop", {
    stopColor: "#CF3161"
  }), __jsx("stop", {
    offset: 1,
    stopColor: "#DB7991"
  })), __jsx("linearGradient", {
    id: "paint6_linear_3497_5844",
    x1: 40.947,
    y1: 23.55,
    x2: 40.947,
    y2: 56.4288,
    gradientUnits: "userSpaceOnUse"
  }, __jsx("stop", {
    stopColor: "#CF3161"
  }), __jsx("stop", {
    offset: 1,
    stopColor: "#DB7991"
  })), __jsx("linearGradient", {
    id: "paint7_linear_3497_5844",
    x1: 21.0305,
    y1: 23.5594,
    x2: 21.0305,
    y2: 56.4512,
    gradientUnits: "userSpaceOnUse"
  }, __jsx("stop", {
    stopColor: "#CF3161"
  }), __jsx("stop", {
    offset: 1,
    stopColor: "#DB7991"
  }))));
};

export default SvgKnowledge;