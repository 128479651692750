var __jsx = React.createElement;
import React from "react";
import { Image } from "@/components/common";
import { ImagePlacecholder, Slider, Tag } from "@/components/ui";
import styles from "./GalleryMobile.styles";

var GalleryMobile = function GalleryMobile(_ref) {
  var images = _ref.images,
      activeSlideIndex = _ref.activeSlideIndex,
      setActiveSlideIndex = _ref.setActiveSlideIndex,
      setSlider = _ref.setSlider,
      discountTag = _ref.discountTag,
      tags = _ref.tags;
  return __jsx("div", {
    className: styles.container()
  }, __jsx(Tag, {
    className: {
      overlay: styles.tags({
        withMarginBottom: images.length > 0
      })
    }
  }, __jsx(Tag.Group, null, discountTag ? __jsx("div", {
    className: styles.discountTagContainer()
  }, __jsx(Tag.Item, {
    color: "white",
    className: styles.discountTag()
  }, discountTag)) : null, tags.top.map(function (_ref2) {
    var id = _ref2.id,
        label = _ref2.label,
        icon = _ref2.icon;
    return __jsx(Tag.Item, {
      color: "lighten",
      icon: icon,
      key: id
    }, label);
  })), __jsx(Tag.Group, null, tags.bottom.map(function (_ref3) {
    var id = _ref3.id,
        label = _ref3.label,
        icon = _ref3.icon;
    return __jsx(Tag.Item, {
      color: "light",
      icon: icon,
      key: id
    }, label);
  }))), images.length > 0 ? __jsx(Slider, {
    className: styles.slider(),
    onSwiper: function onSwiper(swiper) {
      setSlider(swiper);
    },
    onSlideChange: function onSlideChange(swiper) {
      setActiveSlideIndex(swiper.activeIndex);
    },
    onAfterInit: function onAfterInit() {
      setActiveSlideIndex(0);
    },
    initialSlide: 0,
    additionalContent: __jsx("div", {
      className: styles.paginationContainer()
    }, __jsx(Slider.Pagination, {
      initialSlide: activeSlideIndex
    }))
  }, images.map(function (image, imageIndex) {
    return __jsx("div", {
      key: imageIndex,
      className: styles.imageContainer()
    }, __jsx(Image, {
      id: "__product-image-".concat(imageIndex),
      src: image.url,
      alt: image.alt,
      placeholder: "blur" // blurDataURL={image.blurDataURL}
      ,
      unoptimized: true,
      fill: true,
      sizes: "100vw",
      style: {
        objectFit: "cover",
        objectPosition: "center"
      }
    }));
  })) : __jsx("div", {
    className: styles.imagePlaceholderContainer()
  }, __jsx(ImagePlacecholder, null)));
};

export default GalleryMobile;