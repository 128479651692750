import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread(_objectSpread({}, {
    "position": "sticky",
    "bottom": "0px",
    "left": "0px",
    "zIndex": "10",
    "width": "100%",
    "borderTopWidth": "1px",
    "transitionProperty": "all",
    "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "transitionDuration": "300ms",
    "animationDuration": "300ms",
    "animationTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)"
  }), {}, {
    borderColor: "$gray2",
    variants: {
      visible: {
        "true": {
          transform: "translateY(0)"
        },
        "false": {
          transform: "translateY(100%)"
        }
      }
    }
  })),
  content: _css(_objectSpread(_objectSpread({}, {
    "display": "flex",
    "alignItems": "center",
    "justifyContent": "space-between",
    "gap": "2.5rem",
    "paddingTop": "1rem",
    "paddingBottom": "1rem"
  }), {}, {
    "@md": _objectSpread({}, {
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem"
    })
  })),
  imageContainer: _css(_objectSpread({}, {
    "position": "relative",
    "height": "4rem",
    "width": "4rem",
    "alignItems": "flex-start",
    "overflow": "hidden",
    "borderRadius": "0.75rem"
  })),
  productDetails: _css(_objectSpread(_objectSpread({}, {
    "display": "none"
  }), {}, {
    "@md": _objectSpread({}, {
      "display": "flex",
      "flexWrap": "wrap",
      "alignItems": "center",
      "gap": "1rem",
      "overflow": "hidden"
    })
  })),
  name: _css({
    flex: "1",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontFamily: "$tenor",
    fontSize: "$5"
  }),
  separator: _css(_objectSpread(_objectSpread({}, {
    "borderRadius": "9999px"
  }), {}, {
    width: "3px",
    height: "3px",
    backgroundColor: "$black"
  })),
  price: _css({
    span: {
      fontSize: "$5"
    }
  }),
  button: _css(_objectSpread(_objectSpread({}, {
    "width": "100%"
  }), {}, {
    flex: "none",
    "> span": _objectSpread({}, {
      "display": "flex",
      "alignItems": "center",
      "justifyContent": "center",
      "gap": "0.75rem"
    }),
    "@md": {
      width: "15rem"
    },
    "@lg": {
      width: "20rem"
    }
  })),
  buttonIcon: _css(_objectSpread(_objectSpread({}, {
    "height": "1.25rem",
    "width": "1.25rem"
  }), {}, {
    variants: {
      disabled: {
        "true": {
          fill: "$gray5"
        },
        "false": {
          fill: "$white"
        }
      }
    }
  })),
  buttonText: _css(_objectSpread(_objectSpread({}, {
    "display": "flex",
    "alignItems": "center",
    "gap": "0.375rem"
  }), {}, {
    whiteSpace: "nowrap"
  })),
  buttonTextMobile: _css(_objectSpread(_objectSpread({}, {
    "display": "inline-block"
  }), {}, {
    "@md": _objectSpread({}, {
      "display": "none"
    })
  }))
};
export default styles;