var __jsx = React.createElement;
import { Menu as Source } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import React from "react";
import s from "@/components/ui/Button/Button.styles";
import styles from "./Menu.styles";

var Label = function Label(_ref) {
  var children = _ref.children,
      open = _ref.open,
      caption = _ref.caption,
      disabled = _ref.disabled;
  return __jsx(Source.Button, {
    className: "".concat(s.button({
      variant: "slim",
      outlined: false,
      loading: false
    }), " ").concat(styles.label(), " ").concat(styles.button({
      disabled: disabled
    }))
  }, caption ? __jsx("span", {
    className: styles.caption()
  }, caption, ":") : null, __jsx("span", {
    className: styles.truncate()
  }, children), __jsx(ChevronDownIcon, {
    className: styles.chevron({
      open: open
    }),
    "aria-hidden": "true"
  }));
};

Label.displayName = "Label";
export default Label;