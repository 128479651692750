import _toConsumableArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _slicedToArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
import React from "react";
import { Star as StarIcon } from "@/components/ui/Icon/$";
import { cn } from "@/libraries/clsx.library";
import styles from "./StarsRating.styles";

var StarsRating = function StarsRating(_ref) {
  var onRatingChange = _ref.onRatingChange,
      _ref$starCount = _ref.starCount,
      starCount = _ref$starCount === void 0 ? 5 : _ref$starCount,
      _ref$disabled = _ref.disabled,
      disabled = _ref$disabled === void 0 ? false : _ref$disabled,
      iconClassName = _ref.iconClassName,
      className = _ref.className,
      iconSize = _ref.iconSize,
      iconMainColor = _ref.iconMainColor,
      iconSecondaryColor = _ref.iconSecondaryColor;

  var _React$useState = React.useState(starCount),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      hoverRating = _React$useState2[0],
      setHoverRating = _React$useState2[1];

  var _React$useState3 = React.useState(starCount),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      selectedRating = _React$useState4[0],
      setSelectedRating = _React$useState4[1];

  var handleHover = function handleHover(rating) {
    setHoverRating(rating);
  };

  var handleClick = function handleClick(rating) {
    setSelectedRating(rating);
  };

  React.useEffect(function () {
    onRatingChange(selectedRating);
  }, [onRatingChange, selectedRating]);
  return __jsx("div", {
    className: cn(styles.root({
      disabled: disabled
    }), className),
    onMouseLeave: function onMouseLeave() {
      return handleHover(selectedRating);
    }
  }, _toConsumableArray(Array(starCount)).map(function (_, index) {
    var rating = index + 1;
    return __jsx(StarIcon, {
      key: rating,
      percentFill: hoverRating >= rating ? 100 : 0,
      onMouseEnter: function onMouseEnter() {
        return handleHover(rating);
      },
      onClick: function onClick() {
        return handleClick(rating);
      },
      size: iconSize,
      mainColor: iconMainColor,
      secondaryColor: iconSecondaryColor,
      className: iconClassName
    });
  }));
};

export default StarsRating;