import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _slicedToArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _toConsumableArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var __jsx = React.createElement;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import cookie from "js-cookie";
import { useRouter } from "next/router";
import React from "react";
import { NOTIFICATION_SHOW_TIME, UID_COOKIE_KEY } from "@/config/common.config";
import { DELAY_BEETWEN_NOTIFICATIONS, DELAY_BEETWEN_NOTIFICATIONS_MULTIPLIER, INITIAL_MESSAGES_COUNT, MAX_MESSAGE_QUEUE_SIZE, MESSENGER_HOST } from "@/config/messenger.config";
import useNotification from "@/hooks/useNotification.hook";
import createContext from "@/libraries/react.library";
import { mapSupportedPageForNotification } from "./messenger.helpers";
var INITIAL_STATE = {
  isSubscriptionActive: false,
  messages: [],
  messengerWs: null
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_IS_SUBSCRIPTION_ACTIVE":
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isSubscriptionActive: action.payload
        });
      }

    case "PUSH_MESSAGE":
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          messages: [action.payload].concat(_toConsumableArray(state.messages.filter(function (m) {
            return m.id !== action.payload.id;
          }))).splice(0, MAX_MESSAGE_QUEUE_SIZE)
        });
      }

    case "REMOVE_MESSAGE":
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          messages: state.messages.filter(function (m) {
            return m.id !== action.payload;
          })
        });
      }

    case "SET_MESSAGES":
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          messages: action.payload
        });
      }

    default:
      return state;
  }
}

var _createContext = createContext(),
    _createContext2 = _slicedToArray(_createContext, 2),
    useContext = _createContext2[0],
    Provider = _createContext2[1];

export { useContext, Provider };
export var Messenger = function Messenger(props) {
  var _React$useState = React.useState(null),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      messengerWs = _React$useState2[0],
      setMessengerWS = _React$useState2[1];

  var _React$useReducer = React.useReducer(reducer, INITIAL_STATE),
      _React$useReducer2 = _slicedToArray(_React$useReducer, 2),
      state = _React$useReducer2[0],
      dispatch = _React$useReducer2[1];

  var router = useRouter();

  var _useNotification = useNotification(),
      pushNotification = _useNotification.pushNotification;

  var trackerRef = React.useRef([]);
  var timeoutRef = React.useRef([]);
  /*
   * Connect to websocket
   */

  React.useEffect(function () {
    var luid = cookie.get(UID_COOKIE_KEY);
    if (!(luid !== null && luid !== void 0 && luid.length)) return;
    var ws = new WebSocket("wss://".concat(MESSENGER_HOST, "/ws?luid=").concat(luid, "&count=").concat(INITIAL_MESSAGES_COUNT));

    ws.onopen = function () {
      setMessengerWS(ws);
      dispatch({
        type: "SET_IS_SUBSCRIPTION_ACTIVE",
        payload: true
      });
    };

    ws.onmessage = function (msg) {
      var payload = JSON.parse(msg.data);
      if (!payload) return;

      if (payload && "isSubscriptionActive" in payload) {
        var statusPayload = payload;
        dispatch({
          type: "SET_IS_SUBSCRIPTION_ACTIVE",
          payload: statusPayload.isSubscriptionActive
        });
        return;
      }

      if (payload.length === 0) {
        return;
      } else if (payload.length === 1) {
        dispatch({
          type: "PUSH_MESSAGE",
          payload: payload[0]
        });
      } else {
        dispatch({
          type: "SET_MESSAGES",
          payload: payload
        });
      }
    };

    ws.onclose = function () {
      setMessengerWS(null);
      dispatch({
        type: "SET_IS_SUBSCRIPTION_ACTIVE",
        payload: false
      });
    };
  }, []);
  /*
   * Push notification
   */

  React.useEffect(function () {
    var hasNewMessages = state.messages.some(function (m) {
      return !trackerRef.current.includes(m.id);
    });
    if (!state.messages.length || !router.isReady || !hasNewMessages) return;
    var tracker = trackerRef.current;
    var delayPush = tracker.length === 0 || state.messages.length > 1;
    state.messages.forEach(function (m, index) {
      if (tracker.includes(m.id)) return;
      var delay = index > 0 ? DELAY_BEETWEN_NOTIFICATIONS * index * DELAY_BEETWEN_NOTIFICATIONS_MULTIPLIER : DELAY_BEETWEN_NOTIFICATIONS;

      if (m.showOn.type === "PAGE") {
        if (m.showOn.pages === "ALL_PAGES") {
          var timeout = setTimeout(function () {
            pushNotification({
              message: m.message,
              status: "information",
              position: {
                placement: "top",
                justify: "end",
                witOffset: true
              },
              style: {
                size: "fit"
              }
            }, 10000);
            dispatch({
              type: "REMOVE_MESSAGE",
              payload: m.id
            });
          }, delayPush ? delay : 0);
          tracker.push(m.id);
          timeoutRef.current.push(timeout);
        } else {
          m.showOn.pages.forEach(function (page) {
            var _mapSupportedPageForN = mapSupportedPageForNotification(page),
                pathname = _mapSupportedPageForN.pathname,
                position = _mapSupportedPageForN.position,
                style = _mapSupportedPageForN.style,
                showTime = _mapSupportedPageForN.timeout;

            if (router.pathname === pathname) {
              var _timeout = setTimeout(function () {
                pushNotification({
                  message: m.message,
                  position: position !== null && position !== void 0 ? position : {
                    placement: "top",
                    justify: "end",
                    witOffset: true
                  },
                  style: style !== null && style !== void 0 ? style : {
                    size: "fit"
                  }
                }, showTime !== null && showTime !== void 0 ? showTime : NOTIFICATION_SHOW_TIME);
                dispatch({
                  type: "REMOVE_MESSAGE",
                  payload: m.id
                });
              }, delayPush ? delay : 0);

              tracker.push(m.id);
              timeoutRef.current.push(_timeout);
            }
          });
        }
      }

      if (m.showOn.type === "SLUG") {
        m.showOn.slugs.forEach(function (slug) {
          if (router.query.slug === slug) {
            var _timeout2 = setTimeout(function () {
              pushNotification({
                message: m.message,
                status: "none",
                position: {
                  placement: "bottom",
                  justify: "end",
                  witOffset: true
                },
                style: {
                  size: "half"
                }
              }, 10000);
              dispatch({
                type: "REMOVE_MESSAGE",
                payload: m.id
              });
            }, delayPush ? delay : 0);

            tracker.push(m.id);
            timeoutRef.current.push(_timeout2);
          }
        });
      }
    });
    trackerRef.current = tracker; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.messages, router.isReady, router.pathname]);
  /**
   * Clear all timeouts on unmount
   */

  React.useEffect(function () {
    var timeouts = timeoutRef.current;
    return function () {
      timeouts.forEach(function (timeout) {
        return clearTimeout(timeout);
      });
    };
  }, []);
  var value = React.useMemo(function () {
    return _objectSpread(_objectSpread({}, state), {}, {
      messengerWs: messengerWs
    });
  }, [messengerWs, state]);
  return __jsx(Provider, _extends({
    value: value
  }, props));
};
export default Messenger;