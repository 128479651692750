import cookie from "js-cookie";
import { isEqual } from "lodash";
import { useState } from "react";
export function useCookie(key, initialValue) {
  var _useState = useState(function () {
    var result;

    try {
      var item = cookie.get(key);
      result = item ? JSON.parse(item) : initialValue;
    } catch (_unused) {
      result = initialValue;
    }

    return result;
  }),
      value = _useState[0],
      setValue = _useState[1];

  var getCookie = function getCookie() {
    try {
      var cookieValue = cookie.get(key);

      if (cookieValue) {
        var parsedCookieData = JSON.parse(cookieValue);

        if (!isEqual(parsedCookieData, value)) {
          setValue(parsedCookieData);
        }

        return parsedCookieData;
      }
    } catch (_unused2) {
      console.warn("Could not get \"".concat(key, "\" cookie"));
    }
  };

  var setCookie = function setCookie(newValue, options) {
    var parsedNewValue = JSON.stringify(newValue);

    try {
      cookie.set(key, parsedNewValue, options);
      setValue(newValue);
    } catch (_unused3) {
      console.warn("Could not save \"".concat(key, "\" cookie"));
    }
  };

  var removeCookie = function removeCookie(options) {
    try {
      cookie.remove(key, options);
      setValue(undefined);
    } catch (_unused4) {
      console.warn("Could not remove \"".concat(key, "\" cookie"));
    }
  };

  return {
    value: value,
    setCookie: setCookie,
    getCookie: getCookie,
    removeCookie: removeCookie
  };
}