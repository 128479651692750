var __jsx = React.createElement;
import { styled as _styled } from "../../../libraries/stitches.library.ts";
import { Menu as Source } from "@headlessui/react";
import React, { Fragment } from "react";
import Group from "./Menu.Group";
import Item from "./Menu.Item";
import Label from "./Menu.Label";
import styles from "./Menu.styles";

var _TwComponent = _styled(Source.Items, {});

var Menu = function Menu(_ref) {
  var options = _ref.options,
      _ref$position = _ref.position,
      position = _ref$position === void 0 ? "right" : _ref$position,
      _ref$width = _ref.width,
      width = _ref$width === void 0 ? "14rem" : _ref$width,
      caption = _ref.caption,
      disabled = _ref.disabled,
      children = _ref.children;
  if (options.length === 0) return null;
  return __jsx(Source, {
    as: "div",
    className: styles.root()
  }, function (_ref2) {
    var open = _ref2.open;
    return __jsx(Fragment, null, __jsx(Label, {
      open: open,
      caption: caption,
      disabled: disabled
    }, children), __jsx(_TwComponent, {
      className: styles.items({
        position: position
      }),
      css: {
        width: width
      }
    }, __jsx(Fragment, null, options.map(function (group, index) {
      return __jsx(Group, {
        key: index,
        group: group,
        disabled: disabled
      });
    }))));
  });
};

Menu.Group = Group;
Menu.Label = Label;
Menu.Item = Item;
Menu.displayName = "Menu";
export default Menu;