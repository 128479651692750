import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../libraries/stitches.library.ts";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
var variants = {
  solid: {
    backgroundColor: "$light"
  },
  dashed: {
    background: "repeating-linear-gradient(\n        -45deg,\n        $light  0 1px,\n        transparent 1px 20px\n      )"
  }
};
var styles = {
  root: function root(_ref) {
    var width = _ref.width,
        height = _ref.height,
        variant = _ref.variant;
    return _objectSpread(_objectSpread({}, {
      "position": "relative",
      "display": "flex",
      "alignItems": "center",
      "justifyContent": "center",
      "borderRadius": "0.5rem",
      "borderWidth": "1px",
      "borderStyle": "solid"
    }), {}, {
      width: typeof width === "number" ? "".concat(width, "px") : "100%",
      height: typeof height === "number" ? "".concat(height, "px") : "100%",
      borderColor: "$light"
    }, variants[variant]);
  },
  description: _css(_objectSpread(_objectSpread({}, {
    "borderRadius": "0.5rem",
    "paddingLeft": "1rem",
    "paddingRight": "1rem",
    "paddingTop": "0.25rem",
    "paddingBottom": "0.25rem",
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem",
    "--tw-shadow": "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
    "--tw-shadow-colored": "0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color)",
    "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
  }), {}, {
    background: "$light",
    color: "$default"
  }))
};
export default styles;