var __jsx = React.createElement;
import DOMPurify from "isomorphic-dompurify";
import Link from "next/link";
import React from "react";
import { Container, Timer } from "@/components/ui";
import { useGlobal } from "@/hooks/useGlobal.hook";
import { isValidDate } from "@/libraries/dayjs.library";
import useCheckout from "~/src/hooks/useCheckout.hook";
import useInterface from "~/src/hooks/useInterface.hook";
import styles from "./PromoBar.styles";

var PromoBar = function PromoBar(_ref) {
  var id = _ref.id;

  var _useGlobal = useGlobal(),
      _useGlobal$promoBar = _useGlobal.promoBar,
      isVisible = _useGlobal$promoBar.isVisible,
      text = _useGlobal$promoBar.text,
      endDate = _useGlobal$promoBar.endDate,
      showTimer = _useGlobal$promoBar.showTimer,
      url = _useGlobal$promoBar.url,
      addProductVariant = _useGlobal$promoBar.addProductVariant,
      productVariantId = _useGlobal$promoBar.productVariantId,
      productVariantQuantity = _useGlobal$promoBar.productVariantQuantity;

  var _useCheckout = useCheckout(),
      addItems = _useCheckout.addItems;

  var _useInterface = useInterface(),
      openDialog = _useInterface.openDialog;

  var renderContent = React.useCallback(function (children) {
    return addProductVariant ? __jsx("span", {
      className: styles.text({
        pointer: productVariantId !== "" && productVariantQuantity > 0
      }),
      onClick: function onClick() {
        if (productVariantId && productVariantQuantity) {
          addItems({
            id: productVariantId,
            quantity: productVariantQuantity
          });
          openDialog("CART");
        }
      }
    }, children) : url ? __jsx(Link, {
      href: url,
      legacyBehavior: true,
      passHref: true
    }, __jsx("a", {
      className: styles.text()
    }, children)) : __jsx("span", {
      className: styles.text()
    }, children);
  }, [addItems, addProductVariant, openDialog, productVariantId, productVariantQuantity, url]);

  if (!isVisible) {
    return null;
  }

  return __jsx("div", {
    className: styles.root(),
    id: id
  }, __jsx(Container, null, renderContent(__jsx(React.Fragment, null, __jsx("span", {
    dangerouslySetInnerHTML: {
      __html: DOMPurify.sanitize(text)
    }
  }), isValidDate(endDate) && showTimer ? __jsx(Timer, {
    targetDate: endDate
  }) : null))));
};

export default PromoBar;