import _slicedToArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { EffectFade } from "swiper";
import { Slider } from "@/components/ui";
import ReviewsListItem from "@/components/ui/Reviews/components/ReviewsListItem/ReviewsListItem.component";
import { scrollIntoViewWithOffset } from "@/helpers/scroll.helper";
import styles from "./ReviewsList.styles";

var ReviewsList = function ReviewsList(_ref) {
  var reviews = _ref.reviews,
      _ref$itemsPerPage = _ref.itemsPerPage,
      itemsPerPage = _ref$itemsPerPage === void 0 ? 3 : _ref$itemsPerPage,
      setSlider = _ref.setSlider,
      _ref$showReviewDate = _ref.showReviewDate,
      showReviewDate = _ref$showReviewDate === void 0 ? false : _ref$showReviewDate;
  var t = useIntl();

  var _useState = useState(0),
      activeSlideIndex = _useState[0],
      setActiveSlideIndex = _useState[1];

  var _React$useState = React.useState(),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      sliderHeight = _React$useState2[0],
      setSliderHeight = _React$useState2[1];

  var groupedReviews = React.useMemo(function () {
    var result = [];

    for (var i = 0; i < reviews.length; i += itemsPerPage) {
      result.push(reviews.slice(i, i + itemsPerPage));
    }

    return result;
  }, [itemsPerPage, reviews]);
  React.useEffect(function () {
    var observer = new ResizeObserver(function (entries) {
      if (entries && entries[0]) {
        var height = entries[0].contentRect.height;
        setSliderHeight(height + 57);
      }
    });
    var activeSlideContent = document.querySelector("#__product-reviews-slider .swiper-slide-active > div");

    if (activeSlideContent) {
      setSliderHeight(activeSlideContent.clientHeight + 57);
      observer.observe(activeSlideContent);
    }

    return function () {
      observer.disconnect();
    };
  }, [activeSlideIndex, reviews]);

  var scrollToTop = function scrollToTop() {
    var timeout = setTimeout(function () {
      scrollIntoViewWithOffset("__product-reviews-slider", -210);
      clearTimeout(timeout);
    }, 0);
  };

  return __jsx("div", {
    className: styles.root()
  }, groupedReviews.length ? __jsx(Slider, {
    id: "__product-reviews-slider",
    effect: "fade",
    fadeEffect: {
      crossFade: true
    },
    modules: [EffectFade],
    onSwiper: function onSwiper(swiper) {
      setSlider(swiper);
    },
    onSlideChange: function onSlideChange(_ref2) {
      var activeIndex = _ref2.activeIndex;
      setActiveSlideIndex(activeIndex);
      scrollToTop();
    },
    onAfterInit: function onAfterInit() {
      setActiveSlideIndex(0);
    },
    initialSlide: 0,
    observeSlideChildren: false,
    style: {
      height: sliderHeight || "auto"
    },
    additionalContent: reviews.length > 1 ? __jsx("div", {
      className: styles.navigationContainer()
    }, __jsx(Slider.NavigationItem, {
      type: "prev",
      text: t.formatMessage({
        defaultMessage: "Previous",
        id: "common.previous.feminePlural"
      })
    }), __jsx(Slider.NavigationItem, {
      type: "next",
      text: t.formatMessage({
        defaultMessage: "Next",
        id: "common.next.feminePlural"
      })
    })) : null
  }, groupedReviews.map(function (group, groupIndex) {
    return __jsx("div", {
      className: styles.group(),
      key: "reviews-page-".concat(groupIndex)
    }, group.map(function (review, index) {
      return __jsx(ReviewsListItem, {
        key: "review-".concat(index),
        review: review,
        showReviewDate: showReviewDate
      });
    }));
  })) : __jsx("div", {
    className: styles.emptyListInfo()
  }, t.formatMessage({
    id: "reviews.list.emptyInfo",
    defaultMessage: "No reviews"
  })));
};

ReviewsList.displayName = "ReviewsList";
export default ReviewsList;