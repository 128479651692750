import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../libraries/stitches.library.ts";
var styles = {
  container: _css(_objectSpread({}, {
    "position": "relative",
    "zIndex": "20"
  })),
  main: _css(_objectSpread(_objectSpread({}, {
    "paddingTop": "2rem",
    "paddingBottom": "2rem"
  }), {}, {
    "@md": _objectSpread({}, {
      "paddingTop": "3rem",
      "paddingBottom": "3rem"
    })
  })),
  header: _css(_objectSpread(_objectSpread({}, {
    "marginBottom": "2rem",
    "display": "flex",
    "alignItems": "center"
  }), {}, {
    "@md": _objectSpread({}, {
      "marginBottom": "2.5rem"
    }),
    variants: {
      center: {
        "true": _objectSpread({}, {
          "justifyContent": "center"
        })
      }
    }
  })),
  content: _css(_objectSpread(_objectSpread({}, {
    "display": "grid",
    "> :not([hidden]) ~ :not([hidden])": {
      "--tw-space-y-reverse": "0",
      "marginTop": "calc(3rem * calc(1 - var(--tw-space-y-reverse)))",
      "marginBottom": "calc(3rem * var(--tw-space-y-reverse))"
    }
  }), {}, {
    "@md": _objectSpread({}, {
      "gridTemplateColumns": "repeat(4, minmax(0, 1fr))",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": "0",
        "marginTop": "calc(0px * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(0px * var(--tw-space-y-reverse))"
      }
    }),
    variants: {
      full: {
        "true": {
          "@md": _objectSpread({}, {
            "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
            "> :not([hidden]) ~ :not([hidden])": {
              "--tw-space-y-reverse": "0",
              "marginTop": "calc(0px * calc(1 - var(--tw-space-y-reverse)))",
              "marginBottom": "calc(0px * var(--tw-space-y-reverse))"
            }
          })
        }
      }
    }
  })),
  sectionHeader: _css(_objectSpread(_objectSpread({}, {
    "marginBottom": "1.75rem"
  }), {}, {
    fontFamily: "$tenor",
    color: "$white",
    fontSize: "$5"
  })),
  navItem: _css(_objectSpread(_objectSpread({}, {
    "display": "flex",
    "gap": "1rem"
  }), {}, {
    color: "$white",
    "&:not(:last-child)": _objectSpread({}, {
      "marginBottom": "1.5rem"
    })
  })),
  navItemSocials: _css(_objectSpread(_objectSpread({}, {
    "display": "flex",
    "flexWrap": "wrap",
    "gap": "1rem"
  }), {}, {
    variants: {
      staticFooter: {
        "true": _objectSpread(_objectSpread({}, {
          "gap": "1.75rem"
        }), {}, {
          "@sm": _objectSpread({}, {
            "display": "none"
          })
        })
      }
    }
  })),
  link: _css(_objectSpread(_objectSpread({}, {
    "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke",
    "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "transitionDuration": "300ms",
    "animationDuration": "300ms",
    "animationTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)"
  }), {}, {
    "&:hover": {
      color: "$default"
    }
  })),
  icon: _css(_objectSpread(_objectSpread({}, {
    "height": "1.25rem",
    "width": "1.25rem"
  }), {}, {
    color: "$dark"
  })),
  socialIcon: _css(_objectSpread(_objectSpread({}, {
    "height": "1.25rem",
    "width": "1.25rem"
  }), {}, {
    path: {
      fill: "$gray9"
    }
  }))
};
export default styles;