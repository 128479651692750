/**
 * NOTE: Keep this in sync with the (custom) Tailwind theme `screens` config.
 * @see https://tailwindcss.com/docs/breakpoints
 */
export var screens = {
  "sm": "640px",
  "md": "768px",
  "lg": "1024px",
  "xl": "1280px",
  "2xl": "1536px",
  "xs": "375px"
}; // The maximum value is calculated as the minimum of the next one less 0.02px.
// @see https://www.w3.org/TR/mediaqueries-4/#mq-min-max

var getNextBpValue = function getNextBpValue(bp) {
  return "".concat(parseInt(bp) - 0.02, "px");
};

export var up = function up(bp) {
  var screen = screens[bp];
  return "@media only screen and (min-width: ".concat(screen, ")");
};
export var down = function down(bp) {
  var screen = getNextBpValue(screens[bp]);
  return "@media only screen and (max-width: ".concat(screen, ")");
};
export var between = function between(bpMin, bpMax) {
  var screenMin = screens[bpMin];
  var screenMax = getNextBpValue(screens[bpMax]);
  return "@media only screen and (min-width: ".concat(screenMin, ") and (max-width: ").concat(screenMax, ")");
};
export var only = function only(bp) {
  var screenKeys = Object.keys(screens);
  var currentKeyIndex = screenKeys.indexOf(bp);
  var nextBp = screenKeys[currentKeyIndex + 1];
  return nextBp ? between(bp, nextBp) : up(bp);
};