import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _slicedToArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import React from "react";
import NavbarDesktopSubmenu from "../NavbarDesktopSubmenu/NavbarDesktopSubmenu.component";
import styles from "./NavbarDesktopSubmenuItem.styles";

var NavbarDesktopSubmenuItem = function NavbarDesktopSubmenuItem(_ref) {
  var _subLink$subLinks;

  var subLink = _ref.subLink,
      submenuLevel = _ref.submenuLevel,
      setNextLevelActive = _ref.setNextLevelActive;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      isHovered = _React$useState2[0],
      setIsHovered = _React$useState2[1];

  var handleOnMouseEnter = function handleOnMouseEnter() {
    setIsHovered(true);

    if (subLink.subLinks && subLink.subLinks.length > 0) {
      setNextLevelActive(true);
    }
  };

  var handleOnMouseLeave = function handleOnMouseLeave() {
    setIsHovered(false);
    setNextLevelActive(false);
  };

  if (!(subLink !== null && subLink !== void 0 && subLink.href) && (!subLink.subLinks || ((_subLink$subLinks = subLink.subLinks) === null || _subLink$subLinks === void 0 ? void 0 : _subLink$subLinks.length) === 0)) {
    return null;
  }

  return __jsx("li", {
    className: styles.root(),
    onMouseEnter: handleOnMouseEnter,
    onMouseLeave: handleOnMouseLeave
  }, subLink.href ? __jsx(Link, _extends({
    href: subLink.href,
    passHref: true
  }, subLink.hrefOptions, {
    className: styles.submenuItem({
      active: isHovered,
      highlighted: subLink.highlighted
    })
  }), subLink.name, " ", subLink.subLinks ? __jsx(ChevronRightIcon, null) : null) : __jsx("span", {
    className: styles.submenuItem({
      active: isHovered,
      highlighted: subLink.highlighted
    })
  }, subLink.name, " ", subLink.subLinks ? __jsx(ChevronRightIcon, null) : null), __jsx(NavbarDesktopSubmenu, {
    subLinks: subLink.subLinks || [],
    show: !!(subLink.subLinks && subLink.subLinks.length > 0) && isHovered,
    submenuLevel: submenuLevel + 1
  }));
};

NavbarDesktopSubmenuItem.displayName = "NavbarDesktopSubmenuItem";
export default NavbarDesktopSubmenuItem;