import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["title", "description", "media", "nofollow", "noindex"];
import React from "react";
var __jsx = React.createElement;
import { NextSeo } from "next-seo";
import { STOREFRONT_NAME } from "@/config/common.config";
import { IS_TESTING } from "@/config/test.config";
import { isEnvStaging, isProd } from "@/helpers/common.helpers";
export var Seo = function Seo(_ref) {
  var title = _ref.title,
      description = _ref.description,
      media = _ref.media,
      nofollow = _ref.nofollow,
      noindex = _ref.noindex,
      props = _objectWithoutProperties(_ref, _excluded);

  var seoTitle = title ? "".concat(title, " | ").concat(STOREFRONT_NAME) : STOREFRONT_NAME;
  var seoDescription = description || "";
  var considerIndexOrFollow = isProd && !isEnvStaging && !IS_TESTING;
  return __jsx(NextSeo, _extends({}, props, {
    nofollow: considerIndexOrFollow ? nofollow : true,
    noindex: considerIndexOrFollow ? noindex : true,
    title: seoTitle,
    description: seoDescription,
    twitter: {
      cardType: "summary_large_image"
    },
    additionalMetaTags: [{
      name: "google-site-verification",
      content: "Q4qs01clizHirami4jtfcVqaPScVX3Q8BoXwDxu6Wx4"
    }, {
      name: "facebook-domain-verification",
      content: "tfu7h6n08k3y0q8ktjs7iuz1gw92n1"
    }, {
      name: "p:domain_verify",
      content: "b119d827516b05844fecbb3dd6602a25"
    }],
    openGraph: {
      title: seoTitle,
      description: seoDescription,
      images: media ? media : [{
        url: "/social.png",
        alt: seoTitle
      }],
      site_name: STOREFRONT_NAME
    }
  }));
};
export default Seo;