import _objectWithoutProperties from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["icon"];
import React from "react";
var __jsx = React.createElement;
import { OutlineAppStore, OutlineBlog, OutlineFacebook, OutlineGooglePlay, OutlineInstagram, OutlineShop, OutlineSpotify, OutlineTikTok, OutlineYoutube } from "@/components/ui/Icon/$";
export var Icon = function Icon(_ref) {
  var icon = _ref.icon,
      props = _objectWithoutProperties(_ref, _excluded);

  switch (icon) {
    case "shop":
      return __jsx(OutlineShop, props);

    case "instagram":
      return __jsx(OutlineInstagram, props);

    case "facebook":
      return __jsx(OutlineFacebook, props);

    case "tiktok":
      return __jsx(OutlineTikTok, props);

    case "spotify":
      return __jsx(OutlineSpotify, props);

    case "youtube":
      return __jsx(OutlineYoutube, props);

    case "app-store":
      return __jsx(OutlineAppStore, props);

    case "google-play":
      return __jsx(OutlineGooglePlay, props);

    case "blog":
      return __jsx(OutlineBlog, props);

    default:
      return null;
  }
};
Icon.displayName = "Icon";
export default Icon;