var __jsx = React.createElement;
import React from "react";
import { useIntl } from "react-intl";
import { Image } from "@/components/common";
import { Button, Icon, ImagePlacecholder } from "@/components/ui";
import styles from "./BlogArticle.styles";

var BlogArticle = function BlogArticle(_ref) {
  var imgSrc = _ref.imgSrc,
      title = _ref.title,
      description = _ref.description,
      url = _ref.url;
  var t = useIntl();
  return __jsx("div", {
    className: styles.root()
  }, __jsx("div", null, imgSrc ? __jsx("a", {
    "aria-label": t.formatMessage({
      defaultMessage: "Blog article: {title}",
      id: "blog.article.link.aria"
    }, {
      title: title
    }),
    href: url,
    target: "_blank",
    rel: "noopener noreferrer"
  }, __jsx("div", {
    className: styles.imageContainer()
  }, __jsx(Image, {
    src: imgSrc,
    alt: "",
    fill: true,
    placeholder: "blur",
    blurDataRGB: {
      r: 234,
      g: 234,
      b: 234
    },
    sizes: "(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 33vw",
    style: {
      objectFit: "cover",
      objectPosition: "center"
    }
  }))) : __jsx("div", {
    className: styles.imageContainer()
  }, __jsx(ImagePlacecholder, {
    size: "lg"
  })), __jsx("a", {
    "aria-label": t.formatMessage({
      defaultMessage: "Blog article: {title}",
      id: "blog.article.link.aria"
    }, {
      title: title
    }),
    href: url,
    target: "_blank",
    rel: "noopener noreferrer"
  }, __jsx("h4", {
    className: styles.title()
  }, title)), __jsx("p", {
    className: styles.description()
  }, description)), __jsx("a", {
    "aria-label": t.formatMessage({
      defaultMessage: "Blog article: {title}",
      id: "blog.article.link.aria"
    }, {
      title: title
    }),
    href: url,
    target: "_blank",
    rel: "noopener noreferrer"
  }, __jsx(Button, {
    variant: "oval",
    color: "flavorLight",
    width: "100%"
  }, __jsx(Icon, {
    name: "Link",
    className: styles.linkIcon()
  }), t.formatMessage({
    id: "comingSoon.blog.article.link",
    defaultMessage: "Read more"
  }))));
};

export default BlogArticle;