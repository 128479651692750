var __jsx = React.createElement;
import { Dialog } from "@headlessui/react";
import React from "react";
import { Loader } from "@/components/ui";
import { INPOST_EVENT, INPOST_GEOWIDGET_KEY } from "@/config/inpost.config";
import { cn } from "@/libraries/clsx.library";
import styles from "./Overlay.styles";

var Overlay = function Overlay(_ref) {
  var ready = _ref.ready,
      type = _ref.type,
      show = _ref.show,
      onPointSelect = _ref.onPointSelect,
      onClose = _ref.onClose;
  var loading = !ready;
  React.useEffect(function () {
    document.addEventListener(INPOST_EVENT, onPointSelect);
    return function () {
      document.removeEventListener(INPOST_EVENT, onPointSelect);
    };
  }, [onPointSelect]);
  return __jsx(Dialog, {
    as: "div",
    className: styles.dialog(),
    open: show,
    onClose: onClose
  }, __jsx("div", {
    className: styles.background()
  }), __jsx("div", {
    className: styles.wrapper()
  }, __jsx(Dialog.Panel, {
    className: styles.panel()
  }, type === "INPOST" && __jsx("div", {
    className: styles.inpost()
  }, __jsx("inpost-geowidget", {
    onpoint: INPOST_EVENT,
    token: INPOST_GEOWIDGET_KEY,
    language: "pl",
    config: "parcelcollect"
  })), loading && __jsx("div", {
    className: cn(styles.content())
  }, __jsx(Loader, null)))));
};

Overlay.displayName = "Overlay";
export default Overlay;