import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../libraries/stitches.library.ts";
var style = {
  root: _css(_objectSpread(_objectSpread({}, {
    "width": "100%",
    "paddingTop": "2rem",
    "paddingBottom": "2rem"
  }), {}, {
    "@sm": _objectSpread({}, {
      "paddingTop": "3rem",
      "paddingBottom": "3rem"
    })
  })),
  header: _css(_objectSpread({}, {
    "marginBottom": "1.5rem",
    "display": "flex",
    "flexDirection": "column",
    "gap": "0.5rem"
  })),
  title: _css(_objectSpread(_objectSpread({}, {
    "textAlign": "center"
  }), {}, {
    fontFamily: "$tenor",
    fontSize: "$6",
    lineHeight: "$4"
  })),
  description: _css(_objectSpread(_objectSpread({}, {
    "textAlign": "center"
  }), {}, {
    fontFamily: "$inter",
    color: "$gray7"
  }))
};
export default style;