import _slicedToArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectDestructuringEmpty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectDestructuringEmpty.js";
import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _toConsumableArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
var __jsx = React.createElement;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useEffect, useMemo, useReducer } from "react";
import { useIntl } from "react-intl";
import { FOOTER_NAVBAR, MAIN_NAVBAR, PRODUCT_HIDDEN_ON_STOREFRONT_ATTRIBUTE } from "@/config/common.config";
import { EMAIL_ADDRESS, PHONE_NUMBER } from "@/config/contact.config";
import { BLOG_URL, FACEBOOK_URL, INSTAGRAM_URL, SPOTIFY_URL, TIK_TOK_URL, YOUTUBE_URL } from "@/config/link.config";
import { useNavbarsQuery } from "@/graphql/$graphql";
import { mapEdges } from "@/helpers/map.helpers";
import { CollectionMetadataKey } from "@/helpers/metadata.helpers";
import { translate } from "@/helpers/translation.helpers";
import usePath from "@/hooks/usePath.hook";
import useRegion from "@/hooks/useRegion.hook";
import createContext from "@/libraries/react.library";
import { NavbarEnums } from "@/navbar/navbar.enums";

var INITIAL_STATE_ACCOUNT_NAVBAR = function INITIAL_STATE_ACCOUNT_NAVBAR(paths, t) {
  return [{
    id: "accountMyData",
    name: t.formatMessage({
      defaultMessage: "My data",
      id: "navbar.item.myData"
    }),
    href: paths.profile
  }, {
    id: "accountOrdersHistory",
    name: t.formatMessage({
      defaultMessage: "Orders history",
      id: "navbar.item.ordersHistory"
    }),
    href: paths.orders
  }];
};

export var STATIC_SHOP_LINKS = function STATIC_SHOP_LINKS(paths, t) {
  return [{
    id: "lowPriceZone",
    name: t.formatMessage({
      defaultMessage: "Low price zone",
      id: "navbar.shop.lowPriceZone"
    }),
    href: paths.lowPriceZone
  }];
};
export var STATIC_FOOTER_COLLECTIONS_ITEMS = function STATIC_FOOTER_COLLECTIONS_ITEMS(paths, t) {
  return _toConsumableArray(typeof (paths === null || paths === void 0 ? void 0 : paths.lowPriceZone) === "object" ? [{
    id: "lowPriceZone",
    name: t.formatMessage({
      defaultMessage: "Low price zone",
      id: "navbar.shop.lowPriceZone"
    }),
    href: paths.lowPriceZone
  }] : []);
};

function INITIAL_STATE(paths, t) {
  var _ref3;

  return _ref3 = {}, _defineProperty(_ref3, NavbarEnums.MAIN_NAVBAR, {
    isLoading: true,
    links: [{
      id: "shop",
      name: t.formatMessage({
        defaultMessage: "Shop",
        id: "navbar.item.shop"
      }),
      href: paths.shop,
      subLinks: _toConsumableArray(STATIC_SHOP_LINKS(paths, t))
    }, {
      id: "aboutUs",
      name: t.formatMessage({
        defaultMessage: "About us",
        id: "navbar.item.aboutUs"
      }),
      href: paths.about_us
    }, {
      id: "blog",
      name: t.formatMessage({
        defaultMessage: "Blog",
        id: "navbar.item.blog"
      }),
      href: paths.blog,
      hrefOptions: {
        target: "_blank",
        rel: "noopener noreferrer"
      }
    }, {
      id: "podcast",
      name: t.formatMessage({
        defaultMessage: "Podcast",
        id: "navbar.item.podcast"
      }),
      href: paths.podcast
    }, {
      id: "app",
      name: t.formatMessage({
        defaultMessage: "App",
        id: "navbar.item.mobileApp"
      }),
      href: paths.mobileApp,
      hidden: function hidden(_ref) {
        var props = _extends({}, (_objectDestructuringEmpty(_ref), _ref));

        return props.mode !== "mobile";
      }
    }, {
      id: "reviews",
      name: t.formatMessage({
        defaultMessage: "Reviews",
        id: "navbar.item.reviews"
      }),
      href: paths.reviews
    }, {
      id: "account",
      name: t.formatMessage({
        defaultMessage: "Profile",
        id: "navbar.item.profile"
      }),
      href: paths.account,
      hidden: function hidden(_ref2) {
        var props = _extends({}, (_objectDestructuringEmpty(_ref2), _ref2));

        return !props.auth || props.mode !== "mobile";
      },
      subLinks: INITIAL_STATE_ACCOUNT_NAVBAR(paths, t)
    }]
  }), _defineProperty(_ref3, NavbarEnums.ACCOUNT_NAVBAR, INITIAL_STATE_ACCOUNT_NAVBAR(paths, t)), _defineProperty(_ref3, NavbarEnums.COMING_SOON_NAVBAR, {
    links: [{
      id: "aboutUs",
      name: t.formatMessage({
        defaultMessage: "About us",
        id: "navbar.item.aboutUs"
      }),
      href: paths.about_us
    }, {
      id: "blog",
      name: t.formatMessage({
        defaultMessage: "Blog",
        id: "navbar.item.blog"
      }),
      href: paths.blog
    }]
  }), _defineProperty(_ref3, NavbarEnums.FOOTER_NAVBAR, {
    isLoading: true,
    collections: {
      title: t.formatMessage({
        defaultMessage: "Products",
        id: "footerDefault.collections.title"
      }),
      items: _toConsumableArray(STATIC_FOOTER_COLLECTIONS_ITEMS(paths, t))
    },
    pages: {
      title: t.formatMessage({
        defaultMessage: "Customer service",
        id: "footerDefault.pages.title"
      }),
      items: []
    },
    contact: {
      title: t.formatMessage({
        defaultMessage: "Contact",
        id: "footerDefault.contact.title"
      }),
      items: {
        phone: PHONE_NUMBER,
        availability: t.formatMessage({
          defaultMessage: "Mon - Fri, 10:00 - 16:00",
          id: "footerDefault.contact.availability"
        }),
        email: EMAIL_ADDRESS,
        pages: [{
          name: t.formatMessage({
            defaultMessage: "Współpraca",
            id: "footerDefault.contact.partnership"
          }),
          slug: "partnership",
          id: "partnership"
        }],
        socials: [{
          iconName: "Facebook",
          url: FACEBOOK_URL
        }, {
          iconName: "Instagram",
          url: INSTAGRAM_URL
        }, {
          iconName: "TikTok",
          url: TIK_TOK_URL
        }, {
          iconName: "Youtube",
          url: YOUTUBE_URL
        }, {
          iconName: "Spotify",
          url: SPOTIFY_URL
        }]
      }
    },
    newsletter: {
      title: t.formatMessage({
        defaultMessage: "Newsletter",
        id: "footerDefault.newsletter.title"
      })
    }
  }), _ref3;
}

function reducer(state, action) {
  switch (action.type) {
    case "SET_MAIN_NAVBAR_DATA":
      {
        var _action$payload;

        var dynamicItems = (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.navbar.items;

        var parseToLinkItems = function parseToLinkItems(navbarItems) {
          var items = [];

          var parseChildren = function parseChildren(children) {
            return children.map(function (navbarItem) {
              var _navbarItem$collectio;

              var item = {
                id: navbarItem.id,
                name: navbarItem.name,
                highlighted: ((_navbarItem$collectio = navbarItem.collection) === null || _navbarItem$collectio === void 0 || (_navbarItem$collectio = _navbarItem$collectio.metadata.find(function (meta) {
                  return meta.key === CollectionMetadataKey.HIGHLIGHTED;
                })) === null || _navbarItem$collectio === void 0 ? void 0 : _navbarItem$collectio.value) === "true",
                href: navbarItem.collection ? action.payload.dynamicPaths.productsCollection(navbarItem.collection.id) : navbarItem.url
              };

              if (navbarItem.children && navbarItem.children.length) {
                item.subLinks = parseChildren(navbarItem.children);
              }

              return item;
            });
          };

          items.push.apply(items, _toConsumableArray(parseChildren(navbarItems)));
          return items;
        };

        if (dynamicItems) {
          var mainNavbarLinks = state[NavbarEnums.MAIN_NAVBAR].links || [];

          if (mainNavbarLinks.length) {
            mainNavbarLinks[0].subLinks = [].concat(_toConsumableArray(parseToLinkItems(dynamicItems)), _toConsumableArray(STATIC_SHOP_LINKS(action.payload.paths, action.payload.t)));
          }

          return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, NavbarEnums.MAIN_NAVBAR, _objectSpread(_objectSpread({}, state[NavbarEnums.MAIN_NAVBAR]), {}, {
            links: _toConsumableArray(mainNavbarLinks)
          })));
        }

        return state;
      }

    case "SET_MAIN_NAVBAR_LOADING":
      {
        return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, NavbarEnums.MAIN_NAVBAR, _objectSpread(_objectSpread({}, state[NavbarEnums.MAIN_NAVBAR]), {}, {
          isLoading: action.payload
        })));
      }

    case "SET_FOOTER_NAVBAR_DATA":
      {
        var _action$payload2, _action$payload3;

        var navbarCollections = (_action$payload2 = action.payload) === null || _action$payload2 === void 0 ? void 0 : _action$payload2.footer.items[0].children;
        var pages = (_action$payload3 = action.payload) === null || _action$payload3 === void 0 ? void 0 : _action$payload3.footer.items[1].children;
        return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, NavbarEnums.FOOTER_NAVBAR, _objectSpread(_objectSpread({}, state[NavbarEnums.FOOTER_NAVBAR]), {}, {
          collections: _objectSpread(_objectSpread({}, state[NavbarEnums.FOOTER_NAVBAR].collections), {}, {
            items: [].concat(_toConsumableArray((navbarCollections === null || navbarCollections === void 0 ? void 0 : navbarCollections.map(function (navbarCollection) {
              return {
                id: navbarCollection.id || "",
                name: translate(navbarCollection, "name"),
                href: action.payload.dynamicPaths.productsCollection(navbarCollection.collection.id)
              };
            })) || []), _toConsumableArray(STATIC_FOOTER_COLLECTIONS_ITEMS(action.payload.paths, action.payload.t)))
          }),
          pages: _objectSpread(_objectSpread({}, state[NavbarEnums.FOOTER_NAVBAR].pages), {}, {
            items: (pages === null || pages === void 0 ? void 0 : pages.reduce(function (allPages, item) {
              var page = item.page;

              if (page) {
                return [].concat(_toConsumableArray(allPages), [{
                  id: page.id,
                  slug: page.slug,
                  name: translate(page, "title")
                }]);
              }

              return allPages;
            }, [])) || []
          })
        })));
      }

    case "SET_FOOTER_NAVBAR_LOADING":
      {
        return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, NavbarEnums.FOOTER_NAVBAR, _objectSpread(_objectSpread({}, state[NavbarEnums.FOOTER_NAVBAR]), {}, {
          isLoading: action.payload
        })));
      }

    default:
      return state;
  }
}

var _createContext = createContext(),
    _createContext2 = _slicedToArray(_createContext, 2),
    useContext = _createContext2[0],
    Provider = _createContext2[1];

export { useContext, Provider };
export var Navbar = function Navbar(props) {
  var t = useIntl();
  var path = usePath();

  var _useReducer = useReducer(reducer, INITIAL_STATE({
    shop: path.products.$url(),
    about_us: path.about_us.$url(),
    podcast: path.podcast.$url(),
    mobileApp: path.aplikacja_lova_opowiadania_erotyczne.$url(),
    blog: BLOG_URL,
    account: "".concat(path.$url().pathname, "/account"),
    profile: path.account.profile.$url(),
    editProfile: path.account.profile.edit.$url(),
    orders: path.account.orders.$url(),
    reviews: path.reviews.$url(),
    lowPriceZone: path.low_price_zone.$url()
  }, t)),
      state = _useReducer[0],
      dispatch = _useReducer[1];

  var _useRegion = useRegion(),
      query = _useRegion.query;

  var _useNavbarsQuery = useNavbarsQuery({
    variables: _objectSpread(_objectSpread({}, query), {}, {
      productsFilter: {
        attributes: [{
          slug: PRODUCT_HIDDEN_ON_STOREFRONT_ATTRIBUTE,
          "boolean": false
        }]
      }
    })
  }),
      data = _useNavbarsQuery.data,
      loading = _useNavbarsQuery.loading;

  var navbars = useMemo(function () {
    return mapEdges(data === null || data === void 0 ? void 0 : data.menus);
  }, [data]);
  useEffect(function () {
    navbars.map(function (item) {
      switch (item.slug) {
        case MAIN_NAVBAR:
          {
            dispatch({
              type: "SET_MAIN_NAVBAR_DATA",
              payload: {
                navbar: item,
                paths: {
                  lowPriceZone: path.low_price_zone.$url()
                },
                dynamicPaths: {
                  productsCollection: function productsCollection(collectionId) {
                    return path.products._collectionId(collectionId).$url();
                  }
                },
                t: t
              }
            });
            break;
          }

        case FOOTER_NAVBAR:
          {
            dispatch({
              type: "SET_FOOTER_NAVBAR_DATA",
              payload: {
                footer: item,
                paths: {
                  lowPriceZone: path.low_price_zone.$url()
                },
                dynamicPaths: {
                  productsCollection: function productsCollection(collectionId) {
                    return path.products._collectionId(collectionId).$url();
                  }
                },
                t: t
              }
            });
            break;
          }
      }
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navbars]);
  useEffect(function () {
    dispatch({
      type: "SET_MAIN_NAVBAR_LOADING",
      payload: loading
    });
    dispatch({
      type: "SET_FOOTER_NAVBAR_LOADING",
      payload: loading
    });
  }, [loading]);
  var value = useMemo(function () {
    return _objectSpread({}, state);
  }, [state]);
  return __jsx(Provider, _extends({
    value: value
  }, props));
};