import { useEffect, useState } from "react";

var getMatch = function getMatch(query) {
  return window.matchMedia(query);
};

var parseQueryString = function parseQueryString(query) {
  return query.replace(/@media only screen and/g, "").trim();
};

export var useMediaQuery = function useMediaQuery(query) {
  var defaultState = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  var parseAndMatch = function parseAndMatch(s) {
    return getMatch(parseQueryString(s));
  };

  var _useState = useState(defaultState),
      state = _useState[0],
      setState = _useState[1];

  useEffect(function () {
    var mounted = true;
    var mql = parseAndMatch(query);

    var onChange = function onChange() {
      if (!mounted) return;
      setState(!!mql.matches);
    };

    if (mql.addEventListener) {
      mql.addEventListener("change", onChange);
    } else {
      mql.addListener(onChange); // iOS 13 and below
    }

    setState(mql.matches);
    return function () {
      mounted = false;

      if (mql.removeEventListener) {
        mql.removeEventListener("change", onChange);
      } else {
        mql.removeListener(onChange); // iOS 13 and below
      }
    };
  }, [query]);
  return state;
};