import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from "react";

var SvgOutlineBlog = function SvgOutlineBlog(props) {
  return __jsx("svg", _extends({
    width: 25,
    height: 25,
    viewBox: "0 0 25 25",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("g", {
    clipPath: "url(#clip0_9577_11429)"
  }, __jsx("path", {
    d: "M12.7227 9.10449C12.7227 8.30884 13.0387 7.54578 13.6013 6.98317C14.1639 6.42056 14.927 6.10449 15.7227 6.10449H21.7227C21.9216 6.10449 22.1123 6.18351 22.253 6.32416C22.3936 6.46481 22.4727 6.65558 22.4727 6.85449V18.8545C22.4727 19.0534 22.3936 19.2442 22.253 19.3848C22.1123 19.5255 21.9216 19.6045 21.7227 19.6045H15.7227C14.927 19.6045 14.1639 19.9206 13.6013 20.4832C13.0387 21.0458 12.7227 21.8088 12.7227 22.6045",
    stroke: props.stroke || "#5D5656",
    strokeWidth: 1.4,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __jsx("path", {
    d: "M2.97266 18.8545C2.97266 19.0534 3.05167 19.2442 3.19233 19.3848C3.33298 19.5255 3.52374 19.6045 3.72266 19.6045H9.72266C10.5183 19.6045 11.2814 19.9206 11.844 20.4832C12.4066 21.0458 12.7227 21.8088 12.7227 22.6045V9.10449C12.7227 8.30884 12.4066 7.54578 11.844 6.98317C11.2814 6.42056 10.5183 6.10449 9.72266 6.10449H3.72266C3.52374 6.10449 3.33298 6.18351 3.19233 6.32416C3.05167 6.46481 2.97266 6.65558 2.97266 6.85449V18.8545Z",
    stroke: props.stroke || "#5D5656",
    strokeWidth: 1.4,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __jsx("path", {
    d: "M15.7227 9.85449H19.4727",
    stroke: props.stroke || "#5D5656",
    strokeWidth: 1.4,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __jsx("path", {
    d: "M15.7227 12.8545H19.4727",
    stroke: props.stroke || "#5D5656",
    strokeWidth: 1.4,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __jsx("path", {
    d: "M15.7227 15.8545H19.4727",
    stroke: props.stroke || "#5D5656",
    strokeWidth: 1.4,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  })), __jsx("defs", null, __jsx("clipPath", {
    id: "clip0_9577_11429"
  }, __jsx("rect", {
    width: 24,
    height: 24,
    fill: "white",
    transform: "translate(0.722656 0.854492)"
  }))));
};

export default SvgOutlineBlog;