var __jsx = React.createElement;
import { Dialog } from "@headlessui/react";
import { isFunction } from "lodash";
import React from "react";
import { down } from "@/helpers/screens.helpers";
import { useMediaQuery } from "@/hooks/useMediaQuery.hook";
import { ScreenEnum } from "@/theme/theme.enums";
import styles from "./Sidebar.styles";

var Sidebar = function Sidebar(_ref) {
  var isOpen = _ref.isOpen,
      closeAction = _ref.closeAction,
      _ref$position = _ref.position,
      position = _ref$position === void 0 ? "left" : _ref$position,
      _ref$width = _ref.width,
      width = _ref$width === void 0 ? "25rem" : _ref$width,
      _ref$withBg = _ref.withBg,
      withBg = _ref$withBg === void 0 ? true : _ref$withBg,
      _ref$closeOnOuterClic = _ref.closeOnOuterClick,
      closeOnOuterClick = _ref$closeOnOuterClic === void 0 ? true : _ref$closeOnOuterClic,
      children = _ref.children;
  var isMobile = useMediaQuery(down(ScreenEnum.sm), true);

  var onClose = function onClose() {
    if (closeOnOuterClick && isOpen) {
      closeAction();
    }
  };

  return __jsx(Dialog, {
    as: "div",
    open: isOpen,
    onClose: onClose
  }, withBg && __jsx("div", {
    className: styles.sidebarBg()
  }), __jsx("div", {
    className: styles.sidebar({
      position: position
    }),
    css: {
      width: isMobile ? "100%" : width
    }
  }, __jsx(Dialog.Panel, {
    className: styles.dialogPanel()
  }, isFunction(children) ? children(closeAction) : children)));
};

Sidebar.displayName = "Sidebar";
export default Sidebar;