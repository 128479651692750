import _slicedToArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
import Link from "next/link";
import React from "react";
import { useIntl } from "react-intl";
import { Image, Text } from "@/components/common";
import { Button, Rating } from "@/components/ui";
import { Check } from "@/components/ui/Icon/$";
import { getIconFromProductAttribute } from "@/helpers/product.helpers";
import usePath from "@/hooks/usePath.hook";
import { formatDate } from "@/libraries/dayjs.library";
import colors from "@/theme/theme.colors.tw";
import styles from "./ReviewsListItem.styles";

var ReviewsListItem = function ReviewsListItem(_ref) {
  var _review$variant, _review$variant2, _review$variant$produ;

  var review = _ref.review,
      _ref$showReviewDate = _ref.showReviewDate,
      showReviewDate = _ref$showReviewDate === void 0 ? false : _ref$showReviewDate;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      isExpanded = _React$useState2[0],
      setIsExpanded = _React$useState2[1];

  var t = useIntl();

  var _React$useState3 = React.useState(0),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      maxTextHeight = _React$useState4[0],
      setMaxTextHeight = _React$useState4[1];

  var _usePath = usePath(),
      _slug = _usePath._slug;

  var textRef = React.useRef(null);
  var iconUrl = getIconFromProductAttribute(review === null || review === void 0 || (_review$variant = review.variant) === null || _review$variant === void 0 ? void 0 : _review$variant.product);

  var toggleExpansion = function toggleExpansion() {
    setIsExpanded(function (prevState) {
      return !prevState;
    });
  };

  var getTextHeightBeforeExpand = function getTextHeightBeforeExpand(maxHeight) {
    if (maxHeight > 0 && maxHeight <= 24) {
      return 24;
    } else {
      return 48;
    }
  };

  React.useEffect(function () {
    var getTextMaxHeight = function getTextMaxHeight() {
      if (textRef.current) {
        var scrollHeight = textRef.current.scrollHeight;
        return scrollHeight;
      }

      return 0;
    };

    setMaxTextHeight(getTextMaxHeight());
    window.addEventListener("resize", function () {
      setMaxTextHeight(getTextMaxHeight());
    });
    return window.removeEventListener("resize", function () {
      setMaxTextHeight(getTextMaxHeight());
    });
  }, [review]);
  return __jsx("div", {
    className: styles.root()
  }, __jsx("div", {
    className: styles.details()
  }, __jsx("span", {
    className: styles.detailsCustomer()
  }, review.customer), review.customerAge ? __jsx("span", null, t.formatMessage({
    id: "reviews.listItem.customerAge",
    defaultMessage: "{age} {age, plural, one {year} other {years}} old"
  }, {
    age: review.customerAge
  })) : null, review.createDate && showReviewDate ? __jsx("span", null, formatDate({
    date: review.createDate,
    mode: "date"
  })) : null), review.verifiedCustomer ? __jsx("div", {
    className: styles.verifiedCustomer()
  }, __jsx(Check, {
    className: styles.verifiedCustomerIcon()
  }), t.formatMessage({
    id: "reviews.listItem.verifiedCustomer",
    defaultMessage: "Verified customer"
  })) : null, (_review$variant2 = review.variant) !== null && _review$variant2 !== void 0 && (_review$variant2 = _review$variant2.product) !== null && _review$variant2 !== void 0 && _review$variant2.name ? (_review$variant$produ = review.variant.product) !== null && _review$variant$produ !== void 0 && _review$variant$produ.slug ? __jsx(Link, {
    className: styles.productLink(),
    href: _slug(review.variant.product.slug).$url(),
    passHref: true
  }, iconUrl !== null && iconUrl !== void 0 && iconUrl.length ? __jsx(Image, {
    src: iconUrl,
    alt: "".concat(review.variant.product.name, " icon"),
    unoptimized: true,
    width: 24,
    height: 24,
    className: styles.productNameIcon()
  }) : null, review.variant.product.name) : __jsx("div", {
    className: styles.productName()
  }, iconUrl !== null && iconUrl !== void 0 && iconUrl.length ? __jsx(Image, {
    src: iconUrl,
    alt: "".concat(review.variant.product.name, " icon"),
    unoptimized: true,
    width: 24,
    height: 24,
    className: styles.productNameIcon()
  }) : null, review.variant.product.name) : null, __jsx(Rating, {
    className: styles.rating(),
    rating: review.rating,
    iconMainColor: colors.flavor["default"].warning,
    iconSecondaryColor: colors.common.gray1,
    iconSize: 17
  }), __jsx("div", {
    className: styles.textContainer(),
    style: {
      maxHeight: isExpanded ? maxTextHeight : getTextHeightBeforeExpand(maxTextHeight)
    },
    ref: textRef
  }, __jsx(Text, {
    text: review.text
  })), maxTextHeight > 48 ? __jsx(Button, {
    variant: "naked",
    color: "flavor",
    className: styles.readMoreButton(),
    onClick: toggleExpansion
  }, t.formatMessage(!isExpanded ? {
    id: "reviews.listItem.readMoreButton",
    defaultMessage: "Read more"
  } : {
    id: "reviews.listItem.readLessButton",
    defaultMessage: "Read less"
  })) : null);
};

ReviewsListItem.displayName = "ReviewsListItem";
export default ReviewsListItem;