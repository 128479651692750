import _slicedToArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
import { Bars3Icon, ChevronLeftIcon, MagnifyingGlassIcon as SearchIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useAuthState } from "@saleor/sdk";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { Fragment } from "react";
import { useIntl } from "react-intl";
import { Button, Container } from "@/components/ui";
import { User as UserIcon } from "@/components/ui/Icon/$";
import CartIcon from "@/components/ui/Icon/$/Cart.icon";
import LovaIcon from "@/components/ui/Icon/$/Lova.icon";
import NavbarDesktop from "@/components/ui/Navbar/components/NavbarDesktop/NavbarDesktop.component";
import NavbarMobile from "@/components/ui/Navbar/components/NavbarMobile/NavbarMobile.component";
import useCheckout from "@/hooks/useCheckout.hook";
import { useGlobal } from "@/hooks/useGlobal.hook";
import useInterface from "@/hooks/useInterface.hook";
import { useNavbar } from "@/hooks/useNavbar.hook";
import usePath from "@/hooks/usePath.hook";
import { cn } from "@/libraries/clsx.library";
import styles from "./Navbar.styles";

var Navbar = function Navbar(_ref) {
  var _ref$withBorder = _ref.withBorder,
      withBorder = _ref$withBorder === void 0 ? false : _ref$withBorder;
  var router = useRouter();
  var path = usePath();
  var t = useIntl();

  var _useGlobal = useGlobal(),
      lovaAppForEveryOrder = _useGlobal.lovaAppForEveryOrder;

  var _useAuthState = useAuthState(),
      authenticated = _useAuthState.authenticated;

  var _useNavbar = useNavbar(),
      mainNavbarData = _useNavbar.mainNavbar;

  var _useInterface = useInterface(),
      activeDialog = _useInterface.activeDialog,
      openDialog = _useInterface.openDialog,
      closeDialog = _useInterface.closeDialog,
      locked = _useInterface.locked;

  var _useCheckout = useCheckout(),
      checkout = _useCheckout.checkout;

  var _React$useState = React.useState([{
    id: "main",
    name: t.formatMessage({
      id: "navbar.mobile.mainGroup",
      defaultMessage: "Main"
    }),
    links: mainNavbarData.links || []
  }]),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      navbarMobileNestedGroups = _React$useState2[0],
      setNavbarMobileNestedGroups = _React$useState2[1];

  var resetNavbarMobileNestedGroups = React.useCallback(function () {
    setNavbarMobileNestedGroups([{
      id: "main",
      name: t.formatMessage({
        id: "navbar.mobile.mainGroup",
        defaultMessage: "Main"
      }),
      links: mainNavbarData.links || []
    }]);
  }, [mainNavbarData.links, t]);
  var checkoutLinesCount = React.useMemo(function () {
    var _checkout$lines, _linesCount;

    var linesCount = checkout === null || checkout === void 0 || (_checkout$lines = checkout.lines) === null || _checkout$lines === void 0 ? void 0 : _checkout$lines.reduce(function (acc, line) {
      return line ? acc + line.quantity : acc;
    }, 0);

    if (linesCount && lovaAppForEveryOrder) {
      linesCount += 1;
    }

    return (_linesCount = linesCount) !== null && _linesCount !== void 0 ? _linesCount : 0;
  }, [checkout === null || checkout === void 0 ? void 0 : checkout.lines, lovaAppForEveryOrder]);
  var showUserIcon = React.useMemo(function () {
    return router.pathname !== path.login.$url().pathname && router.pathname !== path.register.$url().pathname && router.pathname !== path.register.success.$url().pathname && router.pathname !== path.password_reset.$url().pathname && router.pathname !== path.password_reset.success.$url().pathname && !router.pathname.includes("account"); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);
  React.useEffect(function () {
    var down = function down(e) {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        openDialog("SEARCH");
      }
    };

    document.addEventListener("keydown", down);
    return function () {
      return document.removeEventListener("keydown", down);
    };
  }, [openDialog]);
  return __jsx(Fragment, null, __jsx("div", {
    className: styles.root({
      withBorder: withBorder
    }),
    id: "__nav"
  }, __jsx(NavbarMobile, {
    navbarData: mainNavbarData,
    navbarMobileNestedGroups: navbarMobileNestedGroups,
    setNavbarMobileNestedGroups: setNavbarMobileNestedGroups,
    resetNavbarMobileNestedGroups: resetNavbarMobileNestedGroups,
    testIdPrefix: "default-navbar-mobile-"
  }), __jsx(Container, {
    color: "white"
  }, __jsx("nav", {
    className: styles.nav()
  }, __jsx("div", {
    className: cn(styles.navLeft(), styles.hidden())
  }, __jsx(React.Fragment, null, activeDialog === "NAVBAR" && navbarMobileNestedGroups.length === 1 ? __jsx(Button, {
    "aria-label": t.formatMessage({
      id: "common.close",
      defaultMessage: "close"
    }),
    className: styles.button(),
    variant: "naked",
    color: "dark",
    onClick: function onClick() {
      return closeDialog();
    },
    disabled: locked
  }, __jsx(XMarkIcon, {
    className: styles.icon()
  })) : activeDialog === "NAVBAR" && navbarMobileNestedGroups.length >= 2 ? __jsx(Button, {
    "aria-label": t.formatMessage({
      id: "navbar.back",
      defaultMessage: "back"
    }),
    className: styles.button(),
    variant: "naked",
    color: "dark",
    onClick: function onClick() {
      setNavbarMobileNestedGroups(function (currentSate) {
        return currentSate.slice(0, -1);
      });
    },
    disabled: locked
  }, __jsx(ChevronLeftIcon, {
    className: styles.icon()
  })) : __jsx(Button, {
    "aria-label": t.formatMessage({
      id: "navbar.openNavbar",
      defaultMessage: "open navbar"
    }),
    className: styles.button(),
    variant: "naked",
    color: "dark",
    onClick: function onClick() {
      return openDialog("NAVBAR");
    },
    disabled: locked,
    "data-testid": "default-navbar-mobile-open-button",
    id: "test"
  }, __jsx(Bars3Icon, {
    className: styles.icon()
  }))), __jsx(Button, {
    "aria-label": t.formatMessage({
      id: "navbar.default.search",
      defaultMessage: "search"
    }),
    className: cn(styles.button(), styles.hiddenMd()),
    variant: "naked",
    color: "dark",
    onClick: function onClick() {
      return openDialog("SEARCH");
    }
  }, __jsx(SearchIcon, {
    width: 22,
    height: 22
  }))), __jsx(NavbarDesktop, {
    navbarData: mainNavbarData
  }), __jsx(Link, {
    "aria-label": t.formatMessage({
      id: "common.homePage",
      defaultMessage: "home page"
    }),
    href: path.$url(),
    passHref: true
  }, __jsx(LovaIcon, null)), __jsx("div", {
    className: styles.navRight()
  }, __jsx(Button, {
    "aria-label": t.formatMessage({
      id: "navbar.default.search",
      defaultMessage: "search"
    }),
    className: cn(styles.button(), styles.visibleMd()),
    variant: "naked",
    color: "dark",
    onClick: function onClick() {
      return openDialog("SEARCH");
    }
  }, __jsx(SearchIcon, {
    width: 22,
    height: 22
  })), showUserIcon ? __jsx(Button, {
    className: cn(styles.button(), styles.accountButton()),
    variant: "naked",
    color: "dark",
    onClick: function onClick() {
      return router.push(authenticated ? path.account.profile.$url() : path.login.$url());
    }
  }, __jsx(UserIcon, {
    width: 22,
    height: 22
  })) : null, __jsx(Button, {
    "aria-label": t.formatMessage({
      id: "navbar.default.openCart",
      defaultMessage: "open cart"
    }),
    className: styles.button({
      disabled: checkoutLinesCount === 0
    }),
    variant: "naked",
    color: "dark",
    onClick: function onClick() {
      return openDialog("CART");
    },
    disabled: locked,
    "data-testid": "default-navbar-open-cart-button"
  }, __jsx(CartIcon, {
    className: styles.cartIcon({
      locked: locked
    })
  }), __jsx("span", {
    className: styles.counter()
  }, checkoutLinesCount)))))));
};

Navbar.displayName = "Navbar";
export default Navbar;