var __jsx = React.createElement;
import { RadioGroup as Source } from "@headlessui/react";
import React from "react";
import styles from "./Radio.styles";

var Item = function Item(props) {
  return __jsx(Source.Option, {
    key: props.key,
    value: props.key,
    className: function className(_ref) {
      var checked = _ref.checked;
      return styles.option({
        checked: checked,
        first: props.first,
        last: props.last,
        disabled: props.disabled
      });
    },
    disabled: props.disabled
  }, function (_ref2) {
    var active = _ref2.active,
        checked = _ref2.checked,
        disabled = _ref2.disabled;
    return __jsx(React.Fragment, null, __jsx("div", {
      className: styles.main()
    }, __jsx("div", {
      className: styles.select()
    }, __jsx("span", {
      className: styles.dot({
        active: active,
        checked: checked,
        disabled: disabled
      })
    }, __jsx("span", {
      className: styles.dotInner()
    }))), __jsx("div", {
      className: styles.content()
    }, props.content)), props.caption ? __jsx("div", {
      className: styles.caption()
    }, props.caption) : null);
  });
};

Item.displayName = "Item";
export default Item;