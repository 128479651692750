import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { globalCss } from "@/libraries/stitches.library";
var css = {
  "::-webkit-scrollbar": {
    height: "11px",
    width: "11px"
  },
  "::-webkit-scrollbar-button": {
    display: "none",
    height: 0,
    width: 0
  },
  "::-webkit-scrollbar-corner": {
    backgroundColor: "transparent"
  },
  "::-webkit-scrollbar-thumb": {
    backgroundClip: "padding-box",
    backgroundColor: "$gray5",
    borderLeft: "3px solid transparent",
    borderRight: "2px solid transparent"
  },
  "::-webkit-scrollbar-track": {
    background: "$gray1",
    borderLeft: "solid 1px $gray2"
  },
  html: {
    scrollbarColor: "$gray1 $gray2",
    scrollbarWidth: "thin"
  },
  body: _objectSpread({
    WebkitTapHighlightColor: "transparent",
    backgroundColor: "hsl(359, 4%, 95%)",
    overflow: "visible"
  }, {
    "WebkitFontSmoothing": "antialiased",
    "MozOsxFontSmoothing": "grayscale"
  }),
  "@font-face": [{
    fontFamily: "Inria",
    fontStyle: "normal",
    fontWeight: 400,
    fontDisplay: "swap",
    src: "url('/fonts/Inria-Regular.woff2') format('woff2')"
  }, {
    fontFamily: "Inria",
    fontStyle: "italic",
    fontWeight: 400,
    fontDisplay: "swap",
    src: "url('/fonts/Inria-Regular-Italic.woff2') format('woff2')"
  }, {
    fontFamily: "Inria",
    fontStyle: "normal",
    fontWeight: 700,
    fontDisplay: "swap",
    src: "url('/fonts/Inria-Bold.woff2') format('woff2')"
  }, {
    fontFamily: "Inria",
    fontStyle: "italic",
    fontWeight: 700,
    fontDisplay: "swap",
    src: "url('/fonts/Inria-Bold-Italic.woff2') format('woff2')"
  }, {
    fontFamily: "Inter",
    fontWeight: "100 900",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontNamedInstance: "Regular",
    src: "url('/fonts/Inter-Variable.woff2') format('woff2')"
  }, {
    fontFamily: "Tenor",
    fontStyle: "normal",
    fontWeight: 400,
    fontDisplay: "swap",
    src: "url('/fonts/Tenor-Regular.woff2') format('woff2')"
  }]
};
/* eslint-disable @typescript-eslint/no-explicit-any */

var styles = function styles() {
  globalCss(css)();
  globalCss({
    "*, ::before, ::after": {
      "boxSizing": "border-box",
      "borderWidth": "0",
      "borderStyle": "solid",
      "borderColor": "#e5e7eb",
      "--tw-border-spacing-x": "0",
      "--tw-border-spacing-y": "0",
      "--tw-translate-x": "0",
      "--tw-translate-y": "0",
      "--tw-rotate": "0",
      "--tw-skew-x": "0",
      "--tw-skew-y": "0",
      "--tw-scale-x": "1",
      "--tw-scale-y": "1",
      "--tw-pan-x": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-pan-y": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-pinch-zoom": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-scroll-snap-strictness": "proximity",
      "--tw-ordinal": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-slashed-zero": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-numeric-figure": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-numeric-spacing": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-numeric-fraction": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-ring-offset-shadow": "0 0 #0000",
      "--tw-ring-shadow": "0 0 #0000",
      "--tw-shadow": "0 0 #0000",
      "--tw-shadow-colored": "0 0 #0000",
      "--tw-ring-inset": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-ring-offset-width": "0px",
      "--tw-ring-offset-color": "#fff",
      "--tw-ring-color": "rgb(59 130 246 / 0.5)",
      "--tw-blur": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-brightness": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-contrast": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-grayscale": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-hue-rotate": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-invert": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-saturate": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-sepia": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-drop-shadow": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-backdrop-blur": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-backdrop-brightness": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-backdrop-contrast": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-backdrop-grayscale": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-backdrop-hue-rotate": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-backdrop-invert": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-backdrop-opacity": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-backdrop-saturate": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-backdrop-sepia": "var(--tw-empty,/*!*/ /*!*/)"
    },
    "::before, ::after": {
      "--tw-content": "''"
    },
    "html": {
      "lineHeight": "1.5",
      "WebkitTextSizeAdjust": "100%",
      "MozTabSize": "4",
      "tabSize": "4",
      "fontFamily": "Inter, -apple-system, sans-serif",
      "fontFeatureSettings": " normal",
      "fontVariationSettings": " normal"
    },
    "body": {
      "margin": "0",
      "lineHeight": "inherit"
    },
    "hr": {
      "height": "0",
      "color": "inherit",
      "borderTopWidth": "1px"
    },
    "abbr:where([title])": {
      "textDecoration": "underline dotted"
    },
    "h1, h2, h3, h4, h5, h6": {
      "fontSize": "inherit",
      "fontWeight": "inherit"
    },
    "a": {
      "color": "inherit",
      "textDecoration": "inherit"
    },
    "b, strong": {
      "fontWeight": "bolder"
    },
    "code, kbd, samp, pre": {
      "fontFamily": "ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, \"Liberation Mono\", \"Courier New\", monospace",
      "fontSize": "1em"
    },
    "small": {
      "fontSize": "80%"
    },
    "sub, sup": {
      "fontSize": "75%",
      "lineHeight": "0",
      "position": "relative",
      "verticalAlign": "baseline"
    },
    "sub": {
      "bottom": "-0.25em"
    },
    "sup": {
      "top": "-0.5em"
    },
    "table": {
      "textIndent": "0",
      "borderColor": "inherit",
      "borderCollapse": "collapse"
    },
    "button, input, optgroup, select, textarea": {
      "fontFamily": "inherit",
      "fontSize": "100%",
      "fontWeight": "inherit",
      "lineHeight": "inherit",
      "color": "inherit",
      "margin": "0",
      "padding": "0"
    },
    "button, select": {
      "textTransform": "none"
    },
    "button, [type='button'], [type='reset'], [type='submit']": {
      "WebkitAppearance": "button",
      "backgroundColor": "transparent",
      "backgroundImage": "none"
    },
    ":-moz-focusring": {
      "outline": "auto"
    },
    ":-moz-ui-invalid": {
      "boxShadow": "none"
    },
    "progress": {
      "verticalAlign": "baseline"
    },
    "::-webkit-inner-spin-button, ::-webkit-outer-spin-button": {
      "height": "auto"
    },
    "[type='search']": {
      "WebkitAppearance": "textfield",
      "outlineOffset": "-2px"
    },
    "::-webkit-search-decoration": {
      "WebkitAppearance": "none"
    },
    "::-webkit-file-upload-button": {
      "WebkitAppearance": "button",
      "font": "inherit"
    },
    "summary": {
      "display": "list-item"
    },
    "blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre": {
      "margin": "0"
    },
    "fieldset": {
      "margin": "0",
      "padding": "0"
    },
    "legend": {
      "padding": "0"
    },
    "ol, ul, menu": {
      "listStyle": "none",
      "margin": "0",
      "padding": "0"
    },
    "textarea": {
      "resize": "vertical"
    },
    "input::placeholder, textarea::placeholder": {
      "opacity": "1",
      "color": "#9ca3af"
    },
    "button, [role=\"button\"]": {
      "cursor": "pointer"
    },
    ":disabled": {
      "cursor": "default"
    },
    "img, svg, video, canvas, audio, iframe, embed, object": {
      "display": "block",
      "verticalAlign": "middle"
    },
    "img, video": {
      "maxWidth": "100%",
      "height": "auto"
    },
    "[hidden]": {
      "display": "none"
    },
    "::backdrop": {
      "--tw-border-spacing-x": "0",
      "--tw-border-spacing-y": "0",
      "--tw-translate-x": "0",
      "--tw-translate-y": "0",
      "--tw-rotate": "0",
      "--tw-skew-x": "0",
      "--tw-skew-y": "0",
      "--tw-scale-x": "1",
      "--tw-scale-y": "1",
      "--tw-pan-x": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-pan-y": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-pinch-zoom": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-scroll-snap-strictness": "proximity",
      "--tw-ordinal": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-slashed-zero": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-numeric-figure": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-numeric-spacing": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-numeric-fraction": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-ring-offset-shadow": "0 0 #0000",
      "--tw-ring-shadow": "0 0 #0000",
      "--tw-shadow": "0 0 #0000",
      "--tw-shadow-colored": "0 0 #0000",
      "--tw-ring-inset": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-ring-offset-width": "0px",
      "--tw-ring-offset-color": "#fff",
      "--tw-ring-color": "rgb(59 130 246 / 0.5)",
      "--tw-blur": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-brightness": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-contrast": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-grayscale": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-hue-rotate": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-invert": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-saturate": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-sepia": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-drop-shadow": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-backdrop-blur": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-backdrop-brightness": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-backdrop-contrast": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-backdrop-grayscale": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-backdrop-hue-rotate": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-backdrop-invert": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-backdrop-opacity": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-backdrop-saturate": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-backdrop-sepia": "var(--tw-empty,/*!*/ /*!*/)"
    },
    "@keyframes enter": {
      "from": {
        "opacity": "var(--tw-enter-opacity, 1)",
        "transform": "translate3d(var(--tw-enter-translate-x, 0), var(--tw-enter-translate-y, 0), 0) scale3d(var(--tw-enter-scale, 1), var(--tw-enter-scale, 1), var(--tw-enter-scale, 1)) rotate(var(--tw-enter-rotate, 0))"
      }
    },
    "@keyframes exit": {
      "to": {
        "opacity": "var(--tw-exit-opacity, 1)",
        "transform": "translate3d(var(--tw-exit-translate-x, 0), var(--tw-exit-translate-y, 0), 0) scale3d(var(--tw-exit-scale, 1), var(--tw-exit-scale, 1), var(--tw-exit-scale, 1)) rotate(var(--tw-exit-rotate, 0))"
      }
    },
    "[type='text'],[type='email'],[type='url'],[type='password'],[type='number'],[type='date'],[type='datetime-local'],[type='month'],[type='search'],[type='tel'],[type='time'],[type='week'],[multiple],textarea,select": {
      "appearance": "none",
      "backgroundColor": "#fff",
      "borderColor": "#6b7280",
      "borderWidth": "1px",
      "borderRadius": "0px",
      "paddingTop": "0.5rem",
      "paddingRight": "0.75rem",
      "paddingBottom": "0.5rem",
      "paddingLeft": "0.75rem",
      "fontSize": "1rem",
      "lineHeight": "1.5rem",
      "--tw-shadow": "0 0 #0000"
    },
    "[type='text']:focus, [type='email']:focus, [type='url']:focus, [type='password']:focus, [type='number']:focus, [type='date']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, textarea:focus, select:focus": {
      "outline": "2px solid transparent",
      "outlineOffset": "2px",
      "--tw-ring-inset": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-ring-offset-width": "0px",
      "--tw-ring-offset-color": "#fff",
      "--tw-ring-color": "#2563eb",
      "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
      "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
      "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow)",
      "borderColor": "#2563eb"
    },
    "input::placeholder,textarea::placeholder": {
      "color": "#6b7280",
      "opacity": "1"
    },
    "::-webkit-datetime-edit-fields-wrapper": {
      "padding": "0"
    },
    "::-webkit-date-and-time-value": {
      "minHeight": "1.5em"
    },
    "::-webkit-datetime-edit,::-webkit-datetime-edit-year-field,::-webkit-datetime-edit-month-field,::-webkit-datetime-edit-day-field,::-webkit-datetime-edit-hour-field,::-webkit-datetime-edit-minute-field,::-webkit-datetime-edit-second-field,::-webkit-datetime-edit-millisecond-field,::-webkit-datetime-edit-meridiem-field": {
      "paddingTop": "0",
      "paddingBottom": "0"
    },
    "select": {
      "backgroundImage": "url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e\")",
      "backgroundPosition": "right 0.5rem center",
      "backgroundRepeat": "no-repeat",
      "backgroundSize": "1.5em 1.5em",
      "paddingRight": "2.5rem",
      "printColorAdjust": "exact"
    },
    "[multiple]": {
      "backgroundImage": "initial",
      "backgroundPosition": "initial",
      "backgroundRepeat": "unset",
      "backgroundSize": "initial",
      "paddingRight": "0.75rem",
      "printColorAdjust": "unset"
    },
    "[type='checkbox'],[type='radio']": {
      "appearance": "none",
      "padding": "0",
      "printColorAdjust": "exact",
      "display": "inline-block",
      "verticalAlign": "middle",
      "backgroundOrigin": "border-box",
      "userSelect": "none",
      "flexShrink": "0",
      "height": "1rem",
      "width": "1rem",
      "color": "#2563eb",
      "backgroundColor": "#fff",
      "borderColor": "#6b7280",
      "borderWidth": "1px",
      "--tw-shadow": "0 0 #0000"
    },
    "[type='checkbox']": {
      "borderRadius": "0px"
    },
    "[type='radio']": {
      "borderRadius": "100%"
    },
    "[type='checkbox']:focus,[type='radio']:focus": {
      "outline": "2px solid transparent",
      "outlineOffset": "2px",
      "--tw-ring-inset": "var(--tw-empty,/*!*/ /*!*/)",
      "--tw-ring-offset-width": "2px",
      "--tw-ring-offset-color": "#fff",
      "--tw-ring-color": "#2563eb",
      "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
      "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
      "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow)"
    },
    "[type='checkbox']:checked,[type='radio']:checked": {
      "borderColor": "transparent",
      "backgroundColor": "currentColor",
      "backgroundSize": "100% 100%",
      "backgroundPosition": "center",
      "backgroundRepeat": "no-repeat"
    },
    "[type='checkbox']:checked": {
      "backgroundImage": "url(\"data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e\")"
    },
    "[type='radio']:checked": {
      "backgroundImage": "url(\"data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e\")"
    },
    "[type='checkbox']:checked:hover,[type='checkbox']:checked:focus,[type='radio']:checked:hover,[type='radio']:checked:focus": {
      "borderColor": "transparent",
      "backgroundColor": "currentColor"
    },
    "[type='checkbox']:indeterminate": {
      "backgroundImage": "url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e\")",
      "borderColor": "transparent",
      "backgroundColor": "currentColor",
      "backgroundSize": "100% 100%",
      "backgroundPosition": "center",
      "backgroundRepeat": "no-repeat"
    },
    "[type='checkbox']:indeterminate:hover,[type='checkbox']:indeterminate:focus": {
      "borderColor": "transparent",
      "backgroundColor": "currentColor"
    },
    "[type='file']": {
      "background": "unset",
      "borderColor": "inherit",
      "borderWidth": "0",
      "borderRadius": "0",
      "padding": "0",
      "fontSize": "unset",
      "lineHeight": "inherit"
    },
    "[type='file']:focus": {
      "outline": "1px auto -webkit-focus-ring-color"
    },
    "@keyframes spin": {
      "to": {
        "transform": "rotate(360deg)"
      }
    },
    "@keyframes ping": {
      "75%, 100%": {
        "transform": "scale(2)",
        "opacity": "0"
      }
    },
    "@keyframes pulse": {
      "50%": {
        "opacity": ".5"
      }
    },
    "@keyframes bounce": {
      "0%, 100%": {
        "transform": "translateY(-25%)",
        "animationTimingFunction": "cubic-bezier(0.8,0,1,1)"
      },
      "50%": {
        "transform": "none",
        "animationTimingFunction": "cubic-bezier(0,0,0.2,1)"
      }
    },
    "@keyframes custom-bounce": {
      "0%, 20%, 50%, 80%, 100%": {
        "transform": "translateY(0)"
      },
      "40%": {
        "transform": "translateY(-50%)"
      },
      "60%": {
        "transform": "translateY(-25%)"
      }
    },
    "@keyframes accordion-down": {
      "from": {
        "height": "0"
      },
      "to": {
        "height": "var(--radix-accordion-content-height)"
      }
    },
    "@keyframes accordion-up": {
      "from": {
        "height": "var(--radix-accordion-content-height)"
      },
      "to": {
        "height": "0"
      }
    }
  })();
};

export default styles;