import Default from "./Navbar.Default";
import Simple from "./Navbar.Simple";
import Static from "./Navbar.Static"; //prettier-ignore

var Navbar = function Navbar() {
  return null;
};

Navbar.Simple = Simple;
Navbar.Default = Default;
Navbar.Static = Static;
Navbar.displayName = "Navbar";
export default Navbar;