export function getConsentTypesBasedOnCookieGroups(consentGroups) {
  var consentTypes = [];
  consentGroups.forEach(function (consentGroup) {
    switch (consentGroup) {
      case "functional":
        consentTypes.push("functionality_storage", "personalization_storage", "security_storage");
        break;

      case "analytics":
        consentTypes.push("analytics_storage");
        break;

      case "marketing":
        consentTypes.push("ad_storage", "ad_user_data", "ad_personalization");
        break;
    }
  });
  return consentTypes;
}
export function getConsentConfigurationBasedOnConsentTypes(consentTypes, consentValue) {
  return consentTypes.reduce(function (acc, consentType) {
    acc[consentType] = consentValue;
    return acc;
  }, {});
}