import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from "react";

var SvgCart = function SvgCart(props) {
  return __jsx("svg", _extends({
    width: "100%",
    height: "100%",
    viewBox: "0 0 20 20",
    xmlns: "http://www.w3.org/2000/svg",
    xmlnsXlink: "http://www.w3.org/1999/xlink",
    xmlSpace: "preserve",
    style: {
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2
    }
  }, props), __jsx("path", {
    d: "M7.138,19.164C7.99,19.164 8.681,18.473 8.681,17.621C8.681,16.769 7.99,16.078 7.138,16.078C6.286,16.078 5.595,16.769 5.595,17.621C5.595,18.473 6.286,19.164 7.138,19.164Z"
  }), __jsx("path", {
    d: "M15.862,19.164C16.715,19.164 17.406,18.473 17.406,17.621C17.406,16.769 16.715,16.078 15.862,16.078C15.01,16.078 14.319,16.769 14.319,17.621C14.319,18.473 15.01,19.164 15.862,19.164Z"
  }), __jsx("path", {
    d: "M3.351,2.509L5.355,12.526C5.462,13.062 5.755,13.545 6.182,13.889C6.606,14.231 7.137,14.414 7.681,14.405C7.681,14.405 15.382,14.405 15.382,14.405C15.926,14.414 16.457,14.231 16.881,13.889C17.309,13.545 17.601,13.062 17.708,12.524L18.978,5.865C19.02,5.645 18.962,5.418 18.819,5.246C18.677,5.074 18.465,4.974 18.241,4.974L5.374,4.974L4.701,1.612C4.631,1.261 4.323,1.009 3.965,1.009L0.793,1.009C0.379,1.009 0.043,1.345 0.043,1.759C0.043,2.173 0.379,2.509 0.793,2.509L3.351,2.509ZM5.674,6.474L6.827,12.232C6.865,12.425 6.969,12.598 7.122,12.721C7.122,12.721 7.122,12.721 7.122,12.721C7.275,12.844 7.466,12.909 7.663,12.905L15.401,12.905C15.597,12.909 15.788,12.844 15.941,12.721C15.941,12.721 15.941,12.721 15.941,12.721C16.093,12.598 16.198,12.426 16.236,12.233C16.236,12.233 17.335,6.474 17.335,6.474L5.674,6.474Z"
  }));
};

export default SvgCart;