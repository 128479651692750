import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../libraries/stitches.library.ts";
var styles = {
  article: _css(_objectSpread(_objectSpread({}, {
    "display": "flex",
    "flexDirection": "column",
    "> :not([hidden]) ~ :not([hidden])": {
      "--tw-space-y-reverse": "0",
      "marginTop": "calc(0px * calc(1 - var(--tw-space-y-reverse)))",
      "marginBottom": "calc(0px * var(--tw-space-y-reverse))"
    }
  }), {}, {
    variants: {
      spacing: {
        none: _objectSpread({}, {
          "> :not([hidden]) ~ :not([hidden])": {
            "--tw-space-y-reverse": "0",
            "marginTop": "calc(0px * calc(1 - var(--tw-space-y-reverse)))",
            "marginBottom": "calc(0px * var(--tw-space-y-reverse))"
          }
        }),
        small: _objectSpread({}, {
          "> :not([hidden]) ~ :not([hidden])": {
            "--tw-space-y-reverse": "0",
            "marginTop": "calc(1rem * calc(1 - var(--tw-space-y-reverse)))",
            "marginBottom": "calc(1rem * var(--tw-space-y-reverse))"
          }
        }),
        medium: _objectSpread({}, {
          "> :not([hidden]) ~ :not([hidden])": {
            "--tw-space-y-reverse": "0",
            "marginTop": "calc(1.5rem * calc(1 - var(--tw-space-y-reverse)))",
            "marginBottom": "calc(1.5rem * var(--tw-space-y-reverse))"
          }
        }),
        large: _objectSpread({}, {
          "> :not([hidden]) ~ :not([hidden])": {
            "--tw-space-y-reverse": "0",
            "marginTop": "calc(2rem * calc(1 - var(--tw-space-y-reverse)))",
            "marginBottom": "calc(2rem * var(--tw-space-y-reverse))"
          }
        })
      }
    }
  })),
  code: _css(_objectSpread({}, {})),
  delimiter: _css(_objectSpread({}, {
    "marginLeft": "auto",
    "marginRight": "auto",
    "width": "4rem",
    "borderWidth": "1px"
  })),
  embed: _css(_objectSpread({}, {
    "borderWidth": "0px"
  })),
  header: _css(_objectSpread({}, {
    "fontSize": "1.25rem",
    "lineHeight": "1.75rem",
    "fontWeight": "700"
  })),
  image: _css(_objectSpread({}, {
    "width": "100%",
    "maxWidth": "768px"
  })),
  list: _css(_objectSpread({}, {
    "display": "flex",
    "listStylePosition": "inside",
    "listStyleType": "disc",
    "flexDirection": "column",
    "> :not([hidden]) ~ :not([hidden])": {
      "--tw-space-y-reverse": "0",
      "marginTop": "calc(1rem * calc(1 - var(--tw-space-y-reverse)))",
      "marginBottom": "calc(1rem * var(--tw-space-y-reverse))"
    }
  })),
  paragraph: _css(_objectSpread({}, {
    "fontSize": "1rem",
    "lineHeight": "1.5rem"
  })),
  quote: _css(_objectSpread({}, {
    "paddingLeft": "1.25rem",
    "paddingRight": "1.25rem",
    "paddingTop": "0.75rem",
    "paddingBottom": "0.75rem",
    "fontFamily": "Inria, serif, Tenor, serif",
    "fontStyle": "italic"
  })),
  table: _css(_objectSpread({}, {
    "tableLayout": "auto"
  }))
};
export default styles;