var __jsx = React.createElement;
import Link from "next/link";
import React from "react";
import styles from "@/components/ui/Navbar/Navbar.styles";

var NavbarItem = function NavbarItem(_ref) {
  var _ref$isLink = _ref.isLink,
      isLink = _ref$isLink === void 0 ? true : _ref$isLink,
      _ref$linkHref = _ref.linkHref,
      linkHref = _ref$linkHref === void 0 ? "" : _ref$linkHref,
      children = _ref.children;
  return isLink ? __jsx(Link, {
    href: linkHref,
    passHref: true,
    className: styles.navItem()
  }, children) : __jsx("span", {
    className: styles.navItem()
  }, children);
};

export default NavbarItem;