import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread(_objectSpread({}, {
    "overflow": "hidden",
    "borderRadius": "0.5rem",
    "borderWidth": "2px",
    "borderStyle": "solid"
  }), {}, {
    border: "1px solid $gray2",
    variants: {
      fullWidth: {
        "false": _objectSpread({}, {
          "display": "inline-block"
        })
      }
    }
  })),
  table: _css({
    variants: {
      fullWidth: {
        "true": _objectSpread({}, {
          "width": "100%"
        })
      },
      tableFixed: {
        "true": _objectSpread({}, {
          "tableLayout": "fixed"
        }),
        "false": _objectSpread({}, {
          "tableLayout": "auto"
        })
      }
    }
  }),
  header: _css({
    borderBottom: "1px solid $gray2"
  }),
  headerCol: _css(_objectSpread(_objectSpread({}, {
    "display": "table-cell",
    "paddingLeft": "0.5rem",
    "paddingRight": "0.5rem",
    "paddingTop": "0.875rem",
    "paddingBottom": "0.875rem",
    "textAlign": "left",
    "verticalAlign": "bottom",
    "@media (min-width: 640px)": {
      "paddingLeft": "0.75rem",
      "paddingRight": "0.75rem"
    }
  }), {}, {
    fontSize: "$2",
    "&:first-child": _objectSpread({}, {
      "paddingLeft": "0.75rem",
      "@media (min-width: 640px)": {
        "paddingLeft": "1.5rem"
      }
    }),
    "&:last-child": _objectSpread({}, {
      "paddingRight": "0.75rem",
      "@media (min-width: 640px)": {
        "paddingRight": "1.5rem"
      }
    }),
    variants: {
      hidden: {
        "true": {
          display: "none"
        }
      }
    }
  })),
  body: _css(_objectSpread(_objectSpread({}, {
    "> :not([hidden]) ~ :not([hidden])": {
      "--tw-divide-y-reverse": "0",
      "borderTopWidth": "calc(1px * calc(1 - var(--tw-divide-y-reverse)))",
      "borderBottomWidth": "calc(1px * var(--tw-divide-y-reverse))",
      "--tw-divide-opacity": "1",
      "borderColor": "rgb(229 231 235 / var(--tw-divide-opacity))"
    }
  }), {}, {
    "&> :not([hidden]) ~ :not([hidden])": {
      borderColor: "$gray2"
    }
  })),
  bodyCol: _css(_objectSpread(_objectSpread({}, {
    "display": "table-cell",
    "overflowWrap": "break-word",
    "paddingLeft": "0.5rem",
    "paddingRight": "0.5rem",
    "paddingTop": "0.875rem",
    "paddingBottom": "0.875rem",
    "textAlign": "left",
    "verticalAlign": "top",
    "@media (min-width: 640px)": {
      "paddingLeft": "0.75rem",
      "paddingRight": "0.75rem"
    }
  }), {}, {
    fontSize: "$2",
    "&:first-child": _objectSpread({}, {
      "paddingLeft": "0.75rem",
      "@media (min-width: 640px)": {
        "paddingLeft": "1.5rem"
      }
    }),
    "&:last-child": _objectSpread({}, {
      "paddingRight": "0.75rem",
      "@media (min-width: 640px)": {
        "paddingRight": "1.5rem"
      }
    }),
    variants: {
      hidden: {
        "true": {
          display: "none"
        }
      }
    }
  })),
  emptyInfoContainer: _css(_objectSpread(_objectSpread({}, {
    "display": "flex",
    "flexDirection": "column",
    "alignItems": "center",
    "justifyContent": "center",
    "paddingLeft": "1.5rem",
    "paddingRight": "1.5rem",
    "paddingTop": "5rem",
    "paddingBottom": "5rem"
  }), {}, {
    color: "$gray6"
  })),
  emptyIcon: _css(_objectSpread(_objectSpread({}, {
    "width": "2.5rem"
  }), {}, {
    color: "$gray5"
  })),
  footerCol: _css(_objectSpread({}, {
    "paddingLeft": "1.5rem",
    "paddingRight": "1.5rem",
    "paddingTop": "0.875rem",
    "paddingBottom": "0.875rem",
    "textAlign": "center"
  }))
};
export default styles;