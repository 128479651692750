import _slicedToArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var __jsx = React.createElement;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import React, { Fragment, useMemo } from "react";
import { useIntl } from "react-intl";
import { Container, Icon } from "@/components/ui";
import LovaIcon from "@/components/ui/Icon/$/Lova.icon";
import NavbarDesktop from "@/components/ui/Navbar/components/NavbarDesktop/NavbarDesktop.component";
import NavbarMobile from "@/components/ui/Navbar/components/NavbarMobile/NavbarMobile.component";
import { FACEBOOK_URL, INSTAGRAM_URL, TIK_TOK_URL } from "@/config/link.config";
import useInterface from "@/hooks/useInterface.hook";
import { useNavbar } from "@/hooks/useNavbar.hook";
import { cn } from "@/libraries/clsx.library";
import styles from "./Navbar.styles";

var Navbar = function Navbar(_ref) {
  var _ref$withBorder = _ref.withBorder,
      withBorder = _ref$withBorder === void 0 ? false : _ref$withBorder;
  var t = useIntl();

  var _useInterface = useInterface(),
      activeDialog = _useInterface.activeDialog,
      openDialog = _useInterface.openDialog,
      closeDialog = _useInterface.closeDialog,
      locked = _useInterface.locked;

  var _useNavbar = useNavbar(),
      comingSoonNavbar = _useNavbar.comingSoonNavbar;

  var navbarData = useMemo(function () {
    return _objectSpread({
      isLoading: false
    }, comingSoonNavbar);
  }, [comingSoonNavbar]);

  var _React$useState = React.useState([{
    id: "main",
    name: t.formatMessage({
      id: "navbar.mobile.mainGroup",
      defaultMessage: "Main"
    }),
    links: navbarData.links || []
  }]),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      navbarMobileNestedGroups = _React$useState2[0],
      setNavbarMobileNestedGroups = _React$useState2[1];

  var resetNavbarMobileNestedGroups = React.useCallback(function () {
    setNavbarMobileNestedGroups([{
      id: "main",
      name: t.formatMessage({
        id: "navbar.mobile.mainGroup",
        defaultMessage: "Main"
      }),
      links: navbarData.links || []
    }]);
  }, [navbarData.links, t]);
  return __jsx(Fragment, null, __jsx("div", {
    className: styles.root({
      withBorder: withBorder
    }),
    id: "__nav"
  }, navbarData.links.length > 0 ? __jsx(NavbarMobile, {
    navbarData: navbarData,
    navbarMobileNestedGroups: navbarMobileNestedGroups,
    setNavbarMobileNestedGroups: setNavbarMobileNestedGroups,
    resetNavbarMobileNestedGroups: resetNavbarMobileNestedGroups
  }) : null, __jsx(Container, {
    color: "white"
  }, __jsx("nav", {
    className: styles.nav()
  }, __jsx("div", {
    className: cn(styles.navLeft(), styles.hidden())
  }, navbarData.links.length > 0 ? activeDialog === "NAVBAR" ? __jsx("button", {
    "aria-label": t.formatMessage({
      id: "navbar.openNavbar",
      defaultMessage: "open navbar"
    }),
    type: "button",
    onClick: function onClick() {
      return closeDialog();
    },
    disabled: locked
  }, __jsx(XMarkIcon, {
    className: styles.icon()
  })) : __jsx("button", {
    "aria-label": t.formatMessage({
      id: "navbar.openNavbar",
      defaultMessage: "open navbar"
    }),
    type: "button",
    onClick: function onClick() {
      return openDialog("NAVBAR");
    },
    disabled: locked
  }, __jsx(Bars3Icon, {
    className: styles.icon()
  })) : null), __jsx(NavbarDesktop, {
    navbarData: navbarData
  }), __jsx(Link, {
    "aria-label": t.formatMessage({
      id: "common.homePage",
      defaultMessage: "home page"
    }),
    href: "/",
    passHref: true
  }, __jsx(LovaIcon, null)), __jsx("div", {
    className: styles.navRightStatic()
  }, FACEBOOK_URL ? __jsx("a", {
    "aria-label": t.formatMessage({
      id: "storefront.sections.social.text",
      defaultMessage: "Follow us on {social}"
    }, {
      social: "Facebook"
    }),
    href: FACEBOOK_URL,
    className: styles.link()
  }, __jsx(Icon, {
    name: "Facebook",
    className: styles.linkIcon()
  })) : null, INSTAGRAM_URL ? __jsx("a", {
    "aria-label": t.formatMessage({
      id: "storefront.sections.social.text",
      defaultMessage: "Follow us on {social}"
    }, {
      social: "Instagram"
    }),
    href: INSTAGRAM_URL,
    className: styles.link()
  }, __jsx(Icon, {
    name: "Instagram",
    className: styles.linkIcon()
  })) : null, TIK_TOK_URL ? __jsx("a", {
    "aria-label": t.formatMessage({
      id: "storefront.sections.social.text",
      defaultMessage: "Follow us on {social}"
    }, {
      social: "Tik Tok"
    }),
    href: TIK_TOK_URL,
    className: styles.link()
  }, __jsx(Icon, {
    name: "TikTok",
    className: styles.linkIcon()
  })) : null)))));
};

Navbar.displayName = "Navbar";
export default Navbar;