import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../libraries/stitches.library.ts";
var styles = {
  main: _css(_objectSpread(_objectSpread({}, {
    "position": "relative"
  }), {}, {
    variants: {
      style: {
        list: _objectSpread(_objectSpread({}, {
          "display": "flex",
          "listStyleType": "none",
          "flexDirection": "column",
          "rowGap": "0.75rem"
        }), {}, {
          "@lg": _objectSpread({}, {
            "rowGap": "1rem"
          })
        }),
        grid: _objectSpread(_objectSpread({}, {
          "display": "grid",
          "gridTemplateColumns": "repeat(2, minmax(0, 1fr))",
          "gap": "1rem"
        }), {}, {
          "@lg": _objectSpread({}, {
            "gridTemplateColumns": "repeat(4, minmax(0, 1fr))"
          })
        })
      }
    }
  })),
  element: _css(_objectSpread(_objectSpread({}, {
    "position": "relative"
  }), {}, {
    variants: {
      style: {
        list: _objectSpread({}, {
          "display": "flex",
          "flexDirection": "row",
          "alignItems": "center",
          "columnGap": "0.5rem"
        }),
        grid: _objectSpread({}, {
          "display": "flex",
          "aspectRatio": "1",
          "flexDirection": "column",
          "alignItems": "center",
          "justifyContent": "center",
          "rowGap": "0.5rem",
          "borderRadius": "1rem",
          "borderWidth": "1px",
          "borderStyle": "solid",
          "--tw-border-opacity": "1",
          "borderColor": "hsl(359 4% 85% / var(--tw-border-opacity))"
        })
      }
    }
  })),
  icon: _css(_objectSpread(_objectSpread({}, {
    "position": "relative"
  }), {}, {
    variants: {
      style: {
        list: _objectSpread({}, {
          "height": "24px",
          "width": "24px"
        }),
        grid: _objectSpread({}, {
          "height": "24px",
          "width": "24px"
        })
      }
    }
  })),
  label: _css(_objectSpread(_objectSpread({}, {
    "position": "relative"
  }), {}, {
    color: "$gray6",
    variants: {
      style: {
        list: _objectSpread(_objectSpread({}, {
          "display": "none"
        }), {}, {
          "&::after": _objectSpread(_objectSpread({}, {
            "position": "relative"
          }), {}, {
            content: " - "
          }),
          "@xs": _objectSpread({}, {
            "display": "inline"
          })
        }),
        grid: _objectSpread({}, {
          "display": "flex",
          "flexDirection": "column",
          "alignItems": "center",
          "rowGap": "0.5rem"
        })
      }
    }
  }))
};
export default styles;