import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from "react";

var SvgSmartphone = function SvgSmartphone(props) {
  return __jsx("svg", _extends({
    width: 16,
    height: 16,
    viewBox: "0 0 16 16",
    fill: "none",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("path", {
    d: "M11.334 1.3335H4.66732C3.93094 1.3335 3.33398 1.93045 3.33398 2.66683V13.3335C3.33398 14.0699 3.93094 14.6668 4.66732 14.6668H11.334C12.0704 14.6668 12.6673 14.0699 12.6673 13.3335V2.66683C12.6673 1.93045 12.0704 1.3335 11.334 1.3335Z",
    stroke: props.color || "#C24163"
  }), __jsx("path", {
    d: "M8 12H8.00667",
    stroke: props.color || "#C24163",
    strokeLinecap: "round"
  }));
};

export default SvgSmartphone;