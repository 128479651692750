import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../libraries/stitches.library.ts";
var styles = {
  imageSmallText: _css({
    fontSize: "1.8rem",
    lineHeight: "$3",
    whiteSpace: "pre-wrap",
    "@md": {
      whiteSpace: "initial"
    }
  }),
  imageLargeText: _css({
    fontSize: "2.5rem",
    lineHeight: "$4",
    "@media screen and (min-width: 400px)": {
      fontSize: "3rem"
    },
    "@media screen and (min-width: 500px)": {
      fontSize: "3.3rem"
    }
  }),
  imageLargeTextHighlighted: _css(_objectSpread(_objectSpread({}, {
    "position": "relative",
    "marginTop": "0.5rem",
    "display": "inline-block"
  }), {}, {
    "&::before": _objectSpread(_objectSpread({}, {
      "position": "absolute",
      "display": "block",
      "height": "5rem",
      "width": "5rem",
      "borderRadius": "9999px",
      "--tw-content": "\"\"",
      "content": "var(--tw-content)"
    }), {}, {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#DB7991",
      zIndex: -1
    }),
    "@media screen and (min-width: 400px)": {
      "&::before": _objectSpread({}, {
        "height": "7rem",
        "width": "7rem"
      })
    }
  })),
  infoLargeText: _css({
    fontFamily: "$tenor",
    fontSize: "2.3rem",
    lineHeight: "$3",
    color: "$white",
    whiteSpace: "pre-wrap",
    "@media screen and (min-width: 400px)": {
      fontSize: "2.5rem"
    },
    "@lg": {
      fontSize: "3rem",
      whiteSpace: "pre-wrap"
    }
  }),
  infoSmallText: _css(_objectSpread(_objectSpread({}, {
    "paddingTop": "1rem"
  }), {}, {
    whiteSpace: "pre-wrap",
    fontSize: "1.25rem",
    color: "$white",
    lineHeight: "$5",
    fontWeight: "$semibold",
    "@media screen and (min-width: 400px)": {
      whiteSpace: "initial",
      fontSize: "1.4rem"
    }
  })),
  infoButton: _css(_objectSpread(_objectSpread({}, {
    "marginTop": "2rem",
    "width": "100%"
  }), {}, {
    maxWidth: "300px",
    "&>span": {
      fontSize: "$3 !important",
      fontWeight: "$bold",
      textTransform: "uppercase",
      color: "$default"
    }
  }))
};
export default styles;