var __jsx = React.createElement;
import Blocks from "editorjs-blocks-react-renderer";
import React, { Fragment } from "react";
import { parseJsonString } from "./Text.helpers";
import styles from "./Text.styles";
export var DEFAULT_CONFIG = {
  code: {
    className: styles.code()
  },
  delimiter: {
    className: styles.delimiter()
  },
  embed: {
    className: styles.embed()
  },
  header: {
    className: styles.header()
  },
  image: {
    className: styles.image()
  },
  list: {
    className: styles.list()
  },
  paragraph: {
    className: styles.paragraph()
  },
  quote: {
    className: styles.quote()
  },
  table: {
    className: styles.table()
  }
};

var Text = function Text(_ref) {
  var text = _ref.text,
      _ref$config = _ref.config,
      config = _ref$config === void 0 ? DEFAULT_CONFIG : _ref$config,
      _ref$spacing = _ref.spacing,
      spacing = _ref$spacing === void 0 ? "none" : _ref$spacing;
  var data = parseJsonString(text);

  if (!data) {
    return __jsx(Fragment, null, text);
  }

  return __jsx("article", {
    className: styles.article({
      spacing: spacing
    })
  }, __jsx(Blocks, {
    data: data,
    config: config
  }));
};

export default Text;