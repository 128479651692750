import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread(_objectSpread({}, {
    "display": "flex",
    "flexDirection": "column",
    "alignItems": "flex-start",
    "gap": "0.5rem",
    "padding": "1rem"
  }), {}, {
    borderColor: "$gray2"
  })),
  details: _css({
    color: "$gray6",
    span: {
      "&:not(:last-child)": _objectSpread(_objectSpread({}, {
        "position": "relative",
        "marginRight": "0.875rem",
        "paddingRight": "0.75rem"
      }), {}, {
        "&::after": _objectSpread(_objectSpread({}, {
          "position": "absolute",
          "inset": "0px",
          "left": "100%",
          "top": "50%",
          "display": "block",
          "height": "0.125rem",
          "width": "0.125rem",
          "--tw-translate-y": "-50%",
          "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
          "borderRadius": "9999px",
          "--tw-content": "\"\"",
          "content": "var(--tw-content)"
        }), {}, {
          backgroundColor: "$gray6"
        })
      })
    }
  }),
  detailsCustomer: _css({
    fontWeight: "$medium"
  }),
  verifiedCustomer: _css(_objectSpread(_objectSpread({}, {
    "display": "flex",
    "alignItems": "center",
    "gap": "0.5rem"
  }), {}, {
    color: "$success",
    fontSize: "$2"
  })),
  verifiedCustomerIcon: _css({
    circle: {
      stroke: "$success"
    },
    path: {
      stroke: "$success"
    }
  }),
  productName: _css(_objectSpread(_objectSpread({}, {
    "display": "flex",
    "alignItems": "center",
    "gap": "0.5rem"
  }), {}, {
    color: "$default",
    fontSize: "$2"
  })),
  productLink: _css(_objectSpread(_objectSpread({}, {
    "display": "flex",
    "cursor": "pointer",
    "alignItems": "center",
    "gap": "0.25rem",
    "fontSize": "1rem",
    "lineHeight": "1.5rem",
    "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
    "transitionTimingFunction": "linear",
    "transitionDuration": "300ms",
    "animationDuration": "300ms",
    "animationTimingFunction": "linear"
  }), {}, {
    color: "$default",
    fontSize: "$2",
    wordBreak: "break-word",
    "&:hover": {
      color: "$dark"
    }
  })),
  productNameIcon: _css({
    circle: {
      stroke: "$default"
    },
    path: {
      stroke: "$default"
    }
  }),
  rating: _css(_objectSpread({}, {
    "marginLeft": "0px"
  })),
  textContainer: _css(_objectSpread(_objectSpread({}, {
    "overflow": "hidden",
    "transitionProperty": "all",
    "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "transitionDuration": "300ms",
    "animationDuration": "300ms",
    "animationTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)"
  }), {}, {
    color: "$gray6",
    article: {
      "& > p": {
        fontSize: "$2"
      }
    }
  })),
  readMoreButton: _css({
    span: {
      fontSize: "$2"
    }
  })
};
export default styles;