import { LOCALE } from "@/config/region.config";
export var getGoogleMerchantCenterLanguageCode = function getGoogleMerchantCenterLanguageCode(locale) {
  switch (locale) {
    case LOCALE.enUS:
      return "en-US";

    case LOCALE.plPL:
      return "pl";

    default:
      return "en-US";
  }
};