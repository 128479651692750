var __jsx = React.createElement;
import React from "react";
import styles from "./Notification.styles";

var Shell = function Shell(_ref) {
  var children = _ref.children,
      zMax = _ref.zMax;
  return __jsx("div", {
    id: "__shell",
    "aria-live": "assertive",
    className: styles.shell({
      zMax: zMax
    })
  }, children);
};

Shell.displayName = "Shell";
export default Shell;