import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from "react";

var SvgClock = function SvgClock(props) {
  return __jsx("svg", _extends({
    width: 16,
    height: 17,
    viewBox: "0 0 16 17",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("circle", {
    cx: 8,
    cy: 8.5,
    r: 6,
    stroke: "#5D5656",
    strokeWidth: 1.5
  }), __jsx("path", {
    d: "M8 5.5V8.5L9.5 10",
    stroke: "#5D5656",
    strokeWidth: 1.5,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }));
};

export default SvgClock;