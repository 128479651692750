var __jsx = React.createElement;
import React from "react";
import { useIntl } from "react-intl";
import { cn } from "@/libraries/clsx.library";
import styles from "./QuantitySelector.styles";

var QuantitySelector = function QuantitySelector(_ref) {
  var quantity = _ref.quantity,
      increment = _ref.increment,
      decrement = _ref.decrement,
      _ref$canIncrement = _ref.canIncrement,
      canIncrement = _ref$canIncrement === void 0 ? true : _ref$canIncrement,
      _ref$canDecrement = _ref.canDecrement,
      canDecrement = _ref$canDecrement === void 0 ? quantity > 1 : _ref$canDecrement,
      disabled = _ref.disabled,
      loading = _ref.loading,
      _ref$variant = _ref.variant,
      variant = _ref$variant === void 0 ? "rounded" : _ref$variant,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? "sm" : _ref$size,
      width = _ref.width,
      testIdPrefix = _ref.testIdPrefix,
      type = _ref.type,
      productIndex = _ref.productIndex;
  var t = useIntl();
  return __jsx("div", {
    className: styles.root()
  }, __jsx("button", {
    "aria-label": t.formatMessage({
      id: "common.decrement",
      defaultMessage: "decrement"
    }),
    onClick: decrement,
    className: styles.button({
      type: "decrement",
      variant: variant,
      size: size,
      width: width
    }),
    disabled: !canDecrement || loading || disabled
  }, "-"), __jsx("div", {
    className: styles.valueContainer({
      size: size,
      width: width,
      loading: true
    })
  }, __jsx("span", {
    id: type ? "__".concat(type.toLowerCase(), "-product-").concat(productIndex || 0, "-quantity") : "__quantity",
    className: type ? cn(styles.value({
      loading: loading,
      disabled: disabled
    }), "".concat(type.toLowerCase(), "-product-quantity")) : styles.value({
      loading: loading,
      disabled: disabled
    }),
    "data-product-quantity": quantity,
    "data-testid": "".concat(testIdPrefix !== null && testIdPrefix !== void 0 ? testIdPrefix : "", "quantity")
  }, quantity)), __jsx("button", {
    "aria-label": t.formatMessage({
      id: "common.increment",
      defaultMessage: "increment"
    }),
    onClick: increment,
    className: styles.button({
      type: "increment",
      variant: variant,
      size: size,
      width: width
    }),
    disabled: !canIncrement || loading || disabled
  }, "+"));
};

export default QuantitySelector;