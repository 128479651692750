import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../libraries/stitches.library.ts";
var styles = {
  wrapper: _css(_objectSpread(_objectSpread({}, {
    "position": "relative",
    "marginLeft": "-1rem",
    "marginRight": "-1rem"
  }), {}, {
    "@sm": _objectSpread({}, {
      "marginLeft": "-1.5rem",
      "marginRight": "-1.5rem"
    }),
    "@md": _objectSpread({}, {
      "marginLeft": "0px",
      "marginRight": "0px"
    })
  })),
  root: _css(_objectSpread({}, {
    "scrollbarWidth": "none",
    "MsOverflowStyle": "none",
    "&::-webkit-scrollbar": {
      "display": "none"
    },
    "position": "relative",
    "display": "flex",
    "width": "100%",
    "flexWrap": "nowrap",
    "alignItems": "center",
    "gap": "0.75rem",
    "overflowX": "scroll",
    "paddingTop": "1.25rem",
    "paddingBottom": "1.25rem",
    "paddingLeft": "1rem",
    "@media (min-width: 768px)": {
      "paddingTop": "1.5rem",
      "paddingBottom": "1.5rem",
      "paddingLeft": "0px"
    }
  })),
  breadcrumb: _css(_objectSpread(_objectSpread({}, {
    "display": "flex",
    "flexShrink": "0",
    "alignItems": "center",
    "gap": "0.25rem",
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem",
    "@media (min-width: 640px)": {
      "fontSize": "1rem",
      "lineHeight": "1.5rem"
    }
  }), {}, {
    "&:last-child": {
      // ...tw`relative mr-14`,
      "&::after": _objectSpread(_objectSpread({
        content: ""
      }, {
        "position": "relative"
      }), {}, {
        backgroundColor: "transparent",
        opacity: 0,
        width: 100
      })
    }
  })),
  breadcrumbLink: _css(_objectSpread(_objectSpread({}, {
    "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke",
    "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "transitionDuration": "300ms",
    "animationDuration": "300ms",
    "animationTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)"
  }), {}, {
    color: "$gray6",
    path: _objectSpread(_objectSpread({}, {
      "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke",
      "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
      "transitionDuration": "300ms",
      "animationDuration": "300ms",
      "animationTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)"
    }), {}, {
      stroke: "$gray6"
    }),
    "&:hover": {
      color: "$default ",
      path: {
        stroke: "$default"
      }
    }
  })),
  icon: _css(_objectSpread({}, {
    "height": "1rem"
  })),
  separatorIcon: _css(_objectSpread(_objectSpread({}, {
    "height": "0.75rem",
    "flexShrink": "0"
  }), {}, {
    path: {
      stroke: "$gray5"
    }
  }))
};
export default styles;