import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../libraries/stitches.library.ts";
var styles = {
  imageSmallTextMobile: _css(_objectSpread(_objectSpread({}, {
    "paddingBottom": "0.75rem"
  }), {}, {
    fontSize: "1.8rem",
    whiteSpace: "pre-wrap",
    lineHeight: "$4",
    "@md": {
      whiteSpace: "initial"
    }
  })),
  imageLargeTextMobile: _css({
    fontSize: "2.5rem",
    lineHeight: "$4",
    "@media screen and (min-width: 400px)": {
      fontSize: "3rem"
    }
  }),
  imageLargeTextHighlightedMobile: _css(_objectSpread(_objectSpread({}, {
    "position": "relative"
  }), {}, {
    "&::before": _objectSpread(_objectSpread({}, {
      "position": "absolute",
      "display": "block",
      "height": "5rem",
      "width": "5rem",
      "borderRadius": "9999px",
      "--tw-content": "\"\"",
      "content": "var(--tw-content)"
    }), {}, {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#9E2948",
      zIndex: -1
    }),
    "@media screen and (min-width: 400px)": {
      "&::before": _objectSpread({}, {
        "height": "7rem",
        "width": "7rem"
      })
    }
  })),
  imageLargeTextDesktop: _css({
    fontSize: "3rem"
  }),
  imageMediumTextDesktop: _css({
    fontSize: "3rem",
    lineHeight: "$1"
  }),
  imageSmallTextDesktop: _css(_objectSpread(_objectSpread({}, {
    "paddingTop": "2.5rem"
  }), {}, {
    fontFamily: "$inter",
    fontSize: "1.5rem",
    fontWeight: "$semibold"
  })),
  infoLargeTextMobile: _css({
    fontFamily: "$tenor",
    fontSize: "2.3rem",
    lineHeight: "$2",
    color: "$white",
    "@media screen and (min-width: 400px)": {
      fontSize: "2.5rem"
    }
  }),
  infoMediumTextMobile: _css({
    fontFamily: "$tenor",
    fontSize: "2.3rem",
    lineHeight: "$2",
    color: "$white",
    "@media screen and (min-width: 400px)": {
      fontSize: "2.5rem"
    }
  }),
  infoSmallTextMobile: _css(_objectSpread(_objectSpread({}, {
    "paddingTop": "1.5rem"
  }), {}, {
    whiteSpace: "pre-wrap",
    fontSize: "1.25rem",
    color: "$white",
    lineHeight: "$5",
    fontWeight: "$semibold",
    "@media screen and (min-width: 400px)": {
      whiteSpace: "initial",
      fontSize: "1.4rem"
    }
  })),
  infoSmallTextDesktop: _css(_objectSpread(_objectSpread({}, {
    "paddingBottom": "0.5rem"
  }), {}, {
    fontFamily: "$tenor",
    fontSize: "2.0625rem",
    lineHeight: "$4",
    color: "$white",
    zIndex: 1
  })),
  infoLargeTextDesktop: _css({
    fontFamily: "$tenor",
    fontSize: "3.3rem",
    lineHeight: "$4",
    color: "$white"
  }),
  infoLargeTextHighlightedDesktop: _css(_objectSpread(_objectSpread({}, {
    "position": "relative"
  }), {}, {
    zIndex: 0,
    color: "$white",
    "&::before": _objectSpread(_objectSpread({}, {
      "position": "absolute",
      "display": "block",
      "height": "7rem",
      "width": "7rem",
      "borderRadius": "9999px",
      "--tw-content": "\"\"",
      "content": "var(--tw-content)"
    }), {}, {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#9E2948",
      zIndex: -1
    })
  })),
  infoButton: _css(_objectSpread(_objectSpread({}, {
    "marginTop": "1.5rem",
    "width": "100%"
  }), {}, {
    maxWidth: "300px",
    "&>span": {
      fontSize: "$4 !important",
      fontWeight: "$bold",
      textTransform: "uppercase",
      color: "$default"
    },
    "@lg": _objectSpread({}, {
      "marginTop": "3rem"
    })
  }))
};
export default styles;