import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from "react";

var SvgOutlineAppStore = function SvgOutlineAppStore(props) {
  return __jsx("svg", _extends({
    width: 24,
    height: 25,
    viewBox: "0 0 24 25",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("g", {
    clipPath: "url(#clip0_6429_81942)"
  }, __jsx("path", {
    d: "M13.7419 10.2566L19.5001 20",
    stroke: props.stroke || "#5D5656",
    strokeWidth: 1.4,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __jsx("path", {
    d: "M9.75 3.5L12 7.30813",
    stroke: props.stroke || "#5D5656",
    strokeWidth: 1.4,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __jsx("path", {
    d: "M5.38594 18.5L4.5 20",
    stroke: props.stroke || "#5D5656",
    strokeWidth: 1.4,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __jsx("path", {
    d: "M14.2499 3.5L7.15869 15.5",
    stroke: props.stroke || "#5D5656",
    strokeWidth: 1.4,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __jsx("path", {
    d: "M16.8413 15.5H21.7501",
    stroke: props.stroke || "#5D5656",
    strokeWidth: 1.4,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __jsx("path", {
    d: "M2.25 15.5H13.3566",
    stroke: props.stroke || "#5D5656",
    strokeWidth: 1.4,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  })), __jsx("defs", null, __jsx("clipPath", {
    id: "clip0_6429_81942"
  }, __jsx("rect", {
    width: 24,
    height: 24,
    fill: "white",
    transform: "translate(0 0.5)"
  }))));
};

export default SvgOutlineAppStore;