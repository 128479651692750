import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["group"];
var __jsx = React.createElement;
import React from "react";
import Item from "./Menu.Item";
import styles from "./Menu.styles";

var Group = function Group(_ref) {
  var group = _ref.group,
      rest = _objectWithoutProperties(_ref, _excluded);

  return __jsx("div", {
    className: styles.group()
  }, group.map(function (option, index) {
    return __jsx(Item, _extends({}, option, {
      key: index
    }, rest));
  }));
};

Group.displayName = "Group";
export default Group;