import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from "react";

var SvgOutlineYoutube = function SvgOutlineYoutube(props) {
  return __jsx("svg", _extends({
    width: 24,
    height: 25,
    viewBox: "0 0 24 25",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("g", {
    clipPath: "url(#clip0_6429_81935)"
  }, __jsx("path", {
    d: "M15 12.5L10.5 9.5V15.5L15 12.5Z",
    stroke: props.stroke || "#5D5656",
    strokeWidth: 1.4,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __jsx("path", {
    d: "M2.25 12.5C2.25 15.3041 2.53781 16.9484 2.75719 17.7941C2.8152 18.0241 2.92694 18.237 3.08322 18.4155C3.23951 18.594 3.43589 18.7328 3.65625 18.8206C6.79875 20.0328 12 20 12 20C12 20 17.2012 20.0328 20.3438 18.8206C20.5648 18.7333 20.7619 18.5947 20.9189 18.4162C21.0759 18.2377 21.1882 18.0245 21.2466 17.7941C21.4659 16.9503 21.7537 15.3041 21.7537 12.5C21.7537 9.69594 21.4659 8.05156 21.2466 7.20594C21.1888 6.97468 21.0768 6.76051 20.9198 6.58117C20.7628 6.40183 20.5653 6.26249 20.3438 6.17469C17.2012 4.96719 12 5 12 5C12 5 6.79875 4.96719 3.65625 6.17937C3.43466 6.26717 3.23718 6.40652 3.08017 6.58585C2.92317 6.76519 2.81116 6.97937 2.75344 7.21062C2.53781 8.05062 2.25 9.69594 2.25 12.5Z",
    stroke: props.stroke || "#5D5656",
    strokeWidth: 1.4,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  })), __jsx("defs", null, __jsx("clipPath", {
    id: "clip0_6429_81935"
  }, __jsx("rect", {
    width: 24,
    height: 24,
    fill: "white",
    transform: "translate(0 0.5)"
  }))));
};

export default SvgOutlineYoutube;