import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread({}, {
    "display": "flex",
    "gap": "1rem"
  })),
  imageLink: _css({
    variants: {
      disabled: {
        "true": _objectSpread({}, {
          "pointerEvents": "none",
          "cursor": "default"
        })
      }
    }
  }),
  imageContainer: _css(_objectSpread({}, {
    "position": "relative",
    "height": "6.625rem",
    "width": "6.625rem",
    "alignItems": "flex-start",
    "overflow": "hidden",
    "borderRadius": "1rem"
  })),
  info: _css(_objectSpread({}, {
    "display": "flex",
    "flex": "1 1 0%",
    "flexDirection": "column",
    "flexWrap": "wrap",
    "justifyContent": "space-between"
  })),
  mainInfo: _css(_objectSpread({}, {
    "display": "flex",
    "justifyContent": "space-between",
    "gap": "1rem"
  })),
  name: _css(_objectSpread(_objectSpread({}, {
    "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
    "transitionTimingFunction": "linear",
    "transitionDuration": "300ms",
    "animationDuration": "300ms",
    "animationTimingFunction": "linear"
  }), {}, {
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "$2",
    lineHeight: "$4",
    "&:hover": {
      color: "$default"
    },
    "@md": {
      fontSize: "$3"
    },
    variants: {
      disabled: {
        "true": _objectSpread({}, {
          "pointerEvents": "none",
          "cursor": "default"
        })
      }
    }
  })),
  price: _css({
    fontWeight: "$bold",
    fontSize: "$2",
    "@md": {
      fontSize: "$3"
    }
  }),
  details: _css(_objectSpread({}, {
    "display": "flex",
    "justifyContent": "space-between",
    "gap": "1rem"
  })),
  detail: _css({
    fontFamily: "inter",
    color: "$gray6",
    fontSize: "$2",
    "&:not(:first-child)": _objectSpread(_objectSpread({}, {
      "position": "relative",
      "marginLeft": "0.75rem",
      "paddingLeft": "1rem"
    }), {}, {
      "&:before": _objectSpread(_objectSpread({}, {
        "position": "absolute",
        "left": "0px",
        "top": "50%",
        "height": "0.125rem",
        "width": "0.125rem",
        "borderRadius": "0.25rem"
      }), {}, {
        content: "",
        backgroundColor: "$gray5",
        transform: "translateY(-50%)"
      })
    }),
    "@md": {
      fontSize: "$3"
    }
  }),
  priceUndiscounted: _css({
    color: "$gray6",
    textDecoration: "line-through",
    fontSize: "$2",
    "@md": {
      fontSize: "$3"
    }
  }),
  wrapper: _css(_objectSpread(_objectSpread({}, {
    "position": "relative"
  }), {}, {
    height: 32
  })),
  actions: _css(_objectSpread({}, {
    "display": "flex",
    "width": "100%",
    "alignItems": "center",
    "justifyContent": "space-between"
  })),
  quantity: _css({
    color: "$gray6",
    fontSize: "$2",
    "@md": {
      fontSize: "$3"
    },
    variants: {
      loading: {
        "true": _objectSpread({}, {
          "animation": "pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite"
        })
      }
    }
  }),
  removeButton: _css({
    span: _objectSpread({}, {
      "display": "flex",
      "alignItems": "center",
      "gap": "0.375rem"
    })
  })
};
export default styles;