import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _slicedToArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
import { useAuthState } from "@saleor/sdk";
import Link from "next/link";
import React from "react";
import NavbarDesktopSubmenu from "./components/NavbarDesktopSubmenu/NavbarDesktopSubmenu.component";
import styles from "./NavbarDesktop.styles";

var NavbarDesktop = function NavbarDesktop(_ref) {
  var _navbarData$links;

  var navbarData = _ref.navbarData;

  var _useAuthState = useAuthState(),
      authenticated = _useAuthState.authenticated;

  var _React$useState = React.useState(null),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      hoveredItem = _React$useState2[0],
      setHoveredItem = _React$useState2[1];

  return __jsx("div", {
    className: styles.root()
  }, __jsx("ul", {
    className: styles.navList()
  }, (_navbarData$links = navbarData.links) === null || _navbarData$links === void 0 ? void 0 : _navbarData$links.map(function (link, linkIndex) {
    return __jsx("li", {
      key: "nav-item-".concat(linkIndex),
      className: styles.navItemContainer({
        hidden: link.hidden ? link.hidden({
          auth: authenticated,
          mode: "desktop"
        }) : false
      }),
      onMouseEnter: function onMouseEnter() {
        return setHoveredItem(linkIndex);
      },
      onMouseLeave: function onMouseLeave() {
        return setHoveredItem(null);
      }
    }, link.href ? __jsx(Link, _extends({
      href: link.href,
      passHref: true
    }, link.hrefOptions, {
      className: styles.navItem({
        active: hoveredItem === linkIndex,
        highlighted: link.highlighted
      }),
      "data-testid": "desktop-".concat(link.testId),
      "data-text": link.name
    }), link.name) : link.subLinks && link.subLinks.length ? __jsx("span", {
      className: styles.navItem(),
      "data-text": link.name
    }, link.name) : null, __jsx(NavbarDesktopSubmenu, {
      subLinks: link.subLinks || [],
      show: !!(link.subLinks && link.subLinks.length > 0) && hoveredItem === linkIndex,
      submenuLevel: 1,
      isLoading: navbarData.isLoading
    }));
  })));
};

NavbarDesktop.displayName = "NavbarDesktop";
export default NavbarDesktop;