var __jsx = React.createElement;
import { XMarkIcon } from "@heroicons/react/24/outline";
import isFunction from "lodash/isFunction";
import React from "react";
import { Button } from "@/components/ui";
import PopupContentImage from "@/popupManager/components/PopupContent/componentes/PopupContentImage/PopupContentImage.component";
import PopupContetnInfo from "@/popupManager/components/PopupContent/componentes/PopupContentInfo/PopupContentInfo.component";
import styles from "./PopupContent.styles";

var PopupContent = function PopupContent(_ref) {
  var _ref$color = _ref.color,
      color = _ref$color === void 0 ? "default" : _ref$color,
      closeIconColor = _ref.closeIconColor,
      onClose = _ref.onClose,
      children = _ref.children;
  return __jsx("div", {
    className: styles.root({
      color: color
    }),
    id: "__popup-content"
  }, isFunction(onClose) ? __jsx(Button, {
    className: styles.closeButton(),
    variant: "naked",
    onClick: onClose
  }, __jsx(XMarkIcon, {
    className: styles.closeButtonIcon({
      color: closeIconColor
    })
  })) : null, children);
};

PopupContent.Image = PopupContentImage;
PopupContent.Info = PopupContetnInfo;
PopupContent.displayName = "PopupContent";
export default PopupContent;