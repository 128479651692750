import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../libraries/stitches.library.ts";
var styles = {
  slide: _css(_objectSpread({}, {
    "height": "auto"
  })),
  root: _css(_objectSpread({}, {
    "display": "flex",
    "alignItems": "center",
    "gap": "0.75rem"
  })),
  item: function item(_ref) {
    var isActive = _ref.isActive,
        isClicable = _ref.isClicable;
    return _objectSpread(_objectSpread({}, {
      "height": "0.875rem",
      "borderRadius": "0.75rem"
    }), {}, {
      width: isActive ? "1.75rem" : "0.875rem",
      backgroundColor: isActive ? "$default" : "$gray4",
      transition: "all 0.3s ease",
      cursor: isClicable ? "pointer" : "default",
      pointerEvents: !isClicable ? "none" : "default"
    });
  },
  itemContent: function itemContent(_ref2) {
    var isDisabled = _ref2.isDisabled;
    return _objectSpread(_objectSpread({}, {
      "display": "flex",
      "alignItems": "center",
      "gap": "0.75rem"
    }), {}, {
      ".itemText": {
        color: isDisabled ? "$gray4" : "$default"
      },
      ".itemIcon": {
        path: {
          stroke: isDisabled ? "$gray4" : "$default"
        }
      }
    });
  }
};
export default styles;