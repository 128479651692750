var __jsx = React.createElement;
import { styled as _styled } from "../../../libraries/stitches.library.ts";
import React from "react";
import { useIntl } from "react-intl";
import TableSkeleton from "@/components/ui/Table/skeleton/TableSkeleton.component";
import styles from "./Table.styles";

var _TwComponent = _styled("span", {
  "position": "absolute",
  "width": "1px",
  "height": "1px",
  "padding": "0",
  "margin": "-1px",
  "overflow": "hidden",
  "clip": "rect(0, 0, 0, 0)",
  "whiteSpace": "nowrap",
  "borderWidth": "0"
});

var Table = function Table(_ref) {
  var headerCells = _ref.headerCells,
      bodyRows = _ref.bodyRows,
      actionItems = _ref.actionItems,
      _ref$loading = _ref.loading,
      loading = _ref$loading === void 0 ? false : _ref$loading,
      emptyInfo = _ref.emptyInfo,
      _ref$hiddenColumns = _ref.hiddenColumns,
      hiddenColumns = _ref$hiddenColumns === void 0 ? [] : _ref$hiddenColumns,
      _ref$columnsWidth = _ref.columnsWidth,
      columnsWidth = _ref$columnsWidth === void 0 ? [] : _ref$columnsWidth,
      _ref$fullWidth = _ref.fullWidth,
      fullWidth = _ref$fullWidth === void 0 ? true : _ref$fullWidth,
      _ref$tableFixed = _ref.tableFixed,
      tableFixed = _ref$tableFixed === void 0 ? false : _ref$tableFixed;
  var t = useIntl();
  return __jsx("div", {
    className: styles.root({
      fullWidth: fullWidth
    })
  }, __jsx("table", {
    className: styles.table({
      fullWidth: fullWidth,
      tableFixed: tableFixed
    })
  }, __jsx("thead", {
    className: styles.header()
  }, __jsx("tr", null, headerCells.map(function (headerCell, headerCellKey) {
    return __jsx("th", {
      scope: "col",
      className: styles.headerCol({
        hidden: (hiddenColumns === null || hiddenColumns === void 0 ? void 0 : hiddenColumns.indexOf(headerCellKey + 1)) >= 0
      }),
      key: "table-header-cell-".concat(headerCellKey),
      style: {
        width: columnsWidth[headerCellKey]
      }
    }, headerCell.srOnly ? __jsx(_TwComponent, null, headerCell.title) : headerCell.title);
  }))), __jsx("tbody", {
    className: styles.body()
  }, loading ? __jsx(TableSkeleton, {
    rowCount: 3,
    cellCount: headerCells.length,
    hiddenColumns: hiddenColumns
  }) : bodyRows.length > 0 ? bodyRows.map(function (bodyRow, bodyRowKey) {
    return __jsx("tr", {
      key: "table-body-row-".concat(bodyRowKey)
    }, bodyRow.map(function (rowCell, rowCellKey) {
      return __jsx("td", {
        className: styles.bodyCol({
          hidden: (hiddenColumns === null || hiddenColumns === void 0 ? void 0 : hiddenColumns.indexOf(rowCellKey + 1)) >= 0
        }),
        key: "table-body-row-".concat(bodyRowKey, "-cell-").concat(rowCellKey)
      }, rowCell);
    }));
  }) : __jsx("tr", null, __jsx("td", {
    colSpan: headerCells.length
  }, __jsx("div", {
    className: styles.emptyInfoContainer()
  }, emptyInfo || t.formatMessage({
    id: "table.empty.info",
    defaultMessage: "No data"
  }))))), actionItems ? __jsx("tfoot", null, __jsx("tr", null, __jsx("td", {
    className: styles.footerCol(),
    colSpan: headerCells.length
  }, actionItems.map(function (actionItem) {
    return actionItem;
  })))) : null));
};

export default Table;