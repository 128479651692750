import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread({}, {
    "display": "flex",
    "height": "100%",
    "width": "100%",
    "flexDirection": "column",
    "overflow": "hidden",
    "borderRadius": "0.5rem",
    "--tw-bg-opacity": "1",
    "backgroundColor": "hsl(0 0% 100% / var(--tw-bg-opacity))",
    "outline": "2px solid transparent",
    "outlineOffset": "2px"
  })),
  input: _css(_objectSpread({}, {
    "scrollbarWidth": "none",
    "MsOverflowStyle": "none",
    "&::-webkit-scrollbar": {
      "display": "none"
    },
    "display": "flex",
    "height": "2.75rem",
    "width": "100%",
    "borderRadius": "0.5rem",
    "borderWidth": "1px",
    "borderStyle": "none",
    "backgroundColor": "transparent",
    "paddingTop": "0.5rem",
    "paddingBottom": "0.5rem",
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem",
    "outline": "2px solid transparent",
    "outlineOffset": "2px",
    "&::placeholder": {
      "--tw-text-opacity": "1",
      "color": "hsl(359 4% 45% / var(--tw-text-opacity))"
    },
    "&:focus": {
      "outline": "2px solid transparent",
      "outlineOffset": "2px",
      "--tw-ring-color": "transparent"
    },
    "&:disabled": {
      "cursor": "not-allowed",
      "opacity": "0.5"
    },
    "@media (min-width: 768px)": {
      "height": "3.5rem",
      "paddingTop": "0.75rem",
      "paddingBottom": "0.75rem",
      "fontSize": "1rem",
      "lineHeight": "1.5rem"
    }
  })),
  inputWrapper: _css(_objectSpread({}, {
    "display": "flex",
    "alignItems": "center",
    "borderBottomWidth": "1px",
    "--tw-border-opacity": "1",
    "borderColor": "hsl(359 4% 85% / var(--tw-border-opacity))",
    "paddingLeft": "0.75rem",
    "paddingRight": "0.75rem"
  })),
  icon: _css(_objectSpread({}, {
    "marginRight": "0.5rem",
    "height": "1.25rem",
    "width": "1.25rem",
    "flexShrink": "0",
    "--tw-text-opacity": "1",
    "color": "hsl(359 4% 35% / var(--tw-text-opacity))"
  })),
  list: _css(_objectSpread({}, {
    "maxHeight": "50vh",
    "overflowY": "auto",
    "overflowX": "hidden",
    "paddingLeft": "0.5rem",
    "paddingRight": "0.5rem",
    "paddingTop": "0.25rem",
    "paddingBottom": "0.25rem",
    "@media (min-width: 768px)": {
      "maxHeight": "400px",
      "paddingTop": "0.75rem",
      "paddingBottom": "0.75rem"
    }
  })),
  empty: _css(_objectSpread({}, {
    "paddingTop": "1.5rem",
    "paddingBottom": "1.5rem",
    "textAlign": "center",
    "fontWeight": "500",
    "--tw-text-opacity": "1",
    "color": "hsl(359 4% 35% / var(--tw-text-opacity))"
  })),
  group: _css(_objectSpread({}, {
    "overflow": "hidden",
    "padding": "0.25rem",
    "& [cmdk-group-heading]": {
      "paddingLeft": "0.5rem",
      "paddingRight": "0.5rem",
      "paddingTop": "0.375rem",
      "paddingBottom": "0.375rem",
      "fontSize": "0.75rem",
      "lineHeight": "1rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "hsl(359 4% 45% / var(--tw-text-opacity))"
    }
  })),
  separator: _css(_objectSpread({}, {
    "marginLeft": "-0.25rem",
    "marginRight": "-0.25rem",
    "marginTop": "0.5rem",
    "marginBottom": "0.5rem",
    "height": "1px",
    "--tw-bg-opacity": "1",
    "backgroundColor": "hsl(359 4% 85% / var(--tw-bg-opacity))",
    "@media (min-width: 768px)": {
      "marginTop": "0.75rem",
      "marginBottom": "0.75rem"
    }
  })),
  item: _css(_objectSpread({}, {
    "position": "relative",
    "marginTop": "0.25rem",
    "marginBottom": "0.25rem",
    "display": "flex",
    "cursor": "pointer",
    "userSelect": "none",
    "alignItems": "center",
    "borderRadius": "0.5rem",
    "paddingLeft": "0.75rem",
    "paddingRight": "0.75rem",
    "paddingTop": "0.375rem",
    "paddingBottom": "0.375rem",
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem",
    "fontWeight": "400",
    "--tw-text-opacity": "1",
    "color": "hsl(359 4% 25% / var(--tw-text-opacity))",
    "outline": "2px solid transparent",
    "outlineOffset": "2px",
    "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
    "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
    "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
    "--tw-ring-color": "transparent",
    "&[aria-selected=\"true\"]": {
      "--tw-bg-opacity": "1",
      "backgroundColor": "hsl(359 4% 98% / var(--tw-bg-opacity))",
      "--tw-text-opacity": "1",
      "color": "hsl(359 4% 15% / var(--tw-text-opacity))",
      "--tw-ring-opacity": "1",
      "--tw-ring-color": "hsl(359 4% 95% / var(--tw-ring-opacity))"
    },
    "&[data-disabled=true]": {
      "pointerEvents": "none",
      "opacity": "0.5"
    },
    "@media (min-width: 1024px)": {
      "fontSize": "1rem",
      "lineHeight": "1.5rem"
    }
  })),
  shortcut: _css(_objectSpread({}, {
    "marginLeft": "auto",
    "fontSize": "0.75rem",
    "lineHeight": "1rem",
    "letterSpacing": "0.1em",
    "--tw-text-opacity": "1",
    "color": "hsl(359 4% 35% / var(--tw-text-opacity))"
  })),
  loader: _css(_objectSpread({}, {
    "position": "relative",
    "marginLeft": "1rem",
    "marginRight": "1rem",
    "display": "block",
    "--tw-text-opacity": "1",
    "color": "hsl(344 51% 51% / var(--tw-text-opacity))"
  }))
};
export default styles;