var __jsx = React.createElement;
import Link from "next/link";
import React from "react";
import { useIntl } from "react-intl";
import { Image } from "@/components/common";
import useCheckout from "@/hooks/useCheckout.hook";
import useInterface from "@/hooks/useInterface.hook";
import useRegion from "@/hooks/useRegion.hook";
import { cn } from "@/libraries/clsx.library";
import styles from "./DummyProduct.styles";

var DummyProduct = function DummyProduct(_ref) {
  var name = _ref.name,
      imageSrc = _ref.imageSrc,
      linkHref = _ref.linkHref,
      price = _ref.price,
      priceUndiscounted = _ref.priceUndiscounted,
      detail = _ref.detail,
      className = _ref.className,
      type = _ref.type;
  var t = useIntl();

  var _useRegion = useRegion(),
      formatPrice = _useRegion.formatPrice,
      currentChannel = _useRegion.currentChannel;

  var _useCheckout = useCheckout(),
      loading = _useCheckout.loading,
      submitting = _useCheckout.submitting;

  var _useInterface = useInterface(),
      modifications = _useInterface.modifications;

  var formatedPrice = formatPrice({
    amount: price,
    currency: currentChannel.currencyCode
  });
  var formatedPriceUndiscounted = formatPrice({
    amount: priceUndiscounted,
    currency: currentChannel.currencyCode
  });
  var removeLinks = modifications.includes("REMOVE_INTERNAL_LINKING");

  var withLink = function withLink(href, content) {
    return href && !removeLinks ? __jsx(Link, {
      href: href,
      passHref: true,
      className: styles.link({
        disabled: loading || submitting || removeLinks
      })
    }, content) : content;
  };

  return __jsx("div", {
    id: "__".concat(type.toLowerCase(), "-product-dummy"),
    className: cn(styles.root(), className)
  }, withLink(linkHref, __jsx("div", {
    className: styles.imageContainer()
  }, __jsx(Image, {
    src: imageSrc,
    alt: "".concat(name, " product image"),
    placeholder: "blur",
    fill: true,
    unoptimized: true,
    sizes: "100vw",
    style: {
      objectFit: "cover",
      objectPosition: "center"
    }
  }))), __jsx("div", {
    className: styles.info()
  }, __jsx("div", {
    className: styles.mainInfo()
  }, withLink(linkHref, __jsx("span", {
    className: styles.name({
      hoverEffect: !!linkHref && !removeLinks
    })
  }, name)), __jsx("span", {
    className: styles.price()
  }, price > 0 ? formatedPrice : t.formatMessage({
    id: "checkout.dummyProduct.forFree",
    defaultMessage: "For free"
  }))), __jsx("div", {
    className: styles.details()
  }, __jsx("span", {
    className: styles.detail()
  }, detail), priceUndiscounted ? __jsx("span", {
    className: styles.priceUndiscounted()
  }, formatedPriceUndiscounted) : null)));
};

DummyProduct.displayName = "DummyProduct";
export default DummyProduct;