import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from "react";

var SvgPill = function SvgPill(props) {
  return __jsx("svg", _extends({
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("rect", {
    x: 1,
    y: 7,
    width: 22,
    height: 10,
    rx: 5,
    stroke: "#5D5656",
    strokeWidth: 1.5
  }), __jsx("path", {
    d: "M12 7V17",
    stroke: "#5D5656",
    strokeWidth: 1.5
  }));
};

export default SvgPill;