var __jsx = React.createElement;
import { useRouter } from "next/router";
import React from "react";
import { GoogleCustomerReviewsBadge, Head } from "@/components/common"; // import { API_MOCKING } from "@/config/test.config";

import { Notification } from "@/notification/components";
import styles from "@/theme/theme.styles";
import { UtmParams } from "@/utmParams/components";
import { Context } from "~/src/context"; // async function importMocksHelper() {
//   return await import("~/e2e/helpers/mock.helper");
// }
// if (API_MOCKING) {
//   importMocksHelper().then(async ({ initMocks }) => {
//     await initMocks();
//   });
// }

function App(_ref) {
  var _Component$getLayout;

  var Component = _ref.Component,
      pageProps = _ref.pageProps;
  // const [shouldRender, setShouldRender] = React.useState(!API_MOCKING);
  var router = useRouter();
  styles();
  var getLayout = (_Component$getLayout = Component.getLayout) !== null && _Component$getLayout !== void 0 ? _Component$getLayout : function (page) {
    return page;
  }; // Below useEffect is used to hide newsletter form on specific routes
  // It's a temporary solution and should be remove after WP - Savecart pixel deactivation

  React.useEffect(function () {
    var hideOnRoutes = ["/checkout", "/collect", "/restore"];
    var style = null;

    if (hideOnRoutes.some(function (route) {
      return router.pathname.includes(route);
    })) {
      var _document$head;

      var css = "#lovacare_slider_newsletter_form { display: none !important; }";
      style = document.createElement("style");
      style.textContent = css;
      (_document$head = document.head) === null || _document$head === void 0 || _document$head.appendChild(style);
    }

    return function () {
      if (style) {
        var _document$head2;

        (_document$head2 = document.head) === null || _document$head2 === void 0 || _document$head2.removeChild(style);
      }
    };
  }, [router.pathname]); // React.useEffect(() => {
  //   async function initApiMocks() {
  //     const { initMocks } = await importMocksHelper();
  //     await initMocks();
  //     setShouldRender(true);
  //   }
  //   if (API_MOCKING) {
  //     initApiMocks();
  //   }
  // }, []);
  // if (!shouldRender) {
  //   return null;
  // }

  return __jsx(React.Fragment, null, __jsx(Head, null), __jsx(Context, null, getLayout(__jsx(Component, pageProps)), __jsx(UtmParams, null), __jsx(Notification, null), __jsx(GoogleCustomerReviewsBadge, null)));
} // export function reportWebVitals(metric: NextWebVitalsMetric) {
//   // eslint-disable-next-line no-console
//   console.log(metric);
// }


export default App;