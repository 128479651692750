var __jsx = React.createElement;
import { ClockIcon, EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import React from "react";
import { useIntl } from "react-intl";
import { Button, Container, Icon } from "@/components/ui";
import { EMAIL_ADDRESS, PHONE_NUMBER } from "@/config/contact.config";
import { FACEBOOK_URL, INSTAGRAM_URL, TIK_TOK_URL } from "@/config/link.config";
import styles from "./Footer.styles";

var Footer = function Footer() {
  var t = useIntl();
  return __jsx("footer", null, __jsx(Container, {
    color: "dark"
  }, __jsx("div", {
    className: styles.main()
  }, __jsx("div", {
    className: styles.header()
  }, __jsx(Link, {
    "aria-label": t.formatMessage({
      id: "common.homePage",
      defaultMessage: "home page"
    }),
    href: "/",
    passHref: true
  }, __jsx(Icon, {
    name: "LovaHorizontal",
    color: "white"
  }))), __jsx("div", {
    className: styles.content({
      full: true
    })
  }, __jsx("div", null, __jsx("ul", null, PHONE_NUMBER ? __jsx("li", {
    className: styles.navItem()
  }, __jsx(PhoneIcon, {
    className: styles.icon()
  }), __jsx("a", {
    href: "tel:".concat(PHONE_NUMBER),
    className: styles.link()
  }, PHONE_NUMBER)) : null, __jsx("li", {
    className: styles.navItem()
  }, __jsx(ClockIcon, {
    className: styles.icon()
  }), __jsx("span", null, t.formatMessage({
    defaultMessage: "Mon - Fri, 10:00 - 16:00",
    id: "footerDefault.contact.availability"
  }))), EMAIL_ADDRESS ? __jsx("li", {
    className: styles.navItem()
  }, __jsx(EnvelopeIcon, {
    className: styles.icon()
  }), __jsx("a", {
    href: "mailto:".concat(EMAIL_ADDRESS),
    className: styles.link()
  }, EMAIL_ADDRESS)) : null, __jsx("li", {
    className: styles.navItemSocials({
      staticFooter: true
    })
  }, FACEBOOK_URL ? __jsx("a", {
    "aria-label": t.formatMessage({
      id: "storefront.sections.social.text",
      defaultMessage: "Follow us on {social}"
    }, {
      social: "Facebook"
    }),
    href: FACEBOOK_URL
  }, __jsx(Button, {
    variant: "circle",
    color: "flavor",
    width: 35,
    height: 35
  }, __jsx(Icon, {
    name: "Facebook",
    className: styles.socialIcon()
  }))) : null, INSTAGRAM_URL ? __jsx("a", {
    "aria-label": t.formatMessage({
      id: "storefront.sections.social.text",
      defaultMessage: "Follow us on {social}"
    }, {
      social: "Instagram"
    }),
    href: INSTAGRAM_URL,
    className: styles.link()
  }, __jsx(Button, {
    variant: "circle",
    color: "flavor",
    width: 35,
    height: 35
  }, __jsx(Icon, {
    name: "Instagram",
    className: styles.socialIcon()
  }))) : null, TIK_TOK_URL ? __jsx("a", {
    "aria-label": t.formatMessage({
      id: "storefront.sections.social.text",
      defaultMessage: "Follow us on {social}"
    }, {
      social: "Tik Tok"
    }),
    href: TIK_TOK_URL,
    className: styles.link()
  }, __jsx(Button, {
    variant: "circle",
    color: "flavor",
    width: 35,
    height: 35
  }, __jsx(Icon, {
    name: "TikTok",
    className: styles.socialIcon()
  }))) : null)))))));
};

Footer.displayName = "Footer";
export default Footer;