import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../libraries/stitches.library.ts";
var styles = {
  checkboxWrapper: _css(_objectSpread(_objectSpread({}, {
    "display": "flex",
    "alignItems": "flex-start"
  }), {}, {
    variants: {
      center: {
        "true": _objectSpread({}, {
          "alignItems": "center"
        })
      }
    }
  })),
  input: _css(_objectSpread(_objectSpread({}, {
    "position": "relative",
    "top": "0.125rem",
    "height": "1rem",
    "width": "1rem",
    "cursor": "pointer",
    "borderRadius": "0.25rem"
  }), {}, {
    boxShadow: "none !important",
    variants: {
      color: {
        dark: {
          backgroundColor: "$gray8",
          borderColor: "$gray7",
          color: "$gray8",
          "&[type='checkbox']:focus": {
            borderColor: "$gray7"
          },
          "&[type='checkbox']:checked": {
            borderColor: "$gray7"
          }
        },
        light: {
          borderColor: "$gray3",
          backgroundColor: "$white",
          color: "$white",
          "&[type='checkbox']:focus": {
            borderColor: "$gray3"
          },
          "&[type='checkbox']:checked": {
            borderColor: "$gray3",
            backgroundImage: "url(\"data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='Gray' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e\")"
          }
        },
        lightFlavour: {
          borderColor: "$gray3",
          backgroundColor: "$white",
          color: "$white",
          "&[type='checkbox']:focus": {
            borderColor: "$gray3"
          },
          "&[type='checkbox']:checked": {
            borderColor: "$gray3",
            backgroundImage: "url(\"data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23C24264FF' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z' /%3e%3c/svg%3e\")"
          }
        }
      },
      error: {
        "true": {
          borderColor: "$danger",
          "&[type='checkbox']:focus": {
            borderColor: "$danger"
          }
        }
      }
    }
  })),
  content: _css(_objectSpread({}, {
    "display": "flex",
    "cursor": "pointer",
    "flexDirection": "column",
    "paddingLeft": "0.75rem"
  })),
  label: _css({
    lineHeight: "$5",
    variants: {
      color: {
        dark: {
          color: "$gray9"
        },
        light: {
          color: "$white"
        }
      },
      labelSize: {
        xs: {
          fontSize: "$2"
        },
        sm: {
          fontSize: "$2",
          "@md": {
            fontSize: "$3"
          }
        },
        md: {
          fontSize: "$3"
        },
        lg: {
          fontSize: "$4"
        },
        xl: {
          fontSize: "$5"
        }
      }
    }
  }),
  error: _css(_objectSpread(_objectSpread({}, {
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem"
  }), {}, {
    color: "$danger",
    variants: {
      placement: {
        top: _objectSpread({}, {
          "marginBottom": "0.25rem"
        }),
        bottom: _objectSpread({}, {
          "marginTop": "0.25rem"
        })
      }
    }
  }))
};
export default styles;