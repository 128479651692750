import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import tw from "./theme.media.tw";
export var sizes = {
  sizes: _objectSpread({
    1: ".0625rem",
    2: ".0125rem",
    3: ".25rem",
    4: ".5rem",
    5: ".625rem",
    6: ".75rem",
    7: "1rem",
    8: "1.5rem",
    9: "2rem",
    10: "2.75rem",
    11: "4rem",
    12: "5rem"
  }, tw),
  space: _objectSpread({
    1: ".0625rem",
    2: ".0125rem",
    3: ".25rem",
    4: ".5rem",
    5: ".625rem",
    6: ".75rem",
    7: "1rem",
    8: "1.5rem",
    9: "2rem",
    10: "2.75rem",
    11: "4rem",
    12: "5rem"
  }, tw)
};
export default sizes;