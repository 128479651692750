import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _slicedToArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
import "swiper/css/effect-fade";
import React from "react";
import { useIntl } from "react-intl";
import { EffectFade } from "swiper";
import { Slider } from "@/components/ui";
import { down } from "~/src/helpers/screens.helpers";
import { useMediaQuery } from "~/src/hooks/useMediaQuery.hook";
import { ScreenEnum } from "~/src/theme/theme.enums";
import Item from "./Expert.Item";
import styles from "./Expert.styles";

var Expert = function Expert(_ref) {
  var items = _ref.items,
      sliderId = _ref.sliderId;
  var t = useIntl();

  var _React$useState = React.useState(0),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      activeSlideIndex = _React$useState2[0],
      setActiveSlideIndex = _React$useState2[1];

  var _React$useState3 = React.useState(),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      sliderHeight = _React$useState4[0],
      setSliderHeight = _React$useState4[1];

  var isDownLg = useMediaQuery(down(ScreenEnum.lg), false);
  React.useEffect(function () {
    var observer = new ResizeObserver(function (entries) {
      if (entries && entries[0]) {
        var height = entries[0].contentRect.height;
        setSliderHeight(height + 57);
      }
    });
    var activeSlideContent = document.querySelector("#".concat(sliderId, " .swiper-slide-active > div"));

    if (activeSlideContent) {
      setSliderHeight(activeSlideContent.clientHeight + 57);
      observer.observe(activeSlideContent);
    }

    if (!isDownLg && activeSlideContent) {
      observer.unobserve(activeSlideContent);
    }

    return function () {
      observer.disconnect();
    };
  }, [isDownLg, sliderId, activeSlideIndex]);
  return __jsx(Slider, {
    id: sliderId,
    className: styles.slider(),
    effect: "fade",
    fadeEffect: {
      crossFade: true
    },
    initialSlide: 0,
    observeSlideChildren: false,
    style: {
      height: isDownLg ? sliderHeight || "auto" : "auto"
    },
    modules: [EffectFade],
    onSlideChange: function onSlideChange(_ref2) {
      var activeIndex = _ref2.activeIndex;

      if (activeIndex !== activeSlideIndex) {
        setActiveSlideIndex(activeIndex);
      }
    },
    onAfterInit: function onAfterInit() {
      setActiveSlideIndex(0);
    },
    additionalContent: items.length > 1 ? __jsx("div", {
      className: styles.navigationContainer()
    }, __jsx(Slider.NavigationItem, {
      type: "prev",
      text: t.formatMessage({
        defaultMessage: "Previous",
        id: "common.previous"
      })
    }), __jsx("div", {
      className: styles.paginationContainer()
    }, __jsx(Slider.Pagination, null)), __jsx(Slider.NavigationItem, {
      type: "next",
      text: t.formatMessage({
        defaultMessage: "Next",
        id: "common.next"
      })
    })) : null
  }, items.map(function (expert, index) {
    return __jsx(Item, _extends({}, expert, {
      key: "expert-".concat(index)
    }));
  }));
};

Expert.Item = Item;
Expert.displayName = "Experts";
export default Expert;