import _slicedToArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
import React from "react";
import { useIntl } from "react-intl";
import { Button, QuantitySelector } from "@/components/ui";
import CartIcon from "@/components/ui/Icon/$/Cart.icon";
import { down } from "@/helpers/screens.helpers";
import useCheckout from "@/hooks/useCheckout.hook";
import { useMediaQuery } from "@/hooks/useMediaQuery.hook";
import { ScreenEnum } from "@/theme/theme.enums";
import { useProductVariant } from "~/src/modules/product/hooks/useProductVariant.hook";
import styles from "./AddToCart.styles";

var AddToCart = function AddToCart(_ref) {
  var activeVariant = _ref.activeVariant,
      testIdPrefix = _ref.testIdPrefix;
  var t = useIntl();

  var _useCheckout = useCheckout(),
      loading = _useCheckout.loading,
      submitting = _useCheckout.submitting;

  var _useProductVariant = useProductVariant(activeVariant),
      addToCart = _useProductVariant.addToCart,
      availableQuantity = _useProductVariant.availableQuantity,
      isAvailableForPurchase = _useProductVariant.isAvailableForPurchase;

  var isDownXs = useMediaQuery(down(ScreenEnum.xs), true);

  var _React$useState = React.useState(1),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      quantity = _React$useState2[0],
      setQuantity = _React$useState2[1];

  React.useEffect(function () {
    setQuantity(availableQuantity > 0 ? 1 : 0);
  }, [availableQuantity]);
  if (!activeVariant) return null;
  return __jsx("div", {
    className: styles.root()
  }, __jsx(QuantitySelector, {
    quantity: quantity,
    increment: function increment() {
      return setQuantity(function (currentQuantity) {
        return currentQuantity + 1;
      });
    },
    decrement: function decrement() {
      return setQuantity(function (currentQuantity) {
        return currentQuantity - 1;
      });
    },
    canIncrement: quantity < availableQuantity,
    variant: "oval",
    size: "lg",
    width: isDownXs ? "full" : undefined,
    disabled: submitting || loading,
    testIdPrefix: "".concat(testIdPrefix !== null && testIdPrefix !== void 0 ? testIdPrefix : "", "add-to-cart-"),
    type: "ADD_TO_CART"
  }), __jsx(Button, {
    id: "__productAddToCart",
    variant: "oval",
    color: "flavor",
    "aria-label": t.formatMessage({
      defaultMessage: "To cart",
      id: "product.addToCart.button"
    }),
    className: styles.button(),
    disabled: !isAvailableForPurchase || !(availableQuantity > 0) || submitting || loading,
    onClick: function onClick() {
      return addToCart(activeVariant, quantity, "product");
    },
    loading: submitting || loading,
    "data-testid": "".concat(testIdPrefix !== null && testIdPrefix !== void 0 ? testIdPrefix : "", "add-to-cart-button")
  }, __jsx(CartIcon, {
    className: styles.buttonIcon({
      disabled: !(availableQuantity > 0) || submitting || loading
    })
  }), t.formatMessage({
    defaultMessage: "To cart",
    id: "product.addToCart.button"
  })));
};

export default AddToCart;