var __jsx = React.createElement;
import React from "react";
import styles from "./Main.styles";

var Main = function Main(_ref) {
  var children = _ref.children;
  return __jsx("main", {
    className: styles.root()
  }, children);
};

Main.displayName = "Main";
export default Main;