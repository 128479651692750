import clsx from "clsx";
import { isObject } from "lodash";
export var cn = function cn() {
  for (var _len = arguments.length, classes = new Array(_len), _key = 0; _key < _len; _key++) {
    classes[_key] = arguments[_key];
  }

  return clsx(classes.map(function (singleClass) {
    if (isObject(singleClass) && "className" in singleClass) {
      return singleClass.className;
    }

    return singleClass;
  }));
};