import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from "react";

var SvgQuality = function SvgQuality(props) {
  return __jsx("svg", _extends({
    width: 62,
    height: 61,
    viewBox: "0 0 62 61",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("mask", {
    id: "mask0_3497_5831",
    style: {
      maskType: "luminance"
    },
    maskUnits: "userSpaceOnUse",
    x: 3,
    y: 3,
    width: 55,
    height: 54
  }, __jsx("path", {
    d: "M4 3.5H57V56.5H4V3.5Z",
    fill: "white",
    stroke: "white"
  })), __jsx("g", {
    mask: "url(#mask0_3497_5831)"
  }, __jsx("path", {
    d: "M57.0007 7.69386C57.0007 10.0032 55.0989 11.8878 52.7377 11.8878C50.3763 11.8878 48.4746 10.0032 48.4746 7.69386C48.4746 5.38453 50.3763 3.5 52.7377 3.5C55.0989 3.5 57.0007 5.38453 57.0007 7.69386Z",
    fill: "url(#paint0_linear_3497_5831)",
    stroke: "url(#paint1_linear_3497_5831)"
  }), __jsx("path", {
    d: "M27.3999 32.2645C30.4031 34.1503 33.8391 38.2522 35.641 42.5194C40.9348 32.471 46.0332 28.1625 56.3834 25.8638",
    stroke: "url(#paint2_linear_3497_5831)",
    strokeWidth: 1.32798,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __jsx("path", {
    d: "M50.3626 23.3311L40.5571 21.7619C39.9146 21.6793 39.3558 21.2801 39.0625 20.702L32.33 7.44632C31.6036 6.01476 29.5085 6.01476 28.7961 7.47385L22.2172 20.8121C21.9378 21.3903 21.3791 21.7895 20.7367 21.8857L5.86077 24.102C4.2405 24.3359 3.61194 26.3044 4.78525 27.4193L15.6243 37.7017C16.0853 38.1423 16.3088 38.7892 16.197 39.4224L13.7387 54.0545C13.4733 55.6375 15.1634 56.835 16.6021 56.0781L29.8716 49.0992C30.4443 48.7963 31.1287 48.7963 31.7014 49.0992L45.0548 55.9266C46.5074 56.6699 48.1836 55.4448 47.9043 53.8618L46.0744 39.1883C45.9627 38.5552 46.1861 37.922 46.6471 37.4815",
    stroke: "url(#paint3_linear_3497_5831)",
    strokeWidth: 1.32798,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  })), __jsx("defs", null, __jsx("linearGradient", {
    id: "paint0_linear_3497_5831",
    x1: 52.7377,
    y1: 1.52102,
    x2: 52.7377,
    y2: 12.3878,
    gradientUnits: "userSpaceOnUse"
  }, __jsx("stop", {
    stopColor: "#CF3161"
  }), __jsx("stop", {
    offset: 1,
    stopColor: "#DB7991"
  })), __jsx("linearGradient", {
    id: "paint1_linear_3497_5831",
    x1: 52.7377,
    y1: 1.52102,
    x2: 52.7377,
    y2: 12.3878,
    gradientUnits: "userSpaceOnUse"
  }, __jsx("stop", {
    stopColor: "#CF3161"
  }), __jsx("stop", {
    offset: 1,
    stopColor: "#DB7991"
  })), __jsx("linearGradient", {
    id: "paint2_linear_3497_5831",
    x1: 41.8916,
    y1: 23.2398,
    x2: 41.8916,
    y2: 42.5193,
    gradientUnits: "userSpaceOnUse"
  }, __jsx("stop", {
    stopColor: "#CF3161"
  }), __jsx("stop", {
    offset: 1,
    stopColor: "#DB7991"
  })), __jsx("linearGradient", {
    id: "paint3_linear_3497_5831",
    x1: 27.2716,
    y1: -1.49084,
    x2: 27.2716,
    y2: 56.3109,
    gradientUnits: "userSpaceOnUse"
  }, __jsx("stop", {
    stopColor: "#CF3161"
  }), __jsx("stop", {
    offset: 1,
    stopColor: "#DB7991"
  }))));
};

export default SvgQuality;