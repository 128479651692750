import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../libraries/stitches.library.ts";
var styles = {
  col: _css(_objectSpread(_objectSpread({}, {
    "display": "table-cell",
    "paddingLeft": "0.75rem",
    "paddingRight": "0.75rem",
    "paddingTop": "0.875rem",
    "paddingBottom": "0.875rem",
    "textAlign": "left"
  }), {}, {
    fontSize: "$2",
    "&:first-child": _objectSpread({}, {
      "paddingLeft": "1.5rem"
    }),
    "&:last-child": _objectSpread({}, {
      "paddingRight": "1.5rem"
    }),
    variants: {
      hidden: {
        "true": {
          display: "none"
        }
      }
    }
  }))
};
export default styles;