var __jsx = React.createElement;
import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Button } from "@/components/ui";
import colors from "@/theme/theme.colors.tw";
import styles from "./Overlay.styles";

var Overlay = function Overlay(_ref) {
  var show = _ref.show,
      onClose = _ref.onClose,
      maxWidth = _ref.maxWidth,
      children = _ref.children;
  return __jsx(Dialog, {
    as: "div",
    className: styles.dialog(),
    open: show,
    onClose: onClose
  }, __jsx("div", {
    className: styles.background()
  }), __jsx("div", {
    className: styles.wrapper()
  }, __jsx(Dialog.Panel, {
    className: styles.panel(),
    style: {
      maxWidth: maxWidth
    }
  }, __jsx(Button, {
    variant: "naked",
    className: styles.panelClose(),
    onClick: function onClick() {
      return onClose();
    }
  }, __jsx(XMarkIcon, {
    width: 28,
    height: 28,
    color: colors.common.black
  })), children)));
};

Overlay.displayName = "Overlay";
export default Overlay;