import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../libraries/stitches.library.ts";
var style = {
  root: _css(_objectSpread({}, {
    "display": "grid",
    "gridTemplateColumns": "repeat(8, minmax(0, 1fr))"
  })),
  imageContainer: _css(_objectSpread(_objectSpread({}, {
    "position": "relative",
    "gridColumn": "span 8 / span 8",
    "height": "20rem"
  }), {}, {
    "@sm": _objectSpread({}, {
      "height": "24rem"
    }),
    "@lg": _objectSpread({}, {
      "gridColumn": "span 4 / span 4",
      "height": "auto"
    })
  })),
  infoContainer: _css(_objectSpread(_objectSpread({}, {
    "gridColumn": "span 8 / span 8",
    "paddingLeft": "1rem",
    "paddingRight": "1rem",
    "paddingTop": "2rem",
    "paddingBottom": "2rem"
  }), {}, {
    "@sm": _objectSpread({}, {
      "padding": "2.5rem"
    }),
    "@lg": _objectSpread({}, {
      "gridColumn": "span 4 / span 4"
    })
  })),
  category: _css(_objectSpread(_objectSpread({}, {
    "marginBottom": "0.5rem",
    "textTransform": "uppercase"
  }), {}, {
    color: "$default",
    letterSpacing: "0.25rem"
  })),
  name: _css(_objectSpread(_objectSpread({}, {
    "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke",
    "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "transitionDuration": "300ms",
    "animationDuration": "300ms",
    "animationTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)"
  }), {}, {
    fontFamily: "$tenor",
    fontSize: "2rem",
    "&:hover": {
      color: "$default"
    },
    "@md": {
      fontSize: "2.5rem"
    }
  })),
  description: _css(_objectSpread(_objectSpread({}, {
    "marginBottom": "1.5rem"
  }), {}, {
    lineHeight: "$8",
    color: "$gray7"
  })),
  list: _css(_objectSpread(_objectSpread({}, {
    "marginBottom": "1.75rem",
    "display": "flex",
    "flexDirection": "column",
    "gap": "0.25rem"
  }), {}, {
    lisStyle: "none",
    li: _objectSpread(_objectSpread({}, {
      "position": "relative",
      "marginBottom": "0.5rem",
      "paddingLeft": "1.5rem"
    }), {}, {
      color: "$gray7",
      lineHeight: "$4",
      "&::before": _objectSpread(_objectSpread({}, {
        "position": "absolute",
        "left": "0px",
        "top": "50%",
        "display": "block",
        "height": "0.5rem",
        "width": "0.5rem",
        "borderRadius": "0.25rem"
      }), {}, {
        content: "",
        backgroundColor: "$default",
        transform: "translateY(-50%)"
      })
    })
  })),
  details: _css(_objectSpread(_objectSpread({}, {
    "position": "relative"
  }), {}, {
    color: "$gray7"
  })),
  button: _css(_objectSpread(_objectSpread({}, {
    "width": "100%"
  }), {}, {
    "@xl": _objectSpread({}, {
      "width": "auto",
      "paddingLeft": "3.5rem !important",
      "paddingRight": "3.5rem !important"
    })
  })),
  discountTagContainer: _css({
    width: "100%"
  }),
  discountTag: _css({
    width: "fit-content"
  })
};
export default style;