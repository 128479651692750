import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from "react";

var SvgShoppingBag = function SvgShoppingBag(props) {
  return __jsx("svg", _extends({
    width: 16,
    height: 16,
    viewBox: "0 0 16 16",
    fill: "none",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("path", {
    d: "M4 1.3335L2 4.00016V13.3335C2 13.6871 2.14048 14.0263 2.39052 14.2763C2.64057 14.5264 2.97971 14.6668 3.33333 14.6668H12.6667C13.0203 14.6668 13.3594 14.5264 13.6095 14.2763C13.8595 14.0263 14 13.6871 14 13.3335V4.00016L12 1.3335H4Z",
    stroke: props.color || "#C24163"
  }), __jsx("path", {
    d: "M2 4H14",
    stroke: props.color || "#C24163"
  }), __jsx("path", {
    d: "M10.6673 6.6665C10.6673 7.37375 10.3864 8.05203 9.88627 8.55212C9.38617 9.05222 8.7079 9.33317 8.00065 9.33317C7.29341 9.33317 6.61513 9.05222 6.11503 8.55212C5.61494 8.05203 5.33398 7.37375 5.33398 6.6665",
    stroke: props.color || "#C24163"
  }));
};

export default SvgShoppingBag;