import Default from "./Footer.Default";
import Static from "./Footer.Static"; //prettier-ignore

var Footer = function Footer() {
  return null;
};

Footer.Default = Default;
Footer.Static = Static;
Footer.displayName = "Footer";
export default Footer;