import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../libraries/stitches.library.ts";
import navbarDesktopStyles from "./components/NavbarDesktop/NavbarDesktop.styles";
var styles = {
  root: _css(_objectSpread(_objectSpread({}, {
    "position": "sticky",
    "top": "0px",
    "zIndex": "30",
    "width": "100%"
  }), {}, {
    variants: {
      withBorder: {
        "true": _objectSpread({}, {
          "borderBottomWidth": "1px",
          "borderColor": "hsl(359 4% 85% / 0.6)"
        })
      }
    }
  })),
  simple: _css(_objectSpread(_objectSpread({}, {
    "display": "none",
    "height": "4rem"
  }), {}, {
    "@md": _objectSpread({}, {
      "display": "flex"
    })
  })),
  nav: _css(_objectSpread({}, {
    "display": "flex",
    "height": "5rem",
    "alignItems": "center",
    "justifyContent": "space-between"
  })),
  icon: _css(_objectSpread({}, {
    "height": "1.5rem",
    "width": "1.5rem"
  })),
  navLeft: _css(_objectSpread({}, {
    "display": "flex",
    "flex": "1 1 0%",
    "alignItems": "center",
    "gap": "1.5rem"
  })),
  hidden: _css(_objectSpread({}, {
    "@media (min-width: 1024px)": {
      "display": "none"
    }
  })),
  visibleMd: _css(_objectSpread({}, {
    "display": "none !important",
    "@media (min-width: 768px)": {
      "display": "inline-flex !important"
    }
  })),
  hiddenMd: _css(_objectSpread({}, {
    "display": "inline-flex !important",
    "@media (min-width: 768px)": {
      "display": "none !important"
    }
  })),
  navRight: _css(_objectSpread({}, {
    "display": "flex",
    "flex": "1 1 0%",
    "alignItems": "center",
    "justifyContent": "flex-end",
    "gap": "1.5rem"
  })),
  navRightStatic: _css(_objectSpread({}, {
    "display": "flex",
    "flex": "1 1 0%",
    "alignItems": "center",
    "justifyContent": "flex-end",
    "gap": "1.5rem"
  })),
  button: _css({
    padding: 0,
    "&:focus": {
      span: {
        color: "$black"
      }
    },
    variants: {
      disabled: {
        "true": _objectSpread({}, {
          "pointerEvents": "none",
          "cursor": "default"
        })
      }
    }
  }),
  buttonText: _css(_objectSpread(_objectSpread({}, {
    "display": "inline-block",
    "maxWidth": "100px",
    "paddingLeft": "0.25rem",
    "textAlign": "left"
  }), {}, {
    fontSize: "$2",
    "@sm": _objectSpread(_objectSpread({}, {
      "maxWidth": "230px"
    }), {}, {
      fontSize: "$3"
    })
  })),
  accountButton: _css({
    path: _objectSpread({}, {
      "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
      "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
      "transitionDuration": "150ms"
    }),
    "&:hover": {
      path: {
        stroke: "$default"
      }
    }
  }),
  cartIcon: _css(_objectSpread(_objectSpread({}, {
    "height": "1.25rem",
    "width": "1.25rem"
  }), {}, {
    variants: {
      disabled: {
        "true": _objectSpread(_objectSpread({}, {
          "pointerEvents": "none",
          "cursor": "default",
          "--tw-bg-opacity": "0.75"
        }), {}, {
          fill: "$black",
          "&:hover": {
            fill: "$black"
          }
        })
      }
    }
  })),
  counter: _css(_objectSpread(_objectSpread({}, {
    "marginLeft": "0.25rem",
    "width": "1.5rem",
    "overflow": "hidden",
    "textOverflow": "ellipsis",
    "whiteSpace": "nowrap",
    "fontSize": "1rem",
    "lineHeight": "1.5rem"
  }), {}, {
    lineHeight: "1.25rem",
    color: "$gray9"
  })),
  link: _css({
    "@sm": _objectSpread(_objectSpread({}, {
      "display": "flex",
      "height": "2.5rem",
      "width": "2.5rem",
      "alignItems": "center",
      "justifyContent": "center",
      "borderRadius": "9999px",
      "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
      "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
      "transitionDuration": "150ms"
    }), {}, {
      backgroundColor: "$default",
      "&:hover": {
        backgroundColor: "$dark"
      }
    })
  }),
  linkIcon: _css(_objectSpread({}, {
    "height": "1.25rem",
    "width": "1.25rem"
  }))
};

var s = _objectSpread(_objectSpread({}, navbarDesktopStyles), styles);

export default s;