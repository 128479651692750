import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread({}, {
    "position": "relative"
  })),
  container: _css(_objectSpread({}, {
    "--tw-aspect-h": "1",
    "position": "relative",
    "paddingBottom": "calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%)",
    "--tw-aspect-w": "1",
    "> *": {
      "position": "absolute",
      "height": "100%",
      "width": "100%",
      "top": "0",
      "right": "0",
      "bottom": "0",
      "left": "0"
    },
    "pointerEvents": "none",
    "overflow": "hidden",
    "borderRadius": "1rem"
  })),
  slider: _css(_objectSpread(_objectSpread({}, {
    "pointerEvents": "auto",
    "overflow": "hidden",
    "borderRadius": "1rem"
  }), {}, {
    cursor: "grab",
    ".swiper-slide": _objectSpread({}, {
      "overflow": "hidden"
    })
  })),
  list: _css(_objectSpread({}, {
    "marginTop": "1.5rem",
    "display": "grid",
    "width": "100%",
    "gridTemplateColumns": "repeat(3, minmax(0, 1fr))",
    "alignItems": "center",
    "gap": "0.75rem",
    "@media (min-width: 1024px)": {
      "gap": "1.25rem"
    }
  })),
  listImageWrapper: _css(_objectSpread(_objectSpread({}, {
    "overflow": "hidden",
    "borderRadius": "1rem",
    "transitionProperty": "border-color",
    "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "transitionDuration": "300ms",
    "animationDuration": "300ms"
  }), {}, {
    border: "2px solid transparent",
    clipPath: "border-box",
    variants: {
      active: {
        "true": {
          borderColor: "$default"
        }
      }
    }
  })),
  listImageContainer: _css(_objectSpread({}, {
    "--tw-aspect-h": "1",
    "position": "relative",
    "paddingBottom": "calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%)",
    "--tw-aspect-w": "1",
    "> *": {
      "position": "absolute",
      "height": "100%",
      "width": "100%",
      "top": "0",
      "right": "0",
      "bottom": "0",
      "left": "0"
    },
    "cursor": "pointer",
    "transitionDuration": "300ms",
    "animationDuration": "300ms",
    "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "animationTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "&:hover": {
      "--tw-scale-x": "1.1",
      "--tw-scale-y": "1.1",
      "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
    }
  })),
  image: _css(_objectSpread({}, {
    "position": "relative",
    "height": "100%",
    "width": "100%",
    "borderRadius": "1rem"
  })),
  discountTagContainer: _css({
    width: "100%"
  }),
  discountTag: _css({
    width: "fit-content"
  })
};
export default styles;