import React from "react";
import useChat from "@/hooks/useChat.hook";

var Chat = function Chat() {
  var _useChat = useChat(),
      isReady = _useChat.isReady,
      isVisible = _useChat.isVisible,
      showChat = _useChat.showChat,
      hideChat = _useChat.hideChat;

  React.useEffect(function () {
    if (isReady && !isVisible) {
      showChat();
    }
  }, [showChat, isReady, isVisible]);
  React.useEffect(function () {
    return function () {
      hideChat();
    };
  }, [hideChat]);
  return null;
};

export default Chat;