var __jsx = React.createElement;
import React from "react";
import styles from "./PopupContentInfo.styles";

var PopupContentInfo = function PopupContentInfo(_ref) {
  var children = _ref.children;
  return __jsx("div", {
    className: styles.root()
  }, children);
};

PopupContentInfo.displayName = "PopupContentInfo";
export default PopupContentInfo;