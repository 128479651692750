import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

var _Number, _Number2, _FREE_DELIVERY_PRICE;

import { LOCALE } from "@/config/region.config";
export var API_URI = process.env.NEXT_PUBLIC_API_URI || "";
export var MEDIA_BUCKET_URI = process.env.NEXT_PUBLIC_MEDIA_BUCKET_URI;
export var GEOLOCATION = process.env.NEXT_PUBLIC_GEOLOCATION === "false";
export var STOREFRONT_NAME = process.env.NEXT_PUBLIC_STOREFRONT_NAME || "Lova";
export var SALEOR_APP_TOKEN = process.env.SALEOR_APP_TOKEN || "";
export var BLOG_API_URL = process.env.NEXT_PUBLIC_BLOG_API_URI || "";
export var DEFAULT_PAGINATE_BY = 16;
export var STOREFRONT_FEATURED_COLLECTION = "featured-products";
export var STOREFRONT_BESTSELLER_COLLECTION = "bestseller-product";
export var CHECKOUT_TOKEN_KEY = "checkout-token";
export var OVERRIDE_TEST_GROUP_KEY = "test-group";
export var ALLOWED_COOKIE_GROUPS_KEY = "allowed-cookie-groups";
export var MAIN_NAVBAR = "navbar";
export var FOOTER_NAVBAR = "footer";
export var PRODUCT_SLOGAN_ATTRIBUTE = "slogan";
export var PRODUCT_TAGS_TOP_ATTRIBUTE = "tags-top";
export var PRODUCT_TAGS_BOTTOM_ATTRIBUTE = "tags-bottom";
export var PRODUCT_SHORT_DESCRIPTION_ATTRIBUTE = "short-description";
export var PRODUCT_BENEFITS_ATTRIBUTE = "benefits";
export var PRODUCT_ICON_ATTRIBUTE = "icon";
export var PRODUCT_ADDITIONAL_INFO_ATTRIBUTE_GROUP = "additional-info";
export var PRODUCT_HIDDEN_ON_STOREFRONT_ATTRIBUTE = "hidden-on-storefront";
export var PRODUCT_VARIANT_VOLUME_ATTRIBUTE = "volume";
export var PRODUCT_VARIANT_PRICE_HISTORY_ATTRIBUTE = "price-history";
export var PRODUCT_VARIANT_ALLOWED_PAYMENT_METHOD_ATTRIBUTE = "allowed-payment-methods";
export var PRODUCT_VARIANT_SHOW_DELIVERY_TIME_METHOD_ATTRIBUTE = "show-delivery-time";
export var PRODUCT_VARIANT_LOW_PRICE_ZONE_ATTRIBUTE = "low-price-zone";
export var PRODUCT_VARIANT_HIGHLIGHTED_DESCRIPTION_ATTRIBUTE = "highlighted-description";
export var PRODUCT_VARIANT_NOT_AVAILABLE_FOT_PURCHASE = "not-available-for-purchase";
export var PRODUCTS_CATEGORY_ADITIONS_OF_SETS = "dodatki-do-zestawow";
export var PAGE_PRIVACY_POLICY_SLUG = "privacy-policy";
export var PAGE_TYPE_SIMPLE_SLUG = "default-page";
export var PAGE_TYPE_EXPERT_OPINION_SLUG = "expert-opinion";
export var PAGE_TYPE_EXPERT_OPINION_NAME_ATTRIBUTE = "expert-opinion-name";
export var PAGE_TYPE_EXPERT_OPINION_PHOTO_ATTRIBUTE = "expert-opinion-photo";
export var PAGE_TYPE_INFLUENCER_OPINION_SLUG = "influencer-opinion";
export var PAGE_TYPE_INFLUENCER_OPINION_TITLE_SLUG = "influencer-opinion-title";
export var PAGE_TYPE_INFLUENCER_OPINION_PHOTO_SLUG = "influencer-opinion-photo";
export var PAGE_TYPE_INFLUENCER_OPINION_SIGNATURE_SLUG = "influencer-opinion-signature";
export var PAGE_TYPE_INFLUENCER_OPINION_NAME_SLUG = "influencer-opinion-name";
export var PAGE_TYPE_INFLUENCER_OPINION_INFO_SLUG = "influencer-opinion-info";
export var PAGE_TYPE_CLIENT_REVIEW_SLUG = "client-review";
export var PAGE_TYPE_CLIENT_REVIEW_VARIANT_REFERENCE_ATTRIBUTE = "client-review-variant-reference";
export var PAGE_TYPE_CLIENT_REVIEW_DATE_ATTRIBUTE = "client-review-date";
export var PAGE_TYPE_CLIENT_REVIEW_CUSTOMER_NAME_ATTRIBUTE = "client-review-customer-name";
export var PAGE_TYPE_CLIENT_REVIEW_CUSTOMER_AGE_ATTRIBUTE = "client-review-customer-age";
export var PAGE_TYPE_CLIENT_REVIEW_CUSTOMER_VERIFIED_ATTRIBUTE = "client-review-customer-verified";
export var PAGE_TYPE_CLIENT_REVIEW_RATING_ATTRIBUTE = "client-review-rating";
export var PAGE_TYPE_CLIENT_REVIEW_STOREFRONT_VISIBLE_ATTRIBUTE = "client-review-storefront-visible";
export var PAGE_TYPE_FAQ_SLUG = "faq";
export var PAGE_TYPE_FAQ_QUESTION_ATTRIBUTE = "faq-question";
export var ISR_REVALIDATE = Number(process.env.ISR_REVALIDATE) || 60 * 60; // 60 minutes

export var ISR_WEBHOOK_TOKEN = process.env.ISR_WEBHOOK_TOKEN || "";
export var CSR_POLL_INTERVAL = 1000 * 10 * 1; // 10 seconds

export var COMING_SOON_ACTIVE = process.env.NEXT_PUBLIC_COMING_SOON_ACTIVE === "true";
export var COMING_SOON_WITH_SHOP_ACTIVE = process.env.NEXT_PUBLIC_COMING_SOON_WITH_SHOP_ACTIVE === "true";
export var NOTIFICATION_SHOW_TIME = 1000 * 5 * 1; // 5 seconds

export var FREE_DELIVERY_PRICE = (_FREE_DELIVERY_PRICE = {}, _defineProperty(_FREE_DELIVERY_PRICE, LOCALE.plPL, (_Number = Number(process.env.NEXT_PUBLIC_FREE_DELIVERY_PRICE_PL)) !== null && _Number !== void 0 ? _Number : 0), _defineProperty(_FREE_DELIVERY_PRICE, LOCALE.enUS, (_Number2 = Number(process.env.NEXT_PUBLIC_FREE_DELIVERY_PRICE_EN)) !== null && _Number2 !== void 0 ? _Number2 : 0), _FREE_DELIVERY_PRICE);
export var DEVICE_SIZES = process.env.NEXT_PUBLIC_DEVICE_SIZES ? process.env.NEXT_PUBLIC_DEVICE_SIZES.split(",").map(Number) : [640, 750, 828, 1080, 1200, 1920, 2048, 3840];
export var AUTO_APPLY_PROMO_CODES = process.env.NEXT_PUBLIC_AUTO_APPLY_PROMO_CODES === "true";
export var UID_COOKIE_KEY = "_luid";
export var LAST_ORDER_DATA_COOKIE_KEY = "_llod";