import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from "react";

var SvgSpotify = function SvgSpotify(props) {
  return __jsx("svg", _extends({
    width: 25,
    height: 25,
    viewBox: "0 0 25 25",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("path", {
    d: "M12.4997 0.5C5.87233 0.5 0.499512 5.87268 0.499512 12.5001C0.499512 19.1278 5.87233 24.5 12.4997 24.5C19.1278 24.5 24.5001 19.1278 24.5001 12.5001C24.5001 5.87311 19.1278 0.500573 12.4996 0.500573L12.4997 0.5ZM18.0029 17.8075C17.788 18.1601 17.3266 18.2718 16.9741 18.0555C14.1565 16.3344 10.6096 15.9447 6.43248 16.899C6.02996 16.9907 5.62873 16.7385 5.53701 16.3359C5.44487 15.9332 5.69607 15.532 6.0996 15.4403C10.6708 14.3959 14.5919 14.8456 17.755 16.7787C18.1076 16.995 18.2193 17.455 18.0029 17.8075ZM19.4718 14.5401C19.2009 14.9803 18.6249 15.1193 18.1849 14.8484C14.9593 12.8658 10.0423 12.2916 6.22699 13.4497C5.73219 13.5992 5.20958 13.3203 5.0594 12.8264C4.91037 12.3316 5.18937 11.8099 5.68332 11.6595C10.0414 10.3371 15.4594 10.9777 19.1637 13.254C19.6036 13.5248 19.7426 14.1007 19.4718 14.5401ZM19.5979 11.1376C15.7302 8.84038 9.34917 8.62916 5.65652 9.74989C5.06356 9.92973 4.43648 9.59499 4.25679 9.00202C4.07709 8.40877 4.41155 7.78212 5.00495 7.60185C9.24385 6.31504 16.2905 6.56366 20.7434 9.20708C21.2779 9.52363 21.4527 10.2125 21.136 10.7451C20.8208 11.2785 20.1301 11.4543 19.5984 11.1376H19.5979Z",
    fill: "white"
  }));
};

export default SvgSpotify;