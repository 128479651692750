import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../libraries/stitches.library.ts";
var styles = {
  item: _css(_objectSpread(_objectSpread({}, {
    "display": "flex",
    "flexDirection": "row",
    "alignItems": "center",
    "gap": "0.5rem",
    "borderRadius": "9999px",
    "paddingLeft": "0.75rem",
    "paddingRight": "0.75rem",
    "paddingTop": "0.375rem",
    "paddingBottom": "0.375rem",
    "--tw-shadow": "0 1px 2px 0 rgb(0 0 0 / 0.05)",
    "--tw-shadow-colored": "0 1px 2px 0 var(--tw-shadow-color)",
    "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
  }), {}, {
    backgroundColor: "$light",
    color: "$dark",
    variants: {
      color: {
        dark: _objectSpread(_objectSpread({}, {
          "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
          "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
          "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
          "--tw-ring-opacity": "1",
          "--tw-ring-color": "hsl(344 51% 51% / var(--tw-ring-opacity))",
          "--tw-ring-offset-width": "1px",
          "--tw-ring-offset-color": "hsl(344, 51%, 51%)"
        }), {}, {
          backgroundColor: "$default",
          color: "$white"
        }),
        light: _objectSpread(_objectSpread({}, {
          "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
          "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
          "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
          "--tw-ring-opacity": "1",
          "--tw-ring-color": "hsl(344 51% 87% / var(--tw-ring-opacity))",
          "--tw-ring-offset-width": "1px",
          "--tw-ring-offset-color": "hsl(341, 62%, 96%)"
        }), {}, {
          backgroundColor: "hsl(359, 78%, 87%)",
          color: "$dark"
        }),
        lighten: _objectSpread(_objectSpread({}, {
          "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
          "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
          "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
          "--tw-ring-opacity": "1",
          "--tw-ring-color": "hsl(344 51% 87% / var(--tw-ring-opacity))",
          "--tw-ring-offset-width": "1px",
          "--tw-ring-offset-color": "hsl(341, 62%, 96%)"
        }), {}, {
          backgroundColor: "$lighten",
          color: "$dark"
        }),
        white: _objectSpread(_objectSpread({}, {
          "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
          "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
          "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
          "--tw-ring-opacity": "1",
          "--tw-ring-color": "hsl(0 0% 100% / var(--tw-ring-opacity))",
          "--tw-ring-offset-width": "1px",
          "--tw-ring-offset-color": "hsl(0, 0%, 100%)"
        }), {}, {
          backgroundColor: "$white",
          color: "$dark"
        })
      }
    }
  })),
  overlay: _css(_objectSpread({}, {
    "position": "absolute",
    "inset": "0px",
    "zIndex": "10"
  })),
  content: _css(_objectSpread(_objectSpread({}, {
    "position": "relative",
    "display": "flex",
    "height": "100%",
    "width": "100%",
    "flexDirection": "column",
    "justifyContent": "space-between",
    "paddingLeft": "1rem",
    "paddingRight": "1rem",
    "paddingTop": "0.75rem",
    "paddingBottom": "0.75rem"
  }), {}, {
    fontSize: "$2",
    "@md": _objectSpread({}, {
      "paddingLeft": "1.25rem",
      "paddingRight": "1.25rem",
      "paddingTop": "1rem",
      "paddingBottom": "1rem"
    }),
    variants: {
      variant: {
        slim: _objectSpread(_objectSpread({}, {
          "paddingLeft": "0.5rem",
          "paddingRight": "0.5rem",
          "paddingTop": "0.5rem",
          "paddingBottom": "0.5rem"
        }), {}, {
          "@md": _objectSpread({}, {
            "paddingLeft": "0.375rem",
            "paddingRight": "0.375rem",
            "paddingTop": "0.5rem",
            "paddingBottom": "0.5rem"
          })
        })
      }
    }
  })),
  group: _css(_objectSpread(_objectSpread({}, {
    "display": "flex",
    "flexDirection": "row",
    "flexWrap": "wrap",
    "alignItems": "center",
    "columnGap": "0.5rem",
    "rowGap": "0.75rem"
  }), {}, {
    "@md": _objectSpread({}, {
      "gap": "0.75rem"
    }),
    variants: {
      variant: {
        slim: _objectSpread({}, {
          "columnGap": "0.5rem",
          "rowGap": "0.5rem"
        })
      }
    }
  })),
  icon: _css(_objectSpread({}, {
    "height": "1rem",
    "width": "1rem",
    "flex": "none"
  })),
  children: _css(_objectSpread({}, {
    "display": "flex",
    "flexWrap": "wrap",
    "gap": "0.25rem"
  }))
};
export default styles;