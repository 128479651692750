var __jsx = React.createElement;
import React, { Fragment } from "react";
import Group from "./Tag.Group";
import Item from "./Tag.Item";
import Overlay from "./Tag.Overlay"; //prettier-ignore

var Tag = function Tag(_ref) {
  var children = _ref.children,
      variant = _ref.variant,
      className = _ref.className;
  return __jsx(Fragment, null, __jsx(Overlay, {
    variant: variant,
    className: className
  }, children));
};

Tag.Item = Item;
Tag.Group = Group;
Tag.Overlay = Overlay;
Tag.displayName = "Tag";
export default Tag;