var __jsx = React.createElement;
import Link from "next/link";
import React from "react";
import { useIntl } from "react-intl";
import { Button, Container, Icon, Skeleton } from "@/components/ui";
import { useNavbar } from "@/hooks/useNavbar.hook";
import usePath from "@/hooks/usePath.hook";
import { Newsletter } from "@/storefront/components";
import { GOOGLE_MERCHANT_CENTER_ID } from "~/src/config/googleMerchantCenter.config";
import styles from "./Footer.styles";

var Footer = function Footer() {
  var _usePath = usePath(),
      $url = _usePath.$url,
      _pageSlug = _usePath.page._slug,
      partnership = _usePath.partnership,
      contact = _usePath.contact;

  var _useNavbar = useNavbar(),
      footerData = _useNavbar.footerNavbar;

  var t = useIntl();
  return __jsx("footer", {
    id: "__footer"
  }, __jsx(Container, {
    className: {
      wrapper: styles.container()
    },
    color: "dark"
  }, __jsx("div", {
    className: styles.main()
  }, __jsx("div", {
    className: styles.header({
      center: true
    })
  }, __jsx(Link, {
    "aria-label": t.formatMessage({
      id: "common.homePage",
      defaultMessage: "home page"
    }),
    href: $url(),
    passHref: true
  }, __jsx(Icon, {
    name: "Lova",
    color: "white"
  }))), __jsx("div", {
    className: styles.content()
  }, __jsx("div", null, __jsx("h5", {
    className: styles.sectionHeader()
  }, footerData.collections.title), __jsx("ul", null, footerData.isLoading ? [110, 150, 130, 140, 110].map(function (width, index) {
    return __jsx("li", {
      className: styles.navItem(),
      key: "footer-product-skeleton-".concat(index)
    }, __jsx(Skeleton, {
      width: width,
      height: 13,
      key: index
    }));
  }) : footerData.collections.items.map(function (collection) {
    return __jsx("li", {
      className: styles.navItem(),
      key: collection.id
    }, __jsx(Link, {
      href: collection.href,
      passHref: true,
      className: styles.link()
    }, collection.name));
  }))), __jsx("div", null, __jsx("h5", {
    className: styles.sectionHeader()
  }, footerData.pages.title), footerData.isLoading ? [150, 110, 130].map(function (width, index) {
    return __jsx("li", {
      className: styles.navItem(),
      key: "footer-page-skeleton-".concat(index)
    }, __jsx(Skeleton, {
      width: width,
      height: 13,
      key: index
    }));
  }) : __jsx("ul", null, __jsx("li", {
    className: styles.navItem(),
    key: "contact-form"
  }, __jsx(Link, {
    href: contact.$url(),
    passHref: true,
    className: styles.link()
  }, t.formatMessage({
    defaultMessage: "Contact form",
    id: "footerDefault.customerSupport.contactForm"
  }))), footerData.pages.items.map(function (page) {
    return __jsx("li", {
      className: styles.navItem(),
      key: page.id
    }, __jsx(Link, {
      href: _pageSlug(page.slug).$url(),
      passHref: true,
      className: styles.link()
    }, page.name));
  }))), __jsx("div", null, __jsx("h5", {
    className: styles.sectionHeader()
  }, footerData.contact.title), __jsx("ul", null, __jsx("li", {
    className: styles.navItem()
  }, footerData.contact.items.availability), __jsx("li", {
    className: styles.navItem()
  }, __jsx(Link, {
    href: "tel:".concat(footerData.contact.items.phone),
    passHref: true,
    className: styles.link()
  }, footerData.contact.items.phone)), __jsx("li", {
    className: styles.navItem()
  }, __jsx(Link, {
    href: "mailto:".concat(footerData.contact.items.email),
    passHref: true,
    className: styles.link()
  }, footerData.contact.items.email)), footerData.contact.items.pages.map(function (page) {
    return page.slug === "partnership" ? __jsx("li", {
      className: styles.navItem(),
      key: page.id
    }, __jsx(Link, {
      href: partnership.$url(),
      passHref: true,
      className: styles.link()
    }, page.name)) : __jsx("li", {
      className: styles.navItem(),
      key: page.id
    }, __jsx(Link, {
      href: _pageSlug(page.slug).$url(),
      passHref: true,
      className: styles.link()
    }, page.name));
  }), __jsx("li", {
    className: "".concat(styles.navItem(), " ").concat(styles.navItemSocials)
  }, footerData.contact.items.socials.map(function (social, index) {
    return social.url ? __jsx("a", {
      "aria-label": t.formatMessage({
        id: "footerDefault.social.aria",
        defaultMessage: "Follow us on {social}"
      }, {
        social: social.iconName
      }),
      href: social.url,
      target: "_blank",
      rel: "noopener noreferrer",
      key: "footer-contact-social-".concat(index)
    }, __jsx(Button, {
      "aria-label": t.formatMessage({
        id: "footerDefault.social.aria",
        defaultMessage: "Follow us on {social}"
      }, {
        social: social.iconName
      }),
      variant: "circle",
      color: "light",
      width: 35,
      height: 35
    }, __jsx(Icon, {
      name: social.iconName,
      className: styles.socialIcon()
    }))) : null;
  })), __jsx("li", null, __jsx("g:ratingbadge", {
    merchant_id: GOOGLE_MERCHANT_CENTER_ID
  })))), __jsx("div", null, __jsx("h5", {
    className: styles.sectionHeader()
  }, footerData.newsletter.title), __jsx(Newsletter, null))))));
};

Footer.displayName = "Footer";
export default Footer;