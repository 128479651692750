import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread({}, {
    "display": "none",
    "@media (min-width: 768px)": {
      "alignSelf": "stretch"
    },
    "@media (min-width: 1024px)": {
      "display": "block",
      "flex": "1 1 0%"
    }
  })),
  navList: _css(_objectSpread({}, {
    "display": "flex",
    "height": "100%",
    "alignItems": "center",
    "> :not([hidden]) ~ :not([hidden])": {
      "--tw-space-x-reverse": "0",
      "marginRight": "calc(2rem * var(--tw-space-x-reverse))",
      "marginLeft": "calc(2rem * calc(1 - var(--tw-space-x-reverse)))"
    }
  })),
  navItemContainer: _css(_objectSpread(_objectSpread({}, {
    "position": "relative",
    "display": "flex",
    "height": "100%",
    "alignItems": "center"
  }), {}, {
    variants: {
      hidden: {
        "true": _objectSpread({}, {
          "display": "none"
        })
      }
    }
  })),
  navItem: _css(_objectSpread(_objectSpread({}, {
    "position": "relative",
    "display": "inline-flex",
    "height": "100%",
    "width": "100%",
    "cursor": "pointer",
    "alignItems": "center",
    "transitionProperty": "all",
    "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "transitionDuration": "150ms",
    "animationDuration": "150ms"
  }), {}, {
    outline: "none",
    fontSize: "$3",
    letterSpacing: "0.25px",
    "&::after": _objectSpread(_objectSpread({}, {
      "position": "absolute",
      "bottom": "1.25rem",
      "left": "50%",
      "height": "0.375rem",
      "width": "0.375rem",
      "borderRadius": "9999px",
      "transitionProperty": "all",
      "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
      "transitionDuration": "150ms",
      "animationDuration": "150ms"
    }), {}, {
      content: "",
      transform: "translateX(-50%)"
    }),
    "&::before": _objectSpread(_objectSpread({}, {
      "width": "100%",
      "transitionProperty": "opacity",
      "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
      "transitionDuration": "150ms",
      "--tw-content": "attr(data-text)",
      "content": "var(--tw-content)",
      "animationDuration": "150ms"
    }), {}, {
      top: "50%",
      transform: "translateY(-50%)",
      width: "calc(100% + 1px)",
      position: "absolute",
      fontWeight: "$semibold",
      color: "$default",
      left: "0",
      opacity: 0,
      letterSpacing: "0"
    }),
    variants: {
      active: {
        "true": {
          color: "transparent",
          "&::after": {
            backgroundColor: "$default"
          },
          "&::before": {
            opacity: 1
          }
        },
        "false": {
          "&::after": {
            backgroundColor: "transparent"
          }
        }
      },
      highlighted: {
        "true": {
          color: "$default"
        }
      }
    }
  }))
};
export default styles;