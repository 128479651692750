import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from "react";

var SvgSupport = function SvgSupport(props) {
  return __jsx("svg", _extends({
    width: 62,
    height: 61,
    viewBox: "0 0 62 61",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("mask", {
    id: "mask0_3497_5861",
    style: {
      maskType: "luminance"
    },
    maskUnits: "userSpaceOnUse",
    x: 2,
    y: 3,
    width: 58,
    height: 54
  }, __jsx("path", {
    d: "M3 56.5V3.5H59V56.5H3Z",
    fill: "white",
    stroke: "white"
  })), __jsx("g", {
    mask: "url(#mask0_3497_5861)"
  }, __jsx("path", {
    d: "M27.3098 41.6458C21.5383 32.1844 17.8992 30.8762 16.7628 25.8852C15.4859 20.2973 18.576 15.6237 22.2661 14.4045C28.9826 12.1947 31.5746 19.6623 32.9791 25.0978C32.7493 20.7545 37.2184 11.42 44.1646 13.8203C49.6681 15.7253 51.3535 22.3673 45.863 29.8476C41.2023 36.2102 39.6829 38.6613 38.2016 41.2394",
    stroke: "url(#paint0_linear_3497_5861)",
    strokeWidth: 1.15054,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  }), __jsx("path", {
    d: "M59.0123 7.19096C59.0123 9.22684 57.3522 10.8819 55.2986 10.8819C53.245 10.8819 51.585 9.22686 51.585 7.19096C51.585 5.15503 53.245 3.5 55.2986 3.5C57.3522 3.5 59.0123 5.15505 59.0123 7.19096Z",
    fill: "url(#paint1_linear_3497_5861)",
    stroke: "url(#paint2_linear_3497_5861)"
  }), __jsx("path", {
    d: "M3.22734 45.9764C2.88258 47.3098 3.53379 48.7068 4.79791 49.2784C8.88392 51.158 16.2771 54.1424 19.6735 54.7901C23.4787 55.514 28.5096 55.9204 35.6857 56.3141C41.0869 56.6062 43.7173 55.3108 48.8758 52.1104C52.6938 49.7356 54.9921 47.2463 56.5755 45.4303C58.5546 43.1697 57.4438 41.8616 56.2179 42.0013C52.9492 42.357 49.6676 45.4303 46.7307 46.9162C43.7939 48.402 41.802 48.5037 39.6696 48.9227C35.2771 49.7864 30.8974 47.7289 26.2878 47.2971C29.7354 46.5859 32.7489 45.2906 36.1964 44.5794C37.0265 44.4016 38.3799 44.2111 39.0566 43.5634C39.6951 42.9538 39.5674 41.8616 38.3671 41.4679C36.5412 40.8837 34.5748 41.2013 32.6084 41.3282C30.0163 41.4933 27.4498 42.014 24.9727 42.8396C17.0688 45.4684 13.9787 46.1923 7.99011 43.8682C5.81942 43.03 3.78917 43.7539 3.21457 45.9891L3.22734 45.9764Z",
    stroke: "url(#paint3_linear_3497_5861)",
    strokeWidth: 1.15054,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  })), __jsx("defs", null, __jsx("linearGradient", {
    id: "paint0_linear_3497_5861",
    x1: 32.8512,
    y1: 8.98697,
    x2: 32.8512,
    y2: 41.6458,
    gradientUnits: "userSpaceOnUse"
  }, __jsx("stop", {
    stopColor: "#CF3161"
  }), __jsx("stop", {
    offset: 1,
    stopColor: "#DB7991"
  })), __jsx("linearGradient", {
    id: "paint1_linear_3497_5861",
    x1: 55.2986,
    y1: 1.67948,
    x2: 55.2986,
    y2: 11.3819,
    gradientUnits: "userSpaceOnUse"
  }, __jsx("stop", {
    stopColor: "#CF3161"
  }), __jsx("stop", {
    offset: 1,
    stopColor: "#DB7991"
  })), __jsx("linearGradient", {
    id: "paint2_linear_3497_5861",
    x1: 55.2986,
    y1: 1.67948,
    x2: 55.2986,
    y2: 11.3819,
    gradientUnits: "userSpaceOnUse"
  }, __jsx("stop", {
    stopColor: "#CF3161"
  }), __jsx("stop", {
    offset: 1,
    stopColor: "#DB7991"
  })), __jsx("linearGradient", {
    id: "paint3_linear_3497_5861",
    x1: 30.3968,
    y1: 38.7286,
    x2: 30.3968,
    y2: 56.3542,
    gradientUnits: "userSpaceOnUse"
  }, __jsx("stop", {
    stopColor: "#CF3161"
  }), __jsx("stop", {
    offset: 1,
    stopColor: "#DB7991"
  }))));
};

export default SvgSupport;