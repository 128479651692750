import _slicedToArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
import React from "react";
import { useIntl } from "react-intl";
import { buildDate } from "@/libraries/dayjs.library";
import styles from "./Timer.styles";

var Timer = function Timer(_ref) {
  var targetDate = _ref.targetDate,
      _ref$withLoader = _ref.withLoader,
      withLoader = _ref$withLoader === void 0 ? true : _ref$withLoader;
  var t = useIntl();

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      hidden = _React$useState2[0],
      setHidden = _React$useState2[1];

  var _React$useState3 = React.useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  }),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      timeRemaining = _React$useState4[0],
      setTimeRemaining = _React$useState4[1];

  React.useEffect(function () {
    var interval = setInterval(function () {
      var now = buildDate();
      var target = buildDate(targetDate);
      var diff = target.diff(now, "second");
      var daysRemaining = Math.floor(diff / 86400);
      var hoursRemaining = Math.floor(diff % 86400 / 3600);
      var minutesRemaining = Math.floor(diff % 86400 % 3600 / 60);
      var secondsRemaining = Math.floor(diff % 86400 % 3600 % 60);
      var payload = {
        days: daysRemaining,
        hours: hoursRemaining,
        minutes: minutesRemaining,
        seconds: secondsRemaining
      };
      setTimeRemaining(payload);
      var empty = Object.values(payload).every(function (value) {
        return value <= 0;
      });

      if (empty) {
        setHidden(true);
      }
    }, 1000);
    return function () {
      return clearInterval(interval);
    };
  }, [targetDate]);
  var empty = Object.values(timeRemaining).every(function (value) {
    return value <= 0;
  });
  var loading = withLoader && empty;
  return __jsx("div", {
    className: styles.timer({
      hidden: hidden
    })
  }, __jsx("div", {
    className: styles.column()
  }, loading ? __jsx("div", {
    className: styles.loader()
  }) : __jsx("p", {
    className: styles.number()
  }, timeRemaining.days), __jsx("span", {
    className: styles.caption()
  }, t.formatMessage({
    id: "timer.days",
    defaultMessage: "Days"
  }))), __jsx("div", {
    className: styles.column()
  }, loading ? __jsx("div", {
    className: styles.loader()
  }) : __jsx("p", {
    className: styles.number()
  }, timeRemaining.hours), __jsx("span", {
    className: styles.caption()
  }, t.formatMessage({
    id: "timer.hours",
    defaultMessage: "Hours"
  }))), __jsx("div", {
    className: styles.column()
  }, loading ? __jsx("div", {
    className: styles.loader()
  }) : __jsx("p", {
    className: styles.number()
  }, timeRemaining.minutes), __jsx("span", {
    className: styles.caption()
  }, t.formatMessage({
    id: "timer.minutes",
    defaultMessage: "Minutes"
  }))), __jsx("div", {
    className: styles.column()
  }, loading ? __jsx("div", {
    className: styles.loader()
  }) : __jsx("p", {
    className: styles.number()
  }, timeRemaining.seconds), __jsx("span", {
    className: styles.caption()
  }, t.formatMessage({
    id: "timer.seconds",
    defaultMessage: "Seconds"
  }))));
};

Timer.displayName = "Timer";
export default Timer;