import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _slicedToArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var __jsx = React.createElement;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React from "react";
import { ALLOWED_COOKIE_GROUPS_KEY } from "@/config/common.config";
import { IS_TESTING } from "@/config/test.config";
import { isEnvStaging, isProd } from "@/helpers/common.helpers";
import { useLocalStorage } from "@/hooks/useLocalStorage.hook";
import createContext from "@/libraries/react.library";
import { pushConsetConfiguration, pushGtmEvent } from "~/src/helpers/analytics.helper";
import { getConsentConfigurationBasedOnConsentTypes, getConsentTypesBasedOnCookieGroups } from "../cookieConsent.helper";
var INITIAL_STATE = {
  allowedCookieGroups: [],
  consentConfigured: false,
  setAllowedCookieGroups: function setAllowedCookieGroups() {
    return null;
  },
  setConsetConfigured: function setConsetConfigured() {
    return null;
  }
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_ALLOWED_COOKIE_GROUPS":
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          allowedCookieGroups: action.payload
        });
      }

    case "SET_CONSENT_CONFIGURED":
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          consentConfigured: action.payload
        });
      }

    default:
      return state;
  }
}

var _createContext = createContext(),
    _createContext2 = _slicedToArray(_createContext, 2),
    useContext = _createContext2[0],
    Provider = _createContext2[1];

export { useContext, Provider };
export var CookieConsent = function CookieConsent(props) {
  var _React$useReducer = React.useReducer(reducer, INITIAL_STATE),
      _React$useReducer2 = _slicedToArray(_React$useReducer, 2),
      state = _React$useReducer2[0],
      dispatch = _React$useReducer2[1];

  var _useLocalStorage = useLocalStorage(ALLOWED_COOKIE_GROUPS_KEY, null, {
    sync: true
  }),
      _useLocalStorage2 = _slicedToArray(_useLocalStorage, 2),
      storageAllowedCookieGroups = _useLocalStorage2[0],
      setStorageAllowedCookieGroups = _useLocalStorage2[1];

  var setAllowedCookieGroups = React.useCallback(function (allowedCookieGroups) {
    dispatch({
      type: "SET_ALLOWED_COOKIE_GROUPS",
      payload: allowedCookieGroups
    });
    setStorageAllowedCookieGroups(allowedCookieGroups);
  }, [setStorageAllowedCookieGroups]);
  var setConsentConfigured = React.useCallback(function (consentConfigured) {
    dispatch({
      type: "SET_CONSENT_CONFIGURED",
      payload: consentConfigured
    });
  }, [dispatch]);
  React.useEffect(function () {
    if (isProd && !isEnvStaging && !IS_TESTING && state.allowedCookieGroups && state.allowedCookieGroups.length > 0) {
      pushConsetConfiguration("update", getConsentConfigurationBasedOnConsentTypes(getConsentTypesBasedOnCookieGroups(state.allowedCookieGroups), "granted"));
      pushGtmEvent({
        event: "consent_configured"
      });
      setConsentConfigured(true);
    }
  }, [state.allowedCookieGroups, setConsentConfigured]);
  React.useEffect(function () {
    if (storageAllowedCookieGroups !== state.allowedCookieGroups) {
      dispatch({
        type: "SET_ALLOWED_COOKIE_GROUPS",
        payload: storageAllowedCookieGroups
      });
    }
  }, [state.allowedCookieGroups, storageAllowedCookieGroups]);
  var value = React.useMemo(function () {
    return _objectSpread(_objectSpread({}, state), {}, {
      setAllowedCookieGroups: setAllowedCookieGroups,
      setConsentConfigured: setConsentConfigured
    });
  }, [state, setAllowedCookieGroups, setConsentConfigured]);
  return __jsx(Provider, _extends({
    value: value
  }, props));
};