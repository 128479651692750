import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["color"];
var __jsx = React.createElement;
import * as React from "react";

var SvgLova = function SvgLova(_ref) {
  var _ref$color = _ref.color,
      color = _ref$color === void 0 ? "#1A1A1A" : _ref$color,
      props = _objectWithoutProperties(_ref, _excluded);

  return __jsx("svg", _extends({
    width: 56,
    height: 56,
    viewBox: "0 0 56 56",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("path", {
    d: "M38.321,41.788C36.779,45.492 35.737,47.973 35.194,49.229L34.048,51.89L30.726,44.061C30.311,43.077 29.805,42.419 29.21,42.089C28.614,41.759 27.993,41.594 27.345,41.594L26.684,41.632L26.684,42.021C27.216,42.072 27.623,42.238 27.908,42.516C28.193,42.795 28.497,43.31 28.822,44.061L33.329,54.805L33.678,54.805L39.254,41.788L38.321,41.788Z",
    fill: color
  }), __jsx("path", {
    d: "M7.587,53.833L14.483,53.833L14.483,54.611L5.839,54.611L5.839,44.12C5.839,43.46 5.748,42.971 5.567,42.653C5.385,42.336 5.023,42.177 4.479,42.177L4.09,42.177L4.09,41.788L5.839,41.788C6.318,41.788 6.677,41.85 6.917,41.973C7.156,42.097 7.328,42.32 7.431,42.644C7.535,42.967 7.586,43.46 7.586,44.12L7.586,53.834L7.587,53.833Z",
    fill: color
  }), __jsx("path", {
    d: "M25.946,44.742C26.438,45.778 26.684,46.932 26.684,48.2C26.684,49.469 26.438,50.622 25.946,51.659C25.455,52.695 24.752,53.51 23.838,54.108C22.925,54.702 21.867,55 20.664,55C19.461,55 18.4,54.703 17.486,54.108C16.573,53.51 15.87,52.695 15.378,51.659C14.887,50.622 14.64,49.469 14.64,48.2C14.64,46.932 14.887,45.778 15.378,44.742C15.87,43.706 16.573,42.89 17.486,42.293C18.4,41.699 19.458,41.399 20.664,41.399C21.869,41.399 22.925,41.699 23.838,42.293C24.752,42.89 25.455,43.706 25.946,44.742ZM24.742,48.2C24.742,46.97 24.579,45.906 24.255,45.004C23.932,44.105 23.462,43.41 22.848,42.915C22.231,42.424 21.505,42.177 20.663,42.177C19.821,42.177 19.092,42.424 18.476,42.915C17.861,43.41 17.392,44.105 17.068,45.004C16.744,45.906 16.581,46.971 16.581,48.2C16.581,49.43 16.744,50.495 17.068,51.397C17.392,52.296 17.861,52.991 18.476,53.483C19.092,53.977 19.819,54.221 20.663,54.221C21.507,54.221 22.231,53.976 22.848,53.483C23.462,52.991 23.932,52.296 24.255,51.397C24.579,50.495 24.742,49.43 24.742,48.2Z",
    fill: color
  }), __jsx("path", {
    d: "M50.91,54.222L50.91,54.611C50.678,54.675 50.419,54.709 50.133,54.709C49.512,54.709 48.896,54.536 48.286,54.193C47.679,53.85 47.193,53.181 46.831,52.184L46.344,50.92L40.906,50.92L39.351,54.611L38.476,54.611L43.917,41.788L44.5,41.788L48.774,52.184C49.098,52.934 49.402,53.449 49.687,53.728C49.973,54.006 50.381,54.171 50.91,54.222ZM45.955,50.142L43.625,44.51L41.198,50.142L45.955,50.142Z",
    fill: color
  }), __jsx("path", {
    d: "M44.284,17.819C44.284,26.919 37.015,34.351 27.975,34.622L27.973,34.624C27.804,34.629 27.635,34.635 27.466,34.635C18.194,34.635 10.649,27.089 10.649,17.818C10.649,8.546 18.194,1 27.465,1C36.737,1 44.283,8.544 44.283,17.818L44.284,17.819ZM25.84,33.262C25.697,33.074 25.561,32.885 25.433,32.692C21.402,26.713 22.35,19.209 26.647,14.364C25.393,13.068 23.854,12.003 22.068,11.278C19.612,10.258 16.845,9.982 14.207,10.481L14.205,10.481C13.987,10.572 13.714,10.744 13.493,11.048C12.496,13.095 11.938,15.394 11.938,17.819C11.938,25.832 18.04,32.447 25.841,33.263L25.84,33.262ZM42.996,17.818C42.996,15.458 42.467,13.222 41.521,11.217C41.205,10.673 40.743,10.476 40.482,10.406L40.477,10.406C35.609,9.618 31.235,11.397 28.285,14.478C32.401,19.184 33.627,26.412 29.493,32.689C29.366,32.882 29.23,33.074 29.092,33.262C36.894,32.446 42.996,25.831 42.996,17.818ZM27.515,15.351C23.981,19.705 23.064,26.116 26.898,31.681C26.978,31.793 27.059,31.906 27.144,32.016C27.146,32.018 27.149,32.021 27.151,32.026C27.364,32.258 27.563,32.286 27.924,31.836C27.961,31.789 28,31.74 28.04,31.684C28.051,31.668 28.06,31.651 28.072,31.637C28.082,31.621 28.093,31.604 28.105,31.587C31.705,26.525 31.119,19.872 27.515,15.351ZM14.672,9.029C14.667,9.036 14.665,9.041 14.66,9.048C14.231,9.831 15.092,9.581 15.352,9.56C15.401,9.555 15.422,9.56 15.422,9.569C20.149,8.96 24.396,10.639 27.415,13.563C29.359,11.675 31.868,10.294 34.825,9.715C36.565,9.391 38.368,9.368 40.108,9.675L40.11,9.675C40.37,9.675 40.572,9.574 40.317,9.107C40.312,9.101 40.307,9.093 40.303,9.086C37.504,4.984 32.793,2.288 27.465,2.288C22.137,2.288 17.471,4.961 14.672,9.029Z",
    fill: color
  }), __jsx("path", {
    d: "M27.465,8.912C28.628,8.912 29.568,7.972 29.568,6.809C29.568,5.646 28.628,4.708 27.465,4.708C26.302,4.708 25.364,5.648 25.364,6.809C25.364,7.97 26.304,8.912 27.465,8.912Z",
    fill: color
  }));
};

export default SvgLova;