import _slicedToArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
import React from "react";
import { NavbarDesktopSubmenuItem } from "@/components/ui/Navbar/components/NavbarDesktop/components";
import { useGlobal } from "@/hooks/useGlobal.hook";
import Skeleton from "~/src/components/ui/Skeleton/Skeleton.component";
import styles from "./NavbarDesktopSubmenu.styles";

var NavbarDesktopSubmenu = function NavbarDesktopSubmenu(_ref) {
  var subLinks = _ref.subLinks,
      show = _ref.show,
      submenuLevel = _ref.submenuLevel,
      isLoading = _ref.isLoading;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      nextLevelActive = _React$useState2[0],
      setNextLevelActive = _React$useState2[1];

  var _React$useState3 = React.useState(0),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      rootMaxHeight = _React$useState4[0],
      setRootMaxHeight = _React$useState4[1];

  var _useGlobal = useGlobal(),
      isPromoBarVisible = _useGlobal.promoBar.isVisible;

  var changeRootMaxHeight = React.useCallback(function () {
    var navElement = document.getElementById("__nav");

    if (navElement) {
      var windowHeight = window.innerHeight;
      var navElementHeight = navElement.getBoundingClientRect().height;
      var navElementTopPosition = navElement.getBoundingClientRect().top;
      setRootMaxHeight(windowHeight - (navElementHeight + navElementTopPosition + 30));
    }
  }, []);
  React.useEffect(function () {
    window.addEventListener("scroll", changeRootMaxHeight);
    window.addEventListener("resize", changeRootMaxHeight);
    return function () {
      window.removeEventListener("scroll", changeRootMaxHeight);
      window.removeEventListener("resize", changeRootMaxHeight);
    };
  }, [changeRootMaxHeight]);
  React.useEffect(function () {
    changeRootMaxHeight();
  }, [changeRootMaxHeight, isPromoBarVisible]);
  return show ? __jsx("div", {
    className: styles.root({
      secondLevelOrDeeper: submenuLevel >= 2,
      nextLevelActive: nextLevelActive
    })
  }, __jsx("div", {
    className: styles.listContainer({
      secondLevelOrDeeper: submenuLevel >= 2,
      nextLevelActive: nextLevelActive
    })
  }, __jsx("ul", {
    className: styles.list(),
    style: {
      maxHeight: "".concat(rootMaxHeight, "px")
    }
  }, !isLoading ? subLinks.map(function (deeperLevelSubLink, index) {
    return __jsx(NavbarDesktopSubmenuItem, {
      subLink: deeperLevelSubLink,
      key: index,
      submenuLevel: submenuLevel,
      setNextLevelActive: setNextLevelActive
    });
  }) : [130, 180, 140, 120, 170].map(function (width, index) {
    return __jsx("li", {
      className: styles.listLoadingItem(),
      key: index
    }, __jsx("span", {
      className: styles.listLoadingItemSkeletonContainer()
    }, __jsx(Skeleton, {
      width: width,
      height: 12
    })));
  })))) : null;
};

NavbarDesktopSubmenu.displayName = "NavbarDesktopSubmenu";
export default NavbarDesktopSubmenu;