import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../libraries/stitches.library.ts";
var styles = {
  imageSmallText: _css({
    fontSize: "1.4rem",
    "@media screen and (min-width: 400px)": {
      fontSize: "1.6rem"
    }
  }),
  imageLargeText: _css(_objectSpread(_objectSpread({}, {
    "paddingTop": "0.5rem",
    "paddingBottom": "0.5rem"
  }), {}, {
    fontSize: "2.5rem",
    lineHeight: "$1",
    "@media screen and (min-width: 400px)": _objectSpread(_objectSpread({}, {
      "paddingTop": "1.25rem",
      "paddingBottom": "1.25rem"
    }), {}, {
      fontSize: "3rem"
    }),
    "@media screen and (min-width: 500px)": {
      fontSize: "3.3rem"
    }
  })),
  imageLargeTextHighlighted: _css(_objectSpread(_objectSpread({}, {
    "position": "relative"
  }), {}, {
    "&::before": _objectSpread(_objectSpread({}, {
      "position": "absolute",
      "zIndex": "0",
      "display": "block",
      "height": "5rem",
      "width": "5rem",
      "borderRadius": "9999px",
      "--tw-content": "\"\"",
      "content": "var(--tw-content)"
    }), {}, {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "$dark",
      zIndex: -1
    }),
    "@media screen and (min-width: 400px)": {
      "&::before": _objectSpread({}, {
        "height": "7rem",
        "width": "7rem"
      })
    }
  })),
  imageMediumText: _css({
    fontSize: "1.5625rem",
    "@media screen and (min-width: 400px)": {
      fontSize: "1.875rem"
    }
  }),
  formContainer: _css(_objectSpread({}, {
    "textAlign": "left"
  })),
  formTitle: _css(_objectSpread(_objectSpread({}, {
    "marginBottom": "1.5rem",
    "textAlign": "center",
    "fontSize": "1.5rem",
    "lineHeight": "2rem"
  }), {}, {
    whiteSpace: "pre-wrap",
    fontFamily: "$tenor",
    color: "$white",
    fontSize: "1.8rem",
    lineHeight: "$2",
    "@media screen and (min-width: 400px)": {
      fontSize: "2.2rem"
    },
    "@lg": {
      whiteSpace: "initial"
    }
  })),
  form: _css(_objectSpread({}, {
    "> :not([hidden]) ~ :not([hidden])": {
      "--tw-space-y-reverse": "0",
      "marginTop": "calc(1.25rem * calc(1 - var(--tw-space-y-reverse)))",
      "marginBottom": "calc(1.25rem * var(--tw-space-y-reverse))"
    },
    "@media (min-width: 768px)": {
      "paddingLeft": "5rem",
      "paddingRight": "5rem"
    },
    "@media (min-width: 1024px)": {
      "paddingLeft": "0px",
      "paddingRight": "0px"
    },
    "@media (min-width: 1280px)": {
      "paddingLeft": "2.5rem",
      "paddingRight": "2.5rem"
    }
  })),
  formInput: _css({
    borderColor: "$white !important",
    "&::placeholder": {
      color: "$default !important"
    }
  }),
  formCheckbox: _css(_objectSpread({}, {
    "textAlign": "left !important"
  })),
  formCheckboxInput: _css({
    borderColor: "$white !important"
  }),
  formMessage: _css(_objectSpread(_objectSpread({}, {
    "marginTop": "0.5rem"
  }), {}, {
    fontSize: "$2",
    variants: {
      status: {
        error: {
          color: "$danger"
        },
        success: {
          color: "$success"
        }
      }
    }
  })),
  formButton: _css(_objectSpread(_objectSpread({}, {
    "width": "100%"
  }), {}, {
    "&>span": {
      fontSize: "$4 !important",
      fontWeight: "$bold",
      textTransform: "uppercase"
    }
  })),
  thankYouTitle: _css(_objectSpread(_objectSpread({}, {
    "marginBottom": "1.5rem",
    "textAlign": "center",
    "fontSize": "1.5rem",
    "lineHeight": "2rem"
  }), {}, {
    whiteSpace: "pre-wrap",
    fontFamily: "$tenor",
    color: "$white",
    fontSize: "1.8rem",
    lineHeight: "$2",
    "@media screen and (min-width: 400px)": {
      fontSize: "2.2rem"
    }
  })),
  thankYouDescription: _css({
    fontFamily: "$tenor",
    color: "$white",
    fontSize: "1.2rem"
  }),
  thankYouPromoCodeContainer: _css(_objectSpread(_objectSpread({}, {
    "marginTop": "1.5rem",
    "textAlign": "center"
  }), {}, {
    color: "$white"
  })),
  thankYouPromoCodeTitle: _css({
    textTransform: "uppercase",
    fontSize: "1.1rem"
  }),
  thankYouPromoCode: _css({
    fontSize: "1.6rem",
    fontWeight: "$semibold",
    lineBreak: "anywhere"
  }),
  tahnkYouButton: _css(_objectSpread(_objectSpread({}, {
    "marginTop": "1.5rem",
    "width": "100%"
  }), {}, {
    "&>span": {
      fontSize: "$4 !important",
      fontWeight: "$semibold",
      textTransform: "uppercase"
    }
  }))
};
export default styles;