import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from "react";

var SvgSparklingHeart = function SvgSparklingHeart(props) {
  return __jsx("svg", _extends({
    width: "24px",
    height: "24px",
    viewBox: "0 0 128 128",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("path", {
    d: "M93.96 8.96C72.05 8.96 64 31.34 64 31.34S56.06 8.96 33.99 8.96c-16.58 0-35.48 13.14-28.5 43.01c6.98 29.87 58.56 67.08 58.56 67.08s51.39-37.21 58.38-67.08c6.98-29.87-10.56-43.01-28.47-43.01z",
    fill: "#ef5592"
  }), __jsx("g", {
    fill: "#da2e75"
  }, __jsx("path", {
    d: "M30.61 11.19c17.2 0 25.74 18.49 28.5 25.98c.39 1.07 1.88 1.1 2.33.06l2.52-5.88C60.41 20 50.65 8.96 33.99 8.96c-6.9 0-14.19 2.28-19.86 7.09c5.01-3.29 10.88-4.86 16.48-4.86z"
  }), __jsx("path", {
    d: "M93.96 8.96c-5.29 0-9.77 1.54-13.53 3.85c2.64-1.02 5.56-1.62 8.8-1.62c16.21 0 30.72 12.29 24.17 40.7c-5.62 24.39-38.46 53.98-48.49 65.27c-.64.72-.86 1.88-.86 1.88s51.39-37.21 58.38-67.08c6.98-29.86-11.9-43-28.47-43z"
  })), __jsx("path", {
    d: "M17 24.82c3.75-4.68 10.45-8.55 16.13-4.09c3.07 2.41 1.73 7.35-1.02 9.43c-4 3.04-7.48 4.87-9.92 9.63c-1.46 2.86-2.34 5.99-2.79 9.18c-.18 1.26-1.83 1.57-2.45.46c-4.22-7.49-5.42-17.79.05-24.61z",
    fill: "#f386ab"
  }), __jsx("circle", {
    cx: 74.03,
    cy: 88.49,
    r: 3.6,
    fill: "#fdd835"
  }), __jsx("circle", {
    cx: 77.63,
    cy: 44.27,
    r: 4.28,
    fill: "#fff891"
  }), __jsx("path", {
    fill: "#fff891",
    d: "M83.09 74.258l6.937-6.937l6.937 6.937l-6.937 6.936z"
  }), __jsx("g", null, __jsx("path", {
    d: "M65.43 74.89l-7.81-2.52c-5-1.65-7.92-5.69-9.29-10.77L44.9 44.87c-.09-.33-.41-.56-.98-.56c-.57 0-.89.23-.98.56l-3.43 16.74c-1.38 5.08-4.3 9.12-9.29 10.77l-7.81 2.52c-1.11.36-1.12 1.92-.02 2.29l7.86 2.71c4.98 1.65 7.88 5.69 9.26 10.75l3.44 16.55c.09.33.4.55.98.55s.89-.23.98-.55l3.44-16.55c1.38-5.06 4.28-9.09 9.26-10.75l7.86-2.71c1.09-.38 1.07-1.94-.04-2.3z",
    fill: "#fdd835"
  }), __jsx("path", {
    d: "M66.18 75.59a1.15 1.15 0 0 0-.74-.7l-7.81-2.52c-5-1.65-7.92-5.69-9.29-10.77L44.9 44.87c-.05-.19-.19-.34-.39-.44l1.07 16.6c.82 7.67 1.53 11.28 7.65 12.33c5.25.91 11.33 1.95 12.95 2.23z",
    fill: "#fff176"
  }), __jsx("path", {
    d: "M66.19 76.46l-13.54 3.11c-4.77 1.21-7.4 3.98-7.4 11.2l-1.14 16.95c.46-.05.71-.25.79-.53l3.44-16.55c1.38-5.06 4.28-9.09 9.26-10.75l7.86-2.71c.38-.12.61-.4.73-.72z",
    fill: "#f4b400"
  })), __jsx("g", null, __jsx("path", {
    d: "M109.55 25.17c-6.09-2.01-6.64-4.28-7.67-8.09L99.33 5.07c-.15-.58-1.66-.58-1.82 0L95.78 16.4c-1.03 3.8-3.22 6.83-6.96 8.07l-6.69 2.59c-.83.27-.84 1.44-.02 1.72l6.73 2.12c3.73 1.24 5.91 4.26 6.94 8.05l1.73 10.96c.16.57 1.66.57 1.81 0l2.03-10.93c1.03-3.82 2.6-6.85 7.67-8.09l6.37-2.13c.83-.28.82-1.45-.01-1.72l-5.83-1.87z",
    fill: "#fdd835"
  }), __jsx("path", {
    d: "M99.82 16.95c.62 5.74.94 7.34 5.59 8.52l10.43 1.89a.903.903 0 0 0-.45-.32l-5.84-1.88c-5.34-1.83-6.7-3.92-7.81-8.79l-2.4-11.3a.526.526 0 0 0-.34-.38l.82 12.26z",
    fill: "#fff176"
  }), __jsx("path", {
    d: "M99.99 36.99c0-5.42 2.96-8.09 7.67-8.09l8.05-.3c-.1.14-.63.28-.82.35l-5.33 1.85c-4.46 1.2-6.41 2.55-7.67 8.09l-2.55 11.02c-.05.2-.18.33-.34.39l.99-13.31z",
    fill: "#f4b400"
  })));
};

export default SvgSparklingHeart;