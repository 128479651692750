import _slicedToArray from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
import { XMarkIcon } from "@heroicons/react/20/solid";
import React, { Fragment } from "react";
import { useIntl } from "react-intl";
import { Button, Spacer } from "@/components/ui";
import useNotification from "@/hooks/useNotification.hook";
import Icon from "./Notification.Icon";
import styles from "./Notification.styles";

var Item = function Item(_ref) {
  var notification = _ref.notification,
      offset = _ref.offset;
  var t = useIntl();

  var _useNotification = useNotification(),
      pauseNotificationTimer = _useNotification.pauseNotificationTimer,
      resumeNotificationTimer = _useNotification.resumeNotificationTimer;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      paused = _React$useState2[0],
      setPaused = _React$useState2[1];

  var handleMouseEnter = function handleMouseEnter() {
    setPaused(true);
    pauseNotificationTimer(notification);
  };

  var handleMouseLeave = function handleMouseLeave() {
    setPaused(false);
    resumeNotificationTimer(notification);
  };

  return __jsx(Fragment, null, __jsx(Spacer, {
    height: offset.top
  }), __jsx("div", {
    className: styles.wrapper({
      position: notification.position.justify,
      withoutOffset: !notification.position.witOffset
    })
  }, __jsx("div", {
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
    className: styles.item({
      size: notification.style.size || "small"
    })
  }, __jsx("div", {
    className: styles.progress(),
    style: {
      animationDuration: "".concat(notification.timeout - 250, "ms"),
      animationPlayState: paused ? "paused" : "running"
    }
  }), __jsx("div", {
    className: styles.padding()
  }, __jsx("div", {
    className: styles.inside()
  }, __jsx("div", {
    className: styles.status()
  }, __jsx(Icon, {
    status: notification.status,
    className: styles.icon({
      status: notification.status
    }),
    "aria-hidden": "true"
  })), __jsx("div", {
    className: styles.content()
  }, __jsx("p", {
    className: styles.message()
  }, notification.message)), __jsx("div", {
    className: styles.action()
  }, __jsx(Button, {
    "aria-label": t.formatMessage({
      id: "common.close",
      defaultMessage: "close"
    }),
    onClick: notification.onClose,
    variant: "naked",
    color: "dark"
  }, __jsx(XMarkIcon, {
    width: 24,
    height: 24,
    "aria-hidden": "true"
  }))))))), __jsx(Spacer, {
    height: offset.bottom
  }));
};

Item.displayName = "Item";
export default Item;