import React from "react";
import { GOOGLE_MERCHANT_CENTER_ID } from "~/src/config/googleMerchantCenter.config";
import { getGoogleMerchantCenterLanguageCode } from "~/src/helpers/language.helper";
import useRegion from "~/src/hooks/useRegion.hook";

var GoogleCustomerReviewsBadge = function GoogleCustomerReviewsBadge() {
  var _useRegion = useRegion(),
      currentLocale = _useRegion.currentLocale;

  React.useEffect(function () {
    return;
    var script = document.createElement("script");
    script.src = "https://apis.google.com/js/platform.js?onload=renderBadge";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    window.renderBadge = function () {
      var ratingBadgeContainer = document.createElement("div");
      document.body.appendChild(ratingBadgeContainer);
      window.gapi.load("ratingbadge", function () {
        window.gapi.ratingbadge.render(ratingBadgeContainer, {
          merchant_id: GOOGLE_MERCHANT_CENTER_ID,
          position: "INLINE"
        });
      });
    };

    return function () {
      document.body.removeChild(script);
    };
  }, []);
  React.useEffect(function () {
    return;
    var lang = getGoogleMerchantCenterLanguageCode(currentLocale);
    window.___gcfg = {
      lang: lang
    };
  }, [currentLocale]);
  return null;
};

export default GoogleCustomerReviewsBadge;