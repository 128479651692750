import { createShadow } from "./theme.shadows";
export var utils = {
  p: function p(value) {
    return {
      padding: value
    };
  },
  pt: function pt(value) {
    return {
      paddingTop: value
    };
  },
  pr: function pr(value) {
    return {
      paddingRight: value
    };
  },
  pb: function pb(value) {
    return {
      paddingBottom: value
    };
  },
  pl: function pl(value) {
    return {
      paddingLeft: value
    };
  },
  px: function px(value) {
    return {
      paddingLeft: value,
      paddingRight: value
    };
  },
  py: function py(value) {
    return {
      paddingTop: value,
      paddingBottom: value
    };
  },
  m: function m(value) {
    return {
      margin: value
    };
  },
  mt: function mt(value) {
    return {
      marginTop: value
    };
  },
  mr: function mr(value) {
    return {
      marginRight: value
    };
  },
  mb: function mb(value) {
    return {
      marginBottom: value
    };
  },
  ml: function ml(value) {
    return {
      marginLeft: value
    };
  },
  mx: function mx(value) {
    return {
      marginLeft: value,
      marginRight: value
    };
  },
  my: function my(value) {
    return {
      marginTop: value,
      marginBottom: value
    };
  },
  ta: function ta(value) {
    return {
      textAlign: value
    };
  },
  fd: function fd(value) {
    return {
      flexDirection: value
    };
  },
  fw: function fw(value) {
    return {
      flexWrap: value
    };
  },
  ai: function ai(value) {
    return {
      alignItems: value
    };
  },
  ac: function ac(value) {
    return {
      alignContent: value
    };
  },
  jc: function jc(value) {
    return {
      justifyContent: value
    };
  },
  as: function as(value) {
    return {
      alignSelf: value
    };
  },
  fg: function fg(value) {
    return {
      flexGrow: value
    };
  },
  fs: function fs(value) {
    return {
      flexShrink: value
    };
  },
  fb: function fb(value) {
    return {
      flexBasis: value
    };
  },
  bc: function bc(value) {
    return {
      backgroundColor: value
    };
  },
  br: function br(value) {
    return {
      borderRadius: value
    };
  },
  btrr: function btrr(value) {
    return {
      borderTopRightRadius: value
    };
  },
  bbrr: function bbrr(value) {
    return {
      borderBottomRightRadius: value
    };
  },
  bblr: function bblr(value) {
    return {
      borderBottomLeftRadius: value
    };
  },
  btlr: function btlr(value) {
    return {
      borderTopLeftRadius: value
    };
  },
  bs: function bs(value) {
    return {
      boxShadow: value
    };
  },
  lh: function lh(value) {
    return {
      lineHeight: value
    };
  },
  ox: function ox(value) {
    return {
      overflowX: value
    };
  },
  oy: function oy(value) {
    return {
      overflowY: value
    };
  },
  pe: function pe(value) {
    return {
      pointerEvents: value
    };
  },
  us: function us(value) {
    return {
      WebkitUserSelect: value,
      userSelect: value
    };
  },
  userSelect: function userSelect(value) {
    return {
      WebkitUserSelect: value,
      userSelect: value
    };
  },
  size: function size(value) {
    return {
      width: value,
      height: value
    };
  },
  appearance: function appearance(value) {
    return {
      WebkitAppearance: value,
      appearance: value
    };
  },
  backgroundClip: function backgroundClip(value) {
    return {
      WebkitBackgroundClip: value,
      backgroundClip: value
    };
  },
  shadow: function shadow(fvd, fbs, ft) {
    return {
      boxShadow: createShadow(fvd, fbs, ft)
    };
  }
};
export default utils;