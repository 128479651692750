import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["color"];
var __jsx = React.createElement;
import * as React from "react";

var SvgLovaHorizontal = function SvgLovaHorizontal(_ref) {
  var _ref$color = _ref.color,
      color = _ref$color === void 0 ? "#1A1A1A" : _ref$color,
      props = _objectWithoutProperties(_ref, _excluded);

  return __jsx("svg", _extends({
    width: 82,
    height: 32,
    viewBox: "0 0 82 32",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("path", {
    d: "M30.707,16.001C30.707,24.118 24.224,30.746 16.161,30.988L16.159,30.99C16.009,30.994 15.858,31 15.707,31C7.437,31 0.707,24.269 0.707,16C0.707,7.731 7.437,1 15.706,1C23.976,1 30.706,7.728 30.706,16L30.707,16.001ZM14.257,29.775C14.13,29.608 14.008,29.439 13.893,29.267C10.298,23.934 11.144,17.241 14.977,12.92C13.858,11.763 12.486,10.814 10.892,10.167C8.702,9.257 6.234,9.011 3.881,9.456L3.879,9.456C3.685,9.538 3.442,9.691 3.244,9.962C2.355,11.788 1.857,13.838 1.857,16.001C1.857,23.148 7.299,29.048 14.258,29.776L14.257,29.775ZM29.559,16C29.559,13.896 29.086,11.901 28.243,10.113C27.961,9.628 27.549,9.452 27.316,9.389L27.312,9.389C22.97,8.686 19.069,10.273 16.438,13.022C20.109,17.219 21.202,23.665 17.515,29.264C17.402,29.436 17.281,29.608 17.157,29.775C24.116,29.047 29.559,23.147 29.559,16ZM15.751,13.8C12.599,17.684 11.781,23.402 15.2,28.365C15.271,28.465 15.344,28.565 15.42,28.664C15.422,28.665 15.424,28.668 15.426,28.672C15.616,28.88 15.794,28.904 16.116,28.503C16.149,28.462 16.183,28.417 16.219,28.367C16.229,28.353 16.237,28.338 16.248,28.326C16.256,28.311 16.266,28.296 16.277,28.282C19.488,23.766 18.965,17.832 15.751,13.8ZM4.296,8.161C4.291,8.167 4.29,8.172 4.285,8.178C3.902,8.877 4.67,8.653 4.902,8.635C4.946,8.63 4.965,8.635 4.965,8.643C9.181,8.099 12.969,9.597 15.661,12.205C17.395,10.521 19.633,9.29 22.271,8.773C23.823,8.484 25.431,8.464 26.983,8.737L26.985,8.737C27.217,8.737 27.397,8.647 27.169,8.231C27.165,8.225 27.16,8.218 27.156,8.212C24.66,4.554 20.459,2.149 15.706,2.149C10.954,2.149 6.792,4.533 4.296,8.161Z",
    fill: color
  }), __jsx("path", {
    d: "M15.706,8.057C16.744,8.057 17.582,7.218 17.582,6.181C17.582,5.144 16.744,4.307 15.706,4.307C14.669,4.307 13.832,5.146 13.832,6.181C13.832,7.217 14.671,8.057 15.706,8.057Z",
    fill: color
  }), __jsx("path", {
    d: "M39.735,21.949L46.271,21.949L46.271,22.685L38.078,22.685L38.078,12.743C38.078,12.117 37.992,11.654 37.821,11.353C37.648,11.052 37.305,10.901 36.79,10.901L36.421,10.901L36.421,10.533L38.078,10.533C38.532,10.533 38.872,10.592 39.1,10.708C39.326,10.826 39.49,11.037 39.587,11.344C39.686,11.651 39.734,12.117 39.734,12.743L39.734,21.95L39.735,21.949Z",
    fill: color
  }), __jsx("path", {
    d: "M57.135,13.332C57.601,14.315 57.834,15.408 57.834,16.61C57.834,17.813 57.601,18.906 57.135,19.888C56.669,20.87 56.003,21.643 55.137,22.209C54.272,22.772 53.269,23.055 52.128,23.055C50.988,23.055 49.983,22.773 49.117,22.209C48.251,21.643 47.585,20.87 47.119,19.888C46.654,18.906 46.42,17.813 46.42,16.61C46.42,15.408 46.654,14.315 47.119,13.332C47.585,12.35 48.251,11.578 49.117,11.012C49.983,10.448 50.986,10.164 52.128,10.164C53.271,10.164 54.272,10.448 55.137,11.012C56.003,11.578 56.669,12.35 57.135,13.332ZM55.994,16.61C55.994,15.444 55.84,14.436 55.533,13.581C55.226,12.729 54.781,12.07 54.198,11.601C53.614,11.135 52.926,10.901 52.128,10.901C51.33,10.901 50.639,11.135 50.055,11.601C49.473,12.07 49.028,12.729 48.721,13.581C48.414,14.436 48.259,15.445 48.259,16.61C48.259,17.776 48.414,18.785 48.721,19.64C49.028,20.492 49.473,21.151 50.055,21.617C50.639,22.085 51.328,22.316 52.128,22.316C52.927,22.316 53.614,22.084 54.198,21.617C54.781,21.151 55.226,20.492 55.533,19.64C55.84,18.785 55.994,17.776 55.994,16.61Z",
    fill: color
  }), __jsx("path", {
    d: "M68.864,10.533C67.402,14.044 66.415,16.394 65.899,17.585L64.813,20.107L61.665,12.687C61.272,11.755 60.793,11.131 60.228,10.818C59.663,10.505 59.075,10.349 58.461,10.349C58.338,10.349 58.219,10.355 58.102,10.367C57.985,10.38 57.897,10.385 57.834,10.385L57.834,10.753C58.338,10.802 58.725,10.959 58.994,11.223C59.264,11.487 59.552,11.975 59.86,12.687L64.132,22.87L64.463,22.87L69.747,10.533L68.864,10.533Z",
    fill: color
  }), __jsx("path", {
    d: "M80.795,22.317L80.795,22.685C80.575,22.747 80.329,22.778 80.058,22.778C79.47,22.778 78.886,22.615 78.308,22.29C77.733,21.965 77.272,21.33 76.928,20.385L76.467,19.187L71.313,19.187L69.84,22.685L69.01,22.685L74.167,10.533L74.72,10.533L78.77,20.385C79.077,21.097 79.366,21.585 79.636,21.849C79.907,22.112 80.293,22.269 80.795,22.317ZM76.099,18.451L73.89,13.112L71.59,18.451L76.099,18.451Z",
    fill: color
  }));
};

export default SvgLovaHorizontal;