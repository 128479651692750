import _extends from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["color"];
var __jsx = React.createElement;
import * as React from "react";

var SvgAccent = function SvgAccent(_ref) {
  var _ref$color = _ref.color,
      color = _ref$color === void 0 ? "black" : _ref$color,
      props = _objectWithoutProperties(_ref, _excluded);

  return __jsx("svg", _extends({
    width: 146,
    height: 34,
    viewBox: "0 0 146 34",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("path", {
    opacity: 0.9,
    d: "M144.75 17C144.75 18.0966 144.277 19.1863 143.339 20.2617C142.399 21.3387 141.001 22.3896 139.181 23.3985C135.542 25.4163 130.258 27.2415 123.708 28.7774C110.612 31.8483 92.507 33.75 72.5 33.75C52.493 33.75 34.3881 31.8483 21.2918 28.7774C14.7417 27.2415 9.45836 25.4163 5.81862 23.3985C3.99868 22.3896 2.60122 21.3387 1.6613 20.2617C0.722812 19.1863 0.25 18.0966 0.25 17C0.25 15.9034 0.722812 14.8137 1.6613 13.7383C2.60122 12.6613 3.99868 11.6104 5.81862 10.6015C9.45836 8.58368 14.7417 6.75847 21.2918 5.22258C34.3881 2.15174 52.493 0.25 72.5 0.25C92.507 0.25 110.612 2.15174 123.708 5.22258C130.258 6.75847 135.542 8.58368 139.181 10.6015C141.001 11.6104 142.399 12.6613 143.339 13.7383C144.277 14.8137 144.75 15.9034 144.75 17Z",
    stroke: color,
    strokeWidth: 0.5
  }));
};

export default SvgAccent;