import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread({}, {
    "position": "relative"
  })),
  input: _css(_objectSpread(_objectSpread({}, {
    "scrollbarWidth": "none",
    "MsOverflowStyle": "none",
    "&::-webkit-scrollbar": {
      "display": "none"
    },
    "marginTop": "0.25rem",
    "display": "block",
    "width": "100%",
    "borderRadius": "0.5rem",
    "borderWidth": "1px",
    "paddingLeft": "1.5rem",
    "paddingRight": "1.5rem",
    "paddingTop": "0.75rem",
    "paddingBottom": "0.75rem",
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem"
  }), {}, {
    variants: {
      variant: {
        dark: {
          backgroundColor: "$gray8",
          borderColor: "$gray7",
          color: "$gray4",
          "&:focus": _objectSpread({}, {
            "--tw-border-opacity": "1",
            "borderColor": "hsl(344 51% 43% / var(--tw-border-opacity))",
            "outline": "2px solid transparent",
            "outlineOffset": "2px",
            "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
            "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
            "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
            "--tw-ring-opacity": "1",
            "--tw-ring-color": "hsl(344 51% 43% / var(--tw-ring-opacity))"
          }),
          "&::placeholder": {
            color: "$gray4"
          }
        },
        light: {
          borderColor: "$gray2",
          color: "$gray8",
          "&:focus": _objectSpread({}, {
            "--tw-border-opacity": "1",
            "borderColor": "hsl(344 51% 43% / var(--tw-border-opacity))",
            "outline": "2px solid transparent",
            "outlineOffset": "2px",
            "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
            "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
            "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
            "--tw-ring-opacity": "1",
            "--tw-ring-color": "hsl(344 51% 43% / var(--tw-ring-opacity))"
          }),
          "&::placeholder": {
            color: "$gray5"
          }
        }
      },
      warning: {
        "true": {
          borderColor: "$information",
          "&:focus": _objectSpread({}, {
            "--tw-border-opacity": "1",
            "borderColor": "hsl(218 100% 64% / var(--tw-border-opacity))",
            "outline": "2px solid transparent",
            "outlineOffset": "2px",
            "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
            "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
            "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
            "--tw-ring-opacity": "1",
            "--tw-ring-color": "hsl(218 100% 64% / var(--tw-ring-opacity))"
          })
        }
      },
      error: {
        "true": {
          borderColor: "$danger",
          "&:focus": _objectSpread({}, {
            "--tw-border-opacity": "1",
            "borderColor": "hsl(357 75% 44% / var(--tw-border-opacity))",
            "outline": "2px solid transparent",
            "outlineOffset": "2px",
            "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
            "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
            "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
            "--tw-ring-opacity": "1",
            "--tw-ring-color": "hsl(357 75% 44% / var(--tw-ring-opacity))"
          })
        }
      },
      disabled: {
        "true": _objectSpread(_objectSpread({}, {
          "pointerEvents": "none"
        }), {}, {
          backgroundColor: "$gray1",
          borderColor: "$gray2",
          color: "$gray5",
          "&:focus": _objectSpread({}, {
            "--tw-border-opacity": "1",
            "borderColor": "hsl(344 51% 43% / var(--tw-border-opacity))",
            "outline": "2px solid transparent",
            "outlineOffset": "2px",
            "--tw-ring-opacity": "1",
            "--tw-ring-color": "hsl(344 51% 43% / var(--tw-ring-opacity))"
          })
        })
      }
    }
  })),
  info: _css(_objectSpread({}, {
    "display": "flex",
    "alignItems": "center",
    "justifyContent": "space-between"
  })),
  label: _css(_objectSpread(_objectSpread({}, {
    "display": "block",
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem",
    "fontWeight": "500"
  }), {}, {
    color: "$gray7"
  })),
  text: _css(_objectSpread(_objectSpread({}, {
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem"
  }), {}, {
    color: "$danger",
    variants: {
      warning: {
        "true": {
          color: "$information"
        }
      }
    }
  }))
};
export default styles;