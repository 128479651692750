import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../libraries/stitches.library.ts";
var styles = {
  slider: _css({
    "& > .swiper-wrapper": {
      height: "calc(100% - 57px)"
    }
  }),
  navigationContainer: _css(_objectSpread({}, {
    "marginLeft": "auto",
    "marginRight": "auto",
    "display": "flex",
    "maxWidth": "32rem",
    "justifyContent": "space-between",
    "paddingTop": "2rem"
  })),
  paginationContainer: _css(_objectSpread(_objectSpread({}, {
    "display": "none"
  }), {}, {
    "@md": _objectSpread({}, {
      "display": "flex",
      "alignItems": "center"
    })
  })),
  itemRoot: _css(_objectSpread(_objectSpread({}, {
    "zIndex": "1",
    "display": "grid",
    "height": "100%",
    "gridTemplateColumns": "repeat(8, minmax(0, 1fr))",
    "overflow": "hidden",
    "borderRadius": "1rem",
    "gridTemplateRows": "min-content"
  }), {}, {
    backgroundColor: "$lighter",
    height: "auto",
    "@lg": _objectSpread(_objectSpread({}, {
      "gridTemplateRows": "auto"
    }), {}, {
      height: "100%"
    })
  })),
  imageContainer: _css(_objectSpread(_objectSpread({}, {
    "position": "relative",
    "gridColumn": "span 8 / span 8",
    "aspectRatio": "1"
  }), {}, {
    "@lg": _objectSpread(_objectSpread({}, {
      "gridColumn": "span 4 / span 4"
    }), {}, {
      aspectRatio: "auto",
      minHeight: "20rem"
    })
  })),
  infoContainer: _css(_objectSpread(_objectSpread({}, {
    "gridColumn": "span 8 / span 8",
    "display": "flex",
    "flexDirection": "column",
    "padding": "1rem"
  }), {}, {
    variants: {
      reversed: {
        "true": {
          "@lg": _objectSpread({}, {
            "order": "-1"
          })
        }
      }
    },
    "@md": _objectSpread({}, {
      "padding": "2rem"
    }),
    "@lg": _objectSpread(_objectSpread({}, {
      "gridColumn": "span 4 / span 4",
      "justifyContent": "center"
    }), {}, {
      height: "100%"
    })
  })),
  name: _css({
    fontSize: "$6",
    lineHeight: "$4",
    "@sm": {
      fontSize: "2.5rem"
    },
    variants: {
      font: {
        tenor: {
          fontFamily: "$tenor"
        },
        inria: {
          fontFamily: "$inria"
        }
      }
    }
  }),
  description: _css(_objectSpread(_objectSpread({}, {
    "marginTop": "1rem"
  }), {}, {
    lineHeight: "$9",
    color: "$gray7",
    "& a": _objectSpread(_objectSpread({}, {
      "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke",
      "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
      "transitionDuration": "300ms",
      "animationDuration": "300ms",
      "animationTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)"
    }), {}, {
      color: "$default",
      "&:hover": {
        color: "$dark"
      }
    })
  }))
};
export default styles;