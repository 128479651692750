import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread(_objectSpread({}, {
    "position": "absolute",
    "borderWidth": "1px",
    "--tw-shadow": "0 1px 2px 0 rgb(0 0 0 / 0.05)",
    "--tw-shadow-colored": "0 1px 2px 0 var(--tw-shadow-color)",
    "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
    "transitionProperty": "all",
    "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "transitionDuration": "200ms",
    "animationDuration": "200ms"
  }), {}, {
    borderColor: "$gray2",
    borderTop: "none",
    left: "-20px",
    top: "calc(100% + 1px)",
    minWidth: "320px",
    backgroundColor: "$white",
    variants: {
      secondLevelOrDeeper: {
        "true": _objectSpread(_objectSpread({}, {
          "top": "0px",
          "zIndex": "31"
        }), {}, {
          height: "calc(100% + 1px)",
          left: "calc(100% - 11px)"
        })
      },
      nextLevelActive: {
        "true": {}
      }
    },
    compoundVariants: [{
      secondLevelOrDeeper: true,
      nextLevelActive: true,
      css: _objectSpread({}, {
        "borderBottomLeftRadius": "0",
        "borderBottomRightRadius": "0.5rem"
      })
    }, {
      secondLevelOrDeeper: true,
      nextLevelActive: false,
      css: _objectSpread({}, {
        "borderBottomLeftRadius": "0",
        "borderBottomRightRadius": "0.5rem"
      })
    }, {
      secondLevelOrDeeper: false,
      nextLevelActive: false,
      css: _objectSpread({}, {
        "borderBottomLeftRadius": "0.5rem",
        "borderBottomRightRadius": "0.5rem"
      })
    }]
  })),
  listContainer: _css(_objectSpread(_objectSpread({}, {
    "overflow": "hidden"
  }), {}, {
    variants: {
      secondLevelOrDeeper: {
        "true": {}
      },
      nextLevelActive: {
        "true": {}
      }
    },
    compoundVariants: [{
      secondLevelOrDeeper: true,
      nextLevelActive: true,
      css: _objectSpread({}, {
        "borderBottomLeftRadius": "0",
        "borderBottomRightRadius": "0.5rem"
      })
    }, {
      secondLevelOrDeeper: true,
      nextLevelActive: false,
      css: _objectSpread({}, {
        "borderBottomLeftRadius": "0",
        "borderBottomRightRadius": "0.5rem"
      })
    }, {
      secondLevelOrDeeper: false,
      nextLevelActive: false,
      css: _objectSpread({}, {
        "borderBottomLeftRadius": "0.5rem",
        "borderBottomRightRadius": "0.5rem"
      })
    }]
  })),
  list: _css(_objectSpread({}, {
    "height": "100%",
    "overflowY": "auto"
  })),
  listLoadingItem: _css(_objectSpread(_objectSpread({}, {
    "paddingLeft": "1.25rem"
  }), {}, {
    "&:first-child": {
      "&>span": _objectSpread({}, {
        "paddingTop": "1.25rem"
      })
    },
    "&:last-child": {
      "&>span": _objectSpread({}, {
        "paddingBottom": "1.25rem"
      })
    }
  })),
  listLoadingItemSkeletonContainer: _css(_objectSpread({}, {
    "display": "inline-flex",
    "height": "100%",
    "width": "100%",
    "alignItems": "center",
    "paddingTop": "1rem",
    "paddingBottom": "1rem",
    "paddingLeft": "0px",
    "paddingRight": "1.25rem"
  }))
};
export default styles;