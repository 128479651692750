import _defineProperty from "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.19.0_@playwright+test@1.45.0_babel-plugin-macros@3.1.0_react-dom@18_tg5qc6johhy5626memahkbijai/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { css as _css } from "../../../../libraries/stitches.library.ts";
var styles = {
  root: _css(_objectSpread(_objectSpread({}, {
    "position": "relative",
    "display": "grid",
    "height": "100%",
    "width": "100%",
    "overflow": "auto",
    "@media (min-width: 1024px)": {
      "gridTemplateColumns": "repeat(2, minmax(0, 1fr))"
    }
  }), {}, {
    variants: {
      color: {
        "default": {
          backgroundColor: "$default"
        },
        light: {
          backgroundColor: "#DB7991"
        },
        lighter: {
          backgroundColor: "$lighten"
        }
      }
    }
  })),
  closeButton: _css(_objectSpread({}, {
    "position": "absolute",
    "right": "0.5rem",
    "top": "0.5rem",
    "zIndex": "10",
    "marginLeft": "auto",
    "cursor": "pointer"
  })),
  closeButtonIcon: _css(_objectSpread(_objectSpread({}, {
    "zIndex": "1",
    "height": "2rem",
    "width": "2rem"
  }), {}, {
    variants: {
      color: {
        "default": {
          color: "$default"
        },
        white: {
          color: "$white"
        },
        gray: {
          color: "$gray9"
        }
      }
    }
  }))
};
export default styles;