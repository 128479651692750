var __jsx = React.createElement;
import React from "react";
import { cn } from "@/libraries/clsx.library";
import styles from "./Card.styles";

var Card = function Card(_ref) {
  var children = _ref.children,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? "light" : _ref$color,
      _ref$rounded = _ref.rounded,
      rounded = _ref$rounded === void 0 ? false : _ref$rounded,
      _ref$withBorder = _ref.withBorder,
      withBorder = _ref$withBorder === void 0 ? false : _ref$withBorder,
      _ref$withShadow = _ref.withShadow,
      withShadow = _ref$withShadow === void 0 ? false : _ref$withShadow,
      className = _ref.className;
  var rootStyles = styles.root({
    color: color,
    rounded: rounded,
    withBorder: withBorder,
    withShadow: withShadow
  });
  return __jsx("div", {
    className: cn(rootStyles, className)
  }, children);
};

Card.displayName = "Card";
export default Card;